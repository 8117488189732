.dash-navbar-brand {
    width: 280px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dash-navbar-right {
    width: 160px;
}

.dash-logo {
    height: 42px !important;
    display: inline; 
    vertical-align: middle;
    margin-right: 8px;
    margin-left: 8px;
}

.dash-header {
    display: inline; 
    vertical-align: middle;
    margin-top: 6px;
}

.dash-zone-header {
    text-align: center;

    .dash-zone-header-text {
        height: 50px; 
        padding: 10px;
        font-weight: 500;
    }
}

.dash-zone-item-card {
    margin-bottom: 6px;
    max-height: calc((100vh - 200px) / 6);

    .body {        
        padding-top: 1vh !important;
        padding-bottom: 1vh !important;
    }

    .dash-zone-item {
        .dash-zone-item-header-container {
            display: flex;
            align-items: center;
        }
    
        .dash-zone-item-header {
            font-size: 2.8vh;
        }

        .dash-zone-item-icon {
            font-size: 3vh;
        }

        .dash-zone-item-container {
            display: flex;
            align-items: center;
        }
    
        .dash-zone-item-text-container {
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
        }
    
        .dash-zone-item-text {
            font-size: 3.5vh;
            font-weight: bold;
            display: flex;
            justify-content: flex-end;
            line-height: 3.5vh;
        }
        
        .dash-zone-item-unit {
            font-size: 2vh;
            font-weight: bold;
            margin-left: 6px;
            margin-bottom: -0.5vh;
        }
    }
    
}

.dash-zone-item-card-ec-ph {
    max-height: calc((100vh - 200px) / 3);
    min-height: fit-content;
}

.dash-zone-item-card-luminance-light {
    min-height: fit-content;
    .light-control-container-title {
        display: flex;
        align-items: center; 
        gap: 1rem; 
    }

    .light-control-container-control {
        display: flex;
        align-items: center; 
        gap: 1rem; 
        margin-top: 10px;

        .label {
            font-size: 1.2rem;
        }
    }     

    .body {
        padding-bottom: 0 !important;
    }
}


.dash-zone-item-card-control {
    background-color: #e9ecef;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;

    .title {
        font-size: 1.2rem;
        padding-top: 5px;
    }

    .form-check-label.left-label,
    .form-check-label.right-label {
        color: rgb(90, 90, 90)
    }


    .form-check input[type="checkbox"]:disabled ~ .form-check-label.left-label,
    .form-check input[type="checkbox"]:disabled ~ .form-check-label.right-label {
        color: rgb(90, 90, 90);
        opacity: 1;
    }
}

.dash-zone-item-card-control-ph-ec-pump {
    padding-bottom: 20px;
}

.automatic-switch { 
    display: flex;
    align-items: center;
    .form-check-label {
        font-size: 1.2rem;
    }
}

.automatic-switch .form-check-input {
    width: 53px;         /* Adjust width as needed */
    height: 26px;        /* Adjust height as needed */
    background-color: #ccc; /* Default "off" color */
    border-radius: 15px;
    position: relative;
    transition: background-color 0.3s, transform 0.3s;
}
  
.automatic-switch .form-check-input:checked {
    background-color: green; /* "On" color */
}

.automatic-switch .form-check-input::before {
    content: "";
    position: absolute;
    width: 22px;          /* Adjust size of the toggle circle */
    height: 22px;
    background-color: white;
    border-radius: 50%;
    top: 1px;
    left: 2px;
    transition: transform 0.3s;
}

.automatic-switch .form-check-input:checked::before {
    transform: translateX(25px); /* Adjust based on the width of the switch */
}

.automatic-switch .form-check-label {
    margin-left: 10px;
    padding-top: 4px;
}

.on-off-switch { 
    display: flex;
    align-items: center;
}

.on-off-switch .form-check-input {
    width: 53px;         /* Adjust width as needed */
    height: 26px;        /* Adjust height as needed */
    background-color: #ccc; /* Default "off" color */
    border-radius: 15px;
    position: relative;
    transition: background-color 0.3s, transform 0.3s;
}
  
.on-off-switch .form-check-input:checked {
    background-color: green; /* "On" color */
}

.on-off-switch .form-check-input::before {
    content: "";
    position: absolute;
    width: 22px;          /* Adjust size of the toggle circle */
    height: 22px;
    background-color: white;
    border-radius: 50%;
    top: 1px;
    left: 2px;
    transition: transform 0.3s;
}

.on-off-switch .form-check-input:checked::before {
    transform: translateX(25px); /* Adjust based on the width of the switch */
}

.on-off-switch .form-check-label {
    margin-left: 10px;
    padding-top: 4px;
}

.on-off-switch {
    display: flex;
    align-items: center; /* Center-align items vertically */
    justify-content: space-between; /* Distribute space between the left and right labels */

    .form-check-input {
        margin-left: 0;
    }

    .left-label {
        text-align: right;
        font-size: 1.2rem;
        margin-right: 10px;
    }
      
    .right-label {
        text-align: left;
        font-size: 1.2rem;
        margin-left: 10px;
    }
}

input[type="time"] {
    border: 2px solid #00bdaa;
    border-radius: 5px;
    padding: 5px;
    font-size: 16px;
    color: #333;
    outline: none;
}

.pump-control-container-title {
    display: flex;
    align-items: center; 
    gap: 1rem; 

    .title {
        min-width: 100px;
    }
}

.dash-zone-item-card-ec-ph-mix-pump {
    min-height: fit-content;     

    .body {
        padding-bottom: 0 !important;
    }    

    .slider {
        margin-top: 10px;
    }
}

.slider-component-container {
    width: 95%;

    .slider-title {
        font-size: 1.2rem;
        margin-bottom: 6px;
    }
}
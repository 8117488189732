/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
 @import url("https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700");
 @import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
 
 :root {
     --bs-blue: #0d6efd;
     --bs-indigo: #6610f2;
     --bs-purple: #6f42c1;
     --bs-pink: #d63384;
     --bs-red: #dc3545;
     --bs-orange: #fd7e14;
     --bs-yellow: #ffc107;
     --bs-green: #198754;
     --bs-teal: #20c997;
     --bs-cyan: #0dcaf0;
     --bs-white: #fff;
     --bs-gray: #6c757d;
     --bs-gray-dark: #343a40;
     --bs-gray-100: #f8f9fa;
     --bs-gray-200: #e9ecef;
     --bs-gray-300: #dee2e6;
     --bs-gray-400: #ced4da;
     --bs-gray-500: #adb5bd;
     --bs-gray-600: #6c757d;
     --bs-gray-700: #495057;
     --bs-gray-800: #343a40;
     --bs-gray-900: #212529;
     --bs-primary: #0d6efd;
     --bs-secondary: #6c757d;
     --bs-success: #198754;
     --bs-info: #0dcaf0;
     --bs-warning: #ffc107;
     --bs-danger: #dc3545;
     --bs-light: #f8f9fa;
     --bs-dark: #212529;
     --bs-primary-rgb: 13, 110, 253;
     --bs-secondary-rgb: 108, 117, 125;
     --bs-success-rgb: 25, 135, 84;
     --bs-info-rgb: 13, 202, 240;
     --bs-warning-rgb: 255, 193, 7;
     --bs-danger-rgb: 220, 53, 69;
     --bs-light-rgb: 248, 249, 250;
     --bs-dark-rgb: 33, 37, 41;
     --bs-white-rgb: 255, 255, 255;
     --bs-black-rgb: 0, 0, 0;
     --bs-body-color-rgb: 33, 37, 41;
     --bs-body-bg-rgb: 255, 255, 255;
     --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
     --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
     --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
     --bs-body-font-family: var(--bs-font-sans-serif);
     --bs-body-font-size: 1rem;
     --bs-body-font-weight: 400;
     --bs-body-line-height: 1.5;
     --bs-body-color: #212529;
     --bs-body-bg: #fff
 }
 
 *,
 *::before,
 *::after {
     box-sizing: border-box
 }
 
 @media (prefers-reduced-motion: no-preference) {
     :root {
         scroll-behavior: smooth
     }
 }
 
 body {
     margin: 0;
     font-family: var(--bs-body-font-family);
     font-size: var(--bs-body-font-size);
     font-weight: var(--bs-body-font-weight);
     line-height: var(--bs-body-line-height);
     color: var(--bs-body-color);
     text-align: var(--bs-body-text-align);
     background-color: var(--bs-body-bg);
     -webkit-text-size-adjust: 100%;
     -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
 }
 
 hr {
     margin: 1rem 0;
     color: inherit;
     background-color: currentColor;
     border: 0;
     opacity: .25
 }
 
 hr:not([size]) {
     height: 1px
 }
 
 h1,
 .h1,
 h2,
 .h2,
 h3,
 .h3,
 h4,
 .h4,
 h5,
 .h5,
 h6,
 .h6 {
     margin-top: 0;
     margin-bottom: .5rem;
     font-weight: 500;
     line-height: 1.2
 }
 
 h1,
 .h1 {
     font-size: calc(1.375rem + 1.5vw)
 }
 
 @media (min-width: 1200px) {
 
     h1,
     .h1 {
         font-size: 2.5rem
     }
 }
 
 h2,
 .h2 {
     font-size: calc(1.325rem + .9vw)
 }
 
 @media (min-width: 1200px) {
 
     h2,
     .h2 {
         font-size: 2rem
     }
 }
 
 h3,
 .h3 {
     font-size: calc(1.3rem + .6vw)
 }
 
 @media (min-width: 1200px) {
 
     h3,
     .h3 {
         font-size: 1.75rem
     }
 }
 
 h4,
 .h4 {
     font-size: calc(1.275rem + .3vw)
 }
 
 @media (min-width: 1200px) {
 
     h4,
     .h4 {
         font-size: 1.5rem
     }
 }
 
 h5,
 .h5 {
     font-size: 1.25rem
 }
 
 h6,
 .h6 {
     font-size: 1rem
 }
 
 p {
     margin-top: 0;
     margin-bottom: 1rem
 }
 
 abbr[title],
 abbr[data-bs-original-title] {
     text-decoration: underline dotted;
     cursor: help;
     text-decoration-skip-ink: none
 }
 
 address {
     margin-bottom: 1rem;
     font-style: normal;
     line-height: inherit
 }
 
 ol,
 ul {
     padding-left: 2rem
 }
 
 ol,
 ul,
 dl {
     margin-top: 0;
     margin-bottom: 1rem
 }
 
 ol ol,
 ul ul,
 ol ul,
 ul ol {
     margin-bottom: 0
 }
 
 dt {
     font-weight: 700
 }
 
 dd {
     margin-bottom: .5rem;
     margin-left: 0
 }
 
 blockquote {
     margin: 0 0 1rem
 }
 
 b,
 strong {
     font-weight: bolder
 }
 
 small,
 .small {
     font-size: .875em
 }
 
 mark,
 .mark {
     padding: .2em;
     background-color: #fcf8e3
 }
 
 sub,
 sup {
     position: relative;
     font-size: .75em;
     line-height: 0;
     vertical-align: baseline
 }
 
 sub {
     bottom: -.25em
 }
 
 sup {
     top: -.5em
 }
 
 a {
     color: #0d6efd;
     text-decoration: underline
 }
 
 a:hover {
     color: #0a58ca
 }
 
 a:not([href]):not([class]),
 a:not([href]):not([class]):hover {
     color: inherit;
     text-decoration: none
 }
 
 pre,
 code,
 kbd,
 samp {
     font-family: var(--bs-font-monospace);
     font-size: 1em;
     direction: ltr
         /* rtl:ignore */
     ;
     unicode-bidi: bidi-override
 }
 
 pre {
     display: block;
     margin-top: 0;
     margin-bottom: 1rem;
     overflow: auto;
     font-size: .875em
 }
 
 pre code {
     font-size: inherit;
     color: inherit;
     word-break: normal
 }
 
 code {
     font-size: .875em;
     color: #d63384;
     word-wrap: break-word
 }
 
 a>code {
     color: inherit
 }
 
 kbd {
     padding: .2rem .4rem;
     font-size: .875em;
     color: #fff;
     background-color: #212529;
     border-radius: .2rem
 }
 
 kbd kbd {
     padding: 0;
     font-size: 1em;
     font-weight: 700
 }
 
 figure {
     margin: 0 0 1rem
 }
 
 img,
 svg {
     vertical-align: middle
 }
 
 table {
     caption-side: bottom;
     border-collapse: collapse
 }
 
 caption {
     padding-top: .5rem;
     padding-bottom: .5rem;
     color: #6c757d;
     text-align: left
 }
 
 th {
     text-align: inherit;
     text-align: -webkit-match-parent
 }
 
 thead,
 tbody,
 tfoot,
 tr,
 td,
 th {
     border-color: inherit;
     border-style: solid;
     border-width: 0
 }
 
 label {
     display: inline-block
 }
 
 button {
     border-radius: 0
 }
 
 button:focus:not(:focus-visible) {
     outline: 0
 }
 
 input,
 button,
 select,
 optgroup,
 textarea {
     margin: 0;
     font-family: inherit;
     font-size: inherit;
     line-height: inherit
 }
 
 button,
 select {
     text-transform: none
 }
 
 [role="button"] {
     cursor: pointer
 }
 
 select {
     word-wrap: normal
 }
 
 select:disabled {
     opacity: 1
 }
 
 [list]::-webkit-calendar-picker-indicator {
     display: none
 }
 
 button,
 [type="button"],
 [type="reset"],
 [type="submit"] {
     -webkit-appearance: button
 }
 
 button:not(:disabled),
 [type="button"]:not(:disabled),
 [type="reset"]:not(:disabled),
 [type="submit"]:not(:disabled) {
     cursor: pointer
 }
 
 ::-moz-focus-inner {
     padding: 0;
     border-style: none
 }
 
 textarea {
     resize: vertical
 }
 
 fieldset {
     min-width: 0;
     padding: 0;
     margin: 0;
     border: 0
 }
 
 legend {
     float: left;
     width: 100%;
     padding: 0;
     margin-bottom: .5rem;
     font-size: calc(1.275rem + .3vw);
     line-height: inherit
 }
 
 @media (min-width: 1200px) {
     legend {
         font-size: 1.5rem
     }
 }
 
 legend+* {
     clear: left
 }
 
 ::-webkit-datetime-edit-fields-wrapper,
 ::-webkit-datetime-edit-text,
 ::-webkit-datetime-edit-minute,
 ::-webkit-datetime-edit-hour-field,
 ::-webkit-datetime-edit-day-field,
 ::-webkit-datetime-edit-month-field,
 ::-webkit-datetime-edit-year-field {
     padding: 0
 }
 
 ::-webkit-inner-spin-button {
     height: auto
 }
 
 [type="search"] {
     outline-offset: -2px;
     -webkit-appearance: textfield
 }
 
 ::-webkit-search-decoration {
     -webkit-appearance: none
 }
 
 ::-webkit-color-swatch-wrapper {
     padding: 0
 }
 
 ::file-selector-button {
     font: inherit
 }
 
 ::-webkit-file-upload-button {
     font: inherit;
     -webkit-appearance: button
 }
 
 output {
     display: inline-block
 }
 
 iframe {
     border: 0
 }
 
 summary {
     display: list-item;
     cursor: pointer
 }
 
 progress {
     vertical-align: baseline
 }
 
 [hidden] {
     display: none !important
 }
 
 .lead {
     font-size: 1.25rem;
     font-weight: 300
 }
 
 .display-1 {
     font-size: calc(1.625rem + 4.5vw);
     font-weight: 300;
     line-height: 1.2
 }
 
 @media (min-width: 1200px) {
     .display-1 {
         font-size: 5rem
     }
 }
 
 .display-2 {
     font-size: calc(1.575rem + 3.9vw);
     font-weight: 300;
     line-height: 1.2
 }
 
 @media (min-width: 1200px) {
     .display-2 {
         font-size: 4.5rem
     }
 }
 
 .display-3 {
     font-size: calc(1.525rem + 3.3vw);
     font-weight: 300;
     line-height: 1.2
 }
 
 @media (min-width: 1200px) {
     .display-3 {
         font-size: 4rem
     }
 }
 
 .display-4 {
     font-size: calc(1.475rem + 2.7vw);
     font-weight: 300;
     line-height: 1.2
 }
 
 @media (min-width: 1200px) {
     .display-4 {
         font-size: 3.5rem
     }
 }
 
 .display-5 {
     font-size: calc(1.425rem + 2.1vw);
     font-weight: 300;
     line-height: 1.2
 }
 
 @media (min-width: 1200px) {
     .display-5 {
         font-size: 3rem
     }
 }
 
 .display-6 {
     font-size: calc(1.375rem + 1.5vw);
     font-weight: 300;
     line-height: 1.2
 }
 
 @media (min-width: 1200px) {
     .display-6 {
         font-size: 2.5rem
     }
 }
 
 .list-unstyled {
     padding-left: 0;
     list-style: none
 }
 
 .list-inline {
     padding-left: 0;
     list-style: none
 }
 
 .list-inline-item {
     display: inline-block
 }
 
 .list-inline-item:not(:last-child) {
     margin-right: .5rem
 }
 
 .initialism {
     font-size: .875em;
     text-transform: uppercase
 }
 
 .blockquote {
     margin-bottom: 1rem;
     font-size: 1.25rem
 }
 
 .blockquote>:last-child {
     margin-bottom: 0
 }
 
 .blockquote-footer {
     margin-top: -1rem;
     margin-bottom: 1rem;
     font-size: .875em;
     color: #6c757d
 }
 
 .blockquote-footer::before {
     content: "\2014\00A0"
 }
 
 .img-fluid {
     max-width: 100%;
     height: auto
 }
 
 .img-thumbnail {
     padding: .25rem;
     background-color: #fff;
     border: 1px solid #dee2e6;
     border-radius: .25rem;
     max-width: 100%;
     height: auto
 }
 
 .figure {
     display: inline-block
 }
 
 .figure-img {
     margin-bottom: .5rem;
     line-height: 1
 }
 
 .figure-caption {
     font-size: .875em;
     color: #6c757d
 }
 
 .container,
 .container-fluid,
 .container-sm,
 .container-md,
 .container-lg,
 .container-xl,
 .container-xxl {
     width: 100%;
     padding-right: var(--bs-gutter-x, .75rem);
     padding-left: var(--bs-gutter-x, .75rem);
     margin-right: auto;
     margin-left: auto
 }
 
 @media (min-width: 576px) {
 
     .container,
     .container-sm {
         max-width: 540px
     }
 }
 
 @media (min-width: 768px) {
 
     .container,
     .container-sm,
     .container-md {
         max-width: 720px
     }
 }
 
 @media (min-width: 992px) {
 
     .container,
     .container-sm,
     .container-md,
     .container-lg {
         max-width: 960px
     }
 }
 
 @media (min-width: 1200px) {
 
     .container,
     .container-sm,
     .container-md,
     .container-lg,
     .container-xl {
         max-width: 1140px
     }
 }
 
 @media (min-width: 1400px) {
 
     .container,
     .container-sm,
     .container-md,
     .container-lg,
     .container-xl,
     .container-xxl {
         max-width: 1320px
     }
 }
 
 .row {
     --bs-gutter-x: 1.5rem;
     --bs-gutter-y: 0;
     display: flex;
     flex-wrap: wrap;
     margin-top: calc(-1 * var(--bs-gutter-y));
     margin-right: calc(-.5 * var(--bs-gutter-x));
     margin-left: calc(-.5 * var(--bs-gutter-x))
 }
 
 .row>* {
     flex-shrink: 0;
     width: 100%;
     max-width: 100%;
     padding-right: calc(var(--bs-gutter-x) * .5);
     padding-left: calc(var(--bs-gutter-x) * .5);
     margin-top: var(--bs-gutter-y)
 }
 
 .col {
     flex: 1 0 0%
 }
 
 .row-cols-auto>* {
     flex: 0 0 auto;
     width: auto
 }
 
 .row-cols-1>* {
     flex: 0 0 auto;
     width: 100%
 }
 
 .row-cols-2>* {
     flex: 0 0 auto;
     width: 50%
 }
 
 .row-cols-3>* {
     flex: 0 0 auto;
     width: 33.3333333333%
 }
 
 .row-cols-4>* {
     flex: 0 0 auto;
     width: 25%
 }
 
 .row-cols-5>* {
     flex: 0 0 auto;
     width: 20%
 }
 
 .row-cols-6>* {
     flex: 0 0 auto;
     width: 16.6666666667%
 }
 
 .col-auto {
     flex: 0 0 auto;
     width: auto
 }
 
 .col-1 {
     flex: 0 0 auto;
     width: 8.33333333%
 }
 
 .col-2 {
     flex: 0 0 auto;
     width: 16.66666667%
 }
 
 .col-3 {
     flex: 0 0 auto;
     width: 25%
 }
 
 .col-4 {
     flex: 0 0 auto;
     width: 33.33333333%
 }
 
 .col-5 {
     flex: 0 0 auto;
     width: 41.66666667%
 }
 
 .col-6 {
     flex: 0 0 auto;
     width: 50%
 }
 
 .col-7 {
     flex: 0 0 auto;
     width: 58.33333333%
 }
 
 .col-8 {
     flex: 0 0 auto;
     width: 66.66666667%
 }
 
 .col-9 {
     flex: 0 0 auto;
     width: 75%
 }
 
 .col-10 {
     flex: 0 0 auto;
     width: 83.33333333%
 }
 
 .col-11 {
     flex: 0 0 auto;
     width: 91.66666667%
 }
 
 .col-12 {
     flex: 0 0 auto;
     width: 100%
 }
 
 .offset-1 {
     margin-left: 8.33333333%
 }
 
 .offset-2 {
     margin-left: 16.66666667%
 }
 
 .offset-3 {
     margin-left: 25%
 }
 
 .offset-4 {
     margin-left: 33.33333333%
 }
 
 .offset-5 {
     margin-left: 41.66666667%
 }
 
 .offset-6 {
     margin-left: 50%
 }
 
 .offset-7 {
     margin-left: 58.33333333%
 }
 
 .offset-8 {
     margin-left: 66.66666667%
 }
 
 .offset-9 {
     margin-left: 75%
 }
 
 .offset-10 {
     margin-left: 83.33333333%
 }
 
 .offset-11 {
     margin-left: 91.66666667%
 }
 
 .g-0,
 .gx-0 {
     --bs-gutter-x: 0
 }
 
 .g-0,
 .gy-0 {
     --bs-gutter-y: 0
 }
 
 .g-1,
 .gx-1 {
     --bs-gutter-x: .25rem
 }
 
 .g-1,
 .gy-1 {
     --bs-gutter-y: .25rem
 }
 
 .g-2,
 .gx-2 {
     --bs-gutter-x: .5rem
 }
 
 .g-2,
 .gy-2 {
     --bs-gutter-y: .5rem
 }
 
 .g-3,
 .gx-3 {
     --bs-gutter-x: 1rem
 }
 
 .g-3,
 .gy-3 {
     --bs-gutter-y: 1rem
 }
 
 .g-4,
 .gx-4 {
     --bs-gutter-x: 1.5rem
 }
 
 .g-4,
 .gy-4 {
     --bs-gutter-y: 1.5rem
 }
 
 .g-5,
 .gx-5 {
     --bs-gutter-x: 3rem
 }
 
 .g-5,
 .gy-5 {
     --bs-gutter-y: 3rem
 }
 
 @media (min-width: 576px) {
     .col-sm {
         flex: 1 0 0%
     }
 
     .row-cols-sm-auto>* {
         flex: 0 0 auto;
         width: auto
     }
 
     .row-cols-sm-1>* {
         flex: 0 0 auto;
         width: 100%
     }
 
     .row-cols-sm-2>* {
         flex: 0 0 auto;
         width: 50%
     }
 
     .row-cols-sm-3>* {
         flex: 0 0 auto;
         width: 33.3333333333%
     }
 
     .row-cols-sm-4>* {
         flex: 0 0 auto;
         width: 25%
     }
 
     .row-cols-sm-5>* {
         flex: 0 0 auto;
         width: 20%
     }
 
     .row-cols-sm-6>* {
         flex: 0 0 auto;
         width: 16.6666666667%
     }
 
     .col-sm-auto {
         flex: 0 0 auto;
         width: auto
     }
 
     .col-sm-1 {
         flex: 0 0 auto;
         width: 8.33333333%
     }
 
     .col-sm-2 {
         flex: 0 0 auto;
         width: 16.66666667%
     }
 
     .col-sm-3 {
         flex: 0 0 auto;
         width: 25%
     }
 
     .col-sm-4 {
         flex: 0 0 auto;
         width: 33.33333333%
     }
 
     .col-sm-5 {
         flex: 0 0 auto;
         width: 41.66666667%
     }
 
     .col-sm-6 {
         flex: 0 0 auto;
         width: 50%
     }
 
     .col-sm-7 {
         flex: 0 0 auto;
         width: 58.33333333%
     }
 
     .col-sm-8 {
         flex: 0 0 auto;
         width: 66.66666667%
     }
 
     .col-sm-9 {
         flex: 0 0 auto;
         width: 75%
     }
 
     .col-sm-10 {
         flex: 0 0 auto;
         width: 83.33333333%
     }
 
     .col-sm-11 {
         flex: 0 0 auto;
         width: 91.66666667%
     }
 
     .col-sm-12 {
         flex: 0 0 auto;
         width: 100%
     }
 
     .offset-sm-0 {
         margin-left: 0
     }
 
     .offset-sm-1 {
         margin-left: 8.33333333%
     }
 
     .offset-sm-2 {
         margin-left: 16.66666667%
     }
 
     .offset-sm-3 {
         margin-left: 25%
     }
 
     .offset-sm-4 {
         margin-left: 33.33333333%
     }
 
     .offset-sm-5 {
         margin-left: 41.66666667%
     }
 
     .offset-sm-6 {
         margin-left: 50%
     }
 
     .offset-sm-7 {
         margin-left: 58.33333333%
     }
 
     .offset-sm-8 {
         margin-left: 66.66666667%
     }
 
     .offset-sm-9 {
         margin-left: 75%
     }
 
     .offset-sm-10 {
         margin-left: 83.33333333%
     }
 
     .offset-sm-11 {
         margin-left: 91.66666667%
     }
 
     .g-sm-0,
     .gx-sm-0 {
         --bs-gutter-x: 0
     }
 
     .g-sm-0,
     .gy-sm-0 {
         --bs-gutter-y: 0
     }
 
     .g-sm-1,
     .gx-sm-1 {
         --bs-gutter-x: .25rem
     }
 
     .g-sm-1,
     .gy-sm-1 {
         --bs-gutter-y: .25rem
     }
 
     .g-sm-2,
     .gx-sm-2 {
         --bs-gutter-x: .5rem
     }
 
     .g-sm-2,
     .gy-sm-2 {
         --bs-gutter-y: .5rem
     }
 
     .g-sm-3,
     .gx-sm-3 {
         --bs-gutter-x: 1rem
     }
 
     .g-sm-3,
     .gy-sm-3 {
         --bs-gutter-y: 1rem
     }
 
     .g-sm-4,
     .gx-sm-4 {
         --bs-gutter-x: 1.5rem
     }
 
     .g-sm-4,
     .gy-sm-4 {
         --bs-gutter-y: 1.5rem
     }
 
     .g-sm-5,
     .gx-sm-5 {
         --bs-gutter-x: 3rem
     }
 
     .g-sm-5,
     .gy-sm-5 {
         --bs-gutter-y: 3rem
     }
 }
 
 @media (min-width: 768px) {
     .col-md {
         flex: 1 0 0%
     }
 
     .row-cols-md-auto>* {
         flex: 0 0 auto;
         width: auto
     }
 
     .row-cols-md-1>* {
         flex: 0 0 auto;
         width: 100%
     }
 
     .row-cols-md-2>* {
         flex: 0 0 auto;
         width: 50%
     }
 
     .row-cols-md-3>* {
         flex: 0 0 auto;
         width: 33.3333333333%
     }
 
     .row-cols-md-4>* {
         flex: 0 0 auto;
         width: 25%
     }
 
     .row-cols-md-5>* {
         flex: 0 0 auto;
         width: 20%
     }
 
     .row-cols-md-6>* {
         flex: 0 0 auto;
         width: 16.6666666667%
     }
 
     .col-md-auto {
         flex: 0 0 auto;
         width: auto
     }
 
     .col-md-1 {
         flex: 0 0 auto;
         width: 8.33333333%
     }
 
     .col-md-2 {
         flex: 0 0 auto;
         width: 16.66666667%
     }
 
     .col-md-3 {
         flex: 0 0 auto;
         width: 25%
     }
 
     .col-md-4 {
         flex: 0 0 auto;
         width: 33.33333333%
     }
 
     .col-md-5 {
         flex: 0 0 auto;
         width: 41.66666667%
     }
 
     .col-md-6 {
         flex: 0 0 auto;
         width: 50%
     }
 
     .col-md-7 {
         flex: 0 0 auto;
         width: 58.33333333%
     }
 
     .col-md-8 {
         flex: 0 0 auto;
         width: 66.66666667%
     }
 
     .col-md-9 {
         flex: 0 0 auto;
         width: 75%
     }
 
     .col-md-10 {
         flex: 0 0 auto;
         width: 83.33333333%
     }
 
     .col-md-11 {
         flex: 0 0 auto;
         width: 91.66666667%
     }
 
     .col-md-12 {
         flex: 0 0 auto;
         width: 100%
     }
 
     .offset-md-0 {
         margin-left: 0
     }
 
     .offset-md-1 {
         margin-left: 8.33333333%
     }
 
     .offset-md-2 {
         margin-left: 16.66666667%
     }
 
     .offset-md-3 {
         margin-left: 25%
     }
 
     .offset-md-4 {
         margin-left: 33.33333333%
     }
 
     .offset-md-5 {
         margin-left: 41.66666667%
     }
 
     .offset-md-6 {
         margin-left: 50%
     }
 
     .offset-md-7 {
         margin-left: 58.33333333%
     }
 
     .offset-md-8 {
         margin-left: 66.66666667%
     }
 
     .offset-md-9 {
         margin-left: 75%
     }
 
     .offset-md-10 {
         margin-left: 83.33333333%
     }
 
     .offset-md-11 {
         margin-left: 91.66666667%
     }
 
     .g-md-0,
     .gx-md-0 {
         --bs-gutter-x: 0
     }
 
     .g-md-0,
     .gy-md-0 {
         --bs-gutter-y: 0
     }
 
     .g-md-1,
     .gx-md-1 {
         --bs-gutter-x: .25rem
     }
 
     .g-md-1,
     .gy-md-1 {
         --bs-gutter-y: .25rem
     }
 
     .g-md-2,
     .gx-md-2 {
         --bs-gutter-x: .5rem
     }
 
     .g-md-2,
     .gy-md-2 {
         --bs-gutter-y: .5rem
     }
 
     .g-md-3,
     .gx-md-3 {
         --bs-gutter-x: 1rem
     }
 
     .g-md-3,
     .gy-md-3 {
         --bs-gutter-y: 1rem
     }
 
     .g-md-4,
     .gx-md-4 {
         --bs-gutter-x: 1.5rem
     }
 
     .g-md-4,
     .gy-md-4 {
         --bs-gutter-y: 1.5rem
     }
 
     .g-md-5,
     .gx-md-5 {
         --bs-gutter-x: 3rem
     }
 
     .g-md-5,
     .gy-md-5 {
         --bs-gutter-y: 3rem
     }
 }
 
 @media (min-width: 992px) {
     .col-lg {
         flex: 1 0 0%
     }
 
     .row-cols-lg-auto>* {
         flex: 0 0 auto;
         width: auto
     }
 
     .row-cols-lg-1>* {
         flex: 0 0 auto;
         width: 100%
     }
 
     .row-cols-lg-2>* {
         flex: 0 0 auto;
         width: 50%
     }
 
     .row-cols-lg-3>* {
         flex: 0 0 auto;
         width: 33.3333333333%
     }
 
     .row-cols-lg-4>* {
         flex: 0 0 auto;
         width: 25%
     }
 
     .row-cols-lg-5>* {
         flex: 0 0 auto;
         width: 20%
     }
 
     .row-cols-lg-6>* {
         flex: 0 0 auto;
         width: 16.6666666667%
     }
 
     .col-lg-auto {
         flex: 0 0 auto;
         width: auto
     }
 
     .col-lg-1 {
         flex: 0 0 auto;
         width: 8.33333333%
     }
 
     .col-lg-2 {
         flex: 0 0 auto;
         width: 16.66666667%
     }
 
     .col-lg-3 {
         flex: 0 0 auto;
         width: 25%
     }
 
     .col-lg-4 {
         flex: 0 0 auto;
         width: 33.33333333%
     }
 
     .col-lg-5 {
         flex: 0 0 auto;
         width: 41.66666667%
     }
 
     .col-lg-6 {
         flex: 0 0 auto;
         width: 50%
     }
 
     .col-lg-7 {
         flex: 0 0 auto;
         width: 58.33333333%
     }
 
     .col-lg-8 {
         flex: 0 0 auto;
         width: 66.66666667%
     }
 
     .col-lg-9 {
         flex: 0 0 auto;
         width: 75%
     }
 
     .col-lg-10 {
         flex: 0 0 auto;
         width: 83.33333333%
     }
 
     .col-lg-11 {
         flex: 0 0 auto;
         width: 91.66666667%
     }
 
     .col-lg-12 {
         flex: 0 0 auto;
         width: 100%
     }
 
     .offset-lg-0 {
         margin-left: 0
     }
 
     .offset-lg-1 {
         margin-left: 8.33333333%
     }
 
     .offset-lg-2 {
         margin-left: 16.66666667%
     }
 
     .offset-lg-3 {
         margin-left: 25%
     }
 
     .offset-lg-4 {
         margin-left: 33.33333333%
     }
 
     .offset-lg-5 {
         margin-left: 41.66666667%
     }
 
     .offset-lg-6 {
         margin-left: 50%
     }
 
     .offset-lg-7 {
         margin-left: 58.33333333%
     }
 
     .offset-lg-8 {
         margin-left: 66.66666667%
     }
 
     .offset-lg-9 {
         margin-left: 75%
     }
 
     .offset-lg-10 {
         margin-left: 83.33333333%
     }
 
     .offset-lg-11 {
         margin-left: 91.66666667%
     }
 
     .g-lg-0,
     .gx-lg-0 {
         --bs-gutter-x: 0
     }
 
     .g-lg-0,
     .gy-lg-0 {
         --bs-gutter-y: 0
     }
 
     .g-lg-1,
     .gx-lg-1 {
         --bs-gutter-x: .25rem
     }
 
     .g-lg-1,
     .gy-lg-1 {
         --bs-gutter-y: .25rem
     }
 
     .g-lg-2,
     .gx-lg-2 {
         --bs-gutter-x: .5rem
     }
 
     .g-lg-2,
     .gy-lg-2 {
         --bs-gutter-y: .5rem
     }
 
     .g-lg-3,
     .gx-lg-3 {
         --bs-gutter-x: 1rem
     }
 
     .g-lg-3,
     .gy-lg-3 {
         --bs-gutter-y: 1rem
     }
 
     .g-lg-4,
     .gx-lg-4 {
         --bs-gutter-x: 1.5rem
     }
 
     .g-lg-4,
     .gy-lg-4 {
         --bs-gutter-y: 1.5rem
     }
 
     .g-lg-5,
     .gx-lg-5 {
         --bs-gutter-x: 3rem
     }
 
     .g-lg-5,
     .gy-lg-5 {
         --bs-gutter-y: 3rem
     }
 }
 
 @media (min-width: 1200px) {
     .col-xl {
         flex: 1 0 0%
     }
 
     .row-cols-xl-auto>* {
         flex: 0 0 auto;
         width: auto
     }
 
     .row-cols-xl-1>* {
         flex: 0 0 auto;
         width: 100%
     }
 
     .row-cols-xl-2>* {
         flex: 0 0 auto;
         width: 50%
     }
 
     .row-cols-xl-3>* {
         flex: 0 0 auto;
         width: 33.3333333333%
     }
 
     .row-cols-xl-4>* {
         flex: 0 0 auto;
         width: 25%
     }
 
     .row-cols-xl-5>* {
         flex: 0 0 auto;
         width: 20%
     }
 
     .row-cols-xl-6>* {
         flex: 0 0 auto;
         width: 16.6666666667%
     }
 
     .col-xl-auto {
         flex: 0 0 auto;
         width: auto
     }
 
     .col-xl-1 {
         flex: 0 0 auto;
         width: 8.33333333%
     }
 
     .col-xl-2 {
         flex: 0 0 auto;
         width: 16.66666667%
     }
 
     .col-xl-3 {
         flex: 0 0 auto;
         width: 25%
     }
 
     .col-xl-4 {
         flex: 0 0 auto;
         width: 33.33333333%
     }
 
     .col-xl-5 {
         flex: 0 0 auto;
         width: 41.66666667%
     }
 
     .col-xl-6 {
         flex: 0 0 auto;
         width: 50%
     }
 
     .col-xl-7 {
         flex: 0 0 auto;
         width: 58.33333333%
     }
 
     .col-xl-8 {
         flex: 0 0 auto;
         width: 66.66666667%
     }
 
     .col-xl-9 {
         flex: 0 0 auto;
         width: 75%
     }
 
     .col-xl-10 {
         flex: 0 0 auto;
         width: 83.33333333%
     }
 
     .col-xl-11 {
         flex: 0 0 auto;
         width: 91.66666667%
     }
 
     .col-xl-12 {
         flex: 0 0 auto;
         width: 100%
     }
 
     .offset-xl-0 {
         margin-left: 0
     }
 
     .offset-xl-1 {
         margin-left: 8.33333333%
     }
 
     .offset-xl-2 {
         margin-left: 16.66666667%
     }
 
     .offset-xl-3 {
         margin-left: 25%
     }
 
     .offset-xl-4 {
         margin-left: 33.33333333%
     }
 
     .offset-xl-5 {
         margin-left: 41.66666667%
     }
 
     .offset-xl-6 {
         margin-left: 50%
     }
 
     .offset-xl-7 {
         margin-left: 58.33333333%
     }
 
     .offset-xl-8 {
         margin-left: 66.66666667%
     }
 
     .offset-xl-9 {
         margin-left: 75%
     }
 
     .offset-xl-10 {
         margin-left: 83.33333333%
     }
 
     .offset-xl-11 {
         margin-left: 91.66666667%
     }
 
     .g-xl-0,
     .gx-xl-0 {
         --bs-gutter-x: 0
     }
 
     .g-xl-0,
     .gy-xl-0 {
         --bs-gutter-y: 0
     }
 
     .g-xl-1,
     .gx-xl-1 {
         --bs-gutter-x: .25rem
     }
 
     .g-xl-1,
     .gy-xl-1 {
         --bs-gutter-y: .25rem
     }
 
     .g-xl-2,
     .gx-xl-2 {
         --bs-gutter-x: .5rem
     }
 
     .g-xl-2,
     .gy-xl-2 {
         --bs-gutter-y: .5rem
     }
 
     .g-xl-3,
     .gx-xl-3 {
         --bs-gutter-x: 1rem
     }
 
     .g-xl-3,
     .gy-xl-3 {
         --bs-gutter-y: 1rem
     }
 
     .g-xl-4,
     .gx-xl-4 {
         --bs-gutter-x: 1.5rem
     }
 
     .g-xl-4,
     .gy-xl-4 {
         --bs-gutter-y: 1.5rem
     }
 
     .g-xl-5,
     .gx-xl-5 {
         --bs-gutter-x: 3rem
     }
 
     .g-xl-5,
     .gy-xl-5 {
         --bs-gutter-y: 3rem
     }
 }
 
 @media (min-width: 1400px) {
     .col-xxl {
         flex: 1 0 0%
     }
 
     .row-cols-xxl-auto>* {
         flex: 0 0 auto;
         width: auto
     }
 
     .row-cols-xxl-1>* {
         flex: 0 0 auto;
         width: 100%
     }
 
     .row-cols-xxl-2>* {
         flex: 0 0 auto;
         width: 50%
     }
 
     .row-cols-xxl-3>* {
         flex: 0 0 auto;
         width: 33.3333333333%
     }
 
     .row-cols-xxl-4>* {
         flex: 0 0 auto;
         width: 25%
     }
 
     .row-cols-xxl-5>* {
         flex: 0 0 auto;
         width: 20%
     }
 
     .row-cols-xxl-6>* {
         flex: 0 0 auto;
         width: 16.6666666667%
     }
 
     .col-xxl-auto {
         flex: 0 0 auto;
         width: auto
     }
 
     .col-xxl-1 {
         flex: 0 0 auto;
         width: 8.33333333%
     }
 
     .col-xxl-2 {
         flex: 0 0 auto;
         width: 16.66666667%
     }
 
     .col-xxl-3 {
         flex: 0 0 auto;
         width: 25%
     }
 
     .col-xxl-4 {
         flex: 0 0 auto;
         width: 33.33333333%
     }
 
     .col-xxl-5 {
         flex: 0 0 auto;
         width: 41.66666667%
     }
 
     .col-xxl-6 {
         flex: 0 0 auto;
         width: 50%
     }
 
     .col-xxl-7 {
         flex: 0 0 auto;
         width: 58.33333333%
     }
 
     .col-xxl-8 {
         flex: 0 0 auto;
         width: 66.66666667%
     }
 
     .col-xxl-9 {
         flex: 0 0 auto;
         width: 75%
     }
 
     .col-xxl-10 {
         flex: 0 0 auto;
         width: 83.33333333%
     }
 
     .col-xxl-11 {
         flex: 0 0 auto;
         width: 91.66666667%
     }
 
     .col-xxl-12 {
         flex: 0 0 auto;
         width: 100%
     }
 
     .offset-xxl-0 {
         margin-left: 0
     }
 
     .offset-xxl-1 {
         margin-left: 8.33333333%
     }
 
     .offset-xxl-2 {
         margin-left: 16.66666667%
     }
 
     .offset-xxl-3 {
         margin-left: 25%
     }
 
     .offset-xxl-4 {
         margin-left: 33.33333333%
     }
 
     .offset-xxl-5 {
         margin-left: 41.66666667%
     }
 
     .offset-xxl-6 {
         margin-left: 50%
     }
 
     .offset-xxl-7 {
         margin-left: 58.33333333%
     }
 
     .offset-xxl-8 {
         margin-left: 66.66666667%
     }
 
     .offset-xxl-9 {
         margin-left: 75%
     }
 
     .offset-xxl-10 {
         margin-left: 83.33333333%
     }
 
     .offset-xxl-11 {
         margin-left: 91.66666667%
     }
 
     .g-xxl-0,
     .gx-xxl-0 {
         --bs-gutter-x: 0
     }
 
     .g-xxl-0,
     .gy-xxl-0 {
         --bs-gutter-y: 0
     }
 
     .g-xxl-1,
     .gx-xxl-1 {
         --bs-gutter-x: .25rem
     }
 
     .g-xxl-1,
     .gy-xxl-1 {
         --bs-gutter-y: .25rem
     }
 
     .g-xxl-2,
     .gx-xxl-2 {
         --bs-gutter-x: .5rem
     }
 
     .g-xxl-2,
     .gy-xxl-2 {
         --bs-gutter-y: .5rem
     }
 
     .g-xxl-3,
     .gx-xxl-3 {
         --bs-gutter-x: 1rem
     }
 
     .g-xxl-3,
     .gy-xxl-3 {
         --bs-gutter-y: 1rem
     }
 
     .g-xxl-4,
     .gx-xxl-4 {
         --bs-gutter-x: 1.5rem
     }
 
     .g-xxl-4,
     .gy-xxl-4 {
         --bs-gutter-y: 1.5rem
     }
 
     .g-xxl-5,
     .gx-xxl-5 {
         --bs-gutter-x: 3rem
     }
 
     .g-xxl-5,
     .gy-xxl-5 {
         --bs-gutter-y: 3rem
     }
 }
 
 .table {
     --bs-table-bg: rgba(0, 0, 0, 0);
     --bs-table-accent-bg: rgba(0, 0, 0, 0);
     --bs-table-striped-color: #212529;
     --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
     --bs-table-active-color: #212529;
     --bs-table-active-bg: rgba(0, 0, 0, 0.1);
     --bs-table-hover-color: #212529;
     --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
     width: 100%;
     margin-bottom: 1rem;
     color: #212529;
     vertical-align: top;
     border-color: #dee2e6
 }
 
 .table>:not(caption)>*>* {
     padding: .5rem .5rem;
     background-color: var(--bs-table-bg);
     border-bottom-width: 1px;
     box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg)
 }
 
 .table>tbody {
     vertical-align: inherit
 }
 
 .table>thead {
     vertical-align: bottom
 }
 
 .table>:not(:first-child) {
     border-top: 2px solid currentColor
 }
 
 .caption-top {
     caption-side: top
 }
 
 .table-sm>:not(caption)>*>* {
     padding: .25rem .25rem
 }
 
 .table-bordered>:not(caption)>* {
     border-width: 1px 0
 }
 
 .table-bordered>:not(caption)>*>* {
     border-width: 0 1px
 }
 
 .table-borderless>:not(caption)>*>* {
     border-bottom-width: 0
 }
 
 .table-borderless>:not(:first-child) {
     border-top-width: 0
 }
 
 .table-striped>tbody>tr:nth-of-type(odd)>* {
     --bs-table-accent-bg: var(--bs-table-striped-bg);
     color: var(--bs-table-striped-color)
 }
 
 .table-active {
     --bs-table-accent-bg: var(--bs-table-active-bg);
     color: var(--bs-table-active-color)
 }
 
 .table-hover>tbody>tr:hover>* {
     --bs-table-accent-bg: var(--bs-table-hover-bg);
     color: var(--bs-table-hover-color)
 }
 
 .table-primary {
     --bs-table-bg: #cfe2ff;
     --bs-table-striped-bg: #c5d7f2;
     --bs-table-striped-color: #000;
     --bs-table-active-bg: #bacbe6;
     --bs-table-active-color: #000;
     --bs-table-hover-bg: #bfd1ec;
     --bs-table-hover-color: #000;
     color: #000;
     border-color: #bacbe6
 }
 
 .table-secondary {
     --bs-table-bg: #e2e3e5;
     --bs-table-striped-bg: #d7d8da;
     --bs-table-striped-color: #000;
     --bs-table-active-bg: #cbccce;
     --bs-table-active-color: #000;
     --bs-table-hover-bg: #d1d2d4;
     --bs-table-hover-color: #000;
     color: #000;
     border-color: #cbccce
 }
 
 .table-success {
     --bs-table-bg: #d1e7dd;
     --bs-table-striped-bg: #c7dbd2;
     --bs-table-striped-color: #000;
     --bs-table-active-bg: #bcd0c7;
     --bs-table-active-color: #000;
     --bs-table-hover-bg: #c1d6cc;
     --bs-table-hover-color: #000;
     color: #000;
     border-color: #bcd0c7
 }
 
 .table-info {
     --bs-table-bg: #cff4fc;
     --bs-table-striped-bg: #c5e8ef;
     --bs-table-striped-color: #000;
     --bs-table-active-bg: #badce3;
     --bs-table-active-color: #000;
     --bs-table-hover-bg: #bfe2e9;
     --bs-table-hover-color: #000;
     color: #000;
     border-color: #badce3
 }
 
 .table-warning {
     --bs-table-bg: #fff3cd;
     --bs-table-striped-bg: #f2e7c3;
     --bs-table-striped-color: #000;
     --bs-table-active-bg: #e6dbb9;
     --bs-table-active-color: #000;
     --bs-table-hover-bg: #ece1be;
     --bs-table-hover-color: #000;
     color: #000;
     border-color: #e6dbb9
 }
 
 .table-danger {
     --bs-table-bg: #f8d7da;
     --bs-table-striped-bg: #eccccf;
     --bs-table-striped-color: #000;
     --bs-table-active-bg: #dfc2c4;
     --bs-table-active-color: #000;
     --bs-table-hover-bg: #e5c7ca;
     --bs-table-hover-color: #000;
     color: #000;
     border-color: #dfc2c4
 }
 
 .table-light {
     --bs-table-bg: #f8f9fa;
     --bs-table-striped-bg: #ecedee;
     --bs-table-striped-color: #000;
     --bs-table-active-bg: #dfe0e1;
     --bs-table-active-color: #000;
     --bs-table-hover-bg: #e5e6e7;
     --bs-table-hover-color: #000;
     color: #000;
     border-color: #dfe0e1
 }
 
 .table-dark {
     --bs-table-bg: #212529;
     --bs-table-striped-bg: #2c3034;
     --bs-table-striped-color: #fff;
     --bs-table-active-bg: #373b3e;
     --bs-table-active-color: #fff;
     --bs-table-hover-bg: #323539;
     --bs-table-hover-color: #fff;
     color: #fff;
     border-color: #373b3e
 }
 
 .table-responsive {
     overflow-x: auto;
     -webkit-overflow-scrolling: touch
 }
 
 @media (max-width: 575.98px) {
     .table-responsive-sm {
         overflow-x: auto;
         -webkit-overflow-scrolling: touch
     }
 }
 
 @media (max-width: 767.98px) {
     .table-responsive-md {
         overflow-x: auto;
         -webkit-overflow-scrolling: touch
     }
 }
 
 @media (max-width: 991.98px) {
     .table-responsive-lg {
         overflow-x: auto;
         -webkit-overflow-scrolling: touch
     }
 }
 
 @media (max-width: 1199.98px) {
     .table-responsive-xl {
         overflow-x: auto;
         -webkit-overflow-scrolling: touch
     }
 }
 
 @media (max-width: 1399.98px) {
     .table-responsive-xxl {
         overflow-x: auto;
         -webkit-overflow-scrolling: touch
     }
 }
 
 .form-label {
     margin-bottom: .5rem
 }
 
 .col-form-label {
     padding-top: calc(.375rem + 1px);
     padding-bottom: calc(.375rem + 1px);
     margin-bottom: 0;
     font-size: inherit;
     line-height: 1.5
 }
 
 .col-form-label-lg {
     padding-top: calc(.5rem + 1px);
     padding-bottom: calc(.5rem + 1px);
     font-size: 1.25rem
 }
 
 .col-form-label-sm {
     padding-top: calc(.25rem + 1px);
     padding-bottom: calc(.25rem + 1px);
     font-size: .875rem
 }
 
 .form-text {
     margin-top: .25rem;
     font-size: .875em;
     color: #6c757d
 }
 
 .form-control {
     display: block;
     width: 100%;
     padding: .375rem .75rem;
     font-size: 1rem;
     font-weight: 400;
     line-height: 1.5;
     color: #212529;
     background-color: #fff;
     background-clip: padding-box;
     border: 1px solid #ced4da;
     appearance: none;
     border-radius: .25rem;
     transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
 }
 
 @media (prefers-reduced-motion: reduce) {
     .form-control {
         transition: none
     }
 }
 
 .form-control[type="file"] {
     overflow: hidden
 }
 
 .form-control[type="file"]:not(:disabled):not([readonly]) {
     cursor: pointer
 }
 
 .form-control:focus {
     color: #212529;
     background-color: #fff;
     border-color: #86b7fe;
     outline: 0;
     box-shadow: 0 0 0 .25rem rgba(13, 110, 253, 0.25)
 }
 
 .form-control::-webkit-date-and-time-value {
     height: 1.5em
 }
 
 .form-control::placeholder {
     color: #6c757d;
     opacity: 1
 }
 
 .form-control:disabled,
 .form-control[readonly] {
     background-color: #e9ecef;
     opacity: 1
 }
 
 .form-control::file-selector-button {
     padding: .375rem .75rem;
     margin: -.375rem -.75rem;
     margin-inline-end: .75rem;
     color: #212529;
     background-color: #e9ecef;
     pointer-events: none;
     border-color: inherit;
     border-style: solid;
     border-width: 0;
     border-inline-end-width: 1px;
     border-radius: 0;
     transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
 }
 
 @media (prefers-reduced-motion: reduce) {
     .form-control::file-selector-button {
         transition: none
     }
 }
 
 .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
     background-color: #dde0e3
 }
 
 .form-control::-webkit-file-upload-button {
     padding: .375rem .75rem;
     margin: -.375rem -.75rem;
     margin-inline-end: .75rem;
     color: #212529;
     background-color: #e9ecef;
     pointer-events: none;
     border-color: inherit;
     border-style: solid;
     border-width: 0;
     border-inline-end-width: 1px;
     border-radius: 0;
     transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
 }
 
 @media (prefers-reduced-motion: reduce) {
     .form-control::-webkit-file-upload-button {
         transition: none
     }
 }
 
 .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
     background-color: #dde0e3
 }
 
 .form-control-plaintext {
     display: block;
     width: 100%;
     padding: .375rem 0;
     margin-bottom: 0;
     line-height: 1.5;
     color: #212529;
     background-color: transparent;
     border: solid transparent;
     border-width: 1px 0
 }
 
 .form-control-plaintext.form-control-sm,
 .form-control-plaintext.form-control-lg {
     padding-right: 0;
     padding-left: 0
 }
 
 .form-control-sm {
     min-height: calc(1.5em + .5rem + 2px);
     padding: .25rem .5rem;
     font-size: .875rem;
     border-radius: .2rem
 }
 
 .form-control-sm::file-selector-button {
     padding: .25rem .5rem;
     margin: -.25rem -.5rem;
     margin-inline-end: .5rem
 }
 
 .form-control-sm::-webkit-file-upload-button {
     padding: .25rem .5rem;
     margin: -.25rem -.5rem;
     margin-inline-end: .5rem
 }
 
 .form-control-lg {
     min-height: calc(1.5em + 1rem + 2px);
     padding: .5rem 1rem;
     font-size: 1.25rem;
     border-radius: .3rem
 }
 
 .form-control-lg::file-selector-button {
     padding: .5rem 1rem;
     margin: -.5rem -1rem;
     margin-inline-end: 1rem
 }
 
 .form-control-lg::-webkit-file-upload-button {
     padding: .5rem 1rem;
     margin: -.5rem -1rem;
     margin-inline-end: 1rem
 }
 
 textarea.form-control {
     min-height: calc(1.5em + .75rem + 2px)
 }
 
 textarea.form-control-sm {
     min-height: calc(1.5em + .5rem + 2px)
 }
 
 textarea.form-control-lg {
     min-height: calc(1.5em + 1rem + 2px)
 }
 
 .form-control-color {
     width: 3rem;
     height: auto;
     padding: .375rem
 }
 
 .form-control-color:not(:disabled):not([readonly]) {
     cursor: pointer
 }
 
 .form-control-color::-moz-color-swatch {
     height: 1.5em;
     border-radius: .25rem
 }
 
 .form-control-color::-webkit-color-swatch {
     height: 1.5em;
     border-radius: .25rem
 }
 
 .form-select {
     display: block;
     width: 100%;
     padding: .375rem 2.25rem .375rem .75rem;
     -moz-padding-start: calc(.75rem - 3px);
     font-size: 1rem;
     font-weight: 400;
     line-height: 1.5;
     color: #212529;
     background-color: #fff;
     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
     background-repeat: no-repeat;
     background-position: right .75rem center;
     background-size: 16px 12px;
     border: 1px solid #ced4da;
     border-radius: .25rem;
     transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
     appearance: none
 }
 
 @media (prefers-reduced-motion: reduce) {
     .form-select {
         transition: none
     }
 }
 
 .form-select:focus {
     border-color: #86b7fe;
     outline: 0;
     box-shadow: 0 0 0 .25rem rgba(13, 110, 253, 0.25)
 }
 
 .form-select[multiple],
 .form-select[size]:not([size="1"]) {
     padding-right: .75rem;
     background-image: none
 }
 
 .form-select:disabled {
     background-color: #e9ecef
 }
 
 .form-select:-moz-focusring {
     color: transparent;
     text-shadow: 0 0 0 #212529
 }
 
 .form-select-sm {
     padding-top: .25rem;
     padding-bottom: .25rem;
     padding-left: .5rem;
     font-size: .875rem;
     border-radius: .2rem
 }
 
 .form-select-lg {
     padding-top: .5rem;
     padding-bottom: .5rem;
     padding-left: 1rem;
     font-size: 1.25rem;
     border-radius: .3rem
 }
 
 .form-check {
     display: block;
     min-height: 1.5rem;
     padding-left: 1.5em;
     margin-bottom: .125rem
 }
 
 .form-check .form-check-input {
     float: left;
     margin-left: -1.5em
 }
 
 .form-check-input {
     width: 1em;
     height: 1em;
     margin-top: .25em;
     vertical-align: top;
     background-color: #fff;
     background-repeat: no-repeat;
     background-position: center;
     background-size: contain;
     border: 1px solid rgba(0, 0, 0, 0.25);
     appearance: none;
     color-adjust: exact
 }
 
 .form-check-input[type="checkbox"] {
     border-radius: .25em
 }
 
 .form-check-input[type="radio"] {
     border-radius: 50%
 }
 
 .form-check-input:active {
     filter: brightness(90%)
 }
 
 .form-check-input:focus {
     border-color: #86b7fe;
     outline: 0;
     box-shadow: 0 0 0 .25rem rgba(13, 110, 253, 0.25)
 }
 
 .form-check-input:checked {
     background-color: #0d6efd;
     border-color: #0d6efd
 }
 
 .form-check-input:checked[type="checkbox"] {
     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")
 }
 
 .form-check-input:checked[type="radio"] {
     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e")
 }
 
 .form-check-input[type="checkbox"]:indeterminate {
     background-color: #0d6efd;
     border-color: #0d6efd;
     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e")
 }
 
 .form-check-input:disabled {
     pointer-events: none;
     filter: none;
     opacity: .5
 }
 
 .form-check-input[disabled]~.form-check-label,
 .form-check-input:disabled~.form-check-label {
     opacity: .5
 }
 
 .form-switch {
     padding-left: 2.5em
 }
 
 .form-switch .form-check-input {
     width: 2em;
     margin-left: -2.5em;
     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280,0,0,0.25%29'/%3e%3c/svg%3e");
     background-position: left center;
     border-radius: 2em;
     transition: background-position 0.15s ease-in-out
 }
 
 @media (prefers-reduced-motion: reduce) {
     .form-switch .form-check-input {
         transition: none
     }
 }
 
 .form-switch .form-check-input:focus {
     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e")
 }
 
 .form-switch .form-check-input:checked {
     background-position: right center;
     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")
 }
 
 .form-check-inline {
     display: inline-block;
     margin-right: 1rem
 }
 
 .btn-check {
     position: absolute;
     clip: rect(0, 0, 0, 0);
     pointer-events: none
 }
 
 .btn-check[disabled]+.btn,
 .btn-check:disabled+.btn {
     pointer-events: none;
     filter: none;
     opacity: .65
 }
 
 .form-range {
     width: 100%;
     height: 1.5rem;
     padding: 0;
     background-color: transparent;
     appearance: none
 }
 
 .form-range:focus {
     outline: 0
 }
 
 .form-range:focus::-webkit-slider-thumb {
     box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem rgba(13, 110, 253, 0.25)
 }
 
 .form-range:focus::-moz-range-thumb {
     box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem rgba(13, 110, 253, 0.25)
 }
 
 .form-range::-moz-focus-outer {
     border: 0
 }
 
 .form-range::-webkit-slider-thumb {
     width: 1rem;
     height: 1rem;
     margin-top: -.25rem;
     background-color: #0d6efd;
     border: 0;
     border-radius: 1rem;
     transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
     appearance: none
 }
 
 @media (prefers-reduced-motion: reduce) {
     .form-range::-webkit-slider-thumb {
         transition: none
     }
 }
 
 .form-range::-webkit-slider-thumb:active {
     background-color: #b6d4fe
 }
 
 .form-range::-webkit-slider-runnable-track {
     width: 100%;
     height: .5rem;
     color: transparent;
     cursor: pointer;
     background-color: #dee2e6;
     border-color: transparent;
     border-radius: 1rem
 }
 
 .form-range::-moz-range-thumb {
     width: 1rem;
     height: 1rem;
     background-color: #0d6efd;
     border: 0;
     border-radius: 1rem;
     transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
     appearance: none
 }
 
 @media (prefers-reduced-motion: reduce) {
     .form-range::-moz-range-thumb {
         transition: none
     }
 }
 
 .form-range::-moz-range-thumb:active {
     background-color: #b6d4fe
 }
 
 .form-range::-moz-range-track {
     width: 100%;
     height: .5rem;
     color: transparent;
     cursor: pointer;
     background-color: #dee2e6;
     border-color: transparent;
     border-radius: 1rem
 }
 
 .form-range:disabled {
     pointer-events: none
 }
 
 .form-range:disabled::-webkit-slider-thumb {
     background-color: #adb5bd
 }
 
 .form-range:disabled::-moz-range-thumb {
     background-color: #adb5bd
 }
 
 .form-floating {
     position: relative
 }
 
 .form-floating>.form-control,
 .form-floating>.form-select {
     height: calc(3.5rem + 2px);
     line-height: 1.25
 }
 
 .form-floating>label {
     position: absolute;
     top: 0;
     left: 0;
     height: 100%;
     padding: 1rem .75rem;
     pointer-events: none;
     border: 1px solid transparent;
     transform-origin: 0 0;
     transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out
 }
 
 @media (prefers-reduced-motion: reduce) {
     .form-floating>label {
         transition: none
     }
 }
 
 .form-floating>.form-control {
     padding: 1rem .75rem
 }
 
 .form-floating>.form-control::placeholder {
     color: transparent
 }
 
 .form-floating>.form-control:focus,
 .form-floating>.form-control:not(:placeholder-shown) {
     padding-top: 1.625rem;
     padding-bottom: .625rem
 }
 
 .form-floating>.form-control:-webkit-autofill {
     padding-top: 1.625rem;
     padding-bottom: .625rem
 }
 
 .form-floating>.form-select {
     padding-top: 1.625rem;
     padding-bottom: .625rem
 }
 
 .form-floating>.form-control:focus~label,
 .form-floating>.form-control:not(:placeholder-shown)~label,
 .form-floating>.form-select~label {
     opacity: .65;
     transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem)
 }
 
 .form-floating>.form-control:-webkit-autofill~label {
     opacity: .65;
     transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem)
 }
 
 .input-group {
     position: relative;
     display: flex;
     flex-wrap: wrap;
     align-items: stretch;
     width: 100%
 }
 
 .input-group>.form-control,
 .input-group>.form-select {
     position: relative;
     flex: 1 1 auto;
     width: 1%;
     min-width: 0
 }
 
 .input-group>.form-control:focus,
 .input-group>.form-select:focus {
     z-index: 3
 }
 
 .input-group .btn {
     position: relative;
     z-index: 2
 }
 
 .input-group .btn:focus {
     z-index: 3
 }
 
 .input-group-text {
     display: flex;
     align-items: center;
     padding: .375rem .75rem;
     font-size: 1rem;
     font-weight: 400;
     line-height: 1.5;
     color: #212529;
     text-align: center;
     white-space: nowrap;
     background-color: #e9ecef;
     border: 1px solid #ced4da;
     border-radius: .25rem
 }
 
 .input-group-lg>.form-control,
 .input-group-lg>.form-select,
 .input-group-lg>.input-group-text,
 .input-group-lg>.btn {
     padding: .5rem 1rem;
     font-size: 1.25rem;
     border-radius: .3rem
 }
 
 .input-group-sm>.form-control,
 .input-group-sm>.form-select,
 .input-group-sm>.input-group-text,
 .input-group-sm>.btn {
     padding: .25rem .5rem;
     font-size: .875rem;
     border-radius: .2rem
 }
 
 .input-group-lg>.form-select,
 .input-group-sm>.form-select {
     padding-right: 3rem
 }
 
 .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
 .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3) {
     border-top-right-radius: 0;
     border-bottom-right-radius: 0
 }
 
 .input-group.has-validation>:nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
 .input-group.has-validation>.dropdown-toggle:nth-last-child(n+4) {
     border-top-right-radius: 0;
     border-bottom-right-radius: 0
 }
 
 .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
     margin-left: -1px;
     border-top-left-radius: 0;
     border-bottom-left-radius: 0
 }
 
 .valid-feedback {
     display: none;
     width: 100%;
     margin-top: .25rem;
     font-size: .875em;
     color: #198754
 }
 
 .valid-tooltip {
     position: absolute;
     top: 100%;
     z-index: 5;
     display: none;
     max-width: 100%;
     padding: .25rem .5rem;
     margin-top: .1rem;
     font-size: .875rem;
     color: #fff;
     background-color: rgba(25, 135, 84, 0.9);
     border-radius: .25rem
 }
 
 .was-validated :valid~.valid-feedback,
 .was-validated :valid~.valid-tooltip,
 .is-valid~.valid-feedback,
 .is-valid~.valid-tooltip {
     display: block
 }
 
 .was-validated .form-control:valid,
 .form-control.is-valid {
     border-color: #198754;
     padding-right: calc(1.5em + .75rem);
     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
     background-repeat: no-repeat;
     background-position: right calc(.375em + .1875rem) center;
     background-size: calc(.75em + .375rem) calc(.75em + .375rem)
 }
 
 .was-validated .form-control:valid:focus,
 .form-control.is-valid:focus {
     border-color: #198754;
     box-shadow: 0 0 0 .25rem rgba(25, 135, 84, 0.25)
 }
 
 .was-validated textarea.form-control:valid,
 textarea.form-control.is-valid {
     padding-right: calc(1.5em + .75rem);
     background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem)
 }
 
 .was-validated .form-select:valid,
 .form-select.is-valid {
     border-color: #198754
 }
 
 .was-validated .form-select:valid:not([multiple]):not([size]),
 .was-validated .form-select:valid:not([multiple])[size="1"],
 .form-select.is-valid:not([multiple]):not([size]),
 .form-select.is-valid:not([multiple])[size="1"] {
     padding-right: 4.125rem;
     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
     background-position: right .75rem center, center right 2.25rem;
     background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem)
 }
 
 .was-validated .form-select:valid:focus,
 .form-select.is-valid:focus {
     border-color: #198754;
     box-shadow: 0 0 0 .25rem rgba(25, 135, 84, 0.25)
 }
 
 .was-validated .form-check-input:valid,
 .form-check-input.is-valid {
     border-color: #198754
 }
 
 .was-validated .form-check-input:valid:checked,
 .form-check-input.is-valid:checked {
     background-color: #198754
 }
 
 .was-validated .form-check-input:valid:focus,
 .form-check-input.is-valid:focus {
     box-shadow: 0 0 0 .25rem rgba(25, 135, 84, 0.25)
 }
 
 .was-validated .form-check-input:valid~.form-check-label,
 .form-check-input.is-valid~.form-check-label {
     color: #198754
 }
 
 .form-check-inline .form-check-input~.valid-feedback {
     margin-left: .5em
 }
 
 .was-validated .input-group .form-control:valid,
 .input-group .form-control.is-valid,
 .was-validated .input-group .form-select:valid,
 .input-group .form-select.is-valid {
     z-index: 1
 }
 
 .was-validated .input-group .form-control:valid:focus,
 .input-group .form-control.is-valid:focus,
 .was-validated .input-group .form-select:valid:focus,
 .input-group .form-select.is-valid:focus {
     z-index: 3
 }
 
 .invalid-feedback {
     display: none;
     width: 100%;
     margin-top: .25rem;
     font-size: .875em;
     color: #dc3545
 }
 
 .invalid-tooltip {
     position: absolute;
     top: 100%;
     z-index: 5;
     display: none;
     max-width: 100%;
     padding: .25rem .5rem;
     margin-top: .1rem;
     font-size: .875rem;
     color: #fff;
     background-color: rgba(220, 53, 69, 0.9);
     border-radius: .25rem
 }
 
 .was-validated :invalid~.invalid-feedback,
 .was-validated :invalid~.invalid-tooltip,
 .is-invalid~.invalid-feedback,
 .is-invalid~.invalid-tooltip {
     display: block
 }
 
 .was-validated .form-control:invalid,
 .form-control.is-invalid {
     border-color: #dc3545;
     padding-right: calc(1.5em + .75rem);
     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
     background-repeat: no-repeat;
     background-position: right calc(.375em + .1875rem) center;
     background-size: calc(.75em + .375rem) calc(.75em + .375rem)
 }
 
 .was-validated .form-control:invalid:focus,
 .form-control.is-invalid:focus {
     border-color: #dc3545;
     box-shadow: 0 0 0 .25rem rgba(220, 53, 69, 0.25)
 }
 
 .was-validated textarea.form-control:invalid,
 textarea.form-control.is-invalid {
     padding-right: calc(1.5em + .75rem);
     background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem)
 }
 
 .was-validated .form-select:invalid,
 .form-select.is-invalid {
     border-color: #dc3545
 }
 
 .was-validated .form-select:invalid:not([multiple]):not([size]),
 .was-validated .form-select:invalid:not([multiple])[size="1"],
 .form-select.is-invalid:not([multiple]):not([size]),
 .form-select.is-invalid:not([multiple])[size="1"] {
     padding-right: 4.125rem;
     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
     background-position: right .75rem center, center right 2.25rem;
     background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem)
 }
 
 .was-validated .form-select:invalid:focus,
 .form-select.is-invalid:focus {
     border-color: #dc3545;
     box-shadow: 0 0 0 .25rem rgba(220, 53, 69, 0.25)
 }
 
 .was-validated .form-check-input:invalid,
 .form-check-input.is-invalid {
     border-color: #dc3545
 }
 
 .was-validated .form-check-input:invalid:checked,
 .form-check-input.is-invalid:checked {
     background-color: #dc3545
 }
 
 .was-validated .form-check-input:invalid:focus,
 .form-check-input.is-invalid:focus {
     box-shadow: 0 0 0 .25rem rgba(220, 53, 69, 0.25)
 }
 
 .was-validated .form-check-input:invalid~.form-check-label,
 .form-check-input.is-invalid~.form-check-label {
     color: #dc3545
 }
 
 .form-check-inline .form-check-input~.invalid-feedback {
     margin-left: .5em
 }
 
 .was-validated .input-group .form-control:invalid,
 .input-group .form-control.is-invalid,
 .was-validated .input-group .form-select:invalid,
 .input-group .form-select.is-invalid {
     z-index: 2
 }
 
 .was-validated .input-group .form-control:invalid:focus,
 .input-group .form-control.is-invalid:focus,
 .was-validated .input-group .form-select:invalid:focus,
 .input-group .form-select.is-invalid:focus {
     z-index: 3
 }
 
 .btn {
     display: inline-block;
     font-weight: 400;
     line-height: 1.5;
     color: #212529;
     text-align: center;
     text-decoration: none;
     vertical-align: middle;
     cursor: pointer;
     user-select: none;
     background-color: transparent;
     border: 1px solid transparent;
     padding: .375rem .75rem;
     font-size: 1rem;
     border-radius: .25rem;
     transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
 }
 
 @media (prefers-reduced-motion: reduce) {
     .btn {
         transition: none
     }
 }
 
 .btn:hover {
     color: #212529
 }
 
 .btn-check:focus+.btn,
 .btn:focus {
     outline: 0;
     box-shadow: 0 0 0 .25rem rgba(13, 110, 253, 0.25)
 }
 
 .btn:disabled,
 .btn.disabled,
 fieldset:disabled .btn {
     pointer-events: none;
     opacity: .65
 }
 
 .btn-primary {
     color: #fff;
     background-color: #0d6efd;
     border-color: #0d6efd
 }
 
 .btn-primary:hover {
     color: #fff;
     background-color: #0b5ed7;
     border-color: #0a58ca
 }
 
 .btn-check:focus+.btn-primary,
 .btn-primary:focus {
     color: #fff;
     background-color: #0b5ed7;
     border-color: #0a58ca;
     box-shadow: 0 0 0 .25rem rgba(49, 132, 253, 0.5)
 }
 
 .btn-check:checked+.btn-primary,
 .btn-check:active+.btn-primary,
 .btn-primary:active,
 .btn-primary.active,
 .show>.btn-primary.dropdown-toggle {
     color: #fff;
     background-color: #0a58ca;
     border-color: #0a53be
 }
 
 .btn-check:checked+.btn-primary:focus,
 .btn-check:active+.btn-primary:focus,
 .btn-primary:active:focus,
 .btn-primary.active:focus,
 .show>.btn-primary.dropdown-toggle:focus {
     box-shadow: 0 0 0 .25rem rgba(49, 132, 253, 0.5)
 }
 
 .btn-primary:disabled,
 .btn-primary.disabled {
     color: #fff;
     background-color: #0d6efd;
     border-color: #0d6efd
 }
 
 .btn-secondary {
     color: #fff;
     background-color: #6c757d;
     border-color: #6c757d
 }
 
 .btn-secondary:hover {
     color: #fff;
     background-color: #5c636a;
     border-color: #565e64
 }
 
 .btn-check:focus+.btn-secondary,
 .btn-secondary:focus {
     color: #fff;
     background-color: #5c636a;
     border-color: #565e64;
     box-shadow: 0 0 0 .25rem rgba(130, 138, 145, 0.5)
 }
 
 .btn-check:checked+.btn-secondary,
 .btn-check:active+.btn-secondary,
 .btn-secondary:active,
 .btn-secondary.active,
 .show>.btn-secondary.dropdown-toggle {
     color: #fff;
     background-color: #565e64;
     border-color: #51585e
 }
 
 .btn-check:checked+.btn-secondary:focus,
 .btn-check:active+.btn-secondary:focus,
 .btn-secondary:active:focus,
 .btn-secondary.active:focus,
 .show>.btn-secondary.dropdown-toggle:focus {
     box-shadow: 0 0 0 .25rem rgba(130, 138, 145, 0.5)
 }
 
 .btn-secondary:disabled,
 .btn-secondary.disabled {
     color: #fff;
     background-color: #6c757d;
     border-color: #6c757d
 }
 
 .btn-success {
     color: #fff;
     background-color: #198754;
     border-color: #198754
 }
 
 .btn-success:hover {
     color: #fff;
     background-color: #157347;
     border-color: #146c43
 }
 
 .btn-check:focus+.btn-success,
 .btn-success:focus {
     color: #fff;
     background-color: #157347;
     border-color: #146c43;
     box-shadow: 0 0 0 .25rem rgba(60, 153, 110, 0.5)
 }
 
 .btn-check:checked+.btn-success,
 .btn-check:active+.btn-success,
 .btn-success:active,
 .btn-success.active,
 .show>.btn-success.dropdown-toggle {
     color: #fff;
     background-color: #146c43;
     border-color: #13653f
 }
 
 .btn-check:checked+.btn-success:focus,
 .btn-check:active+.btn-success:focus,
 .btn-success:active:focus,
 .btn-success.active:focus,
 .show>.btn-success.dropdown-toggle:focus {
     box-shadow: 0 0 0 .25rem rgba(60, 153, 110, 0.5)
 }
 
 .btn-success:disabled,
 .btn-success.disabled {
     color: #fff;
     background-color: #198754;
     border-color: #198754
 }
 
 .btn-info {
     color: #000;
     background-color: #0dcaf0;
     border-color: #0dcaf0
 }
 
 .btn-info:hover {
     color: #000;
     background-color: #31d2f2;
     border-color: #25cff2
 }
 
 .btn-check:focus+.btn-info,
 .btn-info:focus {
     color: #000;
     background-color: #31d2f2;
     border-color: #25cff2;
     box-shadow: 0 0 0 .25rem rgba(11, 172, 204, 0.5)
 }
 
 .btn-check:checked+.btn-info,
 .btn-check:active+.btn-info,
 .btn-info:active,
 .btn-info.active,
 .show>.btn-info.dropdown-toggle {
     color: #000;
     background-color: #3dd5f3;
     border-color: #25cff2
 }
 
 .btn-check:checked+.btn-info:focus,
 .btn-check:active+.btn-info:focus,
 .btn-info:active:focus,
 .btn-info.active:focus,
 .show>.btn-info.dropdown-toggle:focus {
     box-shadow: 0 0 0 .25rem rgba(11, 172, 204, 0.5)
 }
 
 .btn-info:disabled,
 .btn-info.disabled {
     color: #000;
     background-color: #0dcaf0;
     border-color: #0dcaf0
 }
 
 .btn-warning {
     color: #000;
     background-color: #ffc107;
     border-color: #ffc107
 }
 
 .btn-warning:hover {
     color: #000;
     background-color: #ffca2c;
     border-color: #ffc720
 }
 
 .btn-check:focus+.btn-warning,
 .btn-warning:focus {
     color: #000;
     background-color: #ffca2c;
     border-color: #ffc720;
     box-shadow: 0 0 0 .25rem rgba(217, 164, 6, 0.5)
 }
 
 .btn-check:checked+.btn-warning,
 .btn-check:active+.btn-warning,
 .btn-warning:active,
 .btn-warning.active,
 .show>.btn-warning.dropdown-toggle {
     color: #000;
     background-color: #ffcd39;
     border-color: #ffc720
 }
 
 .btn-check:checked+.btn-warning:focus,
 .btn-check:active+.btn-warning:focus,
 .btn-warning:active:focus,
 .btn-warning.active:focus,
 .show>.btn-warning.dropdown-toggle:focus {
     box-shadow: 0 0 0 .25rem rgba(217, 164, 6, 0.5)
 }
 
 .btn-warning:disabled,
 .btn-warning.disabled {
     color: #000;
     background-color: #ffc107;
     border-color: #ffc107
 }
 
 .btn-danger {
     color: #fff;
     background-color: #dc3545;
     border-color: #dc3545
 }
 
 .btn-danger:hover {
     color: #fff;
     background-color: #bb2d3b;
     border-color: #b02a37
 }
 
 .btn-check:focus+.btn-danger,
 .btn-danger:focus {
     color: #fff;
     background-color: #bb2d3b;
     border-color: #b02a37;
     box-shadow: 0 0 0 .25rem rgba(225, 83, 97, 0.5)
 }
 
 .btn-check:checked+.btn-danger,
 .btn-check:active+.btn-danger,
 .btn-danger:active,
 .btn-danger.active,
 .show>.btn-danger.dropdown-toggle {
     color: #fff;
     background-color: #b02a37;
     border-color: #a52834
 }
 
 .btn-check:checked+.btn-danger:focus,
 .btn-check:active+.btn-danger:focus,
 .btn-danger:active:focus,
 .btn-danger.active:focus,
 .show>.btn-danger.dropdown-toggle:focus {
     box-shadow: 0 0 0 .25rem rgba(225, 83, 97, 0.5)
 }
 
 .btn-danger:disabled,
 .btn-danger.disabled {
     color: #fff;
     background-color: #dc3545;
     border-color: #dc3545
 }
 
 .btn-light {
     color: #000;
     background-color: #f8f9fa;
     border-color: #f8f9fa
 }
 
 .btn-light:hover {
     color: #000;
     background-color: #f9fafb;
     border-color: #f9fafb
 }
 
 .btn-check:focus+.btn-light,
 .btn-light:focus {
     color: #000;
     background-color: #f9fafb;
     border-color: #f9fafb;
     box-shadow: 0 0 0 .25rem rgba(211, 212, 213, 0.5)
 }
 
 .btn-check:checked+.btn-light,
 .btn-check:active+.btn-light,
 .btn-light:active,
 .btn-light.active,
 .show>.btn-light.dropdown-toggle {
     color: #000;
     background-color: #f9fafb;
     border-color: #f9fafb
 }
 
 .btn-check:checked+.btn-light:focus,
 .btn-check:active+.btn-light:focus,
 .btn-light:active:focus,
 .btn-light.active:focus,
 .show>.btn-light.dropdown-toggle:focus {
     box-shadow: 0 0 0 .25rem rgba(211, 212, 213, 0.5)
 }
 
 .btn-light:disabled,
 .btn-light.disabled {
     color: #000;
     background-color: #f8f9fa;
     border-color: #f8f9fa
 }
 
 .btn-dark {
     color: #fff;
     background-color: #212529;
     border-color: #212529
 }
 
 .btn-dark:hover {
     color: #fff;
     background-color: #1c1f23;
     border-color: #1a1e21
 }
 
 .btn-check:focus+.btn-dark,
 .btn-dark:focus {
     color: #fff;
     background-color: #1c1f23;
     border-color: #1a1e21;
     box-shadow: 0 0 0 .25rem rgba(66, 70, 73, 0.5)
 }
 
 .btn-check:checked+.btn-dark,
 .btn-check:active+.btn-dark,
 .btn-dark:active,
 .btn-dark.active,
 .show>.btn-dark.dropdown-toggle {
     color: #fff;
     background-color: #1a1e21;
     border-color: #191c1f
 }
 
 .btn-check:checked+.btn-dark:focus,
 .btn-check:active+.btn-dark:focus,
 .btn-dark:active:focus,
 .btn-dark.active:focus,
 .show>.btn-dark.dropdown-toggle:focus {
     box-shadow: 0 0 0 .25rem rgba(66, 70, 73, 0.5)
 }
 
 .btn-dark:disabled,
 .btn-dark.disabled {
     color: #fff;
     background-color: #212529;
     border-color: #212529
 }
 
 .btn-outline-primary {
     color: #0d6efd;
     border-color: #0d6efd
 }
 
 .btn-outline-primary:hover {
     color: #fff;
     background-color: #0d6efd;
     border-color: #0d6efd
 }
 
 .btn-check:focus+.btn-outline-primary,
 .btn-outline-primary:focus {
     box-shadow: 0 0 0 .25rem rgba(13, 110, 253, 0.5)
 }
 
 .btn-check:checked+.btn-outline-primary,
 .btn-check:active+.btn-outline-primary,
 .btn-outline-primary:active,
 .btn-outline-primary.active,
 .btn-outline-primary.dropdown-toggle.show {
     color: #fff;
     background-color: #0d6efd;
     border-color: #0d6efd
 }
 
 .btn-check:checked+.btn-outline-primary:focus,
 .btn-check:active+.btn-outline-primary:focus,
 .btn-outline-primary:active:focus,
 .btn-outline-primary.active:focus,
 .btn-outline-primary.dropdown-toggle.show:focus {
     box-shadow: 0 0 0 .25rem rgba(13, 110, 253, 0.5)
 }
 
 .btn-outline-primary:disabled,
 .btn-outline-primary.disabled {
     color: #0d6efd;
     background-color: transparent
 }
 
 .btn-outline-secondary {
     color: #6c757d;
     border-color: #6c757d
 }
 
 .btn-outline-secondary:hover {
     color: #fff;
     background-color: #6c757d;
     border-color: #6c757d
 }
 
 .btn-check:focus+.btn-outline-secondary,
 .btn-outline-secondary:focus {
     box-shadow: 0 0 0 .25rem rgba(108, 117, 125, 0.5)
 }
 
 .btn-check:checked+.btn-outline-secondary,
 .btn-check:active+.btn-outline-secondary,
 .btn-outline-secondary:active,
 .btn-outline-secondary.active,
 .btn-outline-secondary.dropdown-toggle.show {
     color: #fff;
     background-color: #6c757d;
     border-color: #6c757d
 }
 
 .btn-check:checked+.btn-outline-secondary:focus,
 .btn-check:active+.btn-outline-secondary:focus,
 .btn-outline-secondary:active:focus,
 .btn-outline-secondary.active:focus,
 .btn-outline-secondary.dropdown-toggle.show:focus {
     box-shadow: 0 0 0 .25rem rgba(108, 117, 125, 0.5)
 }
 
 .btn-outline-secondary:disabled,
 .btn-outline-secondary.disabled {
     color: #6c757d;
     background-color: transparent
 }
 
 .btn-outline-success {
     color: #198754;
     border-color: #198754
 }
 
 .btn-outline-success:hover {
     color: #fff;
     background-color: #198754;
     border-color: #198754
 }
 
 .btn-check:focus+.btn-outline-success,
 .btn-outline-success:focus {
     box-shadow: 0 0 0 .25rem rgba(25, 135, 84, 0.5)
 }
 
 .btn-check:checked+.btn-outline-success,
 .btn-check:active+.btn-outline-success,
 .btn-outline-success:active,
 .btn-outline-success.active,
 .btn-outline-success.dropdown-toggle.show {
     color: #fff;
     background-color: #198754;
     border-color: #198754
 }
 
 .btn-check:checked+.btn-outline-success:focus,
 .btn-check:active+.btn-outline-success:focus,
 .btn-outline-success:active:focus,
 .btn-outline-success.active:focus,
 .btn-outline-success.dropdown-toggle.show:focus {
     box-shadow: 0 0 0 .25rem rgba(25, 135, 84, 0.5)
 }
 
 .btn-outline-success:disabled,
 .btn-outline-success.disabled {
     color: #198754;
     background-color: transparent
 }
 
 .btn-outline-info {
     color: #0dcaf0;
     border-color: #0dcaf0
 }
 
 .btn-outline-info:hover {
     color: #000;
     background-color: #0dcaf0;
     border-color: #0dcaf0
 }
 
 .btn-check:focus+.btn-outline-info,
 .btn-outline-info:focus {
     box-shadow: 0 0 0 .25rem rgba(13, 202, 240, 0.5)
 }
 
 .btn-check:checked+.btn-outline-info,
 .btn-check:active+.btn-outline-info,
 .btn-outline-info:active,
 .btn-outline-info.active,
 .btn-outline-info.dropdown-toggle.show {
     color: #000;
     background-color: #0dcaf0;
     border-color: #0dcaf0
 }
 
 .btn-check:checked+.btn-outline-info:focus,
 .btn-check:active+.btn-outline-info:focus,
 .btn-outline-info:active:focus,
 .btn-outline-info.active:focus,
 .btn-outline-info.dropdown-toggle.show:focus {
     box-shadow: 0 0 0 .25rem rgba(13, 202, 240, 0.5)
 }
 
 .btn-outline-info:disabled,
 .btn-outline-info.disabled {
     color: #0dcaf0;
     background-color: transparent
 }
 
 .btn-outline-warning {
     color: #ffc107;
     border-color: #ffc107
 }
 
 .btn-outline-warning:hover {
     color: #000;
     background-color: #ffc107;
     border-color: #ffc107
 }
 
 .btn-check:focus+.btn-outline-warning,
 .btn-outline-warning:focus {
     box-shadow: 0 0 0 .25rem rgba(255, 193, 7, 0.5)
 }
 
 .btn-check:checked+.btn-outline-warning,
 .btn-check:active+.btn-outline-warning,
 .btn-outline-warning:active,
 .btn-outline-warning.active,
 .btn-outline-warning.dropdown-toggle.show {
     color: #000;
     background-color: #ffc107;
     border-color: #ffc107
 }
 
 .btn-check:checked+.btn-outline-warning:focus,
 .btn-check:active+.btn-outline-warning:focus,
 .btn-outline-warning:active:focus,
 .btn-outline-warning.active:focus,
 .btn-outline-warning.dropdown-toggle.show:focus {
     box-shadow: 0 0 0 .25rem rgba(255, 193, 7, 0.5)
 }
 
 .btn-outline-warning:disabled,
 .btn-outline-warning.disabled {
     color: #ffc107;
     background-color: transparent
 }
 
 .btn-outline-danger {
     color: #dc3545;
     border-color: #dc3545
 }
 
 .btn-outline-danger:hover {
     color: #fff;
     background-color: #dc3545;
     border-color: #dc3545
 }
 
 .btn-check:focus+.btn-outline-danger,
 .btn-outline-danger:focus {
     box-shadow: 0 0 0 .25rem rgba(220, 53, 69, 0.5)
 }
 
 .btn-check:checked+.btn-outline-danger,
 .btn-check:active+.btn-outline-danger,
 .btn-outline-danger:active,
 .btn-outline-danger.active,
 .btn-outline-danger.dropdown-toggle.show {
     color: #fff;
     background-color: #dc3545;
     border-color: #dc3545
 }
 
 .btn-check:checked+.btn-outline-danger:focus,
 .btn-check:active+.btn-outline-danger:focus,
 .btn-outline-danger:active:focus,
 .btn-outline-danger.active:focus,
 .btn-outline-danger.dropdown-toggle.show:focus {
     box-shadow: 0 0 0 .25rem rgba(220, 53, 69, 0.5)
 }
 
 .btn-outline-danger:disabled,
 .btn-outline-danger.disabled {
     color: #dc3545;
     background-color: transparent
 }
 
 .btn-outline-light {
     color: #f8f9fa;
     border-color: #f8f9fa
 }
 
 .btn-outline-light:hover {
     color: #000;
     background-color: #f8f9fa;
     border-color: #f8f9fa
 }
 
 .btn-check:focus+.btn-outline-light,
 .btn-outline-light:focus {
     box-shadow: 0 0 0 .25rem rgba(248, 249, 250, 0.5)
 }
 
 .btn-check:checked+.btn-outline-light,
 .btn-check:active+.btn-outline-light,
 .btn-outline-light:active,
 .btn-outline-light.active,
 .btn-outline-light.dropdown-toggle.show {
     color: #000;
     background-color: #f8f9fa;
     border-color: #f8f9fa
 }
 
 .btn-check:checked+.btn-outline-light:focus,
 .btn-check:active+.btn-outline-light:focus,
 .btn-outline-light:active:focus,
 .btn-outline-light.active:focus,
 .btn-outline-light.dropdown-toggle.show:focus {
     box-shadow: 0 0 0 .25rem rgba(248, 249, 250, 0.5)
 }
 
 .btn-outline-light:disabled,
 .btn-outline-light.disabled {
     color: #f8f9fa;
     background-color: transparent
 }
 
 .btn-outline-dark {
     color: #212529;
     border-color: #212529
 }
 
 .btn-outline-dark:hover {
     color: #fff;
     background-color: #212529;
     border-color: #212529
 }
 
 .btn-check:focus+.btn-outline-dark,
 .btn-outline-dark:focus {
     box-shadow: 0 0 0 .25rem rgba(33, 37, 41, 0.5)
 }
 
 .btn-check:checked+.btn-outline-dark,
 .btn-check:active+.btn-outline-dark,
 .btn-outline-dark:active,
 .btn-outline-dark.active,
 .btn-outline-dark.dropdown-toggle.show {
     color: #fff;
     background-color: #212529;
     border-color: #212529
 }
 
 .btn-check:checked+.btn-outline-dark:focus,
 .btn-check:active+.btn-outline-dark:focus,
 .btn-outline-dark:active:focus,
 .btn-outline-dark.active:focus,
 .btn-outline-dark.dropdown-toggle.show:focus {
     box-shadow: 0 0 0 .25rem rgba(33, 37, 41, 0.5)
 }
 
 .btn-outline-dark:disabled,
 .btn-outline-dark.disabled {
     color: #212529;
     background-color: transparent
 }
 
 .btn-link {
     font-weight: 400;
     color: #0d6efd;
     text-decoration: underline
 }
 
 .btn-link:hover {
     color: #0a58ca
 }
 
 .btn-link:disabled,
 .btn-link.disabled {
     color: #6c757d
 }
 
 .btn-lg,
 .btn-group-lg>.btn {
     padding: .5rem 1rem;
     font-size: 1.25rem;
     border-radius: .3rem
 }
 
 .btn-sm,
 .btn-group-sm>.btn {
     padding: .25rem .5rem;
     font-size: .875rem;
     border-radius: .2rem
 }
 
 .fade {
     transition: opacity 0.15s linear
 }
 
 @media (prefers-reduced-motion: reduce) {
     .fade {
         transition: none
     }
 }
 
 .fade:not(.show) {
     opacity: 0
 }
 
 .collapse:not(.show) {
     display: none
 }
 
 .collapsing {
     height: 0;
     overflow: hidden;
     transition: height 0.35s ease
 }
 
 @media (prefers-reduced-motion: reduce) {
     .collapsing {
         transition: none
     }
 }
 
 .collapsing.collapse-horizontal {
     width: 0;
     height: auto;
     transition: width 0.35s ease
 }
 
 @media (prefers-reduced-motion: reduce) {
     .collapsing.collapse-horizontal {
         transition: none
     }
 }
 
 .dropup,
 .dropend,
 .dropdown,
 .dropstart {
     position: relative
 }
 
 .dropdown-toggle {
     white-space: nowrap
 }
 
 .dropdown-toggle::after {
     display: inline-block;
     margin-left: .255em;
     vertical-align: .255em;
     content: "";
     border-top: .3em solid;
     border-right: .3em solid transparent;
     border-bottom: 0;
     border-left: .3em solid transparent
 }
 
 .dropdown-toggle:empty::after {
     margin-left: 0
 }
 
 .dropdown-menu {
     position: absolute;
     z-index: 1000;
     display: none;
     min-width: 10rem;
     padding: .5rem 0;
     margin: 0;
     font-size: 1rem;
     color: #212529;
     text-align: left;
     list-style: none;
     background-color: #fff;
     background-clip: padding-box;
     border: 1px solid rgba(0, 0, 0, 0.15);
     border-radius: .25rem
 }
 
 .dropdown-menu[data-bs-popper] {
     top: 100%;
     left: 0;
     margin-top: .125rem
 }
 
 .dropdown-menu-start {
     --bs-position: start
 }
 
 .dropdown-menu-start[data-bs-popper] {
     right: auto;
     left: 0
 }
 
 .dropdown-menu-end {
     --bs-position: end
 }
 
 .dropdown-menu-end[data-bs-popper] {
     right: 0;
     left: auto
 }
 
 @media (min-width: 576px) {
     .dropdown-menu-sm-start {
         --bs-position: start
     }
 
     .dropdown-menu-sm-start[data-bs-popper] {
         right: auto;
         left: 0
     }
 
     .dropdown-menu-sm-end {
         --bs-position: end
     }
 
     .dropdown-menu-sm-end[data-bs-popper] {
         right: 0;
         left: auto
     }
 }
 
 @media (min-width: 768px) {
     .dropdown-menu-md-start {
         --bs-position: start
     }
 
     .dropdown-menu-md-start[data-bs-popper] {
         right: auto;
         left: 0
     }
 
     .dropdown-menu-md-end {
         --bs-position: end
     }
 
     .dropdown-menu-md-end[data-bs-popper] {
         right: 0;
         left: auto
     }
 }
 
 @media (min-width: 992px) {
     .dropdown-menu-lg-start {
         --bs-position: start
     }
 
     .dropdown-menu-lg-start[data-bs-popper] {
         right: auto;
         left: 0
     }
 
     .dropdown-menu-lg-end {
         --bs-position: end
     }
 
     .dropdown-menu-lg-end[data-bs-popper] {
         right: 0;
         left: auto
     }
 }
 
 @media (min-width: 1200px) {
     .dropdown-menu-xl-start {
         --bs-position: start
     }
 
     .dropdown-menu-xl-start[data-bs-popper] {
         right: auto;
         left: 0
     }
 
     .dropdown-menu-xl-end {
         --bs-position: end
     }
 
     .dropdown-menu-xl-end[data-bs-popper] {
         right: 0;
         left: auto
     }
 }
 
 @media (min-width: 1400px) {
     .dropdown-menu-xxl-start {
         --bs-position: start
     }
 
     .dropdown-menu-xxl-start[data-bs-popper] {
         right: auto;
         left: 0
     }
 
     .dropdown-menu-xxl-end {
         --bs-position: end
     }
 
     .dropdown-menu-xxl-end[data-bs-popper] {
         right: 0;
         left: auto
     }
 }
 
 .dropup .dropdown-menu[data-bs-popper] {
     top: auto;
     bottom: 100%;
     margin-top: 0;
     margin-bottom: .125rem
 }
 
 .dropup .dropdown-toggle::after {
     display: inline-block;
     margin-left: .255em;
     vertical-align: .255em;
     content: "";
     border-top: 0;
     border-right: .3em solid transparent;
     border-bottom: .3em solid;
     border-left: .3em solid transparent
 }
 
 .dropup .dropdown-toggle:empty::after {
     margin-left: 0
 }
 
 .dropend .dropdown-menu[data-bs-popper] {
     top: 0;
     right: auto;
     left: 100%;
     margin-top: 0;
     margin-left: .125rem
 }
 
 .dropend .dropdown-toggle::after {
     display: inline-block;
     margin-left: .255em;
     vertical-align: .255em;
     content: "";
     border-top: .3em solid transparent;
     border-right: 0;
     border-bottom: .3em solid transparent;
     border-left: .3em solid
 }
 
 .dropend .dropdown-toggle:empty::after {
     margin-left: 0
 }
 
 .dropend .dropdown-toggle::after {
     vertical-align: 0
 }
 
 .dropstart .dropdown-menu[data-bs-popper] {
     top: 0;
     right: 100%;
     left: auto;
     margin-top: 0;
     margin-right: .125rem
 }
 
 .dropstart .dropdown-toggle::after {
     display: inline-block;
     margin-left: .255em;
     vertical-align: .255em;
     content: ""
 }
 
 .dropstart .dropdown-toggle::after {
     display: none
 }
 
 .dropstart .dropdown-toggle::before {
     display: inline-block;
     margin-right: .255em;
     vertical-align: .255em;
     content: "";
     border-top: .3em solid transparent;
     border-right: .3em solid;
     border-bottom: .3em solid transparent
 }
 
 .dropstart .dropdown-toggle:empty::after {
     margin-left: 0
 }
 
 .dropstart .dropdown-toggle::before {
     vertical-align: 0
 }
 
 .dropdown-divider {
     height: 0;
     margin: .5rem 0;
     overflow: hidden;
     border-top: 1px solid rgba(0, 0, 0, 0.15)
 }
 
 .dropdown-item {
     display: block;
     width: 100%;
     padding: .25rem 1rem;
     clear: both;
     font-weight: 400;
     color: #212529;
     text-align: inherit;
     text-decoration: none;
     white-space: nowrap;
     background-color: transparent;
     border: 0
 }
 
 .dropdown-item:hover,
 .dropdown-item:focus {
     color: #1e2125;
     background-color: #e9ecef
 }
 
 .dropdown-item.active,
 .dropdown-item:active {
     color: #fff;
     text-decoration: none;
     background-color: #0d6efd
 }
 
 .dropdown-item.disabled,
 .dropdown-item:disabled {
     color: #adb5bd;
     pointer-events: none;
     background-color: transparent
 }
 
 .dropdown-menu.show {
     display: block
 }
 
 .dropdown-header {
     display: block;
     padding: .5rem 1rem;
     margin-bottom: 0;
     font-size: .875rem;
     color: #6c757d;
     white-space: nowrap
 }
 
 .dropdown-item-text {
     display: block;
     padding: .25rem 1rem;
     color: #212529
 }
 
 .dropdown-menu-dark {
     color: #dee2e6;
     background-color: #343a40;
     border-color: rgba(0, 0, 0, 0.15)
 }
 
 .dropdown-menu-dark .dropdown-item {
     color: #dee2e6
 }
 
 .dropdown-menu-dark .dropdown-item:hover,
 .dropdown-menu-dark .dropdown-item:focus {
     color: #fff;
     background-color: rgba(255, 255, 255, 0.15)
 }
 
 .dropdown-menu-dark .dropdown-item.active,
 .dropdown-menu-dark .dropdown-item:active {
     color: #fff;
     background-color: #0d6efd
 }
 
 .dropdown-menu-dark .dropdown-item.disabled,
 .dropdown-menu-dark .dropdown-item:disabled {
     color: #adb5bd
 }
 
 .dropdown-menu-dark .dropdown-divider {
     border-color: rgba(0, 0, 0, 0.15)
 }
 
 .dropdown-menu-dark .dropdown-item-text {
     color: #dee2e6
 }
 
 .dropdown-menu-dark .dropdown-header {
     color: #adb5bd
 }
 
 .btn-group,
 .btn-group-vertical {
     position: relative;
     display: inline-flex;
     vertical-align: middle
 }
 
 .btn-group>.btn,
 .btn-group-vertical>.btn {
     position: relative;
     flex: 1 1 auto
 }
 
 .btn-group>.btn-check:checked+.btn,
 .btn-group>.btn-check:focus+.btn,
 .btn-group>.btn:hover,
 .btn-group>.btn:focus,
 .btn-group>.btn:active,
 .btn-group>.btn.active,
 .btn-group-vertical>.btn-check:checked+.btn,
 .btn-group-vertical>.btn-check:focus+.btn,
 .btn-group-vertical>.btn:hover,
 .btn-group-vertical>.btn:focus,
 .btn-group-vertical>.btn:active,
 .btn-group-vertical>.btn.active {
     z-index: 1
 }
 
 .btn-toolbar {
     display: flex;
     flex-wrap: wrap;
     justify-content: flex-start
 }
 
 .btn-toolbar .input-group {
     width: auto
 }
 
 .btn-group>.btn:not(:first-child),
 .btn-group>.btn-group:not(:first-child) {
     margin-left: -1px
 }
 
 .btn-group>.btn:not(:last-child):not(.dropdown-toggle),
 .btn-group>.btn-group:not(:last-child)>.btn {
     border-top-right-radius: 0;
     border-bottom-right-radius: 0
 }
 
 .btn-group>.btn:nth-child(n+3),
 .btn-group>:not(.btn-check)+.btn,
 .btn-group>.btn-group:not(:first-child)>.btn {
     border-top-left-radius: 0;
     border-bottom-left-radius: 0
 }
 
 .dropdown-toggle-split {
     padding-right: .5625rem;
     padding-left: .5625rem
 }
 
 .dropdown-toggle-split::after,
 .dropup .dropdown-toggle-split::after,
 .dropend .dropdown-toggle-split::after {
     margin-left: 0
 }
 
 .dropstart .dropdown-toggle-split::before {
     margin-right: 0
 }
 
 .btn-sm+.dropdown-toggle-split,
 .btn-group-sm>.btn+.dropdown-toggle-split {
     padding-right: .375rem;
     padding-left: .375rem
 }
 
 .btn-lg+.dropdown-toggle-split,
 .btn-group-lg>.btn+.dropdown-toggle-split {
     padding-right: .75rem;
     padding-left: .75rem
 }
 
 .btn-group-vertical {
     flex-direction: column;
     align-items: flex-start;
     justify-content: center
 }
 
 .btn-group-vertical>.btn,
 .btn-group-vertical>.btn-group {
     width: 100%
 }
 
 .btn-group-vertical>.btn:not(:first-child),
 .btn-group-vertical>.btn-group:not(:first-child) {
     margin-top: -1px
 }
 
 .btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
 .btn-group-vertical>.btn-group:not(:last-child)>.btn {
     border-bottom-right-radius: 0;
     border-bottom-left-radius: 0
 }
 
 .btn-group-vertical>.btn~.btn,
 .btn-group-vertical>.btn-group:not(:first-child)>.btn {
     border-top-left-radius: 0;
     border-top-right-radius: 0
 }
 
 .nav {
     display: flex;
     flex-wrap: wrap;
     padding-left: 0;
     margin-bottom: 0;
     list-style: none
 }
 
 .nav-link {
     display: block;
     padding: .5rem 1rem;
     color: #0d6efd;
     text-decoration: none;
     transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out
 }
 
 @media (prefers-reduced-motion: reduce) {
     .nav-link {
         transition: none
     }
 }
 
 .nav-link:hover,
 .nav-link:focus {
     color: #0a58ca
 }
 
 .nav-link.disabled {
     color: #6c757d;
     pointer-events: none;
     cursor: default
 }
 
 .nav-tabs {
     border-bottom: 1px solid #dee2e6
 }
 
 .nav-tabs .nav-link {
     margin-bottom: -1px;
     background: none;
     border: 1px solid transparent;
     border-top-left-radius: .25rem;
     border-top-right-radius: .25rem
 }
 
 .nav-tabs .nav-link:hover,
 .nav-tabs .nav-link:focus {
     border-color: #e9ecef #e9ecef #dee2e6;
     isolation: isolate
 }
 
 .nav-tabs .nav-link.disabled {
     color: #6c757d;
     background-color: transparent;
     border-color: transparent
 }
 
 .nav-tabs .nav-link.active,
 .nav-tabs .nav-item.show .nav-link {
     color: #495057;
     background-color: #fff;
     border-color: #dee2e6 #dee2e6 #fff
 }
 
 .nav-tabs .dropdown-menu {
     margin-top: -1px;
     border-top-left-radius: 0;
     border-top-right-radius: 0
 }
 
 .nav-pills .nav-link {
     background: none;
     border: 0;
     border-radius: .25rem
 }
 
 .nav-pills .nav-link.active,
 .nav-pills .show>.nav-link {
     color: #fff;
     background-color: #0d6efd
 }
 
 .nav-fill>.nav-link,
 .nav-fill .nav-item {
     flex: 1 1 auto;
     text-align: center
 }
 
 .nav-justified>.nav-link,
 .nav-justified .nav-item {
     flex-basis: 0;
     flex-grow: 1;
     text-align: center
 }
 
 .nav-fill .nav-item .nav-link,
 .nav-justified .nav-item .nav-link {
     width: 100%
 }
 
 .tab-content>.tab-pane {
     display: none
 }
 
 .tab-content>.active {
     display: block
 }
 
 .navbar {
     position: relative;
     display: flex;
     flex-wrap: wrap;
     align-items: center;
     justify-content: space-between;
     padding-top: .5rem;
     padding-bottom: .5rem
 }
 
 .navbar>.container,
 .navbar>.container-fluid,
 .navbar>.container-sm,
 .navbar>.container-md,
 .navbar>.container-lg,
 .navbar>.container-xl,
 .navbar>.container-xxl {
     display: flex;
     flex-wrap: inherit;
     align-items: center;
     justify-content: space-between
 }
 
 .navbar-brand {
     padding-top: .3125rem;
     padding-bottom: .3125rem;
     margin-right: 1rem;
     font-size: 1.25rem;
     text-decoration: none;
     white-space: nowrap
 }
 
 .navbar-nav {
     display: flex;
     flex-direction: column;
     padding-left: 0;
     margin-bottom: 0;
     list-style: none
 }
 
 .navbar-nav .nav-link {
     padding-right: 0;
     padding-left: 0
 }
 
 .navbar-nav .dropdown-menu {
     position: static
 }
 
 .navbar-text {
     padding-top: .5rem;
     padding-bottom: .5rem
 }
 
 .navbar-collapse {
     flex-basis: 100%;
     flex-grow: 1;
     align-items: center
 }
 
 .navbar-toggler {
     padding: .25rem .75rem;
     font-size: 1.25rem;
     line-height: 1;
     background-color: transparent;
     border: 1px solid transparent;
     border-radius: .25rem;
     transition: box-shadow 0.15s ease-in-out
 }
 
 @media (prefers-reduced-motion: reduce) {
     .navbar-toggler {
         transition: none
     }
 }
 
 .navbar-toggler:hover {
     text-decoration: none
 }
 
 .navbar-toggler:focus {
     text-decoration: none;
     outline: 0;
     box-shadow: 0 0 0 .25rem
 }
 
 .navbar-toggler-icon {
     display: inline-block;
     width: 1.5em;
     height: 1.5em;
     vertical-align: middle;
     background-repeat: no-repeat;
     background-position: center;
     background-size: 100%
 }
 
 .navbar-nav-scroll {
     max-height: var(--bs-scroll-height, 75vh);
     overflow-y: auto
 }
 
 @media (min-width: 576px) {
     .navbar-expand-sm {
         flex-wrap: nowrap;
         justify-content: flex-start
     }
 
     .navbar-expand-sm .navbar-nav {
         flex-direction: row
     }
 
     .navbar-expand-sm .navbar-nav .dropdown-menu {
         position: absolute
     }
 
     .navbar-expand-sm .navbar-nav .nav-link {
         padding-right: .5rem;
         padding-left: .5rem
     }
 
     .navbar-expand-sm .navbar-nav-scroll {
         overflow: visible
     }
 
     .navbar-expand-sm .navbar-collapse {
         display: flex !important;
         flex-basis: auto
     }
 
     .navbar-expand-sm .navbar-toggler {
         display: none
     }
 
     .navbar-expand-sm .offcanvas-header {
         display: none
     }
 
     .navbar-expand-sm .offcanvas {
         position: inherit;
         bottom: 0;
         z-index: 1000;
         flex-grow: 1;
         visibility: visible !important;
         background-color: transparent;
         border-right: 0;
         border-left: 0;
         transition: none;
         transform: none
     }
 
     .navbar-expand-sm .offcanvas-top,
     .navbar-expand-sm .offcanvas-bottom {
         height: auto;
         border-top: 0;
         border-bottom: 0
     }
 
     .navbar-expand-sm .offcanvas-body {
         display: flex;
         flex-grow: 0;
         padding: 0;
         overflow-y: visible
     }
 }
 
 @media (min-width: 768px) {
     .navbar-expand-md {
         flex-wrap: nowrap;
         justify-content: flex-start
     }
 
     .navbar-expand-md .navbar-nav {
         flex-direction: row
     }
 
     .navbar-expand-md .navbar-nav .dropdown-menu {
         position: absolute
     }
 
     .navbar-expand-md .navbar-nav .nav-link {
         padding-right: .5rem;
         padding-left: .5rem
     }
 
     .navbar-expand-md .navbar-nav-scroll {
         overflow: visible
     }
 
     .navbar-expand-md .navbar-collapse {
         display: flex !important;
         flex-basis: auto
     }
 
     .navbar-expand-md .navbar-toggler {
         display: none
     }
 
     .navbar-expand-md .offcanvas-header {
         display: none
     }
 
     .navbar-expand-md .offcanvas {
         position: inherit;
         bottom: 0;
         z-index: 1000;
         flex-grow: 1;
         visibility: visible !important;
         background-color: transparent;
         border-right: 0;
         border-left: 0;
         transition: none;
         transform: none
     }
 
     .navbar-expand-md .offcanvas-top,
     .navbar-expand-md .offcanvas-bottom {
         height: auto;
         border-top: 0;
         border-bottom: 0
     }
 
     .navbar-expand-md .offcanvas-body {
         display: flex;
         flex-grow: 0;
         padding: 0;
         overflow-y: visible
     }
 }
 
 @media (min-width: 992px) {
     .navbar-expand-lg {
         flex-wrap: nowrap;
         justify-content: flex-start
     }
 
     .navbar-expand-lg .navbar-nav {
         flex-direction: row
     }
 
     .navbar-expand-lg .navbar-nav .dropdown-menu {
         position: absolute
     }
 
     .navbar-expand-lg .navbar-nav .nav-link {
         padding-right: .5rem;
         padding-left: .5rem
     }
 
     .navbar-expand-lg .navbar-nav-scroll {
         overflow: visible
     }
 
     .navbar-expand-lg .navbar-collapse {
         display: flex !important;
         flex-basis: auto
     }
 
     .navbar-expand-lg .navbar-toggler {
         display: none
     }
 
     .navbar-expand-lg .offcanvas-header {
         display: none
     }
 
     .navbar-expand-lg .offcanvas {
         position: inherit;
         bottom: 0;
         z-index: 1000;
         flex-grow: 1;
         visibility: visible !important;
         background-color: transparent;
         border-right: 0;
         border-left: 0;
         transition: none;
         transform: none
     }
 
     .navbar-expand-lg .offcanvas-top,
     .navbar-expand-lg .offcanvas-bottom {
         height: auto;
         border-top: 0;
         border-bottom: 0
     }
 
     .navbar-expand-lg .offcanvas-body {
         display: flex;
         flex-grow: 0;
         padding: 0;
         overflow-y: visible
     }
 }
 
 @media (min-width: 1200px) {
     .navbar-expand-xl {
         flex-wrap: nowrap;
         justify-content: flex-start
     }
 
     .navbar-expand-xl .navbar-nav {
         flex-direction: row
     }
 
     .navbar-expand-xl .navbar-nav .dropdown-menu {
         position: absolute
     }
 
     .navbar-expand-xl .navbar-nav .nav-link {
         padding-right: .5rem;
         padding-left: .5rem
     }
 
     .navbar-expand-xl .navbar-nav-scroll {
         overflow: visible
     }
 
     .navbar-expand-xl .navbar-collapse {
         display: flex !important;
         flex-basis: auto
     }
 
     .navbar-expand-xl .navbar-toggler {
         display: none
     }
 
     .navbar-expand-xl .offcanvas-header {
         display: none
     }
 
     .navbar-expand-xl .offcanvas {
         position: inherit;
         bottom: 0;
         z-index: 1000;
         flex-grow: 1;
         visibility: visible !important;
         background-color: transparent;
         border-right: 0;
         border-left: 0;
         transition: none;
         transform: none
     }
 
     .navbar-expand-xl .offcanvas-top,
     .navbar-expand-xl .offcanvas-bottom {
         height: auto;
         border-top: 0;
         border-bottom: 0
     }
 
     .navbar-expand-xl .offcanvas-body {
         display: flex;
         flex-grow: 0;
         padding: 0;
         overflow-y: visible
     }
 }
 
 @media (min-width: 1400px) {
     .navbar-expand-xxl {
         flex-wrap: nowrap;
         justify-content: flex-start
     }
 
     .navbar-expand-xxl .navbar-nav {
         flex-direction: row
     }
 
     .navbar-expand-xxl .navbar-nav .dropdown-menu {
         position: absolute
     }
 
     .navbar-expand-xxl .navbar-nav .nav-link {
         padding-right: .5rem;
         padding-left: .5rem
     }
 
     .navbar-expand-xxl .navbar-nav-scroll {
         overflow: visible
     }
 
     .navbar-expand-xxl .navbar-collapse {
         display: flex !important;
         flex-basis: auto
     }
 
     .navbar-expand-xxl .navbar-toggler {
         display: none
     }
 
     .navbar-expand-xxl .offcanvas-header {
         display: none
     }
 
     .navbar-expand-xxl .offcanvas {
         position: inherit;
         bottom: 0;
         z-index: 1000;
         flex-grow: 1;
         visibility: visible !important;
         background-color: transparent;
         border-right: 0;
         border-left: 0;
         transition: none;
         transform: none
     }
 
     .navbar-expand-xxl .offcanvas-top,
     .navbar-expand-xxl .offcanvas-bottom {
         height: auto;
         border-top: 0;
         border-bottom: 0
     }
 
     .navbar-expand-xxl .offcanvas-body {
         display: flex;
         flex-grow: 0;
         padding: 0;
         overflow-y: visible
     }
 }
 
 .navbar-expand {
     flex-wrap: nowrap;
     justify-content: flex-start
 }
 
 .navbar-expand .navbar-nav {
     flex-direction: row
 }
 
 .navbar-expand .navbar-nav .dropdown-menu {
     position: absolute
 }
 
 .navbar-expand .navbar-nav .nav-link {
     padding-right: .5rem;
     padding-left: .5rem
 }
 
 .navbar-expand .navbar-nav-scroll {
     overflow: visible
 }
 
 .navbar-expand .navbar-collapse {
     display: flex !important;
     flex-basis: auto
 }
 
 .navbar-expand .navbar-toggler {
     display: none
 }
 
 .navbar-expand .offcanvas-header {
     display: none
 }
 
 .navbar-expand .offcanvas {
     position: inherit;
     bottom: 0;
     z-index: 1000;
     flex-grow: 1;
     visibility: visible !important;
     background-color: transparent;
     border-right: 0;
     border-left: 0;
     transition: none;
     transform: none
 }
 
 .navbar-expand .offcanvas-top,
 .navbar-expand .offcanvas-bottom {
     height: auto;
     border-top: 0;
     border-bottom: 0
 }
 
 .navbar-expand .offcanvas-body {
     display: flex;
     flex-grow: 0;
     padding: 0;
     overflow-y: visible
 }
 
 .navbar-light .navbar-brand {
     color: rgba(0, 0, 0, 0.9)
 }
 
 .navbar-light .navbar-brand:hover,
 .navbar-light .navbar-brand:focus {
     color: rgba(0, 0, 0, 0.9)
 }
 
 .navbar-light .navbar-nav .nav-link {
     color: rgba(0, 0, 0, 0.55)
 }
 
 .navbar-light .navbar-nav .nav-link:hover,
 .navbar-light .navbar-nav .nav-link:focus {
     color: rgba(0, 0, 0, 0.7)
 }
 
 .navbar-light .navbar-nav .nav-link.disabled {
     color: rgba(0, 0, 0, 0.3)
 }
 
 .navbar-light .navbar-nav .show>.nav-link,
 .navbar-light .navbar-nav .nav-link.active {
     color: rgba(0, 0, 0, 0.9)
 }
 
 .navbar-light .navbar-toggler {
     color: rgba(0, 0, 0, 0.55);
     border-color: rgba(0, 0, 0, 0.1)
 }
 
 .navbar-light .navbar-toggler-icon {
     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280,0,0,0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
 }
 
 .navbar-light .navbar-text {
     color: rgba(0, 0, 0, 0.55)
 }
 
 .navbar-light .navbar-text a,
 .navbar-light .navbar-text a:hover,
 .navbar-light .navbar-text a:focus {
     color: rgba(0, 0, 0, 0.9)
 }
 
 .navbar-dark .navbar-brand {
     color: #fff
 }
 
 .navbar-dark .navbar-brand:hover,
 .navbar-dark .navbar-brand:focus {
     color: #fff
 }
 
 .navbar-dark .navbar-nav .nav-link {
     color: rgba(255, 255, 255, 0.55)
 }
 
 .navbar-dark .navbar-nav .nav-link:hover,
 .navbar-dark .navbar-nav .nav-link:focus {
     color: rgba(255, 255, 255, 0.75)
 }
 
 .navbar-dark .navbar-nav .nav-link.disabled {
     color: rgba(255, 255, 255, 0.25)
 }
 
 .navbar-dark .navbar-nav .show>.nav-link,
 .navbar-dark .navbar-nav .nav-link.active {
     color: #fff
 }
 
 .navbar-dark .navbar-toggler {
     color: rgba(255, 255, 255, 0.55);
     border-color: rgba(255, 255, 255, 0.1)
 }
 
 .navbar-dark .navbar-toggler-icon {
     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255,255,255,0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
 }
 
 .navbar-dark .navbar-text {
     color: rgba(255, 255, 255, 0.55)
 }
 
 .navbar-dark .navbar-text a,
 .navbar-dark .navbar-text a:hover,
 .navbar-dark .navbar-text a:focus {
     color: #fff
 }
 
 .card {
     position: relative;
     display: flex;
     flex-direction: column;
     min-width: 0;
     word-wrap: break-word;
     background-color: #fff;
     background-clip: border-box;
     border: 1px solid rgba(0, 0, 0, 0.125);
     border-radius: .25rem
 }
 
 .card>hr {
     margin-right: 0;
     margin-left: 0
 }
 
 .card>.list-group {
     border-top: inherit;
     border-bottom: inherit
 }
 
 .card>.list-group:first-child {
     border-top-width: 0;
     border-top-left-radius: calc(.25rem - 1px);
     border-top-right-radius: calc(.25rem - 1px)
 }
 
 .card>.list-group:last-child {
     border-bottom-width: 0;
     border-bottom-right-radius: calc(.25rem - 1px);
     border-bottom-left-radius: calc(.25rem - 1px)
 }
 
 .card>.card-header+.list-group,
 .card>.list-group+.card-footer {
     border-top: 0
 }
 
 .card-body {
     flex: 1 1 auto;
     padding: 1rem 1rem
 }
 
 .card-title {
     margin-bottom: .5rem
 }
 
 .card-subtitle {
     margin-top: -.25rem;
     margin-bottom: 0
 }
 
 .card-text:last-child {
     margin-bottom: 0
 }
 
 .card-link+.card-link {
     margin-left: 1rem
 }
 
 .card-header {
     padding: .5rem 1rem;
     margin-bottom: 0;
     background-color: rgba(0, 0, 0, 0.03);
     border-bottom: 1px solid rgba(0, 0, 0, 0.125)
 }
 
 .card-header:first-child {
     border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0
 }
 
 .card-footer {
     padding: .5rem 1rem;
     background-color: rgba(0, 0, 0, 0.03);
     border-top: 1px solid rgba(0, 0, 0, 0.125)
 }
 
 .card-footer:last-child {
     border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px)
 }
 
 .card-header-tabs {
     margin-right: -.5rem;
     margin-bottom: -.5rem;
     margin-left: -.5rem;
     border-bottom: 0
 }
 
 .card-header-pills {
     margin-right: -.5rem;
     margin-left: -.5rem
 }
 
 .card-img-overlay {
     position: absolute;
     top: 0;
     right: 0;
     bottom: 0;
     left: 0;
     padding: 1rem;
     border-radius: calc(.25rem - 1px)
 }
 
 .card-img,
 .card-img-top,
 .card-img-bottom {
     width: 100%
 }
 
 .card-img,
 .card-img-top {
     border-top-left-radius: calc(.25rem - 1px);
     border-top-right-radius: calc(.25rem - 1px)
 }
 
 .card-img,
 .card-img-bottom {
     border-bottom-right-radius: calc(.25rem - 1px);
     border-bottom-left-radius: calc(.25rem - 1px)
 }
 
 .card-group>.card {
     margin-bottom: .75rem
 }
 
 @media (min-width: 576px) {
     .card-group {
         display: flex;
         flex-flow: row wrap
     }
 
     .card-group>.card {
         flex: 1 0 0%;
         margin-bottom: 0
     }
 
     .card-group>.card+.card {
         margin-left: 0;
         border-left: 0
     }
 
     .card-group>.card:not(:last-child) {
         border-top-right-radius: 0;
         border-bottom-right-radius: 0
     }
 
     .card-group>.card:not(:last-child) .card-img-top,
     .card-group>.card:not(:last-child) .card-header {
         border-top-right-radius: 0
     }
 
     .card-group>.card:not(:last-child) .card-img-bottom,
     .card-group>.card:not(:last-child) .card-footer {
         border-bottom-right-radius: 0
     }
 
     .card-group>.card:not(:first-child) {
         border-top-left-radius: 0;
         border-bottom-left-radius: 0
     }
 
     .card-group>.card:not(:first-child) .card-img-top,
     .card-group>.card:not(:first-child) .card-header {
         border-top-left-radius: 0
     }
 
     .card-group>.card:not(:first-child) .card-img-bottom,
     .card-group>.card:not(:first-child) .card-footer {
         border-bottom-left-radius: 0
     }
 }
 
 .accordion-button {
     position: relative;
     display: flex;
     align-items: center;
     width: 100%;
     padding: 1rem 1.25rem;
     font-size: 1rem;
     color: #212529;
     text-align: left;
     background-color: #fff;
     border: 0;
     border-radius: 0;
     overflow-anchor: none;
     transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease
 }
 
 @media (prefers-reduced-motion: reduce) {
     .accordion-button {
         transition: none
     }
 }
 
 .accordion-button:not(.collapsed) {
     color: #0c63e4;
     background-color: #e7f1ff;
     box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125)
 }
 
 .accordion-button:not(.collapsed)::after {
     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
     transform: rotate(-180deg)
 }
 
 .accordion-button::after {
     flex-shrink: 0;
     width: 1.25rem;
     height: 1.25rem;
     margin-left: auto;
     content: "";
     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
     background-repeat: no-repeat;
     background-size: 1.25rem;
     transition: transform 0.2s ease-in-out
 }
 
 @media (prefers-reduced-motion: reduce) {
     .accordion-button::after {
         transition: none
     }
 }
 
 .accordion-button:hover {
     z-index: 2
 }
 
 .accordion-button:focus {
     z-index: 3;
     border-color: #86b7fe;
     outline: 0;
     box-shadow: 0 0 0 .25rem rgba(13, 110, 253, 0.25)
 }
 
 .accordion-header {
     margin-bottom: 0
 }
 
 .accordion-item {
     background-color: #fff;
     border: 1px solid rgba(0, 0, 0, 0.125)
 }
 
 .accordion-item:first-of-type {
     border-top-left-radius: .25rem;
     border-top-right-radius: .25rem
 }
 
 .accordion-item:first-of-type .accordion-button {
     border-top-left-radius: calc(.25rem - 1px);
     border-top-right-radius: calc(.25rem - 1px)
 }
 
 .accordion-item:not(:first-of-type) {
     border-top: 0
 }
 
 .accordion-item:last-of-type {
     border-bottom-right-radius: .25rem;
     border-bottom-left-radius: .25rem
 }
 
 .accordion-item:last-of-type .accordion-button.collapsed {
     border-bottom-right-radius: calc(.25rem - 1px);
     border-bottom-left-radius: calc(.25rem - 1px)
 }
 
 .accordion-item:last-of-type .accordion-collapse {
     border-bottom-right-radius: .25rem;
     border-bottom-left-radius: .25rem
 }
 
 .accordion-body {
     padding: 1rem 1.25rem
 }
 
 .accordion-flush .accordion-collapse {
     border-width: 0
 }
 
 .accordion-flush .accordion-item {
     border-right: 0;
     border-left: 0;
     border-radius: 0
 }
 
 .accordion-flush .accordion-item:first-child {
     border-top: 0
 }
 
 .accordion-flush .accordion-item:last-child {
     border-bottom: 0
 }
 
 .accordion-flush .accordion-item .accordion-button {
     border-radius: 0
 }
 
 .breadcrumb {
     display: flex;
     flex-wrap: wrap;
     padding: 0 0;
     margin-bottom: 1rem;
     list-style: none
 }
 
 .breadcrumb-item+.breadcrumb-item {
     padding-left: .5rem
 }
 
 .breadcrumb-item+.breadcrumb-item::before {
     float: left;
     padding-right: .5rem;
     color: #6c757d;
     content: var(--bs-breadcrumb-divider, "/")
         /* rtl: var(--bs-breadcrumb-divider, "/") */
 }
 
 .breadcrumb-item.active {
     color: #6c757d
 }
 
 .pagination {
     display: flex;
     padding-left: 0;
     list-style: none
 }
 
 .page-link {
     position: relative;
     display: block;
     color: #0d6efd;
     text-decoration: none;
     background-color: #fff;
     border: 1px solid #dee2e6;
     transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
 }
 
 @media (prefers-reduced-motion: reduce) {
     .page-link {
         transition: none
     }
 }
 
 .page-link:hover {
     z-index: 2;
     color: #0a58ca;
     background-color: #e9ecef;
     border-color: #dee2e6
 }
 
 .page-link:focus {
     z-index: 3;
     color: #0a58ca;
     background-color: #e9ecef;
     outline: 0;
     box-shadow: 0 0 0 .25rem rgba(13, 110, 253, 0.25)
 }
 
 .page-item:not(:first-child) .page-link {
     margin-left: -1px
 }
 
 .page-item.active .page-link {
     z-index: 3;
     color: #fff;
     background-color: #0d6efd;
     border-color: #0d6efd
 }
 
 .page-item.disabled .page-link {
     color: #6c757d;
     pointer-events: none;
     background-color: #fff;
     border-color: #dee2e6
 }
 
 .page-link {
     padding: .375rem .75rem
 }
 
 .page-item:first-child .page-link {
     border-top-left-radius: .25rem;
     border-bottom-left-radius: .25rem
 }
 
 .page-item:last-child .page-link {
     border-top-right-radius: .25rem;
     border-bottom-right-radius: .25rem
 }
 
 .pagination-lg .page-link {
     padding: .75rem 1.5rem;
     font-size: 1.25rem
 }
 
 .pagination-lg .page-item:first-child .page-link {
     border-top-left-radius: .3rem;
     border-bottom-left-radius: .3rem
 }
 
 .pagination-lg .page-item:last-child .page-link {
     border-top-right-radius: .3rem;
     border-bottom-right-radius: .3rem
 }
 
 .pagination-sm .page-link {
     padding: .25rem .5rem;
     font-size: .875rem
 }
 
 .pagination-sm .page-item:first-child .page-link {
     border-top-left-radius: .2rem;
     border-bottom-left-radius: .2rem
 }
 
 .pagination-sm .page-item:last-child .page-link {
     border-top-right-radius: .2rem;
     border-bottom-right-radius: .2rem
 }
 
 .badge {
     display: inline-block;
     padding: .35em .65em;
     font-size: .75em;
     font-weight: 700;
     line-height: 1;
     color: #fff;
     text-align: center;
     white-space: nowrap;
     vertical-align: baseline;
     border-radius: .25rem
 }
 
 .badge:empty {
     display: none
 }
 
 .btn .badge {
     position: relative;
     top: -1px
 }
 
 .alert {
     position: relative;
     padding: 1rem 1rem;
     margin-bottom: 1rem;
     border: 1px solid transparent;
     border-radius: .25rem
 }
 
 .alert-heading {
     color: inherit
 }
 
 .alert-link {
     font-weight: 700
 }
 
 .alert-dismissible {
     padding-right: 3rem
 }
 
 .alert-dismissible .btn-close {
     position: absolute;
     top: 0;
     right: 0;
     z-index: 2;
     padding: 1.25rem 1rem
 }
 
 .alert-primary {
     color: #084298;
     background-color: #cfe2ff;
     border-color: #b6d4fe
 }
 
 .alert-primary .alert-link {
     color: #06357a
 }
 
 .alert-secondary {
     color: #41464b;
     background-color: #e2e3e5;
     border-color: #d3d6d8
 }
 
 .alert-secondary .alert-link {
     color: #34383c
 }
 
 .alert-success {
     color: #0f5132;
     background-color: #d1e7dd;
     border-color: #badbcc
 }
 
 .alert-success .alert-link {
     color: #0c4128
 }
 
 .alert-info {
     color: #055160;
     background-color: #cff4fc;
     border-color: #b6effb
 }
 
 .alert-info .alert-link {
     color: #04414d
 }
 
 .alert-warning {
     color: #664d03;
     background-color: #fff3cd;
     border-color: #ffecb5
 }
 
 .alert-warning .alert-link {
     color: #523e02
 }
 
 .alert-danger {
     color: #842029;
     background-color: #f8d7da;
     border-color: #f5c2c7
 }
 
 .alert-danger .alert-link {
     color: #6a1a21
 }
 
 .alert-light {
     color: #636464;
     background-color: #fefefe;
     border-color: #fdfdfe
 }
 
 .alert-light .alert-link {
     color: #4f5050
 }
 
 .alert-dark {
     color: #141619;
     background-color: #d3d3d4;
     border-color: #bcbebf
 }
 
 .alert-dark .alert-link {
     color: #101214
 }
 
 @keyframes progress-bar-stripes {
     0% {
         background-position-x: 1rem
     }
 }
 
 .progress {
     display: flex;
     height: 1rem;
     overflow: hidden;
     font-size: .75rem;
     background-color: #e9ecef;
     border-radius: .25rem
 }
 
 .progress-bar {
     display: flex;
     flex-direction: column;
     justify-content: center;
     overflow: hidden;
     color: #fff;
     text-align: center;
     white-space: nowrap;
     background-color: #0d6efd;
     transition: width 0.6s ease
 }
 
 @media (prefers-reduced-motion: reduce) {
     .progress-bar {
         transition: none
     }
 }
 
 .progress-bar-striped {
     background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
     background-size: 1rem 1rem
 }
 
 .progress-bar-animated {
     animation: 1s linear infinite progress-bar-stripes
 }
 
 @media (prefers-reduced-motion: reduce) {
     .progress-bar-animated {
         animation: none
     }
 }
 
 .list-group {
     display: flex;
     flex-direction: column;
     padding-left: 0;
     margin-bottom: 0;
     border-radius: .25rem
 }
 
 .list-group-numbered {
     list-style-type: none;
     counter-reset: section
 }
 
 .list-group-numbered>li::before {
     content: counters(section, ".") ". ";
     counter-increment: section
 }
 
 .list-group-item-action {
     width: 100%;
     color: #495057;
     text-align: inherit
 }
 
 .list-group-item-action:hover,
 .list-group-item-action:focus {
     z-index: 1;
     color: #495057;
     text-decoration: none;
     background-color: #f8f9fa
 }
 
 .list-group-item-action:active {
     color: #212529;
     background-color: #e9ecef
 }
 
 .list-group-item {
     position: relative;
     display: block;
     padding: .5rem 1rem;
     color: #212529;
     text-decoration: none;
     background-color: #fff;
     border: 1px solid rgba(0, 0, 0, 0.125)
 }
 
 .list-group-item:first-child {
     border-top-left-radius: inherit;
     border-top-right-radius: inherit
 }
 
 .list-group-item:last-child {
     border-bottom-right-radius: inherit;
     border-bottom-left-radius: inherit
 }
 
 .list-group-item.disabled,
 .list-group-item:disabled {
     color: #6c757d;
     pointer-events: none;
     background-color: #fff
 }
 
 .list-group-item.active {
     z-index: 2;
     color: #fff;
     background-color: #0d6efd;
     border-color: #0d6efd
 }
 
 .list-group-item+.list-group-item {
     border-top-width: 0
 }
 
 .list-group-item+.list-group-item.active {
     margin-top: -1px;
     border-top-width: 1px
 }
 
 .list-group-horizontal {
     flex-direction: row
 }
 
 .list-group-horizontal>.list-group-item:first-child {
     border-bottom-left-radius: .25rem;
     border-top-right-radius: 0
 }
 
 .list-group-horizontal>.list-group-item:last-child {
     border-top-right-radius: .25rem;
     border-bottom-left-radius: 0
 }
 
 .list-group-horizontal>.list-group-item.active {
     margin-top: 0
 }
 
 .list-group-horizontal>.list-group-item+.list-group-item {
     border-top-width: 1px;
     border-left-width: 0
 }
 
 .list-group-horizontal>.list-group-item+.list-group-item.active {
     margin-left: -1px;
     border-left-width: 1px
 }
 
 @media (min-width: 576px) {
     .list-group-horizontal-sm {
         flex-direction: row
     }
 
     .list-group-horizontal-sm>.list-group-item:first-child {
         border-bottom-left-radius: .25rem;
         border-top-right-radius: 0
     }
 
     .list-group-horizontal-sm>.list-group-item:last-child {
         border-top-right-radius: .25rem;
         border-bottom-left-radius: 0
     }
 
     .list-group-horizontal-sm>.list-group-item.active {
         margin-top: 0
     }
 
     .list-group-horizontal-sm>.list-group-item+.list-group-item {
         border-top-width: 1px;
         border-left-width: 0
     }
 
     .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
         margin-left: -1px;
         border-left-width: 1px
     }
 }
 
 @media (min-width: 768px) {
     .list-group-horizontal-md {
         flex-direction: row
     }
 
     .list-group-horizontal-md>.list-group-item:first-child {
         border-bottom-left-radius: .25rem;
         border-top-right-radius: 0
     }
 
     .list-group-horizontal-md>.list-group-item:last-child {
         border-top-right-radius: .25rem;
         border-bottom-left-radius: 0
     }
 
     .list-group-horizontal-md>.list-group-item.active {
         margin-top: 0
     }
 
     .list-group-horizontal-md>.list-group-item+.list-group-item {
         border-top-width: 1px;
         border-left-width: 0
     }
 
     .list-group-horizontal-md>.list-group-item+.list-group-item.active {
         margin-left: -1px;
         border-left-width: 1px
     }
 }
 
 @media (min-width: 992px) {
     .list-group-horizontal-lg {
         flex-direction: row
     }
 
     .list-group-horizontal-lg>.list-group-item:first-child {
         border-bottom-left-radius: .25rem;
         border-top-right-radius: 0
     }
 
     .list-group-horizontal-lg>.list-group-item:last-child {
         border-top-right-radius: .25rem;
         border-bottom-left-radius: 0
     }
 
     .list-group-horizontal-lg>.list-group-item.active {
         margin-top: 0
     }
 
     .list-group-horizontal-lg>.list-group-item+.list-group-item {
         border-top-width: 1px;
         border-left-width: 0
     }
 
     .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
         margin-left: -1px;
         border-left-width: 1px
     }
 }
 
 @media (min-width: 1200px) {
     .list-group-horizontal-xl {
         flex-direction: row
     }
 
     .list-group-horizontal-xl>.list-group-item:first-child {
         border-bottom-left-radius: .25rem;
         border-top-right-radius: 0
     }
 
     .list-group-horizontal-xl>.list-group-item:last-child {
         border-top-right-radius: .25rem;
         border-bottom-left-radius: 0
     }
 
     .list-group-horizontal-xl>.list-group-item.active {
         margin-top: 0
     }
 
     .list-group-horizontal-xl>.list-group-item+.list-group-item {
         border-top-width: 1px;
         border-left-width: 0
     }
 
     .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
         margin-left: -1px;
         border-left-width: 1px
     }
 }
 
 @media (min-width: 1400px) {
     .list-group-horizontal-xxl {
         flex-direction: row
     }
 
     .list-group-horizontal-xxl>.list-group-item:first-child {
         border-bottom-left-radius: .25rem;
         border-top-right-radius: 0
     }
 
     .list-group-horizontal-xxl>.list-group-item:last-child {
         border-top-right-radius: .25rem;
         border-bottom-left-radius: 0
     }
 
     .list-group-horizontal-xxl>.list-group-item.active {
         margin-top: 0
     }
 
     .list-group-horizontal-xxl>.list-group-item+.list-group-item {
         border-top-width: 1px;
         border-left-width: 0
     }
 
     .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
         margin-left: -1px;
         border-left-width: 1px
     }
 }
 
 .list-group-flush {
     border-radius: 0
 }
 
 .list-group-flush>.list-group-item {
     border-width: 0 0 1px
 }
 
 .list-group-flush>.list-group-item:last-child {
     border-bottom-width: 0
 }
 
 .list-group-item-primary {
     color: #084298;
     background-color: #cfe2ff
 }
 
 .list-group-item-primary.list-group-item-action:hover,
 .list-group-item-primary.list-group-item-action:focus {
     color: #084298;
     background-color: #bacbe6
 }
 
 .list-group-item-primary.list-group-item-action.active {
     color: #fff;
     background-color: #084298;
     border-color: #084298
 }
 
 .list-group-item-secondary {
     color: #41464b;
     background-color: #e2e3e5
 }
 
 .list-group-item-secondary.list-group-item-action:hover,
 .list-group-item-secondary.list-group-item-action:focus {
     color: #41464b;
     background-color: #cbccce
 }
 
 .list-group-item-secondary.list-group-item-action.active {
     color: #fff;
     background-color: #41464b;
     border-color: #41464b
 }
 
 .list-group-item-success {
     color: #0f5132;
     background-color: #d1e7dd
 }
 
 .list-group-item-success.list-group-item-action:hover,
 .list-group-item-success.list-group-item-action:focus {
     color: #0f5132;
     background-color: #bcd0c7
 }
 
 .list-group-item-success.list-group-item-action.active {
     color: #fff;
     background-color: #0f5132;
     border-color: #0f5132
 }
 
 .list-group-item-info {
     color: #055160;
     background-color: #cff4fc
 }
 
 .list-group-item-info.list-group-item-action:hover,
 .list-group-item-info.list-group-item-action:focus {
     color: #055160;
     background-color: #badce3
 }
 
 .list-group-item-info.list-group-item-action.active {
     color: #fff;
     background-color: #055160;
     border-color: #055160
 }
 
 .list-group-item-warning {
     color: #664d03;
     background-color: #fff3cd
 }
 
 .list-group-item-warning.list-group-item-action:hover,
 .list-group-item-warning.list-group-item-action:focus {
     color: #664d03;
     background-color: #e6dbb9
 }
 
 .list-group-item-warning.list-group-item-action.active {
     color: #fff;
     background-color: #664d03;
     border-color: #664d03
 }
 
 .list-group-item-danger {
     color: #842029;
     background-color: #f8d7da
 }
 
 .list-group-item-danger.list-group-item-action:hover,
 .list-group-item-danger.list-group-item-action:focus {
     color: #842029;
     background-color: #dfc2c4
 }
 
 .list-group-item-danger.list-group-item-action.active {
     color: #fff;
     background-color: #842029;
     border-color: #842029
 }
 
 .list-group-item-light {
     color: #636464;
     background-color: #fefefe
 }
 
 .list-group-item-light.list-group-item-action:hover,
 .list-group-item-light.list-group-item-action:focus {
     color: #636464;
     background-color: #e5e5e5
 }
 
 .list-group-item-light.list-group-item-action.active {
     color: #fff;
     background-color: #636464;
     border-color: #636464
 }
 
 .list-group-item-dark {
     color: #141619;
     background-color: #d3d3d4
 }
 
 .list-group-item-dark.list-group-item-action:hover,
 .list-group-item-dark.list-group-item-action:focus {
     color: #141619;
     background-color: #bebebf
 }
 
 .list-group-item-dark.list-group-item-action.active {
     color: #fff;
     background-color: #141619;
     border-color: #141619
 }
 
 .btn-close {
     box-sizing: content-box;
     width: 1em;
     height: 1em;
     padding: .25em .25em;
     color: #000;
     background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
     border: 0;
     border-radius: .25rem;
     opacity: .5
 }
 
 .btn-close:hover {
     color: #000;
     text-decoration: none;
     opacity: .75
 }
 
 .btn-close:focus {
     outline: 0;
     box-shadow: 0 0 0 .25rem rgba(13, 110, 253, 0.25);
     opacity: 1
 }
 
 .btn-close:disabled,
 .btn-close.disabled {
     pointer-events: none;
     user-select: none;
     opacity: .25
 }
 
 .btn-close-white {
     filter: invert(1) grayscale(100%) brightness(200%)
 }
 
 .toast {
     width: 350px;
     max-width: 100%;
     font-size: .875rem;
     pointer-events: auto;
     background-color: rgba(255, 255, 255, 0.85);
     background-clip: padding-box;
     border: 1px solid rgba(0, 0, 0, 0.1);
     box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
     border-radius: .25rem
 }
 
 .toast.showing {
     opacity: 0
 }
 
 .toast:not(.show) {
     display: none
 }
 
 .toast-container {
     width: max-content;
     max-width: 100%;
     pointer-events: none
 }
 
 .toast-container>:not(:last-child) {
     margin-bottom: .75rem
 }
 
 .toast-header {
     display: flex;
     align-items: center;
     padding: .5rem .75rem;
     color: #6c757d;
     background-color: rgba(255, 255, 255, 0.85);
     background-clip: padding-box;
     border-bottom: 1px solid rgba(0, 0, 0, 0.05);
     border-top-left-radius: calc(.25rem - 1px);
     border-top-right-radius: calc(.25rem - 1px)
 }
 
 .toast-header .btn-close {
     margin-right: -.375rem;
     margin-left: .75rem
 }
 
 .toast-body {
     padding: .75rem;
     word-wrap: break-word
 }
 
 .modal {
     position: fixed;
     top: 0;
     left: 0;
     z-index: 1055;
     display: none;
     width: 100%;
     height: 100%;
     overflow-x: hidden;
     overflow-y: auto;
     outline: 0
 }
 
 .modal-dialog {
     position: relative;
     width: auto;
     margin: .5rem;
     pointer-events: none
 }
 
 .modal.fade .modal-dialog {
     transition: transform 0.3s ease-out;
     transform: translate(0, -50px)
 }
 
 @media (prefers-reduced-motion: reduce) {
     .modal.fade .modal-dialog {
         transition: none
     }
 }
 
 .modal.show .modal-dialog {
     transform: none
 }
 
 .modal.modal-static .modal-dialog {
     transform: scale(1.02)
 }
 
 .modal-dialog-scrollable {
     height: calc(100% - 1rem)
 }
 
 .modal-dialog-scrollable .modal-content {
     max-height: 100%;
     overflow: hidden
 }
 
 .modal-dialog-scrollable .modal-body {
     overflow-y: auto
 }
 
 .modal-dialog-centered {
     display: flex;
     align-items: center;
     min-height: calc(100% - 1rem)
 }
 
 .modal-content {
     position: relative;
     display: flex;
     flex-direction: column;
     width: 100%;
     pointer-events: auto;
     background-color: #fff;
     background-clip: padding-box;
     border: 1px solid rgba(0, 0, 0, 0.2);
     border-radius: .3rem;
     outline: 0
 }
 
 .modal-backdrop {
     position: fixed;
     top: 0;
     left: 0;
     z-index: 1050;
     width: 100vw;
     height: 100vh;
     background-color: #000
 }
 
 .modal-backdrop.fade {
     opacity: 0
 }
 
 .modal-backdrop.show {
     opacity: .5
 }
 
 .modal-header {
     display: flex;
     flex-shrink: 0;
     align-items: center;
     justify-content: space-between;
     padding: 1rem 1rem;
     border-bottom: 1px solid #dee2e6;
     border-top-left-radius: calc(.3rem - 1px);
     border-top-right-radius: calc(.3rem - 1px)
 }
 
 .modal-header .btn-close {
     padding: .5rem .5rem;
     margin: -.5rem -.5rem -.5rem auto
 }
 
 .modal-title {
     margin-bottom: 0;
     line-height: 1.5
 }
 
 .modal-body {
     position: relative;
     flex: 1 1 auto;
     padding: 1rem
 }
 
 .modal-footer {
     display: flex;
     flex-wrap: wrap;
     flex-shrink: 0;
     align-items: center;
     justify-content: flex-end;
     padding: .75rem;
     border-top: 1px solid #dee2e6;
     border-bottom-right-radius: calc(.3rem - 1px);
     border-bottom-left-radius: calc(.3rem - 1px)
 }
 
 .modal-footer>* {
     margin: .25rem
 }
 
 @media (min-width: 576px) {
     .modal-dialog {
         max-width: 500px;
         margin: 1.75rem auto
     }
 
     .modal-dialog-scrollable {
         height: calc(100% - 3.5rem)
     }
 
     .modal-dialog-centered {
         min-height: calc(100% - 3.5rem)
     }
 
     .modal-sm {
         max-width: 300px
     }
 }
 
 @media (min-width: 992px) {
 
     .modal-lg,
     .modal-xl {
         max-width: 800px
     }
 }
 
 @media (min-width: 1200px) {
     .modal-xl {
         max-width: 1140px
     }
 }
 
 .modal-fullscreen {
     width: 100vw;
     max-width: none;
     height: 100%;
     margin: 0
 }
 
 .modal-fullscreen .modal-content {
     height: 100%;
     border: 0;
     border-radius: 0
 }
 
 .modal-fullscreen .modal-header {
     border-radius: 0
 }
 
 .modal-fullscreen .modal-body {
     overflow-y: auto
 }
 
 .modal-fullscreen .modal-footer {
     border-radius: 0
 }
 
 @media (max-width: 575.98px) {
     .modal-fullscreen-sm-down {
         width: 100vw;
         max-width: none;
         height: 100%;
         margin: 0
     }
 
     .modal-fullscreen-sm-down .modal-content {
         height: 100%;
         border: 0;
         border-radius: 0
     }
 
     .modal-fullscreen-sm-down .modal-header {
         border-radius: 0
     }
 
     .modal-fullscreen-sm-down .modal-body {
         overflow-y: auto
     }
 
     .modal-fullscreen-sm-down .modal-footer {
         border-radius: 0
     }
 }
 
 @media (max-width: 767.98px) {
     .modal-fullscreen-md-down {
         width: 100vw;
         max-width: none;
         height: 100%;
         margin: 0
     }
 
     .modal-fullscreen-md-down .modal-content {
         height: 100%;
         border: 0;
         border-radius: 0
     }
 
     .modal-fullscreen-md-down .modal-header {
         border-radius: 0
     }
 
     .modal-fullscreen-md-down .modal-body {
         overflow-y: auto
     }
 
     .modal-fullscreen-md-down .modal-footer {
         border-radius: 0
     }
 }
 
 @media (max-width: 991.98px) {
     .modal-fullscreen-lg-down {
         width: 100vw;
         max-width: none;
         height: 100%;
         margin: 0
     }
 
     .modal-fullscreen-lg-down .modal-content {
         height: 100%;
         border: 0;
         border-radius: 0
     }
 
     .modal-fullscreen-lg-down .modal-header {
         border-radius: 0
     }
 
     .modal-fullscreen-lg-down .modal-body {
         overflow-y: auto
     }
 
     .modal-fullscreen-lg-down .modal-footer {
         border-radius: 0
     }
 }
 
 @media (max-width: 1199.98px) {
     .modal-fullscreen-xl-down {
         width: 100vw;
         max-width: none;
         height: 100%;
         margin: 0
     }
 
     .modal-fullscreen-xl-down .modal-content {
         height: 100%;
         border: 0;
         border-radius: 0
     }
 
     .modal-fullscreen-xl-down .modal-header {
         border-radius: 0
     }
 
     .modal-fullscreen-xl-down .modal-body {
         overflow-y: auto
     }
 
     .modal-fullscreen-xl-down .modal-footer {
         border-radius: 0
     }
 }
 
 @media (max-width: 1399.98px) {
     .modal-fullscreen-xxl-down {
         width: 100vw;
         max-width: none;
         height: 100%;
         margin: 0
     }
 
     .modal-fullscreen-xxl-down .modal-content {
         height: 100%;
         border: 0;
         border-radius: 0
     }
 
     .modal-fullscreen-xxl-down .modal-header {
         border-radius: 0
     }
 
     .modal-fullscreen-xxl-down .modal-body {
         overflow-y: auto
     }
 
     .modal-fullscreen-xxl-down .modal-footer {
         border-radius: 0
     }
 }
 
 .tooltip {
     position: absolute;
     z-index: 1080;
     display: block;
     margin: 0;
     font-family: var(--bs-font-sans-serif);
     font-style: normal;
     font-weight: 400;
     line-height: 1.5;
     text-align: left;
     text-align: start;
     text-decoration: none;
     text-shadow: none;
     text-transform: none;
     letter-spacing: normal;
     word-break: normal;
     word-spacing: normal;
     white-space: normal;
     line-break: auto;
     font-size: .875rem;
     word-wrap: break-word;
     opacity: 0
 }
 
 .tooltip.show {
     opacity: .9
 }
 
 .tooltip .tooltip-arrow {
     position: absolute;
     display: block;
     width: .8rem;
     height: .4rem
 }
 
 .tooltip .tooltip-arrow::before {
     position: absolute;
     content: "";
     border-color: transparent;
     border-style: solid
 }
 
 .bs-tooltip-top,
 .bs-tooltip-auto[data-popper-placement^="top"] {
     padding: .4rem 0
 }
 
 .bs-tooltip-top .tooltip-arrow,
 .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
     bottom: 0
 }
 
 .bs-tooltip-top .tooltip-arrow::before,
 .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
     top: -1px;
     border-width: .4rem .4rem 0;
     border-top-color: #000
 }
 
 .bs-tooltip-end,
 .bs-tooltip-auto[data-popper-placement^="right"] {
     padding: 0 .4rem
 }
 
 .bs-tooltip-end .tooltip-arrow,
 .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
     left: 0;
     width: .4rem;
     height: .8rem
 }
 
 .bs-tooltip-end .tooltip-arrow::before,
 .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
     right: -1px;
     border-width: .4rem .4rem .4rem 0;
     border-right-color: #000
 }
 
 .bs-tooltip-bottom,
 .bs-tooltip-auto[data-popper-placement^="bottom"] {
     padding: .4rem 0
 }
 
 .bs-tooltip-bottom .tooltip-arrow,
 .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
     top: 0
 }
 
 .bs-tooltip-bottom .tooltip-arrow::before,
 .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
     bottom: -1px;
     border-width: 0 .4rem .4rem;
     border-bottom-color: #000
 }
 
 .bs-tooltip-start,
 .bs-tooltip-auto[data-popper-placement^="left"] {
     padding: 0 .4rem
 }
 
 .bs-tooltip-start .tooltip-arrow,
 .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
     right: 0;
     width: .4rem;
     height: .8rem
 }
 
 .bs-tooltip-start .tooltip-arrow::before,
 .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
     left: -1px;
     border-width: .4rem 0 .4rem .4rem;
     border-left-color: #000
 }
 
 .tooltip-inner {
     max-width: 200px;
     padding: .25rem .5rem;
     color: #fff;
     text-align: center;
     background-color: #000;
     border-radius: .25rem
 }
 
 .popover {
     position: absolute;
     top: 0;
     left: 0
         /* rtl:ignore */
     ;
     z-index: 1070;
     display: block;
     max-width: 276px;
     font-family: var(--bs-font-sans-serif);
     font-style: normal;
     font-weight: 400;
     line-height: 1.5;
     text-align: left;
     text-align: start;
     text-decoration: none;
     text-shadow: none;
     text-transform: none;
     letter-spacing: normal;
     word-break: normal;
     word-spacing: normal;
     white-space: normal;
     line-break: auto;
     font-size: .875rem;
     word-wrap: break-word;
     background-color: #fff;
     background-clip: padding-box;
     border: 1px solid rgba(0, 0, 0, 0.2);
     border-radius: .3rem
 }
 
 .popover .popover-arrow {
     position: absolute;
     display: block;
     width: 1rem;
     height: .5rem
 }
 
 .popover .popover-arrow::before,
 .popover .popover-arrow::after {
     position: absolute;
     display: block;
     content: "";
     border-color: transparent;
     border-style: solid
 }
 
 .bs-popover-top>.popover-arrow,
 .bs-popover-auto[data-popper-placement^="top"]>.popover-arrow {
     bottom: calc(-.5rem - 1px)
 }
 
 .bs-popover-top>.popover-arrow::before,
 .bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::before {
     bottom: 0;
     border-width: .5rem .5rem 0;
     border-top-color: rgba(0, 0, 0, 0.25)
 }
 
 .bs-popover-top>.popover-arrow::after,
 .bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::after {
     bottom: 1px;
     border-width: .5rem .5rem 0;
     border-top-color: #fff
 }
 
 .bs-popover-end>.popover-arrow,
 .bs-popover-auto[data-popper-placement^="right"]>.popover-arrow {
     left: calc(-.5rem - 1px);
     width: .5rem;
     height: 1rem
 }
 
 .bs-popover-end>.popover-arrow::before,
 .bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::before {
     left: 0;
     border-width: .5rem .5rem .5rem 0;
     border-right-color: rgba(0, 0, 0, 0.25)
 }
 
 .bs-popover-end>.popover-arrow::after,
 .bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::after {
     left: 1px;
     border-width: .5rem .5rem .5rem 0;
     border-right-color: #fff
 }
 
 .bs-popover-bottom>.popover-arrow,
 .bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow {
     top: calc(-.5rem - 1px)
 }
 
 .bs-popover-bottom>.popover-arrow::before,
 .bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::before {
     top: 0;
     border-width: 0 .5rem .5rem .5rem;
     border-bottom-color: rgba(0, 0, 0, 0.25)
 }
 
 .bs-popover-bottom>.popover-arrow::after,
 .bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::after {
     top: 1px;
     border-width: 0 .5rem .5rem .5rem;
     border-bottom-color: #fff
 }
 
 .bs-popover-bottom .popover-header::before,
 .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
     position: absolute;
     top: 0;
     left: 50%;
     display: block;
     width: 1rem;
     margin-left: -.5rem;
     content: "";
     border-bottom: 1px solid #f0f0f0
 }
 
 .bs-popover-start>.popover-arrow,
 .bs-popover-auto[data-popper-placement^="left"]>.popover-arrow {
     right: calc(-.5rem - 1px);
     width: .5rem;
     height: 1rem
 }
 
 .bs-popover-start>.popover-arrow::before,
 .bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::before {
     right: 0;
     border-width: .5rem 0 .5rem .5rem;
     border-left-color: rgba(0, 0, 0, 0.25)
 }
 
 .bs-popover-start>.popover-arrow::after,
 .bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::after {
     right: 1px;
     border-width: .5rem 0 .5rem .5rem;
     border-left-color: #fff
 }
 
 .popover-header {
     padding: .5rem 1rem;
     margin-bottom: 0;
     font-size: 1rem;
     background-color: #f0f0f0;
     border-bottom: 1px solid rgba(0, 0, 0, 0.2);
     border-top-left-radius: calc(.3rem - 1px);
     border-top-right-radius: calc(.3rem - 1px)
 }
 
 .popover-header:empty {
     display: none
 }
 
 .popover-body {
     padding: 1rem 1rem;
     color: #212529
 }
 
 .carousel {
     position: relative
 }
 
 .carousel.pointer-event {
     touch-action: pan-y
 }
 
 .carousel-inner {
     position: relative;
     width: 100%;
     overflow: hidden
 }
 
 .carousel-inner::after {
     display: block;
     clear: both;
     content: ""
 }
 
 .carousel-item {
     position: relative;
     display: none;
     float: left;
     width: 100%;
     margin-right: -100%;
     backface-visibility: hidden;
     transition: transform .6s ease-in-out
 }
 
 @media (prefers-reduced-motion: reduce) {
     .carousel-item {
         transition: none
     }
 }
 
 .carousel-item.active,
 .carousel-item-next,
 .carousel-item-prev {
     display: block
 }
 
 .carousel-item-next:not(.carousel-item-start),
 .active.carousel-item-end {
     transform: translateX(100%)
 }
 
 .carousel-item-prev:not(.carousel-item-end),
 .active.carousel-item-start {
     transform: translateX(-100%)
 }
 
 .carousel-fade .carousel-item {
     opacity: 0;
     transition-property: opacity;
     transform: none
 }
 
 .carousel-fade .carousel-item.active,
 .carousel-fade .carousel-item-next.carousel-item-start,
 .carousel-fade .carousel-item-prev.carousel-item-end {
     z-index: 1;
     opacity: 1
 }
 
 .carousel-fade .active.carousel-item-start,
 .carousel-fade .active.carousel-item-end {
     z-index: 0;
     opacity: 0;
     transition: opacity 0s .6s
 }
 
 @media (prefers-reduced-motion: reduce) {
 
     .carousel-fade .active.carousel-item-start,
     .carousel-fade .active.carousel-item-end {
         transition: none
     }
 }
 
 .carousel-control-prev,
 .carousel-control-next {
     position: absolute;
     top: 0;
     bottom: 0;
     z-index: 1;
     display: flex;
     align-items: center;
     justify-content: center;
     width: 15%;
     padding: 0;
     color: #fff;
     text-align: center;
     background: none;
     border: 0;
     opacity: .5;
     transition: opacity 0.15s ease
 }
 
 @media (prefers-reduced-motion: reduce) {
 
     .carousel-control-prev,
     .carousel-control-next {
         transition: none
     }
 }
 
 .carousel-control-prev:hover,
 .carousel-control-prev:focus,
 .carousel-control-next:hover,
 .carousel-control-next:focus {
     color: #fff;
     text-decoration: none;
     outline: 0;
     opacity: .9
 }
 
 .carousel-control-prev {
     left: 0
 }
 
 .carousel-control-next {
     right: 0
 }
 
 .carousel-control-prev-icon,
 .carousel-control-next-icon {
     display: inline-block;
     width: 2rem;
     height: 2rem;
     background-repeat: no-repeat;
     background-position: 50%;
     background-size: 100% 100%
 }
 
 .carousel-control-prev-icon {
     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")
 }
 
 .carousel-control-next-icon {
     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
 }
 
 .carousel-indicators {
     position: absolute;
     right: 0;
     bottom: 0;
     left: 0;
     z-index: 2;
     display: flex;
     justify-content: center;
     padding: 0;
     margin-right: 15%;
     margin-bottom: 1rem;
     margin-left: 15%;
     list-style: none
 }
 
 .carousel-indicators [data-bs-target] {
     box-sizing: content-box;
     flex: 0 1 auto;
     width: 30px;
     height: 3px;
     padding: 0;
     margin-right: 3px;
     margin-left: 3px;
     text-indent: -999px;
     cursor: pointer;
     background-color: #fff;
     background-clip: padding-box;
     border: 0;
     border-top: 10px solid transparent;
     border-bottom: 10px solid transparent;
     opacity: .5;
     transition: opacity 0.6s ease
 }
 
 @media (prefers-reduced-motion: reduce) {
     .carousel-indicators [data-bs-target] {
         transition: none
     }
 }
 
 .carousel-indicators .active {
     opacity: 1
 }
 
 .carousel-caption {
     position: absolute;
     right: 15%;
     bottom: 1.25rem;
     left: 15%;
     padding-top: 1.25rem;
     padding-bottom: 1.25rem;
     color: #fff;
     text-align: center
 }
 
 .carousel-dark .carousel-control-prev-icon,
 .carousel-dark .carousel-control-next-icon {
     filter: invert(1) grayscale(100)
 }
 
 .carousel-dark .carousel-indicators [data-bs-target] {
     background-color: #000
 }
 
 .carousel-dark .carousel-caption {
     color: #000
 }
 
 @keyframes spinner-border {
     to {
         transform: rotate(360deg)
             /* rtl:ignore */
     }
 }
 
 .spinner-border {
     display: inline-block;
     width: 2rem;
     height: 2rem;
     vertical-align: -.125em;
     border: .25em solid currentColor;
     border-right-color: transparent;
     border-radius: 50%;
     animation: .75s linear infinite spinner-border
 }
 
 .spinner-border-sm {
     width: 1rem;
     height: 1rem;
     border-width: .2em
 }
 
 @keyframes spinner-grow {
     0% {
         transform: scale(0)
     }
 
     50% {
         opacity: 1;
         transform: none
     }
 }
 
 .spinner-grow {
     display: inline-block;
     width: 2rem;
     height: 2rem;
     vertical-align: -.125em;
     background-color: currentColor;
     border-radius: 50%;
     opacity: 0;
     animation: .75s linear infinite spinner-grow
 }
 
 .spinner-grow-sm {
     width: 1rem;
     height: 1rem
 }
 
 @media (prefers-reduced-motion: reduce) {
 
     .spinner-border,
     .spinner-grow {
         animation-duration: 1.5s
     }
 }
 
 .offcanvas {
     position: fixed;
     bottom: 0;
     z-index: 1045;
     display: flex;
     flex-direction: column;
     max-width: 100%;
     visibility: hidden;
     background-color: #fff;
     background-clip: padding-box;
     outline: 0;
     transition: transform .3s ease-in-out
 }
 
 @media (prefers-reduced-motion: reduce) {
     .offcanvas {
         transition: none
     }
 }
 
 .offcanvas-backdrop {
     position: fixed;
     top: 0;
     left: 0;
     z-index: 1040;
     width: 100vw;
     height: 100vh;
     background-color: #000
 }
 
 .offcanvas-backdrop.fade {
     opacity: 0
 }
 
 .offcanvas-backdrop.show {
     opacity: .5
 }
 
 .offcanvas-header {
     display: flex;
     align-items: center;
     justify-content: space-between;
     padding: 1rem 1rem
 }
 
 .offcanvas-header .btn-close {
     padding: .5rem .5rem;
     margin-top: -.5rem;
     margin-right: -.5rem;
     margin-bottom: -.5rem
 }
 
 .offcanvas-title {
     margin-bottom: 0;
     line-height: 1.5
 }
 
 .offcanvas-body {
     flex-grow: 1;
     padding: 1rem 1rem;
     overflow-y: auto
 }
 
 .offcanvas-start {
     top: 0;
     left: 0;
     width: 400px;
     border-right: 1px solid rgba(0, 0, 0, 0.2);
     transform: translateX(-100%)
 }
 
 .offcanvas-end {
     top: 0;
     right: 0;
     width: 400px;
     border-left: 1px solid rgba(0, 0, 0, 0.2);
     transform: translateX(100%)
 }
 
 .offcanvas-top {
     top: 0;
     right: 0;
     left: 0;
     height: 30vh;
     max-height: 100%;
     border-bottom: 1px solid rgba(0, 0, 0, 0.2);
     transform: translateY(-100%)
 }
 
 .offcanvas-bottom {
     right: 0;
     left: 0;
     height: 30vh;
     max-height: 100%;
     border-top: 1px solid rgba(0, 0, 0, 0.2);
     transform: translateY(100%)
 }
 
 .offcanvas.show {
     transform: none
 }
 
 .placeholder {
     display: inline-block;
     min-height: 1em;
     vertical-align: middle;
     cursor: wait;
     background-color: currentColor;
     opacity: .5
 }
 
 .placeholder.btn::before {
     display: inline-block;
     content: ""
 }
 
 .placeholder-xs {
     min-height: .6em
 }
 
 .placeholder-sm {
     min-height: .8em
 }
 
 .placeholder-lg {
     min-height: 1.2em
 }
 
 .placeholder-glow .placeholder {
     animation: placeholder-glow 2s ease-in-out infinite
 }
 
 @keyframes placeholder-glow {
     50% {
         opacity: .2
     }
 }
 
 .placeholder-wave {
     mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
     mask-size: 200% 100%;
     animation: placeholder-wave 2s linear infinite
 }
 
 @keyframes placeholder-wave {
     100% {
         mask-position: -200% 0%
     }
 }
 
 .clearfix::after {
     display: block;
     clear: both;
     content: ""
 }
 
 .link-primary {
     color: #0d6efd
 }
 
 .link-primary:hover,
 .link-primary:focus {
     color: #0a58ca
 }
 
 .link-secondary {
     color: #6c757d
 }
 
 .link-secondary:hover,
 .link-secondary:focus {
     color: #565e64
 }
 
 .link-success {
     color: #198754
 }
 
 .link-success:hover,
 .link-success:focus {
     color: #146c43
 }
 
 .link-info {
     color: #0dcaf0
 }
 
 .link-info:hover,
 .link-info:focus {
     color: #3dd5f3
 }
 
 .link-warning {
     color: #ffc107
 }
 
 .link-warning:hover,
 .link-warning:focus {
     color: #ffcd39
 }
 
 .link-danger {
     color: #dc3545
 }
 
 .link-danger:hover,
 .link-danger:focus {
     color: #b02a37
 }
 
 .link-light {
     color: #f8f9fa
 }
 
 .link-light:hover,
 .link-light:focus {
     color: #f9fafb
 }
 
 .link-dark {
     color: #212529
 }
 
 .link-dark:hover,
 .link-dark:focus {
     color: #1a1e21
 }
 
 .ratio {
     position: relative;
     width: 100%
 }
 
 .ratio::before {
     display: block;
     padding-top: var(--bs-aspect-ratio);
     content: ""
 }
 
 .ratio>* {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%
 }
 
 .ratio-1x1 {
     --bs-aspect-ratio: 100%
 }
 
 .ratio-4x3 {
     --bs-aspect-ratio: calc(3 / 4 * 100%)
 }
 
 .ratio-16x9 {
     --bs-aspect-ratio: calc(9 / 16 * 100%)
 }
 
 .ratio-21x9 {
     --bs-aspect-ratio: calc(9 / 21 * 100%)
 }
 
 .fixed-top {
     position: fixed;
     top: 0;
     right: 0;
     left: 0;
     z-index: 1030
 }
 
 .fixed-bottom {
     position: fixed;
     right: 0;
     bottom: 0;
     left: 0;
     z-index: 1030
 }
 
 .sticky-top {
     position: sticky;
     top: 0;
     z-index: 1020
 }
 
 @media (min-width: 576px) {
     .sticky-sm-top {
         position: sticky;
         top: 0;
         z-index: 1020
     }
 }
 
 @media (min-width: 768px) {
     .sticky-md-top {
         position: sticky;
         top: 0;
         z-index: 1020
     }
 }
 
 @media (min-width: 992px) {
     .sticky-lg-top {
         position: sticky;
         top: 0;
         z-index: 1020
     }
 }
 
 @media (min-width: 1200px) {
     .sticky-xl-top {
         position: sticky;
         top: 0;
         z-index: 1020
     }
 }
 
 @media (min-width: 1400px) {
     .sticky-xxl-top {
         position: sticky;
         top: 0;
         z-index: 1020
     }
 }
 
 .hstack {
     display: flex;
     flex-direction: row;
     align-items: center;
     align-self: stretch
 }
 
 .vstack {
     display: flex;
     flex: 1 1 auto;
     flex-direction: column;
     align-self: stretch
 }
 
 .visually-hidden,
 .visually-hidden-focusable:not(:focus):not(:focus-within) {
     position: absolute !important;
     width: 1px !important;
     height: 1px !important;
     padding: 0 !important;
     margin: -1px !important;
     overflow: hidden !important;
     clip: rect(0, 0, 0, 0) !important;
     white-space: nowrap !important;
     border: 0 !important
 }
 
 .stretched-link::after {
     position: absolute;
     top: 0;
     right: 0;
     bottom: 0;
     left: 0;
     z-index: 1;
     content: ""
 }
 
 .text-truncate {
     overflow: hidden;
     text-overflow: ellipsis;
     white-space: nowrap
 }
 
 .vr {
     display: inline-block;
     align-self: stretch;
     width: 1px;
     min-height: 1em;
     background-color: currentColor;
     opacity: .25
 }
 
 .align-baseline {
     vertical-align: baseline !important
 }
 
 .align-top {
     vertical-align: top !important
 }
 
 .align-middle {
     vertical-align: middle !important
 }
 
 .align-bottom {
     vertical-align: bottom !important
 }
 
 .align-text-bottom {
     vertical-align: text-bottom !important
 }
 
 .align-text-top {
     vertical-align: text-top !important
 }
 
 .float-start {
     float: left !important
 }
 
 .float-end {
     float: right !important
 }
 
 .float-none {
     float: none !important
 }
 
 .opacity-0 {
     opacity: 0 !important
 }
 
 .opacity-25 {
     opacity: .25 !important
 }
 
 .opacity-50 {
     opacity: .5 !important
 }
 
 .opacity-75 {
     opacity: .75 !important
 }
 
 .opacity-100 {
     opacity: 1 !important
 }
 
 .overflow-auto {
     overflow: auto !important
 }
 
 .overflow-hidden {
     overflow: hidden !important
 }
 
 .overflow-visible {
     overflow: visible !important
 }
 
 .overflow-scroll {
     overflow: scroll !important
 }
 
 .d-inline {
     display: inline !important
 }
 
 .d-inline-block {
     display: inline-block !important
 }
 
 .d-block {
     display: block !important
 }
 
 .d-grid {
     display: grid !important
 }
 
 .d-table {
     display: table !important
 }
 
 .d-table-row {
     display: table-row !important
 }
 
 .d-table-cell {
     display: table-cell !important
 }
 
 .d-flex {
     display: flex !important
 }
 
 .d-inline-flex {
     display: inline-flex !important
 }
 
 .d-none {
     display: none !important
 }
 
 .shadow {
     box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important
 }
 
 .shadow-sm {
     box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important
 }
 
 .shadow-lg {
     box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important
 }
 
 .shadow-none {
     box-shadow: none !important
 }
 
 .position-static {
     position: static !important
 }
 
 .position-relative {
     position: relative !important
 }
 
 .position-absolute {
     position: absolute !important
 }
 
 .position-fixed {
     position: fixed !important
 }
 
 .position-sticky {
     position: sticky !important
 }
 
 .top-0 {
     top: 0 !important
 }
 
 .top-50 {
     top: 50% !important
 }
 
 .top-100 {
     top: 100% !important
 }
 
 .bottom-0 {
     bottom: 0 !important
 }
 
 .bottom-50 {
     bottom: 50% !important
 }
 
 .bottom-100 {
     bottom: 100% !important
 }
 
 .start-0 {
     left: 0 !important
 }
 
 .start-50 {
     left: 50% !important
 }
 
 .start-100 {
     left: 100% !important
 }
 
 .end-0 {
     right: 0 !important
 }
 
 .end-50 {
     right: 50% !important
 }
 
 .end-100 {
     right: 100% !important
 }
 
 .translate-middle {
     transform: translate(-50%, -50%) !important
 }
 
 .translate-middle-x {
     transform: translateX(-50%) !important
 }
 
 .translate-middle-y {
     transform: translateY(-50%) !important
 }
 
 .border {
     border: 1px solid #dee2e6 !important
 }
 
 .border-0 {
     border: 0 !important
 }
 
 .border-top {
     border-top: 1px solid #dee2e6 !important
 }
 
 .border-top-0 {
     border-top: 0 !important
 }
 
 .border-end {
     border-right: 1px solid #dee2e6 !important
 }
 
 .border-end-0 {
     border-right: 0 !important
 }
 
 .border-bottom {
     border-bottom: 1px solid #dee2e6 !important
 }
 
 .border-bottom-0 {
     border-bottom: 0 !important
 }
 
 .border-start {
     border-left: 1px solid #dee2e6 !important
 }
 
 .border-start-0 {
     border-left: 0 !important
 }
 
 .border-primary {
     border-color: #0d6efd !important
 }
 
 .border-secondary {
     border-color: #6c757d !important
 }
 
 .border-success {
     border-color: #198754 !important
 }
 
 .border-info {
     border-color: #0dcaf0 !important
 }
 
 .border-warning {
     border-color: #ffc107 !important
 }
 
 .border-danger {
     border-color: #dc3545 !important
 }
 
 .border-light {
     border-color: #f8f9fa !important
 }
 
 .border-dark {
     border-color: #212529 !important
 }
 
 .border-white {
     border-color: #fff !important
 }
 
 .border-1 {
     border-width: 1px !important
 }
 
 .border-2 {
     border-width: 2px !important
 }
 
 .border-3 {
     border-width: 3px !important
 }
 
 .border-4 {
     border-width: 4px !important
 }
 
 .border-5 {
     border-width: 5px !important
 }
 
 .w-25 {
     width: 25% !important
 }
 
 .w-50 {
     width: 50% !important
 }
 
 .w-75 {
     width: 75% !important
 }
 
 .w-100 {
     width: 100% !important
 }
 
 .w-auto {
     width: auto !important
 }
 
 .mw-100 {
     max-width: 100% !important
 }
 
 .vw-100 {
     width: 100vw !important
 }
 
 .min-vw-100 {
     min-width: 100vw !important
 }
 
 .h-25 {
     height: 25% !important
 }
 
 .h-50 {
     height: 50% !important
 }
 
 .h-75 {
     height: 75% !important
 }
 
 .h-100 {
     height: 100% !important
 }
 
 .h-auto {
     height: auto !important
 }
 
 .mh-100 {
     max-height: 100% !important
 }
 
 .vh-100 {
     height: 100vh !important
 }
 
 .min-vh-100 {
     min-height: 100vh !important
 }
 
 .flex-fill {
     flex: 1 1 auto !important
 }
 
 .flex-row {
     flex-direction: row !important
 }
 
 .flex-column {
     flex-direction: column !important
 }
 
 .flex-row-reverse {
     flex-direction: row-reverse !important
 }
 
 .flex-column-reverse {
     flex-direction: column-reverse !important
 }
 
 .flex-grow-0 {
     flex-grow: 0 !important
 }
 
 .flex-grow-1 {
     flex-grow: 1 !important
 }
 
 .flex-shrink-0 {
     flex-shrink: 0 !important
 }
 
 .flex-shrink-1 {
     flex-shrink: 1 !important
 }
 
 .flex-wrap {
     flex-wrap: wrap !important
 }
 
 .flex-nowrap {
     flex-wrap: nowrap !important
 }
 
 .flex-wrap-reverse {
     flex-wrap: wrap-reverse !important
 }
 
 .gap-0 {
     gap: 0 !important
 }
 
 .gap-1 {
     gap: .25rem !important
 }
 
 .gap-2 {
     gap: .5rem !important
 }
 
 .gap-3 {
     gap: 1rem !important
 }
 
 .gap-4 {
     gap: 1.5rem !important
 }
 
 .gap-5 {
     gap: 3rem !important
 }
 
 .justify-content-start {
     justify-content: flex-start !important
 }
 
 .justify-content-end {
     justify-content: flex-end !important
 }
 
 .justify-content-center {
     justify-content: center !important
 }
 
 .justify-content-between {
     justify-content: space-between !important
 }
 
 .justify-content-around {
     justify-content: space-around !important
 }
 
 .justify-content-evenly {
     justify-content: space-evenly !important
 }
 
 .align-items-start {
     align-items: flex-start !important
 }
 
 .align-items-end {
     align-items: flex-end !important
 }
 
 .align-items-center {
     align-items: center !important
 }
 
 .align-items-baseline {
     align-items: baseline !important
 }
 
 .align-items-stretch {
     align-items: stretch !important
 }
 
 .align-content-start {
     align-content: flex-start !important
 }
 
 .align-content-end {
     align-content: flex-end !important
 }
 
 .align-content-center {
     align-content: center !important
 }
 
 .align-content-between {
     align-content: space-between !important
 }
 
 .align-content-around {
     align-content: space-around !important
 }
 
 .align-content-stretch {
     align-content: stretch !important
 }
 
 .align-self-auto {
     align-self: auto !important
 }
 
 .align-self-start {
     align-self: flex-start !important
 }
 
 .align-self-end {
     align-self: flex-end !important
 }
 
 .align-self-center {
     align-self: center !important
 }
 
 .align-self-baseline {
     align-self: baseline !important
 }
 
 .align-self-stretch {
     align-self: stretch !important
 }
 
 .order-first {
     order: -1 !important
 }
 
 .order-0 {
     order: 0 !important
 }
 
 .order-1 {
     order: 1 !important
 }
 
 .order-2 {
     order: 2 !important
 }
 
 .order-3 {
     order: 3 !important
 }
 
 .order-4 {
     order: 4 !important
 }
 
 .order-5 {
     order: 5 !important
 }
 
 .order-last {
     order: 6 !important
 }
 
 .m-0 {
     margin: 0 !important
 }
 
 .m-1 {
     margin: .25rem !important
 }
 
 .m-2 {
     margin: .5rem !important
 }
 
 .m-3 {
     margin: 1rem !important
 }
 
 .m-4 {
     margin: 1.5rem !important
 }
 
 .m-5 {
     margin: 3rem !important
 }
 
 .m-auto {
     margin: auto !important
 }
 
 .mx-0 {
     margin-right: 0 !important;
     margin-left: 0 !important
 }
 
 .mx-1 {
     margin-right: .25rem !important;
     margin-left: .25rem !important
 }
 
 .mx-2 {
     margin-right: .5rem !important;
     margin-left: .5rem !important
 }
 
 .mx-3 {
     margin-right: 1rem !important;
     margin-left: 1rem !important
 }
 
 .mx-4 {
     margin-right: 1.5rem !important;
     margin-left: 1.5rem !important
 }
 
 .mx-5 {
     margin-right: 3rem !important;
     margin-left: 3rem !important
 }
 
 .mx-auto {
     margin-right: auto !important;
     margin-left: auto !important
 }
 
 .my-0 {
     margin-top: 0 !important;
     margin-bottom: 0 !important
 }
 
 .my-1 {
     margin-top: .25rem !important;
     margin-bottom: .25rem !important
 }
 
 .my-2 {
     margin-top: .5rem !important;
     margin-bottom: .5rem !important
 }
 
 .my-3 {
     margin-top: 1rem !important;
     margin-bottom: 1rem !important
 }
 
 .my-4 {
     margin-top: 1.5rem !important;
     margin-bottom: 1.5rem !important
 }
 
 .my-5 {
     margin-top: 3rem !important;
     margin-bottom: 3rem !important
 }
 
 .my-auto {
     margin-top: auto !important;
     margin-bottom: auto !important
 }
 
 .mt-0 {
     margin-top: 0 !important
 }
 
 .mt-1,
 .card .header h2 small,
 .card .header .h2 small,
 .card .header h2 .small,
 .card .header .h2 .small,
 .user-account .dropdown,
 .auth-box .lead,
 .setting-list li,
 .team-info {
     margin-top: .25rem !important
 }
 
 .mt-2 {
     margin-top: .5rem !important
 }
 
 .mt-3 {
     margin-top: 1rem !important
 }
 
 .mt-4 {
     margin-top: 1.5rem !important
 }
 
 .mt-5 {
     margin-top: 3rem !important
 }
 
 .mt-auto {
     margin-top: auto !important
 }
 
 .me-0 {
     margin-right: 0 !important
 }
 
 .me-1 {
     margin-right: .25rem !important
 }
 
 .me-2 {
     margin-right: .5rem !important
 }
 
 .me-3 {
     margin-right: 1rem !important
 }
 
 .me-4 {
     margin-right: 1.5rem !important
 }
 
 .me-5 {
     margin-right: 3rem !important
 }
 
 .me-auto {
     margin-right: auto !important
 }
 
 .mb-0 {
     margin-bottom: 0 !important
 }
 
 .mb-1 {
     margin-bottom: .25rem !important
 }
 
 .mb-2 {
     margin-bottom: .5rem !important
 }
 
 .mb-3 {
     margin-bottom: 1rem !important
 }
 
 .mb-4 {
     margin-bottom: 1.5rem !important
 }
 
 .mb-5 {
     margin-bottom: 3rem !important
 }
 
 .mb-auto {
     margin-bottom: auto !important
 }
 
 .ms-0 {
     margin-left: 0 !important
 }
 
 .ms-1 {
     margin-left: .25rem !important
 }
 
 .ms-2 {
     margin-left: .5rem !important
 }
 
 .ms-3 {
     margin-left: 1rem !important
 }
 
 .ms-4 {
     margin-left: 1.5rem !important
 }
 
 .ms-5 {
     margin-left: 3rem !important
 }
 
 .ms-auto {
     margin-left: auto !important
 }
 
 .p-0 {
     padding: 0 !important
 }
 
 .p-1 {
     padding: .25rem !important
 }
 
 .p-2 {
     padding: .5rem !important
 }
 
 .p-3 {
     padding: 1rem !important
 }
 
 .p-4 {
     padding: 1.5rem !important
 }
 
 .p-5 {
     padding: 3rem !important
 }
 
 .px-0 {
     padding-right: 0 !important;
     padding-left: 0 !important
 }
 
 .px-1 {
     padding-right: .25rem !important;
     padding-left: .25rem !important
 }
 
 .px-2 {
     padding-right: .5rem !important;
     padding-left: .5rem !important
 }
 
 .px-3 {
     padding-right: 1rem !important;
     padding-left: 1rem !important
 }
 
 .px-4 {
     padding-right: 1.5rem !important;
     padding-left: 1.5rem !important
 }
 
 .px-5 {
     padding-right: 3rem !important;
     padding-left: 3rem !important
 }
 
 .py-0 {
     padding-top: 0 !important;
     padding-bottom: 0 !important
 }
 
 .py-1 {
     padding-top: .25rem !important;
     padding-bottom: .25rem !important
 }
 
 .py-2 {
     padding-top: .5rem !important;
     padding-bottom: .5rem !important
 }
 
 .py-3 {
     padding-top: 1rem !important;
     padding-bottom: 1rem !important
 }
 
 .py-4 {
     padding-top: 1.5rem !important;
     padding-bottom: 1.5rem !important
 }
 
 .py-5 {
     padding-top: 3rem !important;
     padding-bottom: 3rem !important
 }
 
 .pt-0 {
     padding-top: 0 !important
 }
 
 .pt-1 {
     padding-top: .25rem !important
 }
 
 .pt-2 {
     padding-top: .5rem !important
 }
 
 .pt-3 {
     padding-top: 1rem !important
 }
 
 .pt-4 {
     padding-top: 1.5rem !important
 }
 
 .pt-5 {
     padding-top: 3rem !important
 }
 
 .pe-0 {
     padding-right: 0 !important
 }
 
 .pe-1 {
     padding-right: .25rem !important
 }
 
 .pe-2 {
     padding-right: .5rem !important
 }
 
 .pe-3 {
     padding-right: 1rem !important
 }
 
 .pe-4 {
     padding-right: 1.5rem !important
 }
 
 .pe-5 {
     padding-right: 3rem !important
 }
 
 .pb-0 {
     padding-bottom: 0 !important
 }
 
 .pb-1 {
     padding-bottom: .25rem !important
 }
 
 .pb-2 {
     padding-bottom: .5rem !important
 }
 
 .pb-3 {
     padding-bottom: 1rem !important
 }
 
 .pb-4 {
     padding-bottom: 1.5rem !important
 }
 
 .pb-5 {
     padding-bottom: 3rem !important
 }
 
 .ps-0 {
     padding-left: 0 !important
 }
 
 .ps-1 {
     padding-left: .25rem !important
 }
 
 .ps-2 {
     padding-left: .5rem !important
 }
 
 .ps-3 {
     padding-left: 1rem !important
 }
 
 .ps-4 {
     padding-left: 1.5rem !important
 }
 
 .ps-5 {
     padding-left: 3rem !important
 }
 
 .font-monospace {
     font-family: var(--bs-font-monospace) !important
 }
 
 .fs-1 {
     font-size: calc(1.375rem + 1.5vw) !important
 }
 
 .fs-2 {
     font-size: calc(1.325rem + .9vw) !important
 }
 
 .fs-3 {
     font-size: calc(1.3rem + .6vw) !important
 }
 
 .fs-4 {
     font-size: calc(1.275rem + .3vw) !important
 }
 
 .fs-5 {
     font-size: 1.25rem !important
 }
 
 .fs-6 {
     font-size: 1rem !important
 }
 
 .fst-italic {
     font-style: italic !important
 }
 
 .fst-normal {
     font-style: normal !important
 }
 
 .fw-light {
     font-weight: 300 !important
 }
 
 .fw-lighter {
     font-weight: lighter !important
 }
 
 .fw-normal {
     font-weight: 400 !important
 }
 
 .fw-bold {
     font-weight: 700 !important
 }
 
 .fw-bolder {
     font-weight: bolder !important
 }
 
 .lh-1 {
     line-height: 1 !important
 }
 
 .lh-sm {
     line-height: 1.25 !important
 }
 
 .lh-base {
     line-height: 1.5 !important
 }
 
 .lh-lg {
     line-height: 2 !important
 }
 
 .text-start {
     text-align: left !important
 }
 
 .text-end {
     text-align: right !important
 }
 
 .text-center {
     text-align: center !important
 }
 
 .text-decoration-none {
     text-decoration: none !important
 }
 
 .text-decoration-underline {
     text-decoration: underline !important
 }
 
 .text-decoration-line-through {
     text-decoration: line-through !important
 }
 
 .text-lowercase {
     text-transform: lowercase !important
 }
 
 .text-uppercase {
     text-transform: uppercase !important
 }
 
 .text-capitalize {
     text-transform: capitalize !important
 }
 
 .text-wrap {
     white-space: normal !important
 }
 
 .text-nowrap {
     white-space: nowrap !important
 }
 
 .text-break {
     word-wrap: break-word !important;
     word-break: break-word !important
 }
 
 .text-primary {
     --bs-text-opacity: 1;
     color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important
 }
 
 .text-secondary {
     --bs-text-opacity: 1;
     color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important
 }
 
 .text-success {
     --bs-text-opacity: 1;
     color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important
 }
 
 .text-info {
     --bs-text-opacity: 1;
     color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important
 }
 
 .text-warning {
     --bs-text-opacity: 1;
     color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important
 }
 
 .text-danger {
     --bs-text-opacity: 1;
     color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important
 }
 
 .text-light {
     --bs-text-opacity: 1;
     color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important
 }
 
 .text-dark {
     --bs-text-opacity: 1;
     color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important
 }
 
 .text-black {
     --bs-text-opacity: 1;
     color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important
 }
 
 .text-white {
     --bs-text-opacity: 1;
     color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important
 }
 
 .text-body {
     --bs-text-opacity: 1;
     color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important
 }
 
 .text-muted {
     --bs-text-opacity: 1;
     color: #6c757d !important
 }
 
 .text-black-50 {
     --bs-text-opacity: 1;
     color: rgba(0, 0, 0, 0.5) !important
 }
 
 .text-white-50 {
     --bs-text-opacity: 1;
     color: rgba(255, 255, 255, 0.5) !important
 }
 
 .text-reset {
     --bs-text-opacity: 1;
     color: inherit !important
 }
 
 .text-opacity-25 {
     --bs-text-opacity: .25
 }
 
 .text-opacity-50 {
     --bs-text-opacity: .5
 }
 
 .text-opacity-75 {
     --bs-text-opacity: .75
 }
 
 .text-opacity-100 {
     --bs-text-opacity: 1
 }
 
 .bg-primary {
     --bs-bg-opacity: 1;
     background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important
 }
 
 .bg-secondary {
     --bs-bg-opacity: 1;
     background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important
 }
 
 .bg-success {
     --bs-bg-opacity: 1;
     background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important
 }
 
 .bg-info {
     --bs-bg-opacity: 1;
     background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important
 }
 
 .bg-warning {
     --bs-bg-opacity: 1;
     background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important
 }
 
 .bg-danger {
     --bs-bg-opacity: 1;
     background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important
 }
 
 .bg-light {
     --bs-bg-opacity: 1;
     background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important
 }
 
 .bg-dark {
     --bs-bg-opacity: 1;
     background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important
 }
 
 .bg-black {
     --bs-bg-opacity: 1;
     background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important
 }
 
 .bg-white {
     --bs-bg-opacity: 1;
     background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important
 }
 
 .bg-body {
     --bs-bg-opacity: 1;
     background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important
 }
 
 .bg-transparent {
     --bs-bg-opacity: 1;
     background-color: rgba(0, 0, 0, 0) !important
 }
 
 .bg-opacity-10 {
     --bs-bg-opacity: .1
 }
 
 .bg-opacity-25 {
     --bs-bg-opacity: .25
 }
 
 .bg-opacity-50 {
     --bs-bg-opacity: .5
 }
 
 .bg-opacity-75 {
     --bs-bg-opacity: .75
 }
 
 .bg-opacity-100 {
     --bs-bg-opacity: 1
 }
 
 .bg-gradient {
     background-image: var(--bs-gradient) !important
 }
 
 .user-select-all {
     user-select: all !important
 }
 
 .user-select-auto {
     user-select: auto !important
 }
 
 .user-select-none {
     user-select: none !important
 }
 
 .pe-none {
     pointer-events: none !important
 }
 
 .pe-auto {
     pointer-events: auto !important
 }
 
 .rounded {
     border-radius: .25rem !important
 }
 
 .rounded-0 {
     border-radius: 0 !important
 }
 
 .rounded-1 {
     border-radius: .2rem !important
 }
 
 .rounded-2 {
     border-radius: .25rem !important
 }
 
 .rounded-3 {
     border-radius: .3rem !important
 }
 
 .rounded-circle {
     border-radius: 50% !important
 }
 
 .rounded-pill {
     border-radius: 50rem !important
 }
 
 .rounded-top {
     border-top-left-radius: .25rem !important;
     border-top-right-radius: .25rem !important
 }
 
 .rounded-end {
     border-top-right-radius: .25rem !important;
     border-bottom-right-radius: .25rem !important
 }
 
 .rounded-bottom {
     border-bottom-right-radius: .25rem !important;
     border-bottom-left-radius: .25rem !important
 }
 
 .rounded-start {
     border-bottom-left-radius: .25rem !important;
     border-top-left-radius: .25rem !important
 }
 
 .visible {
     visibility: visible !important
 }
 
 .invisible {
     visibility: hidden !important
 }
 
 @media (min-width: 576px) {
     .float-sm-start {
         float: left !important
     }
 
     .float-sm-end {
         float: right !important
     }
 
     .float-sm-none {
         float: none !important
     }
 
     .d-sm-inline {
         display: inline !important
     }
 
     .d-sm-inline-block {
         display: inline-block !important
     }
 
     .d-sm-block {
         display: block !important
     }
 
     .d-sm-grid {
         display: grid !important
     }
 
     .d-sm-table {
         display: table !important
     }
 
     .d-sm-table-row {
         display: table-row !important
     }
 
     .d-sm-table-cell {
         display: table-cell !important
     }
 
     .d-sm-flex {
         display: flex !important
     }
 
     .d-sm-inline-flex {
         display: inline-flex !important
     }
 
     .d-sm-none {
         display: none !important
     }
 
     .flex-sm-fill {
         flex: 1 1 auto !important
     }
 
     .flex-sm-row {
         flex-direction: row !important
     }
 
     .flex-sm-column {
         flex-direction: column !important
     }
 
     .flex-sm-row-reverse {
         flex-direction: row-reverse !important
     }
 
     .flex-sm-column-reverse {
         flex-direction: column-reverse !important
     }
 
     .flex-sm-grow-0 {
         flex-grow: 0 !important
     }
 
     .flex-sm-grow-1 {
         flex-grow: 1 !important
     }
 
     .flex-sm-shrink-0 {
         flex-shrink: 0 !important
     }
 
     .flex-sm-shrink-1 {
         flex-shrink: 1 !important
     }
 
     .flex-sm-wrap {
         flex-wrap: wrap !important
     }
 
     .flex-sm-nowrap {
         flex-wrap: nowrap !important
     }
 
     .flex-sm-wrap-reverse {
         flex-wrap: wrap-reverse !important
     }
 
     .gap-sm-0 {
         gap: 0 !important
     }
 
     .gap-sm-1 {
         gap: .25rem !important
     }
 
     .gap-sm-2 {
         gap: .5rem !important
     }
 
     .gap-sm-3 {
         gap: 1rem !important
     }
 
     .gap-sm-4 {
         gap: 1.5rem !important
     }
 
     .gap-sm-5 {
         gap: 3rem !important
     }
 
     .justify-content-sm-start {
         justify-content: flex-start !important
     }
 
     .justify-content-sm-end {
         justify-content: flex-end !important
     }
 
     .justify-content-sm-center {
         justify-content: center !important
     }
 
     .justify-content-sm-between {
         justify-content: space-between !important
     }
 
     .justify-content-sm-around {
         justify-content: space-around !important
     }
 
     .justify-content-sm-evenly {
         justify-content: space-evenly !important
     }
 
     .align-items-sm-start {
         align-items: flex-start !important
     }
 
     .align-items-sm-end {
         align-items: flex-end !important
     }
 
     .align-items-sm-center {
         align-items: center !important
     }
 
     .align-items-sm-baseline {
         align-items: baseline !important
     }
 
     .align-items-sm-stretch {
         align-items: stretch !important
     }
 
     .align-content-sm-start {
         align-content: flex-start !important
     }
 
     .align-content-sm-end {
         align-content: flex-end !important
     }
 
     .align-content-sm-center {
         align-content: center !important
     }
 
     .align-content-sm-between {
         align-content: space-between !important
     }
 
     .align-content-sm-around {
         align-content: space-around !important
     }
 
     .align-content-sm-stretch {
         align-content: stretch !important
     }
 
     .align-self-sm-auto {
         align-self: auto !important
     }
 
     .align-self-sm-start {
         align-self: flex-start !important
     }
 
     .align-self-sm-end {
         align-self: flex-end !important
     }
 
     .align-self-sm-center {
         align-self: center !important
     }
 
     .align-self-sm-baseline {
         align-self: baseline !important
     }
 
     .align-self-sm-stretch {
         align-self: stretch !important
     }
 
     .order-sm-first {
         order: -1 !important
     }
 
     .order-sm-0 {
         order: 0 !important
     }
 
     .order-sm-1 {
         order: 1 !important
     }
 
     .order-sm-2 {
         order: 2 !important
     }
 
     .order-sm-3 {
         order: 3 !important
     }
 
     .order-sm-4 {
         order: 4 !important
     }
 
     .order-sm-5 {
         order: 5 !important
     }
 
     .order-sm-last {
         order: 6 !important
     }
 
     .m-sm-0 {
         margin: 0 !important
     }
 
     .m-sm-1 {
         margin: .25rem !important
     }
 
     .m-sm-2 {
         margin: .5rem !important
     }
 
     .m-sm-3 {
         margin: 1rem !important
     }
 
     .m-sm-4 {
         margin: 1.5rem !important
     }
 
     .m-sm-5 {
         margin: 3rem !important
     }
 
     .m-sm-auto {
         margin: auto !important
     }
 
     .mx-sm-0 {
         margin-right: 0 !important;
         margin-left: 0 !important
     }
 
     .mx-sm-1 {
         margin-right: .25rem !important;
         margin-left: .25rem !important
     }
 
     .mx-sm-2 {
         margin-right: .5rem !important;
         margin-left: .5rem !important
     }
 
     .mx-sm-3 {
         margin-right: 1rem !important;
         margin-left: 1rem !important
     }
 
     .mx-sm-4 {
         margin-right: 1.5rem !important;
         margin-left: 1.5rem !important
     }
 
     .mx-sm-5 {
         margin-right: 3rem !important;
         margin-left: 3rem !important
     }
 
     .mx-sm-auto {
         margin-right: auto !important;
         margin-left: auto !important
     }
 
     .my-sm-0 {
         margin-top: 0 !important;
         margin-bottom: 0 !important
     }
 
     .my-sm-1 {
         margin-top: .25rem !important;
         margin-bottom: .25rem !important
     }
 
     .my-sm-2 {
         margin-top: .5rem !important;
         margin-bottom: .5rem !important
     }
 
     .my-sm-3 {
         margin-top: 1rem !important;
         margin-bottom: 1rem !important
     }
 
     .my-sm-4 {
         margin-top: 1.5rem !important;
         margin-bottom: 1.5rem !important
     }
 
     .my-sm-5 {
         margin-top: 3rem !important;
         margin-bottom: 3rem !important
     }
 
     .my-sm-auto {
         margin-top: auto !important;
         margin-bottom: auto !important
     }
 
     .mt-sm-0 {
         margin-top: 0 !important
     }
 
     .mt-sm-1 {
         margin-top: .25rem !important
     }
 
     .mt-sm-2 {
         margin-top: .5rem !important
     }
 
     .mt-sm-3 {
         margin-top: 1rem !important
     }
 
     .mt-sm-4 {
         margin-top: 1.5rem !important
     }
 
     .mt-sm-5 {
         margin-top: 3rem !important
     }
 
     .mt-sm-auto {
         margin-top: auto !important
     }
 
     .me-sm-0 {
         margin-right: 0 !important
     }
 
     .me-sm-1 {
         margin-right: .25rem !important
     }
 
     .me-sm-2 {
         margin-right: .5rem !important
     }
 
     .me-sm-3 {
         margin-right: 1rem !important
     }
 
     .me-sm-4 {
         margin-right: 1.5rem !important
     }
 
     .me-sm-5 {
         margin-right: 3rem !important
     }
 
     .me-sm-auto {
         margin-right: auto !important
     }
 
     .mb-sm-0 {
         margin-bottom: 0 !important
     }
 
     .mb-sm-1 {
         margin-bottom: .25rem !important
     }
 
     .mb-sm-2 {
         margin-bottom: .5rem !important
     }
 
     .mb-sm-3 {
         margin-bottom: 1rem !important
     }
 
     .mb-sm-4 {
         margin-bottom: 1.5rem !important
     }
 
     .mb-sm-5 {
         margin-bottom: 3rem !important
     }
 
     .mb-sm-auto {
         margin-bottom: auto !important
     }
 
     .ms-sm-0 {
         margin-left: 0 !important
     }
 
     .ms-sm-1 {
         margin-left: .25rem !important
     }
 
     .ms-sm-2 {
         margin-left: .5rem !important
     }
 
     .ms-sm-3 {
         margin-left: 1rem !important
     }
 
     .ms-sm-4 {
         margin-left: 1.5rem !important
     }
 
     .ms-sm-5 {
         margin-left: 3rem !important
     }
 
     .ms-sm-auto {
         margin-left: auto !important
     }
 
     .p-sm-0 {
         padding: 0 !important
     }
 
     .p-sm-1 {
         padding: .25rem !important
     }
 
     .p-sm-2 {
         padding: .5rem !important
     }
 
     .p-sm-3 {
         padding: 1rem !important
     }
 
     .p-sm-4 {
         padding: 1.5rem !important
     }
 
     .p-sm-5 {
         padding: 3rem !important
     }
 
     .px-sm-0 {
         padding-right: 0 !important;
         padding-left: 0 !important
     }
 
     .px-sm-1 {
         padding-right: .25rem !important;
         padding-left: .25rem !important
     }
 
     .px-sm-2 {
         padding-right: .5rem !important;
         padding-left: .5rem !important
     }
 
     .px-sm-3 {
         padding-right: 1rem !important;
         padding-left: 1rem !important
     }
 
     .px-sm-4 {
         padding-right: 1.5rem !important;
         padding-left: 1.5rem !important
     }
 
     .px-sm-5 {
         padding-right: 3rem !important;
         padding-left: 3rem !important
     }
 
     .py-sm-0 {
         padding-top: 0 !important;
         padding-bottom: 0 !important
     }
 
     .py-sm-1 {
         padding-top: .25rem !important;
         padding-bottom: .25rem !important
     }
 
     .py-sm-2 {
         padding-top: .5rem !important;
         padding-bottom: .5rem !important
     }
 
     .py-sm-3 {
         padding-top: 1rem !important;
         padding-bottom: 1rem !important
     }
 
     .py-sm-4 {
         padding-top: 1.5rem !important;
         padding-bottom: 1.5rem !important
     }
 
     .py-sm-5 {
         padding-top: 3rem !important;
         padding-bottom: 3rem !important
     }
 
     .pt-sm-0 {
         padding-top: 0 !important
     }
 
     .pt-sm-1 {
         padding-top: .25rem !important
     }
 
     .pt-sm-2 {
         padding-top: .5rem !important
     }
 
     .pt-sm-3 {
         padding-top: 1rem !important
     }
 
     .pt-sm-4 {
         padding-top: 1.5rem !important
     }
 
     .pt-sm-5 {
         padding-top: 3rem !important
     }
 
     .pe-sm-0 {
         padding-right: 0 !important
     }
 
     .pe-sm-1 {
         padding-right: .25rem !important
     }
 
     .pe-sm-2 {
         padding-right: .5rem !important
     }
 
     .pe-sm-3 {
         padding-right: 1rem !important
     }
 
     .pe-sm-4 {
         padding-right: 1.5rem !important
     }
 
     .pe-sm-5 {
         padding-right: 3rem !important
     }
 
     .pb-sm-0 {
         padding-bottom: 0 !important
     }
 
     .pb-sm-1 {
         padding-bottom: .25rem !important
     }
 
     .pb-sm-2 {
         padding-bottom: .5rem !important
     }
 
     .pb-sm-3 {
         padding-bottom: 1rem !important
     }
 
     .pb-sm-4 {
         padding-bottom: 1.5rem !important
     }
 
     .pb-sm-5 {
         padding-bottom: 3rem !important
     }
 
     .ps-sm-0 {
         padding-left: 0 !important
     }
 
     .ps-sm-1 {
         padding-left: .25rem !important
     }
 
     .ps-sm-2 {
         padding-left: .5rem !important
     }
 
     .ps-sm-3 {
         padding-left: 1rem !important
     }
 
     .ps-sm-4 {
         padding-left: 1.5rem !important
     }
 
     .ps-sm-5 {
         padding-left: 3rem !important
     }
 
     .text-sm-start {
         text-align: left !important
     }
 
     .text-sm-end {
         text-align: right !important
     }
 
     .text-sm-center {
         text-align: center !important
     }
 }
 
 @media (min-width: 768px) {
     .float-md-start {
         float: left !important
     }
 
     .float-md-end {
         float: right !important
     }
 
     .float-md-none {
         float: none !important
     }
 
     .d-md-inline {
         display: inline !important
     }
 
     .d-md-inline-block {
         display: inline-block !important
     }
 
     .d-md-block {
         display: block !important
     }
 
     .d-md-grid {
         display: grid !important
     }
 
     .d-md-table {
         display: table !important
     }
 
     .d-md-table-row {
         display: table-row !important
     }
 
     .d-md-table-cell {
         display: table-cell !important
     }
 
     .d-md-flex {
         display: flex !important
     }
 
     .d-md-inline-flex {
         display: inline-flex !important
     }
 
     .d-md-none {
         display: none !important
     }
 
     .flex-md-fill {
         flex: 1 1 auto !important
     }
 
     .flex-md-row {
         flex-direction: row !important
     }
 
     .flex-md-column {
         flex-direction: column !important
     }
 
     .flex-md-row-reverse {
         flex-direction: row-reverse !important
     }
 
     .flex-md-column-reverse {
         flex-direction: column-reverse !important
     }
 
     .flex-md-grow-0 {
         flex-grow: 0 !important
     }
 
     .flex-md-grow-1 {
         flex-grow: 1 !important
     }
 
     .flex-md-shrink-0 {
         flex-shrink: 0 !important
     }
 
     .flex-md-shrink-1 {
         flex-shrink: 1 !important
     }
 
     .flex-md-wrap {
         flex-wrap: wrap !important
     }
 
     .flex-md-nowrap {
         flex-wrap: nowrap !important
     }
 
     .flex-md-wrap-reverse {
         flex-wrap: wrap-reverse !important
     }
 
     .gap-md-0 {
         gap: 0 !important
     }
 
     .gap-md-1 {
         gap: .25rem !important
     }
 
     .gap-md-2 {
         gap: .5rem !important
     }
 
     .gap-md-3 {
         gap: 1rem !important
     }
 
     .gap-md-4 {
         gap: 1.5rem !important
     }
 
     .gap-md-5 {
         gap: 3rem !important
     }
 
     .justify-content-md-start {
         justify-content: flex-start !important
     }
 
     .justify-content-md-end {
         justify-content: flex-end !important
     }
 
     .justify-content-md-center {
         justify-content: center !important
     }
 
     .justify-content-md-between {
         justify-content: space-between !important
     }
 
     .justify-content-md-around {
         justify-content: space-around !important
     }
 
     .justify-content-md-evenly {
         justify-content: space-evenly !important
     }
 
     .align-items-md-start {
         align-items: flex-start !important
     }
 
     .align-items-md-end {
         align-items: flex-end !important
     }
 
     .align-items-md-center {
         align-items: center !important
     }
 
     .align-items-md-baseline {
         align-items: baseline !important
     }
 
     .align-items-md-stretch {
         align-items: stretch !important
     }
 
     .align-content-md-start {
         align-content: flex-start !important
     }
 
     .align-content-md-end {
         align-content: flex-end !important
     }
 
     .align-content-md-center {
         align-content: center !important
     }
 
     .align-content-md-between {
         align-content: space-between !important
     }
 
     .align-content-md-around {
         align-content: space-around !important
     }
 
     .align-content-md-stretch {
         align-content: stretch !important
     }
 
     .align-self-md-auto {
         align-self: auto !important
     }
 
     .align-self-md-start {
         align-self: flex-start !important
     }
 
     .align-self-md-end {
         align-self: flex-end !important
     }
 
     .align-self-md-center {
         align-self: center !important
     }
 
     .align-self-md-baseline {
         align-self: baseline !important
     }
 
     .align-self-md-stretch {
         align-self: stretch !important
     }
 
     .order-md-first {
         order: -1 !important
     }
 
     .order-md-0 {
         order: 0 !important
     }
 
     .order-md-1 {
         order: 1 !important
     }
 
     .order-md-2 {
         order: 2 !important
     }
 
     .order-md-3 {
         order: 3 !important
     }
 
     .order-md-4 {
         order: 4 !important
     }
 
     .order-md-5 {
         order: 5 !important
     }
 
     .order-md-last {
         order: 6 !important
     }
 
     .m-md-0 {
         margin: 0 !important
     }
 
     .m-md-1 {
         margin: .25rem !important
     }
 
     .m-md-2 {
         margin: .5rem !important
     }
 
     .m-md-3 {
         margin: 1rem !important
     }
 
     .m-md-4 {
         margin: 1.5rem !important
     }
 
     .m-md-5 {
         margin: 3rem !important
     }
 
     .m-md-auto {
         margin: auto !important
     }
 
     .mx-md-0 {
         margin-right: 0 !important;
         margin-left: 0 !important
     }
 
     .mx-md-1 {
         margin-right: .25rem !important;
         margin-left: .25rem !important
     }
 
     .mx-md-2 {
         margin-right: .5rem !important;
         margin-left: .5rem !important
     }
 
     .mx-md-3 {
         margin-right: 1rem !important;
         margin-left: 1rem !important
     }
 
     .mx-md-4 {
         margin-right: 1.5rem !important;
         margin-left: 1.5rem !important
     }
 
     .mx-md-5 {
         margin-right: 3rem !important;
         margin-left: 3rem !important
     }
 
     .mx-md-auto {
         margin-right: auto !important;
         margin-left: auto !important
     }
 
     .my-md-0 {
         margin-top: 0 !important;
         margin-bottom: 0 !important
     }
 
     .my-md-1 {
         margin-top: .25rem !important;
         margin-bottom: .25rem !important
     }
 
     .my-md-2 {
         margin-top: .5rem !important;
         margin-bottom: .5rem !important
     }
 
     .my-md-3 {
         margin-top: 1rem !important;
         margin-bottom: 1rem !important
     }
 
     .my-md-4 {
         margin-top: 1.5rem !important;
         margin-bottom: 1.5rem !important
     }
 
     .my-md-5 {
         margin-top: 3rem !important;
         margin-bottom: 3rem !important
     }
 
     .my-md-auto {
         margin-top: auto !important;
         margin-bottom: auto !important
     }
 
     .mt-md-0 {
         margin-top: 0 !important
     }
 
     .mt-md-1 {
         margin-top: .25rem !important
     }
 
     .mt-md-2 {
         margin-top: .5rem !important
     }
 
     .mt-md-3 {
         margin-top: 1rem !important
     }
 
     .mt-md-4 {
         margin-top: 1.5rem !important
     }
 
     .mt-md-5 {
         margin-top: 3rem !important
     }
 
     .mt-md-auto {
         margin-top: auto !important
     }
 
     .me-md-0 {
         margin-right: 0 !important
     }
 
     .me-md-1 {
         margin-right: .25rem !important
     }
 
     .me-md-2 {
         margin-right: .5rem !important
     }
 
     .me-md-3 {
         margin-right: 1rem !important
     }
 
     .me-md-4 {
         margin-right: 1.5rem !important
     }
 
     .me-md-5 {
         margin-right: 3rem !important
     }
 
     .me-md-auto {
         margin-right: auto !important
     }
 
     .mb-md-0 {
         margin-bottom: 0 !important
     }
 
     .mb-md-1 {
         margin-bottom: .25rem !important
     }
 
     .mb-md-2 {
         margin-bottom: .5rem !important
     }
 
     .mb-md-3 {
         margin-bottom: 1rem !important
     }
 
     .mb-md-4 {
         margin-bottom: 1.5rem !important
     }
 
     .mb-md-5 {
         margin-bottom: 3rem !important
     }
 
     .mb-md-auto {
         margin-bottom: auto !important
     }
 
     .ms-md-0 {
         margin-left: 0 !important
     }
 
     .ms-md-1 {
         margin-left: .25rem !important
     }
 
     .ms-md-2 {
         margin-left: .5rem !important
     }
 
     .ms-md-3 {
         margin-left: 1rem !important
     }
 
     .ms-md-4 {
         margin-left: 1.5rem !important
     }
 
     .ms-md-5 {
         margin-left: 3rem !important
     }
 
     .ms-md-auto {
         margin-left: auto !important
     }
 
     .p-md-0 {
         padding: 0 !important
     }
 
     .p-md-1 {
         padding: .25rem !important
     }
 
     .p-md-2 {
         padding: .5rem !important
     }
 
     .p-md-3 {
         padding: 1rem !important
     }
 
     .p-md-4 {
         padding: 1.5rem !important
     }
 
     .p-md-5 {
         padding: 3rem !important
     }
 
     .px-md-0 {
         padding-right: 0 !important;
         padding-left: 0 !important
     }
 
     .px-md-1 {
         padding-right: .25rem !important;
         padding-left: .25rem !important
     }
 
     .px-md-2 {
         padding-right: .5rem !important;
         padding-left: .5rem !important
     }
 
     .px-md-3 {
         padding-right: 1rem !important;
         padding-left: 1rem !important
     }
 
     .px-md-4 {
         padding-right: 1.5rem !important;
         padding-left: 1.5rem !important
     }
 
     .px-md-5 {
         padding-right: 3rem !important;
         padding-left: 3rem !important
     }
 
     .py-md-0 {
         padding-top: 0 !important;
         padding-bottom: 0 !important
     }
 
     .py-md-1 {
         padding-top: .25rem !important;
         padding-bottom: .25rem !important
     }
 
     .py-md-2 {
         padding-top: .5rem !important;
         padding-bottom: .5rem !important
     }
 
     .py-md-3 {
         padding-top: 1rem !important;
         padding-bottom: 1rem !important
     }
 
     .py-md-4 {
         padding-top: 1.5rem !important;
         padding-bottom: 1.5rem !important
     }
 
     .py-md-5 {
         padding-top: 3rem !important;
         padding-bottom: 3rem !important
     }
 
     .pt-md-0 {
         padding-top: 0 !important
     }
 
     .pt-md-1 {
         padding-top: .25rem !important
     }
 
     .pt-md-2 {
         padding-top: .5rem !important
     }
 
     .pt-md-3 {
         padding-top: 1rem !important
     }
 
     .pt-md-4 {
         padding-top: 1.5rem !important
     }
 
     .pt-md-5 {
         padding-top: 3rem !important
     }
 
     .pe-md-0 {
         padding-right: 0 !important
     }
 
     .pe-md-1 {
         padding-right: .25rem !important
     }
 
     .pe-md-2 {
         padding-right: .5rem !important
     }
 
     .pe-md-3 {
         padding-right: 1rem !important
     }
 
     .pe-md-4 {
         padding-right: 1.5rem !important
     }
 
     .pe-md-5 {
         padding-right: 3rem !important
     }
 
     .pb-md-0 {
         padding-bottom: 0 !important
     }
 
     .pb-md-1 {
         padding-bottom: .25rem !important
     }
 
     .pb-md-2 {
         padding-bottom: .5rem !important
     }
 
     .pb-md-3 {
         padding-bottom: 1rem !important
     }
 
     .pb-md-4 {
         padding-bottom: 1.5rem !important
     }
 
     .pb-md-5 {
         padding-bottom: 3rem !important
     }
 
     .ps-md-0 {
         padding-left: 0 !important
     }
 
     .ps-md-1 {
         padding-left: .25rem !important
     }
 
     .ps-md-2 {
         padding-left: .5rem !important
     }
 
     .ps-md-3 {
         padding-left: 1rem !important
     }
 
     .ps-md-4 {
         padding-left: 1.5rem !important
     }
 
     .ps-md-5 {
         padding-left: 3rem !important
     }
 
     .text-md-start {
         text-align: left !important
     }
 
     .text-md-end {
         text-align: right !important
     }
 
     .text-md-center {
         text-align: center !important
     }
 }
 
 @media (min-width: 992px) {
     .float-lg-start {
         float: left !important
     }
 
     .float-lg-end {
         float: right !important
     }
 
     .float-lg-none {
         float: none !important
     }
 
     .d-lg-inline {
         display: inline !important
     }
 
     .d-lg-inline-block {
         display: inline-block !important
     }
 
     .d-lg-block {
         display: block !important
     }
 
     .d-lg-grid {
         display: grid !important
     }
 
     .d-lg-table {
         display: table !important
     }
 
     .d-lg-table-row {
         display: table-row !important
     }
 
     .d-lg-table-cell {
         display: table-cell !important
     }
 
     .d-lg-flex {
         display: flex !important
     }
 
     .d-lg-inline-flex {
         display: inline-flex !important
     }
 
     .d-lg-none {
         display: none !important
     }
 
     .flex-lg-fill {
         flex: 1 1 auto !important
     }
 
     .flex-lg-row {
         flex-direction: row !important
     }
 
     .flex-lg-column {
         flex-direction: column !important
     }
 
     .flex-lg-row-reverse {
         flex-direction: row-reverse !important
     }
 
     .flex-lg-column-reverse {
         flex-direction: column-reverse !important
     }
 
     .flex-lg-grow-0 {
         flex-grow: 0 !important
     }
 
     .flex-lg-grow-1 {
         flex-grow: 1 !important
     }
 
     .flex-lg-shrink-0 {
         flex-shrink: 0 !important
     }
 
     .flex-lg-shrink-1 {
         flex-shrink: 1 !important
     }
 
     .flex-lg-wrap {
         flex-wrap: wrap !important
     }
 
     .flex-lg-nowrap {
         flex-wrap: nowrap !important
     }
 
     .flex-lg-wrap-reverse {
         flex-wrap: wrap-reverse !important
     }
 
     .gap-lg-0 {
         gap: 0 !important
     }
 
     .gap-lg-1 {
         gap: .25rem !important
     }
 
     .gap-lg-2 {
         gap: .5rem !important
     }
 
     .gap-lg-3 {
         gap: 1rem !important
     }
 
     .gap-lg-4 {
         gap: 1.5rem !important
     }
 
     .gap-lg-5 {
         gap: 3rem !important
     }
 
     .justify-content-lg-start {
         justify-content: flex-start !important
     }
 
     .justify-content-lg-end {
         justify-content: flex-end !important
     }
 
     .justify-content-lg-center {
         justify-content: center !important
     }
 
     .justify-content-lg-between {
         justify-content: space-between !important
     }
 
     .justify-content-lg-around {
         justify-content: space-around !important
     }
 
     .justify-content-lg-evenly {
         justify-content: space-evenly !important
     }
 
     .align-items-lg-start {
         align-items: flex-start !important
     }
 
     .align-items-lg-end {
         align-items: flex-end !important
     }
 
     .align-items-lg-center {
         align-items: center !important
     }
 
     .align-items-lg-baseline {
         align-items: baseline !important
     }
 
     .align-items-lg-stretch {
         align-items: stretch !important
     }
 
     .align-content-lg-start {
         align-content: flex-start !important
     }
 
     .align-content-lg-end {
         align-content: flex-end !important
     }
 
     .align-content-lg-center {
         align-content: center !important
     }
 
     .align-content-lg-between {
         align-content: space-between !important
     }
 
     .align-content-lg-around {
         align-content: space-around !important
     }
 
     .align-content-lg-stretch {
         align-content: stretch !important
     }
 
     .align-self-lg-auto {
         align-self: auto !important
     }
 
     .align-self-lg-start {
         align-self: flex-start !important
     }
 
     .align-self-lg-end {
         align-self: flex-end !important
     }
 
     .align-self-lg-center {
         align-self: center !important
     }
 
     .align-self-lg-baseline {
         align-self: baseline !important
     }
 
     .align-self-lg-stretch {
         align-self: stretch !important
     }
 
     .order-lg-first {
         order: -1 !important
     }
 
     .order-lg-0 {
         order: 0 !important
     }
 
     .order-lg-1 {
         order: 1 !important
     }
 
     .order-lg-2 {
         order: 2 !important
     }
 
     .order-lg-3 {
         order: 3 !important
     }
 
     .order-lg-4 {
         order: 4 !important
     }
 
     .order-lg-5 {
         order: 5 !important
     }
 
     .order-lg-last {
         order: 6 !important
     }
 
     .m-lg-0 {
         margin: 0 !important
     }
 
     .m-lg-1 {
         margin: .25rem !important
     }
 
     .m-lg-2 {
         margin: .5rem !important
     }
 
     .m-lg-3 {
         margin: 1rem !important
     }
 
     .m-lg-4 {
         margin: 1.5rem !important
     }
 
     .m-lg-5 {
         margin: 3rem !important
     }
 
     .m-lg-auto {
         margin: auto !important
     }
 
     .mx-lg-0 {
         margin-right: 0 !important;
         margin-left: 0 !important
     }
 
     .mx-lg-1 {
         margin-right: .25rem !important;
         margin-left: .25rem !important
     }
 
     .mx-lg-2 {
         margin-right: .5rem !important;
         margin-left: .5rem !important
     }
 
     .mx-lg-3 {
         margin-right: 1rem !important;
         margin-left: 1rem !important
     }
 
     .mx-lg-4 {
         margin-right: 1.5rem !important;
         margin-left: 1.5rem !important
     }
 
     .mx-lg-5 {
         margin-right: 3rem !important;
         margin-left: 3rem !important
     }
 
     .mx-lg-auto {
         margin-right: auto !important;
         margin-left: auto !important
     }
 
     .my-lg-0 {
         margin-top: 0 !important;
         margin-bottom: 0 !important
     }
 
     .my-lg-1 {
         margin-top: .25rem !important;
         margin-bottom: .25rem !important
     }
 
     .my-lg-2 {
         margin-top: .5rem !important;
         margin-bottom: .5rem !important
     }
 
     .my-lg-3 {
         margin-top: 1rem !important;
         margin-bottom: 1rem !important
     }
 
     .my-lg-4 {
         margin-top: 1.5rem !important;
         margin-bottom: 1.5rem !important
     }
 
     .my-lg-5 {
         margin-top: 3rem !important;
         margin-bottom: 3rem !important
     }
 
     .my-lg-auto {
         margin-top: auto !important;
         margin-bottom: auto !important
     }
 
     .mt-lg-0 {
         margin-top: 0 !important
     }
 
     .mt-lg-1 {
         margin-top: .25rem !important
     }
 
     .mt-lg-2 {
         margin-top: .5rem !important
     }
 
     .mt-lg-3 {
         margin-top: 1rem !important
     }
 
     .mt-lg-4 {
         margin-top: 1.5rem !important
     }
 
     .mt-lg-5 {
         margin-top: 3rem !important
     }
 
     .mt-lg-auto {
         margin-top: auto !important
     }
 
     .me-lg-0 {
         margin-right: 0 !important
     }
 
     .me-lg-1 {
         margin-right: .25rem !important
     }
 
     .me-lg-2 {
         margin-right: .5rem !important
     }
 
     .me-lg-3 {
         margin-right: 1rem !important
     }
 
     .me-lg-4 {
         margin-right: 1.5rem !important
     }
 
     .me-lg-5 {
         margin-right: 3rem !important
     }
 
     .me-lg-auto {
         margin-right: auto !important
     }
 
     .mb-lg-0 {
         margin-bottom: 0 !important
     }
 
     .mb-lg-1 {
         margin-bottom: .25rem !important
     }
 
     .mb-lg-2 {
         margin-bottom: .5rem !important
     }
 
     .mb-lg-3 {
         margin-bottom: 1rem !important
     }
 
     .mb-lg-4 {
         margin-bottom: 1.5rem !important
     }
 
     .mb-lg-5 {
         margin-bottom: 3rem !important
     }
 
     .mb-lg-auto {
         margin-bottom: auto !important
     }
 
     .ms-lg-0 {
         margin-left: 0 !important
     }
 
     .ms-lg-1 {
         margin-left: .25rem !important
     }
 
     .ms-lg-2 {
         margin-left: .5rem !important
     }
 
     .ms-lg-3 {
         margin-left: 1rem !important
     }
 
     .ms-lg-4 {
         margin-left: 1.5rem !important
     }
 
     .ms-lg-5 {
         margin-left: 3rem !important
     }
 
     .ms-lg-auto {
         margin-left: auto !important
     }
 
     .p-lg-0 {
         padding: 0 !important
     }
 
     .p-lg-1 {
         padding: .25rem !important
     }
 
     .p-lg-2 {
         padding: .5rem !important
     }
 
     .p-lg-3 {
         padding: 1rem !important
     }
 
     .p-lg-4 {
         padding: 1.5rem !important
     }
 
     .p-lg-5 {
         padding: 3rem !important
     }
 
     .px-lg-0 {
         padding-right: 0 !important;
         padding-left: 0 !important
     }
 
     .px-lg-1 {
         padding-right: .25rem !important;
         padding-left: .25rem !important
     }
 
     .px-lg-2 {
         padding-right: .5rem !important;
         padding-left: .5rem !important
     }
 
     .px-lg-3 {
         padding-right: 1rem !important;
         padding-left: 1rem !important
     }
 
     .px-lg-4 {
         padding-right: 1.5rem !important;
         padding-left: 1.5rem !important
     }
 
     .px-lg-5 {
         padding-right: 3rem !important;
         padding-left: 3rem !important
     }
 
     .py-lg-0 {
         padding-top: 0 !important;
         padding-bottom: 0 !important
     }
 
     .py-lg-1 {
         padding-top: .25rem !important;
         padding-bottom: .25rem !important
     }
 
     .py-lg-2 {
         padding-top: .5rem !important;
         padding-bottom: .5rem !important
     }
 
     .py-lg-3 {
         padding-top: 1rem !important;
         padding-bottom: 1rem !important
     }
 
     .py-lg-4 {
         padding-top: 1.5rem !important;
         padding-bottom: 1.5rem !important
     }
 
     .py-lg-5 {
         padding-top: 3rem !important;
         padding-bottom: 3rem !important
     }
 
     .pt-lg-0 {
         padding-top: 0 !important
     }
 
     .pt-lg-1 {
         padding-top: .25rem !important
     }
 
     .pt-lg-2 {
         padding-top: .5rem !important
     }
 
     .pt-lg-3 {
         padding-top: 1rem !important
     }
 
     .pt-lg-4 {
         padding-top: 1.5rem !important
     }
 
     .pt-lg-5 {
         padding-top: 3rem !important
     }
 
     .pe-lg-0 {
         padding-right: 0 !important
     }
 
     .pe-lg-1 {
         padding-right: .25rem !important
     }
 
     .pe-lg-2 {
         padding-right: .5rem !important
     }
 
     .pe-lg-3 {
         padding-right: 1rem !important
     }
 
     .pe-lg-4 {
         padding-right: 1.5rem !important
     }
 
     .pe-lg-5 {
         padding-right: 3rem !important
     }
 
     .pb-lg-0 {
         padding-bottom: 0 !important
     }
 
     .pb-lg-1 {
         padding-bottom: .25rem !important
     }
 
     .pb-lg-2 {
         padding-bottom: .5rem !important
     }
 
     .pb-lg-3 {
         padding-bottom: 1rem !important
     }
 
     .pb-lg-4 {
         padding-bottom: 1.5rem !important
     }
 
     .pb-lg-5 {
         padding-bottom: 3rem !important
     }
 
     .ps-lg-0 {
         padding-left: 0 !important
     }
 
     .ps-lg-1 {
         padding-left: .25rem !important
     }
 
     .ps-lg-2 {
         padding-left: .5rem !important
     }
 
     .ps-lg-3 {
         padding-left: 1rem !important
     }
 
     .ps-lg-4 {
         padding-left: 1.5rem !important
     }
 
     .ps-lg-5 {
         padding-left: 3rem !important
     }
 
     .text-lg-start {
         text-align: left !important
     }
 
     .text-lg-end {
         text-align: right !important
     }
 
     .text-lg-center {
         text-align: center !important
     }
 }
 
 @media (min-width: 1200px) {
     .float-xl-start {
         float: left !important
     }
 
     .float-xl-end {
         float: right !important
     }
 
     .float-xl-none {
         float: none !important
     }
 
     .d-xl-inline {
         display: inline !important
     }
 
     .d-xl-inline-block {
         display: inline-block !important
     }
 
     .d-xl-block {
         display: block !important
     }
 
     .d-xl-grid {
         display: grid !important
     }
 
     .d-xl-table {
         display: table !important
     }
 
     .d-xl-table-row {
         display: table-row !important
     }
 
     .d-xl-table-cell {
         display: table-cell !important
     }
 
     .d-xl-flex {
         display: flex !important
     }
 
     .d-xl-inline-flex {
         display: inline-flex !important
     }
 
     .d-xl-none {
         display: none !important
     }
 
     .flex-xl-fill {
         flex: 1 1 auto !important
     }
 
     .flex-xl-row {
         flex-direction: row !important
     }
 
     .flex-xl-column {
         flex-direction: column !important
     }
 
     .flex-xl-row-reverse {
         flex-direction: row-reverse !important
     }
 
     .flex-xl-column-reverse {
         flex-direction: column-reverse !important
     }
 
     .flex-xl-grow-0 {
         flex-grow: 0 !important
     }
 
     .flex-xl-grow-1 {
         flex-grow: 1 !important
     }
 
     .flex-xl-shrink-0 {
         flex-shrink: 0 !important
     }
 
     .flex-xl-shrink-1 {
         flex-shrink: 1 !important
     }
 
     .flex-xl-wrap {
         flex-wrap: wrap !important
     }
 
     .flex-xl-nowrap {
         flex-wrap: nowrap !important
     }
 
     .flex-xl-wrap-reverse {
         flex-wrap: wrap-reverse !important
     }
 
     .gap-xl-0 {
         gap: 0 !important
     }
 
     .gap-xl-1 {
         gap: .25rem !important
     }
 
     .gap-xl-2 {
         gap: .5rem !important
     }
 
     .gap-xl-3 {
         gap: 1rem !important
     }
 
     .gap-xl-4 {
         gap: 1.5rem !important
     }
 
     .gap-xl-5 {
         gap: 3rem !important
     }
 
     .justify-content-xl-start {
         justify-content: flex-start !important
     }
 
     .justify-content-xl-end {
         justify-content: flex-end !important
     }
 
     .justify-content-xl-center {
         justify-content: center !important
     }
 
     .justify-content-xl-between {
         justify-content: space-between !important
     }
 
     .justify-content-xl-around {
         justify-content: space-around !important
     }
 
     .justify-content-xl-evenly {
         justify-content: space-evenly !important
     }
 
     .align-items-xl-start {
         align-items: flex-start !important
     }
 
     .align-items-xl-end {
         align-items: flex-end !important
     }
 
     .align-items-xl-center {
         align-items: center !important
     }
 
     .align-items-xl-baseline {
         align-items: baseline !important
     }
 
     .align-items-xl-stretch {
         align-items: stretch !important
     }
 
     .align-content-xl-start {
         align-content: flex-start !important
     }
 
     .align-content-xl-end {
         align-content: flex-end !important
     }
 
     .align-content-xl-center {
         align-content: center !important
     }
 
     .align-content-xl-between {
         align-content: space-between !important
     }
 
     .align-content-xl-around {
         align-content: space-around !important
     }
 
     .align-content-xl-stretch {
         align-content: stretch !important
     }
 
     .align-self-xl-auto {
         align-self: auto !important
     }
 
     .align-self-xl-start {
         align-self: flex-start !important
     }
 
     .align-self-xl-end {
         align-self: flex-end !important
     }
 
     .align-self-xl-center {
         align-self: center !important
     }
 
     .align-self-xl-baseline {
         align-self: baseline !important
     }
 
     .align-self-xl-stretch {
         align-self: stretch !important
     }
 
     .order-xl-first {
         order: -1 !important
     }
 
     .order-xl-0 {
         order: 0 !important
     }
 
     .order-xl-1 {
         order: 1 !important
     }
 
     .order-xl-2 {
         order: 2 !important
     }
 
     .order-xl-3 {
         order: 3 !important
     }
 
     .order-xl-4 {
         order: 4 !important
     }
 
     .order-xl-5 {
         order: 5 !important
     }
 
     .order-xl-last {
         order: 6 !important
     }
 
     .m-xl-0 {
         margin: 0 !important
     }
 
     .m-xl-1 {
         margin: .25rem !important
     }
 
     .m-xl-2 {
         margin: .5rem !important
     }
 
     .m-xl-3 {
         margin: 1rem !important
     }
 
     .m-xl-4 {
         margin: 1.5rem !important
     }
 
     .m-xl-5 {
         margin: 3rem !important
     }
 
     .m-xl-auto {
         margin: auto !important
     }
 
     .mx-xl-0 {
         margin-right: 0 !important;
         margin-left: 0 !important
     }
 
     .mx-xl-1 {
         margin-right: .25rem !important;
         margin-left: .25rem !important
     }
 
     .mx-xl-2 {
         margin-right: .5rem !important;
         margin-left: .5rem !important
     }
 
     .mx-xl-3 {
         margin-right: 1rem !important;
         margin-left: 1rem !important
     }
 
     .mx-xl-4 {
         margin-right: 1.5rem !important;
         margin-left: 1.5rem !important
     }
 
     .mx-xl-5 {
         margin-right: 3rem !important;
         margin-left: 3rem !important
     }
 
     .mx-xl-auto {
         margin-right: auto !important;
         margin-left: auto !important
     }
 
     .my-xl-0 {
         margin-top: 0 !important;
         margin-bottom: 0 !important
     }
 
     .my-xl-1 {
         margin-top: .25rem !important;
         margin-bottom: .25rem !important
     }
 
     .my-xl-2 {
         margin-top: .5rem !important;
         margin-bottom: .5rem !important
     }
 
     .my-xl-3 {
         margin-top: 1rem !important;
         margin-bottom: 1rem !important
     }
 
     .my-xl-4 {
         margin-top: 1.5rem !important;
         margin-bottom: 1.5rem !important
     }
 
     .my-xl-5 {
         margin-top: 3rem !important;
         margin-bottom: 3rem !important
     }
 
     .my-xl-auto {
         margin-top: auto !important;
         margin-bottom: auto !important
     }
 
     .mt-xl-0 {
         margin-top: 0 !important
     }
 
     .mt-xl-1 {
         margin-top: .25rem !important
     }
 
     .mt-xl-2 {
         margin-top: .5rem !important
     }
 
     .mt-xl-3 {
         margin-top: 1rem !important
     }
 
     .mt-xl-4 {
         margin-top: 1.5rem !important
     }
 
     .mt-xl-5 {
         margin-top: 3rem !important
     }
 
     .mt-xl-auto {
         margin-top: auto !important
     }
 
     .me-xl-0 {
         margin-right: 0 !important
     }
 
     .me-xl-1 {
         margin-right: .25rem !important
     }
 
     .me-xl-2 {
         margin-right: .5rem !important
     }
 
     .me-xl-3 {
         margin-right: 1rem !important
     }
 
     .me-xl-4 {
         margin-right: 1.5rem !important
     }
 
     .me-xl-5 {
         margin-right: 3rem !important
     }
 
     .me-xl-auto {
         margin-right: auto !important
     }
 
     .mb-xl-0 {
         margin-bottom: 0 !important
     }
 
     .mb-xl-1 {
         margin-bottom: .25rem !important
     }
 
     .mb-xl-2 {
         margin-bottom: .5rem !important
     }
 
     .mb-xl-3 {
         margin-bottom: 1rem !important
     }
 
     .mb-xl-4 {
         margin-bottom: 1.5rem !important
     }
 
     .mb-xl-5 {
         margin-bottom: 3rem !important
     }
 
     .mb-xl-auto {
         margin-bottom: auto !important
     }
 
     .ms-xl-0 {
         margin-left: 0 !important
     }
 
     .ms-xl-1 {
         margin-left: .25rem !important
     }
 
     .ms-xl-2 {
         margin-left: .5rem !important
     }
 
     .ms-xl-3 {
         margin-left: 1rem !important
     }
 
     .ms-xl-4 {
         margin-left: 1.5rem !important
     }
 
     .ms-xl-5 {
         margin-left: 3rem !important
     }
 
     .ms-xl-auto {
         margin-left: auto !important
     }
 
     .p-xl-0 {
         padding: 0 !important
     }
 
     .p-xl-1 {
         padding: .25rem !important
     }
 
     .p-xl-2 {
         padding: .5rem !important
     }
 
     .p-xl-3 {
         padding: 1rem !important
     }
 
     .p-xl-4 {
         padding: 1.5rem !important
     }
 
     .p-xl-5 {
         padding: 3rem !important
     }
 
     .px-xl-0 {
         padding-right: 0 !important;
         padding-left: 0 !important
     }
 
     .px-xl-1 {
         padding-right: .25rem !important;
         padding-left: .25rem !important
     }
 
     .px-xl-2 {
         padding-right: .5rem !important;
         padding-left: .5rem !important
     }
 
     .px-xl-3 {
         padding-right: 1rem !important;
         padding-left: 1rem !important
     }
 
     .px-xl-4 {
         padding-right: 1.5rem !important;
         padding-left: 1.5rem !important
     }
 
     .px-xl-5 {
         padding-right: 3rem !important;
         padding-left: 3rem !important
     }
 
     .py-xl-0 {
         padding-top: 0 !important;
         padding-bottom: 0 !important
     }
 
     .py-xl-1 {
         padding-top: .25rem !important;
         padding-bottom: .25rem !important
     }
 
     .py-xl-2 {
         padding-top: .5rem !important;
         padding-bottom: .5rem !important
     }
 
     .py-xl-3 {
         padding-top: 1rem !important;
         padding-bottom: 1rem !important
     }
 
     .py-xl-4 {
         padding-top: 1.5rem !important;
         padding-bottom: 1.5rem !important
     }
 
     .py-xl-5 {
         padding-top: 3rem !important;
         padding-bottom: 3rem !important
     }
 
     .pt-xl-0 {
         padding-top: 0 !important
     }
 
     .pt-xl-1 {
         padding-top: .25rem !important
     }
 
     .pt-xl-2 {
         padding-top: .5rem !important
     }
 
     .pt-xl-3 {
         padding-top: 1rem !important
     }
 
     .pt-xl-4 {
         padding-top: 1.5rem !important
     }
 
     .pt-xl-5 {
         padding-top: 3rem !important
     }
 
     .pe-xl-0 {
         padding-right: 0 !important
     }
 
     .pe-xl-1 {
         padding-right: .25rem !important
     }
 
     .pe-xl-2 {
         padding-right: .5rem !important
     }
 
     .pe-xl-3 {
         padding-right: 1rem !important
     }
 
     .pe-xl-4 {
         padding-right: 1.5rem !important
     }
 
     .pe-xl-5 {
         padding-right: 3rem !important
     }
 
     .pb-xl-0 {
         padding-bottom: 0 !important
     }
 
     .pb-xl-1 {
         padding-bottom: .25rem !important
     }
 
     .pb-xl-2 {
         padding-bottom: .5rem !important
     }
 
     .pb-xl-3 {
         padding-bottom: 1rem !important
     }
 
     .pb-xl-4 {
         padding-bottom: 1.5rem !important
     }
 
     .pb-xl-5 {
         padding-bottom: 3rem !important
     }
 
     .ps-xl-0 {
         padding-left: 0 !important
     }
 
     .ps-xl-1 {
         padding-left: .25rem !important
     }
 
     .ps-xl-2 {
         padding-left: .5rem !important
     }
 
     .ps-xl-3 {
         padding-left: 1rem !important
     }
 
     .ps-xl-4 {
         padding-left: 1.5rem !important
     }
 
     .ps-xl-5 {
         padding-left: 3rem !important
     }
 
     .text-xl-start {
         text-align: left !important
     }
 
     .text-xl-end {
         text-align: right !important
     }
 
     .text-xl-center {
         text-align: center !important
     }
 }
 
 @media (min-width: 1400px) {
     .float-xxl-start {
         float: left !important
     }
 
     .float-xxl-end {
         float: right !important
     }
 
     .float-xxl-none {
         float: none !important
     }
 
     .d-xxl-inline {
         display: inline !important
     }
 
     .d-xxl-inline-block {
         display: inline-block !important
     }
 
     .d-xxl-block {
         display: block !important
     }
 
     .d-xxl-grid {
         display: grid !important
     }
 
     .d-xxl-table {
         display: table !important
     }
 
     .d-xxl-table-row {
         display: table-row !important
     }
 
     .d-xxl-table-cell {
         display: table-cell !important
     }
 
     .d-xxl-flex {
         display: flex !important
     }
 
     .d-xxl-inline-flex {
         display: inline-flex !important
     }
 
     .d-xxl-none {
         display: none !important
     }
 
     .flex-xxl-fill {
         flex: 1 1 auto !important
     }
 
     .flex-xxl-row {
         flex-direction: row !important
     }
 
     .flex-xxl-column {
         flex-direction: column !important
     }
 
     .flex-xxl-row-reverse {
         flex-direction: row-reverse !important
     }
 
     .flex-xxl-column-reverse {
         flex-direction: column-reverse !important
     }
 
     .flex-xxl-grow-0 {
         flex-grow: 0 !important
     }
 
     .flex-xxl-grow-1 {
         flex-grow: 1 !important
     }
 
     .flex-xxl-shrink-0 {
         flex-shrink: 0 !important
     }
 
     .flex-xxl-shrink-1 {
         flex-shrink: 1 !important
     }
 
     .flex-xxl-wrap {
         flex-wrap: wrap !important
     }
 
     .flex-xxl-nowrap {
         flex-wrap: nowrap !important
     }
 
     .flex-xxl-wrap-reverse {
         flex-wrap: wrap-reverse !important
     }
 
     .gap-xxl-0 {
         gap: 0 !important
     }
 
     .gap-xxl-1 {
         gap: .25rem !important
     }
 
     .gap-xxl-2 {
         gap: .5rem !important
     }
 
     .gap-xxl-3 {
         gap: 1rem !important
     }
 
     .gap-xxl-4 {
         gap: 1.5rem !important
     }
 
     .gap-xxl-5 {
         gap: 3rem !important
     }
 
     .justify-content-xxl-start {
         justify-content: flex-start !important
     }
 
     .justify-content-xxl-end {
         justify-content: flex-end !important
     }
 
     .justify-content-xxl-center {
         justify-content: center !important
     }
 
     .justify-content-xxl-between {
         justify-content: space-between !important
     }
 
     .justify-content-xxl-around {
         justify-content: space-around !important
     }
 
     .justify-content-xxl-evenly {
         justify-content: space-evenly !important
     }
 
     .align-items-xxl-start {
         align-items: flex-start !important
     }
 
     .align-items-xxl-end {
         align-items: flex-end !important
     }
 
     .align-items-xxl-center {
         align-items: center !important
     }
 
     .align-items-xxl-baseline {
         align-items: baseline !important
     }
 
     .align-items-xxl-stretch {
         align-items: stretch !important
     }
 
     .align-content-xxl-start {
         align-content: flex-start !important
     }
 
     .align-content-xxl-end {
         align-content: flex-end !important
     }
 
     .align-content-xxl-center {
         align-content: center !important
     }
 
     .align-content-xxl-between {
         align-content: space-between !important
     }
 
     .align-content-xxl-around {
         align-content: space-around !important
     }
 
     .align-content-xxl-stretch {
         align-content: stretch !important
     }
 
     .align-self-xxl-auto {
         align-self: auto !important
     }
 
     .align-self-xxl-start {
         align-self: flex-start !important
     }
 
     .align-self-xxl-end {
         align-self: flex-end !important
     }
 
     .align-self-xxl-center {
         align-self: center !important
     }
 
     .align-self-xxl-baseline {
         align-self: baseline !important
     }
 
     .align-self-xxl-stretch {
         align-self: stretch !important
     }
 
     .order-xxl-first {
         order: -1 !important
     }
 
     .order-xxl-0 {
         order: 0 !important
     }
 
     .order-xxl-1 {
         order: 1 !important
     }
 
     .order-xxl-2 {
         order: 2 !important
     }
 
     .order-xxl-3 {
         order: 3 !important
     }
 
     .order-xxl-4 {
         order: 4 !important
     }
 
     .order-xxl-5 {
         order: 5 !important
     }
 
     .order-xxl-last {
         order: 6 !important
     }
 
     .m-xxl-0 {
         margin: 0 !important
     }
 
     .m-xxl-1 {
         margin: .25rem !important
     }
 
     .m-xxl-2 {
         margin: .5rem !important
     }
 
     .m-xxl-3 {
         margin: 1rem !important
     }
 
     .m-xxl-4 {
         margin: 1.5rem !important
     }
 
     .m-xxl-5 {
         margin: 3rem !important
     }
 
     .m-xxl-auto {
         margin: auto !important
     }
 
     .mx-xxl-0 {
         margin-right: 0 !important;
         margin-left: 0 !important
     }
 
     .mx-xxl-1 {
         margin-right: .25rem !important;
         margin-left: .25rem !important
     }
 
     .mx-xxl-2 {
         margin-right: .5rem !important;
         margin-left: .5rem !important
     }
 
     .mx-xxl-3 {
         margin-right: 1rem !important;
         margin-left: 1rem !important
     }
 
     .mx-xxl-4 {
         margin-right: 1.5rem !important;
         margin-left: 1.5rem !important
     }
 
     .mx-xxl-5 {
         margin-right: 3rem !important;
         margin-left: 3rem !important
     }
 
     .mx-xxl-auto {
         margin-right: auto !important;
         margin-left: auto !important
     }
 
     .my-xxl-0 {
         margin-top: 0 !important;
         margin-bottom: 0 !important
     }
 
     .my-xxl-1 {
         margin-top: .25rem !important;
         margin-bottom: .25rem !important
     }
 
     .my-xxl-2 {
         margin-top: .5rem !important;
         margin-bottom: .5rem !important
     }
 
     .my-xxl-3 {
         margin-top: 1rem !important;
         margin-bottom: 1rem !important
     }
 
     .my-xxl-4 {
         margin-top: 1.5rem !important;
         margin-bottom: 1.5rem !important
     }
 
     .my-xxl-5 {
         margin-top: 3rem !important;
         margin-bottom: 3rem !important
     }
 
     .my-xxl-auto {
         margin-top: auto !important;
         margin-bottom: auto !important
     }
 
     .mt-xxl-0 {
         margin-top: 0 !important
     }
 
     .mt-xxl-1 {
         margin-top: .25rem !important
     }
 
     .mt-xxl-2 {
         margin-top: .5rem !important
     }
 
     .mt-xxl-3 {
         margin-top: 1rem !important
     }
 
     .mt-xxl-4 {
         margin-top: 1.5rem !important
     }
 
     .mt-xxl-5 {
         margin-top: 3rem !important
     }
 
     .mt-xxl-auto {
         margin-top: auto !important
     }
 
     .me-xxl-0 {
         margin-right: 0 !important
     }
 
     .me-xxl-1 {
         margin-right: .25rem !important
     }
 
     .me-xxl-2 {
         margin-right: .5rem !important
     }
 
     .me-xxl-3 {
         margin-right: 1rem !important
     }
 
     .me-xxl-4 {
         margin-right: 1.5rem !important
     }
 
     .me-xxl-5 {
         margin-right: 3rem !important
     }
 
     .me-xxl-auto {
         margin-right: auto !important
     }
 
     .mb-xxl-0 {
         margin-bottom: 0 !important
     }
 
     .mb-xxl-1 {
         margin-bottom: .25rem !important
     }
 
     .mb-xxl-2 {
         margin-bottom: .5rem !important
     }
 
     .mb-xxl-3 {
         margin-bottom: 1rem !important
     }
 
     .mb-xxl-4 {
         margin-bottom: 1.5rem !important
     }
 
     .mb-xxl-5 {
         margin-bottom: 3rem !important
     }
 
     .mb-xxl-auto {
         margin-bottom: auto !important
     }
 
     .ms-xxl-0 {
         margin-left: 0 !important
     }
 
     .ms-xxl-1 {
         margin-left: .25rem !important
     }
 
     .ms-xxl-2 {
         margin-left: .5rem !important
     }
 
     .ms-xxl-3 {
         margin-left: 1rem !important
     }
 
     .ms-xxl-4 {
         margin-left: 1.5rem !important
     }
 
     .ms-xxl-5 {
         margin-left: 3rem !important
     }
 
     .ms-xxl-auto {
         margin-left: auto !important
     }
 
     .p-xxl-0 {
         padding: 0 !important
     }
 
     .p-xxl-1 {
         padding: .25rem !important
     }
 
     .p-xxl-2 {
         padding: .5rem !important
     }
 
     .p-xxl-3 {
         padding: 1rem !important
     }
 
     .p-xxl-4 {
         padding: 1.5rem !important
     }
 
     .p-xxl-5 {
         padding: 3rem !important
     }
 
     .px-xxl-0 {
         padding-right: 0 !important;
         padding-left: 0 !important
     }
 
     .px-xxl-1 {
         padding-right: .25rem !important;
         padding-left: .25rem !important
     }
 
     .px-xxl-2 {
         padding-right: .5rem !important;
         padding-left: .5rem !important
     }
 
     .px-xxl-3 {
         padding-right: 1rem !important;
         padding-left: 1rem !important
     }
 
     .px-xxl-4 {
         padding-right: 1.5rem !important;
         padding-left: 1.5rem !important
     }
 
     .px-xxl-5 {
         padding-right: 3rem !important;
         padding-left: 3rem !important
     }
 
     .py-xxl-0 {
         padding-top: 0 !important;
         padding-bottom: 0 !important
     }
 
     .py-xxl-1 {
         padding-top: .25rem !important;
         padding-bottom: .25rem !important
     }
 
     .py-xxl-2 {
         padding-top: .5rem !important;
         padding-bottom: .5rem !important
     }
 
     .py-xxl-3 {
         padding-top: 1rem !important;
         padding-bottom: 1rem !important
     }
 
     .py-xxl-4 {
         padding-top: 1.5rem !important;
         padding-bottom: 1.5rem !important
     }
 
     .py-xxl-5 {
         padding-top: 3rem !important;
         padding-bottom: 3rem !important
     }
 
     .pt-xxl-0 {
         padding-top: 0 !important
     }
 
     .pt-xxl-1 {
         padding-top: .25rem !important
     }
 
     .pt-xxl-2 {
         padding-top: .5rem !important
     }
 
     .pt-xxl-3 {
         padding-top: 1rem !important
     }
 
     .pt-xxl-4 {
         padding-top: 1.5rem !important
     }
 
     .pt-xxl-5 {
         padding-top: 3rem !important
     }
 
     .pe-xxl-0 {
         padding-right: 0 !important
     }
 
     .pe-xxl-1 {
         padding-right: .25rem !important
     }
 
     .pe-xxl-2 {
         padding-right: .5rem !important
     }
 
     .pe-xxl-3 {
         padding-right: 1rem !important
     }
 
     .pe-xxl-4 {
         padding-right: 1.5rem !important
     }
 
     .pe-xxl-5 {
         padding-right: 3rem !important
     }
 
     .pb-xxl-0 {
         padding-bottom: 0 !important
     }
 
     .pb-xxl-1 {
         padding-bottom: .25rem !important
     }
 
     .pb-xxl-2 {
         padding-bottom: .5rem !important
     }
 
     .pb-xxl-3 {
         padding-bottom: 1rem !important
     }
 
     .pb-xxl-4 {
         padding-bottom: 1.5rem !important
     }
 
     .pb-xxl-5 {
         padding-bottom: 3rem !important
     }
 
     .ps-xxl-0 {
         padding-left: 0 !important
     }
 
     .ps-xxl-1 {
         padding-left: .25rem !important
     }
 
     .ps-xxl-2 {
         padding-left: .5rem !important
     }
 
     .ps-xxl-3 {
         padding-left: 1rem !important
     }
 
     .ps-xxl-4 {
         padding-left: 1.5rem !important
     }
 
     .ps-xxl-5 {
         padding-left: 3rem !important
     }
 
     .text-xxl-start {
         text-align: left !important
     }
 
     .text-xxl-end {
         text-align: right !important
     }
 
     .text-xxl-center {
         text-align: center !important
     }
 }
 
 @media (min-width: 1200px) {
     .fs-1 {
         font-size: 2.5rem !important
     }
 
     .fs-2 {
         font-size: 2rem !important
     }
 
     .fs-3 {
         font-size: 1.75rem !important
     }
 
     .fs-4 {
         font-size: 1.5rem !important
     }
 }
 
 @media print {
     .d-print-inline {
         display: inline !important
     }
 
     .d-print-inline-block {
         display: inline-block !important
     }
 
     .d-print-block {
         display: block !important
     }
 
     .d-print-grid {
         display: grid !important
     }
 
     .d-print-table {
         display: table !important
     }
 
     .d-print-table-row {
         display: table-row !important
     }
 
     .d-print-table-cell {
         display: table-cell !important
     }
 
     .d-print-flex {
         display: flex !important
     }
 
     .d-print-inline-flex {
         display: inline-flex !important
     }
 
     .d-print-none {
         display: none !important
     }
 }
 
 @font-face {
     font-family: 'simple-Line-Icons';
     src: url("../fonts/simple-Line-Icons.eot");
     src: url("../fonts/simple-Line-Icons.eot") format("embedded-opentype"), url("../fonts/simple-Line-Icons.woff") format("woff"), url("../fonts/simple-Line-Icons.ttf") format("truetype"), url("../fonts/simple-Line-Icons.svg#Simple-Line-Icons") format("svg");
     font-weight: normal;
     font-style: normal
 }
 
 [data-icon]:before {
     font-family: 'simple-Line-Icons';
     content: attr(data-icon);
     speak: none;
     font-weight: normal;
     font-variant: normal;
     text-transform: none;
     line-height: 1;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
     font-size: 17px
 }
 
 .icon-user-female,
 .icon-user-follow,
 .icon-user-following,
 .icon-user-unfollow,
 .icon-trophy,
 .icon-screen-smartphone,
 .icon-screen-desktop,
 .icon-plane,
 .icon-notebook,
 .icon-moustache,
 .icon-mouse,
 .icon-magnet,
 .icon-energy,
 .icon-emoticon-smile,
 .icon-disc,
 .icon-cursor-move,
 .icon-crop,
 .icon-credit-card,
 .icon-chemistry,
 .icon-user,
 .icon-speedometer,
 .icon-social-youtube,
 .icon-social-twitter,
 .icon-social-tumblr,
 .icon-social-facebook,
 .icon-social-dropbox,
 .icon-social-dribbble,
 .icon-shield,
 .icon-screen-tablet,
 .icon-magic-wand,
 .icon-hourglass,
 .icon-graduation,
 .icon-ghost,
 .icon-game-controller,
 .icon-fire,
 .icon-eyeglasses,
 .icon-envelope-open,
 .icon-envelope-letter,
 .icon-bell,
 .icon-badge,
 .icon-anchor,
 .icon-wallet,
 .icon-vector,
 .icon-speech,
 .icon-puzzle,
 .icon-printer,
 .icon-present,
 .icon-playlist,
 .icon-pin,
 .icon-picture,
 .icon-map,
 .icon-layers,
 .icon-handbag,
 .icon-globe-alt,
 .icon-globe,
 .icon-frame,
 .icon-folder-alt,
 .icon-film,
 .icon-feed,
 .icon-earphones-alt,
 .icon-earphones,
 .icon-drop,
 .icon-drawer,
 .icon-docs,
 .icon-directions,
 .icon-direction,
 .icon-diamond,
 .icon-cup,
 .icon-compass,
 .icon-call-out,
 .icon-call-in,
 .icon-call-end,
 .icon-calculator,
 .icon-bubbles,
 .icon-briefcase,
 .icon-book-open,
 .icon-basket-loaded,
 .icon-basket,
 .icon-bag,
 .icon-action-undo,
 .icon-action-redo,
 .icon-wrench,
 .icon-umbrella,
 .icon-trash,
 .icon-tag,
 .icon-support,
 .icon-size-fullscreen,
 .icon-size-actual,
 .icon-shuffle,
 .icon-share-alt,
 .icon-share,
 .icon-rocket,
 .icon-question,
 .icon-pie-chart,
 .icon-pencil,
 .icon-note,
 .icon-music-tone-alt,
 .icon-music-tone,
 .icon-microphone,
 .icon-loop,
 .icon-logout,
 .icon-login,
 .icon-list,
 .icon-like,
 .icon-home,
 .icon-grid,
 .icon-graph,
 .icon-equalizer,
 .icon-dislike,
 .icon-cursor,
 .icon-control-start,
 .icon-control-rewind,
 .icon-control-play,
 .icon-control-pause,
 .icon-control-forward,
 .icon-control-end,
 .icon-calendar,
 .icon-bulb,
 .icon-bar-chart,
 .icon-arrow-up,
 .icon-arrow-right,
 .icon-arrow-left,
 .icon-arrow-down,
 .icon-ban,
 .icon-bubble,
 .icon-camcorder,
 .icon-camera,
 .icon-check,
 .icon-clock,
 .icon-close,
 .icon-cloud-download,
 .icon-cloud-upload,
 .icon-doc,
 .icon-envelope,
 .icon-eye,
 .icon-flag,
 .icon-folder,
 .icon-heart,
 .icon-info,
 .icon-key,
 .icon-link,
 .icon-lock,
 .icon-lock-open,
 .icon-magnifier,
 .icon-magnifier-add,
 .icon-magnifier-remove,
 .icon-paper-clip,
 .icon-paper-plane,
 .icon-plus,
 .icon-pointer,
 .icon-power,
 .icon-refresh,
 .icon-reload,
 .icon-settings,
 .icon-star,
 .icon-symbol-female,
 .icon-symbol-male,
 .icon-target,
 .icon-volume-1,
 .icon-volume-2,
 .icon-volume-off,
 .icon-users {
     font-family: 'Simple-Line-Icons';
     speak: none;
     font-style: normal;
     font-weight: normal;
     font-variant: normal;
     text-transform: none;
     line-height: 1;
     -webkit-font-smoothing: antialiased
 }
 
 .icon-user-female:before {
     content: "\e000"
 }
 
 .icon-user-follow:before {
     content: "\e002"
 }
 
 .icon-user-following:before {
     content: "\e003"
 }
 
 .icon-user-unfollow:before {
     content: "\e004"
 }
 
 .icon-trophy:before {
     content: "\e006"
 }
 
 .icon-screen-smartphone:before {
     content: "\e010"
 }
 
 .icon-screen-desktop:before {
     content: "\e011"
 }
 
 .icon-plane:before {
     content: "\e012"
 }
 
 .icon-notebook:before {
     content: "\e013"
 }
 
 .icon-moustache:before {
     content: "\e014"
 }
 
 .icon-mouse:before {
     content: "\e015"
 }
 
 .icon-magnet:before {
     content: "\e016"
 }
 
 .icon-energy:before {
     content: "\e020"
 }
 
 .icon-emoticon-smile:before {
     content: "\e021"
 }
 
 .icon-disc:before {
     content: "\e022"
 }
 
 .icon-cursor-move:before {
     content: "\e023"
 }
 
 .icon-crop:before {
     content: "\e024"
 }
 
 .icon-credit-card:before {
     content: "\e025"
 }
 
 .icon-chemistry:before {
     content: "\e026"
 }
 
 .icon-user:before {
     content: "\e005"
 }
 
 .icon-speedometer:before {
     content: "\e007"
 }
 
 .icon-social-youtube:before {
     content: "\e008"
 }
 
 .icon-social-twitter:before {
     content: "\e009"
 }
 
 .icon-social-tumblr:before {
     content: "\e00a"
 }
 
 .icon-social-facebook:before {
     content: "\e00b"
 }
 
 .icon-social-dropbox:before {
     content: "\e00c"
 }
 
 .icon-social-dribbble:before {
     content: "\e00d"
 }
 
 .icon-shield:before {
     content: "\e00e"
 }
 
 .icon-screen-tablet:before {
     content: "\e00f"
 }
 
 .icon-magic-wand:before {
     content: "\e017"
 }
 
 .icon-hourglass:before {
     content: "\e018"
 }
 
 .icon-graduation:before {
     content: "\e019"
 }
 
 .icon-ghost:before {
     content: "\e01a"
 }
 
 .icon-game-controller:before {
     content: "\e01b"
 }
 
 .icon-fire:before {
     content: "\e01c"
 }
 
 .icon-eyeglasses:before {
     content: "\e01d"
 }
 
 .icon-envelope-open:before {
     content: "\e01e"
 }
 
 .icon-envelope-letter:before {
     content: "\e01f"
 }
 
 .icon-bell:before {
     content: "\e027"
 }
 
 .icon-badge:before {
     content: "\e028"
 }
 
 .icon-anchor:before {
     content: "\e029"
 }
 
 .icon-wallet:before {
     content: "\e02a"
 }
 
 .icon-vector:before {
     content: "\e02b"
 }
 
 .icon-speech:before {
     content: "\e02c"
 }
 
 .icon-puzzle:before {
     content: "\e02d"
 }
 
 .icon-printer:before {
     content: "\e02e"
 }
 
 .icon-present:before {
     content: "\e02f"
 }
 
 .icon-playlist:before {
     content: "\e030"
 }
 
 .icon-pin:before {
     content: "\e031"
 }
 
 .icon-picture:before {
     content: "\e032"
 }
 
 .icon-map:before {
     content: "\e033"
 }
 
 .icon-layers:before {
     content: "\e034"
 }
 
 .icon-handbag:before {
     content: "\e035"
 }
 
 .icon-globe-alt:before {
     content: "\e036"
 }
 
 .icon-globe:before {
     content: "\e037"
 }
 
 .icon-frame:before {
     content: "\e038"
 }
 
 .icon-folder-alt:before {
     content: "\e039"
 }
 
 .icon-film:before {
     content: "\e03a"
 }
 
 .icon-feed:before {
     content: "\e03b"
 }
 
 .icon-earphones-alt:before {
     content: "\e03c"
 }
 
 .icon-earphones:before {
     content: "\e03d"
 }
 
 .icon-drop:before {
     content: "\e03e"
 }
 
 .icon-drawer:before {
     content: "\e03f"
 }
 
 .icon-docs:before {
     content: "\e040"
 }
 
 .icon-directions:before {
     content: "\e041"
 }
 
 .icon-direction:before {
     content: "\e042"
 }
 
 .icon-diamond:before {
     content: "\e043"
 }
 
 .icon-cup:before {
     content: "\e044"
 }
 
 .icon-compass:before {
     content: "\e045"
 }
 
 .icon-call-out:before {
     content: "\e046"
 }
 
 .icon-call-in:before {
     content: "\e047"
 }
 
 .icon-call-end:before {
     content: "\e048"
 }
 
 .icon-calculator:before {
     content: "\e049"
 }
 
 .icon-bubbles:before {
     content: "\e04a"
 }
 
 .icon-briefcase:before {
     content: "\e04b"
 }
 
 .icon-book-open:before {
     content: "\e04c"
 }
 
 .icon-basket-loaded:before {
     content: "\e04d"
 }
 
 .icon-basket:before {
     content: "\e04e"
 }
 
 .icon-bag:before {
     content: "\e04f"
 }
 
 .icon-action-undo:before {
     content: "\e050"
 }
 
 .icon-action-redo:before {
     content: "\e051"
 }
 
 .icon-wrench:before {
     content: "\e052"
 }
 
 .icon-umbrella:before {
     content: "\e053"
 }
 
 .icon-trash:before {
     content: "\e054"
 }
 
 .icon-tag:before {
     content: "\e055"
 }
 
 .icon-support:before {
     content: "\e056"
 }
 
 .icon-size-fullscreen:before {
     content: "\e057"
 }
 
 .icon-size-actual:before {
     content: "\e058"
 }
 
 .icon-shuffle:before {
     content: "\e059"
 }
 
 .icon-share-alt:before {
     content: "\e05a"
 }
 
 .icon-share:before {
     content: "\e05b"
 }
 
 .icon-rocket:before {
     content: "\e05c"
 }
 
 .icon-question:before {
     content: "\e05d"
 }
 
 .icon-pie-chart:before {
     content: "\e05e"
 }
 
 .icon-pencil:before {
     content: "\e05f"
 }
 
 .icon-note:before {
     content: "\e060"
 }
 
 .icon-music-tone-alt:before {
     content: "\e061"
 }
 
 .icon-music-tone:before {
     content: "\e062"
 }
 
 .icon-microphone:before {
     content: "\e063"
 }
 
 .icon-loop:before {
     content: "\e064"
 }
 
 .icon-logout:before {
     content: "\e065"
 }
 
 .icon-login:before {
     content: "\e066"
 }
 
 .icon-list:before {
     content: "\e067"
 }
 
 .icon-like:before {
     content: "\e068"
 }
 
 .icon-home:before {
     content: "\e069"
 }
 
 .icon-grid:before {
     content: "\e06a"
 }
 
 .icon-graph:before {
     content: "\e06b"
 }
 
 .icon-equalizer:before {
     content: "\e06c"
 }
 
 .icon-dislike:before {
     content: "\e06d"
 }
 
 .icon-cursor:before {
     content: "\e06e"
 }
 
 .icon-control-start:before {
     content: "\e06f"
 }
 
 .icon-control-rewind:before {
     content: "\e070"
 }
 
 .icon-control-play:before {
     content: "\e071"
 }
 
 .icon-control-pause:before {
     content: "\e072"
 }
 
 .icon-control-forward:before {
     content: "\e073"
 }
 
 .icon-control-end:before {
     content: "\e074"
 }
 
 .icon-calendar:before {
     content: "\e075"
 }
 
 .icon-bulb:before {
     content: "\e076"
 }
 
 .icon-bar-chart:before {
     content: "\e077"
 }
 
 .icon-arrow-up:before {
     content: "\e078"
 }
 
 .icon-arrow-right:before {
     content: "\e079"
 }
 
 .icon-arrow-left:before {
     content: "\e07a"
 }
 
 .icon-arrow-down:before {
     content: "\e07b"
 }
 
 .icon-ban:before {
     content: "\e07c"
 }
 
 .icon-bubble:before {
     content: "\e07d"
 }
 
 .icon-camcorder:before {
     content: "\e07e"
 }
 
 .icon-camera:before {
     content: "\e07f"
 }
 
 .icon-check:before {
     content: "\e080"
 }
 
 .icon-clock:before {
     content: "\e081"
 }
 
 .icon-close:before {
     content: "\e082"
 }
 
 .icon-cloud-download:before {
     content: "\e083"
 }
 
 .icon-cloud-upload:before {
     content: "\e084"
 }
 
 .icon-doc:before {
     content: "\e085"
 }
 
 .icon-envelope:before {
     content: "\e086"
 }
 
 .icon-eye:before {
     content: "\e087"
 }
 
 .icon-flag:before {
     content: "\e088"
 }
 
 .icon-folder:before {
     content: "\e089"
 }
 
 .icon-heart:before {
     content: "\e08a"
 }
 
 .icon-info:before {
     content: "\e08b"
 }
 
 .icon-key:before {
     content: "\e08c"
 }
 
 .icon-link:before {
     content: "\e08d"
 }
 
 .icon-lock:before {
     content: "\e08e"
 }
 
 .icon-lock-open:before {
     content: "\e08f"
 }
 
 .icon-magnifier:before {
     content: "\e090"
 }
 
 .icon-magnifier-add:before {
     content: "\e091"
 }
 
 .icon-magnifier-remove:before {
     content: "\e092"
 }
 
 .icon-paper-clip:before {
     content: "\e093"
 }
 
 .icon-paper-plane:before {
     content: "\e094"
 }
 
 .icon-plus:before {
     content: "\e095"
 }
 
 .icon-pointer:before {
     content: "\e096"
 }
 
 .icon-power:before {
     content: "\e097"
 }
 
 .icon-refresh:before {
     content: "\e098"
 }
 
 .icon-reload:before {
     content: "\e099"
 }
 
 .icon-settings:before {
     content: "\e09a"
 }
 
 .icon-star:before {
     content: "\e09b"
 }
 
 .icon-symbol-female:before {
     content: "\e09c"
 }
 
 .icon-symbol-male:before {
     content: "\e09d"
 }
 
 .icon-target:before {
     content: "\e09e"
 }
 
 .icon-volume-1:before {
     content: "\e09f"
 }
 
 .icon-volume-2:before {
     content: "\e0a0"
 }
 
 .icon-volume-off:before {
     content: "\e0a1"
 }
 
 .icon-users:before {
     content: "\e001"
 }
 
 @font-face {
     font-family: 'weathericons';
     src: url("../fonts/weathericons-regular-webfont.eot");
     src: url("../fonts/weathericons-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/weathericons-regular-webfont.woff") format("woff"), url("../fonts/weathericons-regular-webfont.ttf") format("truetype"), url("../fonts/weathericons-regular-webfont.svg#weathericons-regular-webfontRg") format("svg");
     font-weight: normal;
     font-style: normal
 }
 
 .wi {
     display: inline-block;
     font-family: 'weathericons';
     font-style: normal;
     font-weight: normal;
     line-height: 1;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale
 }
 
 .wi-day-cloudy-gusts:before {
     content: "\f000"
 }
 
 .wi-day-cloudy-windy:before {
     content: "\f001"
 }
 
 .wi-day-cloudy:before {
     content: "\f002"
 }
 
 .wi-day-fog:before {
     content: "\f003"
 }
 
 .wi-day-hail:before {
     content: "\f004"
 }
 
 .wi-day-lightning:before {
     content: "\f005"
 }
 
 .wi-day-rain-mix:before {
     content: "\f006"
 }
 
 .wi-day-rain-wind:before {
     content: "\f007"
 }
 
 .wi-day-rain:before {
     content: "\f008"
 }
 
 .wi-day-showers:before {
     content: "\f009"
 }
 
 .wi-day-snow:before {
     content: "\f00a"
 }
 
 .wi-day-sprinkle:before {
     content: "\f00b"
 }
 
 .wi-day-sunny-overcast:before {
     content: "\f00c"
 }
 
 .wi-day-sunny:before {
     content: "\f00d"
 }
 
 .wi-day-storm-showers:before {
     content: "\f00e"
 }
 
 .wi-day-thunderstorm:before {
     content: "\f010"
 }
 
 .wi-cloudy-gusts:before {
     content: "\f011"
 }
 
 .wi-cloudy-windy:before {
     content: "\f012"
 }
 
 .wi-cloudy:before {
     content: "\f013"
 }
 
 .wi-fog:before {
     content: "\f014"
 }
 
 .wi-hail:before {
     content: "\f015"
 }
 
 .wi-lightning:before {
     content: "\f016"
 }
 
 .wi-rain-mix:before {
     content: "\f017"
 }
 
 .wi-rain-wind:before {
     content: "\f018"
 }
 
 .wi-rain:before {
     content: "\f019"
 }
 
 .wi-showers:before {
     content: "\f01a"
 }
 
 .wi-snow:before {
     content: "\f01b"
 }
 
 .wi-sprinkle:before {
     content: "\f01c"
 }
 
 .wi-storm-showers:before {
     content: "\f01d"
 }
 
 .wi-thunderstorm:before {
     content: "\f01e"
 }
 
 .wi-windy:before {
     content: "\f021"
 }
 
 .wi-night-alt-cloudy-gusts:before {
     content: "\f022"
 }
 
 .wi-night-alt-cloudy-windy:before {
     content: "\f023"
 }
 
 .wi-night-alt-hail:before {
     content: "\f024"
 }
 
 .wi-night-alt-lightning:before {
     content: "\f025"
 }
 
 .wi-night-alt-rain-mix:before {
     content: "\f026"
 }
 
 .wi-night-alt-rain-wind:before {
     content: "\f027"
 }
 
 .wi-night-alt-rain:before {
     content: "\f028"
 }
 
 .wi-night-alt-showers:before {
     content: "\f029"
 }
 
 .wi-night-alt-snow:before {
     content: "\f02a"
 }
 
 .wi-night-alt-sprinkle:before {
     content: "\f02b"
 }
 
 .wi-night-alt-storm-showers:before {
     content: "\f02c"
 }
 
 .wi-night-alt-thunderstorm:before {
     content: "\f02d"
 }
 
 .wi-night-clear:before {
     content: "\f02e"
 }
 
 .wi-night-cloudy-gusts:before {
     content: "\f02f"
 }
 
 .wi-night-cloudy-windy:before {
     content: "\f030"
 }
 
 .wi-night-cloudy:before {
     content: "\f031"
 }
 
 .wi-night-hail:before {
     content: "\f032"
 }
 
 .wi-night-lightning:before {
     content: "\f033"
 }
 
 .wi-night-rain-mix:before {
     content: "\f034"
 }
 
 .wi-night-rain-wind:before {
     content: "\f035"
 }
 
 .wi-night-rain:before {
     content: "\f036"
 }
 
 .wi-night-showers:before {
     content: "\f037"
 }
 
 .wi-night-snow:before {
     content: "\f038"
 }
 
 .wi-night-sprinkle:before {
     content: "\f039"
 }
 
 .wi-night-storm-showers:before {
     content: "\f03a"
 }
 
 .wi-night-thunderstorm:before {
     content: "\f03b"
 }
 
 .wi-celsius:before {
     content: "\f03c"
 }
 
 .wi-cloud-down:before {
     content: "\f03d"
 }
 
 .wi-cloud-refresh:before {
     content: "\f03e"
 }
 
 .wi-cloud-up:before {
     content: "\f040"
 }
 
 .wi-cloud:before {
     content: "\f041"
 }
 
 .wi-degrees:before {
     content: "\f042"
 }
 
 .wi-down-left:before {
     content: "\f043"
 }
 
 .wi-down:before {
     content: "\f044"
 }
 
 .wi-fahrenheit:before {
     content: "\f045"
 }
 
 .wi-horizon-alt:before {
     content: "\f046"
 }
 
 .wi-horizon:before {
     content: "\f047"
 }
 
 .wi-left:before {
     content: "\f048"
 }
 
 .wi-lightning:before {
     content: "\f016"
 }
 
 .wi-night-fog:before {
     content: "\f04a"
 }
 
 .wi-refresh-alt:before {
     content: "\f04b"
 }
 
 .wi-refresh:before {
     content: "\f04c"
 }
 
 .wi-right:before {
     content: "\f04d"
 }
 
 .wi-sprinkles:before {
     content: "\f04e"
 }
 
 .wi-strong-wind:before {
     content: "\f050"
 }
 
 .wi-sunrise:before {
     content: "\f051"
 }
 
 .wi-sunset:before {
     content: "\f052"
 }
 
 .wi-thermometer-exterior:before {
     content: "\f053"
 }
 
 .wi-thermometer-internal:before {
     content: "\f054"
 }
 
 .wi-thermometer:before {
     content: "\f055"
 }
 
 .wi-tornado:before {
     content: "\f056"
 }
 
 .wi-up-right:before {
     content: "\f057"
 }
 
 .wi-up:before {
     content: "\f058"
 }
 
 .wi-wind-west:before {
     content: "\f059"
 }
 
 .wi-wind-south-west:before {
     content: "\f05a"
 }
 
 .wi-wind-south-east:before {
     content: "\f05b"
 }
 
 .wi-wind-south:before {
     content: "\f05c"
 }
 
 .wi-wind-north-west:before {
     content: "\f05d"
 }
 
 .wi-wind-north-east:before {
     content: "\f05e"
 }
 
 .wi-wind-north:before {
     content: "\f060"
 }
 
 .wi-wind-east:before {
     content: "\f061"
 }
 
 .wi-smoke:before {
     content: "\f062"
 }
 
 .wi-dust:before {
     content: "\f063"
 }
 
 .wi-snow-wind:before {
     content: "\f064"
 }
 
 .wi-day-snow-wind:before {
     content: "\f065"
 }
 
 .wi-night-snow-wind:before {
     content: "\f066"
 }
 
 .wi-night-alt-snow-wind:before {
     content: "\f067"
 }
 
 .wi-day-sleet-storm:before {
     content: "\f068"
 }
 
 .wi-night-sleet-storm:before {
     content: "\f069"
 }
 
 .wi-night-alt-sleet-storm:before {
     content: "\f06a"
 }
 
 .wi-day-snow-thunderstorm:before {
     content: "\f06b"
 }
 
 .wi-night-snow-thunderstorm:before {
     content: "\f06c"
 }
 
 .wi-night-alt-snow-thunderstorm:before {
     content: "\f06d"
 }
 
 .wi-solar-eclipse:before {
     content: "\f06e"
 }
 
 .wi-lunar-eclipse:before {
     content: "\f070"
 }
 
 .wi-meteor:before {
     content: "\f071"
 }
 
 .wi-hot:before {
     content: "\f072"
 }
 
 .wi-hurricane:before {
     content: "\f073"
 }
 
 .wi-smog:before {
     content: "\f074"
 }
 
 .wi-alien:before {
     content: "\f075"
 }
 
 .wi-snowflake-cold:before {
     content: "\f076"
 }
 
 .wi-stars:before {
     content: "\f077"
 }
 
 .wi-night-partly-cloudy:before {
     content: "\f083"
 }
 
 .wi-umbrella:before {
     content: "\f084"
 }
 
 .wi-day-windy:before {
     content: "\f085"
 }
 
 .wi-night-alt-cloudy:before {
     content: "\f086"
 }
 
 .wi-up-left:before {
     content: "\f087"
 }
 
 .wi-down-right:before {
     content: "\f088"
 }
 
 .wi-time-12:before {
     content: "\f089"
 }
 
 .wi-time-1:before {
     content: "\f08a"
 }
 
 .wi-time-2:before {
     content: "\f08b"
 }
 
 .wi-time-3:before {
     content: "\f08c"
 }
 
 .wi-time-4:before {
     content: "\f08d"
 }
 
 .wi-time-5:before {
     content: "\f08e"
 }
 
 .wi-time-6:before {
     content: "\f08f"
 }
 
 .wi-time-7:before {
     content: "\f090"
 }
 
 .wi-time-8:before {
     content: "\f091"
 }
 
 .wi-time-9:before {
     content: "\f092"
 }
 
 .wi-time-10:before {
     content: "\f093"
 }
 
 .wi-time-11:before {
     content: "\f094"
 }
 
 .wi-day-sleet:before {
     content: "\f0b2"
 }
 
 .wi-night-sleet:before {
     content: "\f0b3"
 }
 
 .wi-night-alt-sleet:before {
     content: "\f0b4"
 }
 
 .wi-sleet:before {
     content: "\f0b5"
 }
 
 .wi-day-haze:before {
     content: "\f0b6"
 }
 
 .wi-beafort-0:before {
     content: "\f0b7"
 }
 
 .wi-beafort-1:before {
     content: "\f0b8"
 }
 
 .wi-beafort-2:before {
     content: "\f0b9"
 }
 
 .wi-beafort-3:before {
     content: "\f0ba"
 }
 
 .wi-beafort-4:before {
     content: "\f0bb"
 }
 
 .wi-beafort-5:before {
     content: "\f0bc"
 }
 
 .wi-beafort-6:before {
     content: "\f0bd"
 }
 
 .wi-beafort-7:before {
     content: "\f0be"
 }
 
 .wi-beafort-8:before {
     content: "\f0bf"
 }
 
 .wi-beafort-9:before {
     content: "\f0c0"
 }
 
 .wi-beafort-10:before {
     content: "\f0c1"
 }
 
 .wi-beafort-11:before {
     content: "\f0c2"
 }
 
 .wi-beafort-12:before {
     content: "\f0c3"
 }
 
 .wi-wind-default:before {
     content: "\f0b1"
 }
 
 .wi-wind-default._0-deg {
     -webkit-transform: rotate(0deg);
     -moz-transform: rotate(0deg);
     -ms-transform: rotate(0deg);
     -o-transform: rotate(0deg);
     transform: rotate(0deg)
 }
 
 .wi-wind-default._15-deg {
     -webkit-transform: rotate(15deg);
     -moz-transform: rotate(15deg);
     -ms-transform: rotate(15deg);
     -o-transform: rotate(15deg);
     transform: rotate(15deg)
 }
 
 .wi-wind-default._30-deg {
     -webkit-transform: rotate(30deg);
     -moz-transform: rotate(30deg);
     -ms-transform: rotate(30deg);
     -o-transform: rotate(30deg);
     transform: rotate(30deg)
 }
 
 .wi-wind-default._45-deg {
     -webkit-transform: rotate(45deg);
     -moz-transform: rotate(45deg);
     -ms-transform: rotate(45deg);
     -o-transform: rotate(45deg);
     transform: rotate(45deg)
 }
 
 .wi-wind-default._60-deg {
     -webkit-transform: rotate(60deg);
     -moz-transform: rotate(60deg);
     -ms-transform: rotate(60deg);
     -o-transform: rotate(60deg);
     transform: rotate(60deg)
 }
 
 .wi-wind-default._75-deg {
     -webkit-transform: rotate(75deg);
     -moz-transform: rotate(75deg);
     -ms-transform: rotate(75deg);
     -o-transform: rotate(75deg);
     transform: rotate(75deg)
 }
 
 .wi-wind-default._90-deg {
     -webkit-transform: rotate(90deg);
     -moz-transform: rotate(90deg);
     -ms-transform: rotate(90deg);
     -o-transform: rotate(90deg);
     transform: rotate(90deg)
 }
 
 .wi-wind-default._105-deg {
     -webkit-transform: rotate(105deg);
     -moz-transform: rotate(105deg);
     -ms-transform: rotate(105deg);
     -o-transform: rotate(105deg);
     transform: rotate(105deg)
 }
 
 .wi-wind-default._120-deg {
     -webkit-transform: rotate(120deg);
     -moz-transform: rotate(120deg);
     -ms-transform: rotate(120deg);
     -o-transform: rotate(120deg);
     transform: rotate(120deg)
 }
 
 .wi-wind-default._135-deg {
     -webkit-transform: rotate(135deg);
     -moz-transform: rotate(135deg);
     -ms-transform: rotate(135deg);
     -o-transform: rotate(135deg);
     transform: rotate(135deg)
 }
 
 .wi-wind-default._150-deg {
     -webkit-transform: rotate(150deg);
     -moz-transform: rotate(150deg);
     -ms-transform: rotate(150deg);
     -o-transform: rotate(150deg);
     transform: rotate(150deg)
 }
 
 .wi-wind-default._165-deg {
     -webkit-transform: rotate(165deg);
     -moz-transform: rotate(165deg);
     -ms-transform: rotate(165deg);
     -o-transform: rotate(165deg);
     transform: rotate(165deg)
 }
 
 .wi-wind-default._180-deg {
     -webkit-transform: rotate(180deg);
     -moz-transform: rotate(180deg);
     -ms-transform: rotate(180deg);
     -o-transform: rotate(180deg);
     transform: rotate(180deg)
 }
 
 .wi-wind-default._195-deg {
     -webkit-transform: rotate(195deg);
     -moz-transform: rotate(195deg);
     -ms-transform: rotate(195deg);
     -o-transform: rotate(195deg);
     transform: rotate(195deg)
 }
 
 .wi-wind-default._210-deg {
     -webkit-transform: rotate(210deg);
     -moz-transform: rotate(210deg);
     -ms-transform: rotate(210deg);
     -o-transform: rotate(210deg);
     transform: rotate(210deg)
 }
 
 .wi-wind-default._225-deg {
     -webkit-transform: rotate(225deg);
     -moz-transform: rotate(225deg);
     -ms-transform: rotate(225deg);
     -o-transform: rotate(225deg);
     transform: rotate(225deg)
 }
 
 .wi-wind-default._240-deg {
     -webkit-transform: rotate(240deg);
     -moz-transform: rotate(240deg);
     -ms-transform: rotate(240deg);
     -o-transform: rotate(240deg);
     transform: rotate(240deg)
 }
 
 .wi-wind-default._255-deg {
     -webkit-transform: rotate(255deg);
     -moz-transform: rotate(255deg);
     -ms-transform: rotate(255deg);
     -o-transform: rotate(255deg);
     transform: rotate(255deg)
 }
 
 .wi-wind-default._270-deg {
     -webkit-transform: rotate(270deg);
     -moz-transform: rotate(270deg);
     -ms-transform: rotate(270deg);
     -o-transform: rotate(270deg);
     transform: rotate(270deg)
 }
 
 .wi-wind-default._285-deg {
     -webkit-transform: rotate(295deg);
     -moz-transform: rotate(295deg);
     -ms-transform: rotate(295deg);
     -o-transform: rotate(295deg);
     transform: rotate(295deg)
 }
 
 .wi-wind-default._300-deg {
     -webkit-transform: rotate(300deg);
     -moz-transform: rotate(300deg);
     -ms-transform: rotate(300deg);
     -o-transform: rotate(300deg);
     transform: rotate(300deg)
 }
 
 .wi-wind-default._315-deg {
     -webkit-transform: rotate(315deg);
     -moz-transform: rotate(315deg);
     -ms-transform: rotate(315deg);
     -o-transform: rotate(315deg);
     transform: rotate(315deg)
 }
 
 .wi-wind-default._330-deg {
     -webkit-transform: rotate(330deg);
     -moz-transform: rotate(330deg);
     -ms-transform: rotate(330deg);
     -o-transform: rotate(330deg);
     transform: rotate(330deg)
 }
 
 .wi-wind-default._345-deg {
     -webkit-transform: rotate(345deg);
     -moz-transform: rotate(345deg);
     -ms-transform: rotate(345deg);
     -o-transform: rotate(345deg);
     transform: rotate(345deg)
 }
 
 .wi-moon-new:before {
     content: "\f095"
 }
 
 .wi-moon-waxing-cresent-1:before {
     content: "\f096"
 }
 
 .wi-moon-waxing-cresent-2:before {
     content: "\f097"
 }
 
 .wi-moon-waxing-cresent-3:before {
     content: "\f098"
 }
 
 .wi-moon-waxing-cresent-4:before {
     content: "\f099"
 }
 
 .wi-moon-waxing-cresent-5:before {
     content: "\f09a"
 }
 
 .wi-moon-waxing-cresent-6:before {
     content: "\f09b"
 }
 
 .wi-moon-first-quarter:before {
     content: "\f09c"
 }
 
 .wi-moon-waxing-gibbous-1:before {
     content: "\f09d"
 }
 
 .wi-moon-waxing-gibbous-2:before {
     content: "\f09e"
 }
 
 .wi-moon-waxing-gibbous-3:before {
     content: "\f09f"
 }
 
 .wi-moon-waxing-gibbous-4:before {
     content: "\f0a0"
 }
 
 .wi-moon-waxing-gibbous-5:before {
     content: "\f0a1"
 }
 
 .wi-moon-waxing-gibbous-6:before {
     content: "\f0a2"
 }
 
 .wi-moon-full:before {
     content: "\f0a3"
 }
 
 .wi-moon-waning-gibbous-1:before {
     content: "\f0a4"
 }
 
 .wi-moon-waning-gibbous-2:before {
     content: "\f0a5"
 }
 
 .wi-moon-waning-gibbous-3:before {
     content: "\f0a6"
 }
 
 .wi-moon-waning-gibbous-4:before {
     content: "\f0a7"
 }
 
 .wi-moon-waning-gibbous-5:before {
     content: "\f0a8"
 }
 
 .wi-moon-waning-gibbous-6:before {
     content: "\f0a9"
 }
 
 .wi-moon-3rd-quarter:before {
     content: "\f0aa"
 }
 
 .wi-moon-waning-crescent-1:before {
     content: "\f0ab"
 }
 
 .wi-moon-waning-crescent-2:before {
     content: "\f0ac"
 }
 
 .wi-moon-waning-crescent-3:before {
     content: "\f0ad"
 }
 
 .wi-moon-waning-crescent-4:before {
     content: "\f0ae"
 }
 
 .wi-moon-waning-crescent-5:before {
     content: "\f0af"
 }
 
 .wi-moon-waning-crescent-6:before {
     content: "\f0b0"
 }
 
 :root {
     --dark-color: #323A45;
     --color-fff: #FFFFFF;
     --color-100: #F0F0F0;
     --color-200: #EEEEEE;
     --color-300: #E0E0E0;
     --color-400: #BDBDBD;
     --color-500: #555353;
     --color-600: #464545;
     --color-700: #363535;
     --color-800: #252525;
     --color-900: #181818;
     --color-000: #000000;
     --text-color: #5A5A5A;
     --htext-color: #212529;
     --border-color: #F0F0F0;
     --card-color: #ffffff;
     --body-color: #f4f7f6;
     --content-color: #f5f5f5;
     --white-color: #ffffff;
     --sidebar-color: #fcfcfc;
     --hc-font-color: #555555;
     --hc-bg-color: #f0f0f0;
     --primary-color: #0d6efd;
     --secondary-color: #ffd55d;
     --chart-color1: #2794eb;
     --chart-color2: #17b3c1;
     --chart-color3: #64868e;
     --chart-color4: #47d6b6;
     --chart-color5: #4362D0;
     --sidebar-img: url(https://alpino.thememakker.com/html/assets/images/sidebar-bg/sidebar-1.jpg)
 }
 
 [data-theme="dark"] {
     --dark-color: #323A45;
     --color-fff: #000000;
     --color-100: #212121;
     --color-200: #424242;
     --color-300: #616161;
     --color-400: #757575;
     --color-500: #8f8f8f;
     --color-600: #BDBDBD;
     --color-700: #E0E0E0;
     --color-800: #999999;
     --color-900: #F0F0F0;
     --color-000: #FFFFFF;
     --text-color: #999999;
     --htext-color: #c7c2c2;
     --border-color: #3e3e3e;
     --card-color: #1c222c;
     --body-color: #191f28;
     --content-color: #383838;
     --white-color: #ffffff;
     --sidebar-color: #fcfcfc;
     --hc-font-color: #555555;
     --hc-bg-color: #f0f0f0
 }
 
 [data-theme="dark"] [data-theme="theme-purple"] {
     --primary-color: #5a66a7
 }
 
 [data-theme="dark"] [data-theme="theme-cyan"] {
     --primary-color: #00bdaa
 }
 
 [data-theme="dark"] [data-theme="theme-indigo"] {
     --border-color: #212020;
     --card-color: #101010;
     --body-color: #050505
 }
 
 [data-theme="dark"] [data-theme="theme-blue"] {
     --primary-color: #2e7bec;
     --border-color: #1a1f2d;
     --card-color: #10141f;
     --body-color: #080b13
 }
 
 [data-theme="dark"] [data-theme="theme-orange"] {
     --border-color: #092940;
     --card-color: #052133;
     --body-color: #051e2f
 }
 
 [data-theme="dark"] [data-theme="theme-blush"] {
     --border-color: #242331;
     --card-color: #1a1925;
     --body-color: #15141f
 }
 
 [data-theme="dark"] [data-theme="theme-red"] {
     --primary-color: #f73e60;
     --border-color: #222335;
     --card-color: #161725;
     --body-color: #11121d
 }
 
 [data-theme="high-contrast"] {
     --dark-color: #323A45;
     --color-fff: #000000;
     --color-100: #000000;
     --color-200: #ffffff;
     --color-300: #ffffff;
     --color-400: #000000;
     --color-500: #ffffff;
     --color-600: #ffffff;
     --color-700: #ffffff;
     --color-800: #ffffff;
     --color-900: #ffffff;
     --color-000: #ffffff;
     --text-color: #ffffff;
     --htext-color: #ffffff;
     --border-color: #ffffff;
     --card-color: #0e0e0e;
     --body-color: #000000;
     --white-color: #ffffff;
     --black-color: #000000;
     --content-color: #161616;
     --sidebar-color: #fcfcfc;
     --hc-font-color: #555555;
     --hc-bg-color: #f0f0f0
 }
 
 [data-theme="high-contrast"] .avatar.no-thumbnail {
     background-color: #212529
 }
 
 [data-theme="high-contrast"] .form-control::placeholder {
     color: var(--white-color)
 }
 
 [data-theme="high-contrast"] .modal {
     color: #212529
 }
 
 [data-theme="high-contrast"] .modal .card {
     border-color: var(--hc-bg-color);
     background-color: var(--white-color)
 }
 
 [data-theme="high-contrast"] .border-dark {
     border-color: var(--color-200) !important
 }
 
 [data-theme="theme-purple"] {
     --primary-color: #6572b8;
     --secondary-color: #d8d419;
     --primary-rgb: 72, 76, 127;
     --chart-color1: #6572b8;
     --chart-color2: #d8d419;
     --chart-color3: #7394b9;
     --chart-color4: #79b6bd;
     --chart-color5: #a0c1b8;
     --primary-gradient: linear-gradient(145deg, var(--primary-color), var(--chart-color3))
 }
 
 [data-theme="theme-blue"] {
     --primary-color: #3eacff;
     --secondary-color: #ffd55d;
     --primary-rgb: 13, 110, 253;
     --chart-color1: #2794eb;
     --chart-color2: #17b3c1;
     --chart-color3: #64868e;
     --chart-color4: #47d6b6;
     --chart-color5: #4362D0;
     --primary-gradient: linear-gradient(145deg, var(--primary-color), var(--chart-color3))
 }
 
 [data-theme="theme-cyan"] {
     --primary-color: #00bdaa;
     --secondary-color: #f1ff17;
     --primary-rgb: 0, 189, 170;
     --chart-color1: #00bdaa;
     --chart-color2: #c1c0b9;
     --chart-color3: #537791;
     --chart-color4: #9cbf3b;
     --chart-color5: #cdb60c;
     --primary-gradient: linear-gradient(145deg, var(--primary-color), var(--chart-color3))
 }
 
 [data-theme="theme-green"] {
     --primary-color: #7EB93F;
     --secondary-color: #fffb83;
     --primary-rgb: 126, 185, 63;
     --chart-color1: #7EB93F;
     --chart-color2: #b9bc6d;
     --chart-color3: #427a5b;
     --chart-color4: #4e7376;
     --chart-color5: #a6a6a4;
     --primary-gradient: linear-gradient(145deg, var(--primary-color), var(--chart-color3))
 }
 
 [data-theme="theme-orange"] {
     --primary-color: #F0B90B;
     --secondary-color: #35f3fd;
     --primary-rgb: 254, 176, 25;
     --chart-color1: #ef7e56;
     --chart-color2: #44558f;
     --chart-color3: #ded5c4;
     --chart-color4: #f59292;
     --chart-color5: #90a4ae;
     --primary-gradient: linear-gradient(145deg, var(--primary-color), var(--chart-color4))
 }
 
 [data-theme="theme-blush"] {
     --primary-color: #ED5782;
     --secondary-color: #ffdd95;
     --primary-rgb: 237, 87, 130;
     --chart-color1: #ED5782;
     --chart-color2: #e5df88;
     --chart-color3: #726a95;
     --chart-color4: #a6a6a4;
     --chart-color5: #c9b8b8;
     --primary-gradient: linear-gradient(145deg, var(--primary-color), var(--chart-color3))
 }
 
 [data-theme="theme-red"] {
     --primary-color: #ff4560;
     --secondary-color: #FFDF41;
     --primary-rgb: 255, 69, 96;
     --chart-color1: #ff5959;
     --chart-color2: #ffad5a;
     --chart-color3: #4f9da6;
     --chart-color4: #c06c84;
     --chart-color5: #f67280;
     --primary-gradient: linear-gradient(145deg, var(--primary-color), var(--chart-color4))
 }
 
 [class="theme-dynamic"] {
     --primary-gradient: linear-gradient(145deg, var(--primary-color), var(--chart-color3))
 }
 
 [data-theme="high-contrast"] [data-lucid="theme-cyanh"],
 [data-theme="high-contrast"] [class="theme-indigo"],
 [data-theme="high-contrast"] [class="theme-blue"],
 [data-theme="high-contrast"] [class="theme-green"],
 [data-theme="high-contrast"] [class="theme-orange"],
 [data-theme="high-contrast"] [class="theme-blush"],
 [data-theme="high-contrast"] [class="theme-red"] {
     --chart-color1: #f1ff17;
     --chart-color2: #ff3a17;
     --chart-color3: #17e4ff;
     --chart-color4: #17ff17;
     --chart-color5: #e417ff;
     --border-color: #ffffff
 }
 
 [data-theme="high-contrast"] .form-control {
     border-color: var(--white-color);
     color: var(--text-color)
 }
 
 [data-theme="high-contrast"] .form-control:focus {
     background-color: var(--card-color)
 }
 
 [data-theme="high-contrast"] .breadcrumb {
     background-color: var(--black-color)
 }
 
 [data-theme="high-contrast"] .form-switch,
 [data-theme="high-contrast"] .btn.btn-primary {
     color: var(--text-color)
 }
 
 [data-theme="high-contrast"] .main .text-muted {
     color: var(--white-color) !important
 }
 
 [data-theme="high-contrast"] .main a {
     color: #f1ff17
 }
 
 [data-theme="high-contrast"] .main a.btn.btn-dark {
     color: var(--black-color)
 }
 
 [data-theme="high-contrast"] .main a.list-group-item {
     color: var(--black-color)
 }
 
 [data-theme="high-contrast"] .main .btn-outline-secondary {
     color: var(--white-color);
     border-color: var(--white-color)
 }
 
 [data-theme="high-contrast"] .main .breadcrumb-item.active {
     color: var(--white-color)
 }
 
 [data-theme="high-contrast"] .main .btn-check:checked+.btn-outline-secondary,
 [data-theme="high-contrast"] .main .btn-check:active+.btn-outline-secondary,
 [data-theme="high-contrast"] .main .btn-outline-secondary:active,
 [data-theme="high-contrast"] .main .btn-outline-secondary.active,
 [data-theme="high-contrast"] .main .btn-outline-secondary:hover {
     color: var(--black-color);
     background-color: var(--white-color);
     border-color: var(--white-color)
 }
 
 [data-theme="high-contrast"] .main .alert-danger {
     color: var(--white-color);
     background-color: var(--black-color);
     border-color: var(--white-color)
 }
 
 [data-theme="high-contrast"] .main .dropdown-menu a {
     color: var(--black-color)
 }
 
 [data-theme="high-contrast"] .main .dropdown-menu a:hover {
     color: var(--white-color);
     background-color: var(--black-color)
 }
 
 [data-theme="high-contrast"] .main .fc .fc-button-primary {
     color: var(--black-color);
     background-color: var(--white-color);
     border-color: var(--white-color)
 }
 
 [data-theme="high-contrast"] .main .fc .fc-button-primary .fc-icon {
     color: var(--black-color)
 }
 
 [data-theme="high-contrast"] .main .fc .fc-daygrid-day .fc-daygrid-event-dot {
     border-color: var(--white-color)
 }
 
 [data-theme="high-contrast"] .main .fc .fc-daygrid-day.fc-day-today {
     background-color: var(--chart-color4) !important
 }
 
 [data-theme="high-contrast"] .main .fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number,
 [data-theme="high-contrast"] .main .fc .fc-daygrid-day.fc-day-today .fc-event-time,
 [data-theme="high-contrast"] .main .fc .fc-daygrid-day.fc-day-today .fc-event-title {
     color: var(--black-color)
 }
 
 [data-theme="high-contrast"] .main .fc .fc-daygrid-day.fc-day-today .fc-daygrid-event-dot {
     border-color: var(--black-color)
 }
 
 [data-theme="high-contrast"] .main .fc-h-event {
     border: 1px solid var(--white-color);
     background-color: var(--white-color)
 }
 
 [data-theme="high-contrast"] .main .fc-h-event .fc-event-title {
     color: var(--black-color) !important
 }
 
 [data-theme="high-contrast"] .main .card-chat-body .text-end .message {
     color: var(--black-color)
 }
 
 [data-theme="high-contrast"] .main .bg-secondary {
     background: var(--white-color) !important
 }
 
 [data-theme="high-contrast"] .main .bg-secondary .nav-tabs.tab-card .nav-link {
     color: var(--black-color)
 }
 
 [data-theme="high-contrast"] .main .bg-secondary.badge {
     color: var(--black-color) !important
 }
 
 [data-theme="high-contrast"] .main .nav-tabs.tab-body-header .nav-link.active {
     color: var(--black-color)
 }
 
 [data-theme="high-contrast"] .main .btn.btn-primary {
     color: var(--black-color)
 }
 
 [data-theme="high-contrast"] .main .resume-box .time {
     color: var(--black-color)
 }
 
 [data-theme="high-contrast"] .main .bg-primary.text-light,
 [data-theme="high-contrast"] .main .bg-primary.badge,
 [data-theme="high-contrast"] .main .bg-primary .text-light {
     color: var(--black-color) !important
 }
 
 [data-theme="high-contrast"] .main .paginate_button a {
     color: var(--white-color)
 }
 
 [data-theme="high-contrast"] .main .page-item.active .page-link {
     color: var(--black-color);
     background-color: var(--white-color);
     border-color: var(--white-color)
 }
 
 [data-theme="high-contrast"] .main .page-item .page-link {
     border-color: var(--white-color)
 }
 
 [data-theme="high-contrast"] .main .btn-link {
     color: var(--white-color)
 }
 
 [data-theme="high-contrast"] .main .list-group.list-group-custom .list-group-item {
     color: var(--white-color)
 }
 
 [data-theme="high-contrast"] .main .list-group .list-group-item {
     color: var(--black-color)
 }
 
 [data-theme="high-contrast"] .main .nav-pills .nav-link {
     color: var(--white-color)
 }
 
 [data-theme="high-contrast"] .main .nav-pills .nav-link.active,
 [data-theme="high-contrast"] .main .nav-pills .show>.nav-link {
     background-color: var(--white-color);
     color: var(--black-color)
 }
 
 [data-theme="high-contrast"] .main .card.bg-primary,
 [data-theme="high-contrast"] .main .card.bg-secondary,
 [data-theme="high-contrast"] .main .card.bg-light {
     color: var(--black-color) !important
 }
 
 [data-theme="high-contrast"] .main .card .text-dark {
     background: var(--white-color)
 }
 
 [data-theme="high-contrast"] .main .accordion .card-header {
     background-color: var(--white-color)
 }
 
 [data-theme="high-contrast"] .main .accordion .card-header .btn-light {
     background-color: var(--white-color)
 }
 
 [data-theme="high-contrast"] .main .navbar a {
     color: var(--black-color)
 }
 
 [data-theme="high-contrast"] .main .navbar a:hover {
     color: var(--black-color)
 }
 
 [data-theme="high-contrast"] .main .navbar.navbar-light {
     background-color: var(--white-color)
 }
 
 [data-theme="high-contrast"] .main .navbar.navbar-light .brand-icon path {
     fill: var(--black-color)
 }
 
 [data-theme="high-contrast"] .main .navbar.navbar-light .text-muted,
 [data-theme="high-contrast"] .main .navbar.navbar-light .text-primary,
 [data-theme="high-contrast"] .main .navbar.navbar-light .notifications .pulse,
 [data-theme="high-contrast"] .main .navbar.navbar-light .notifications .pulse:focus {
     color: var(--black-color) !important
 }
 
 [data-theme="high-contrast"] .main .navbar.navbar-light .input-group.border {
     border-color: var(--black-color) !important
 }
 
 [data-theme="high-contrast"] .main .navbar.navbar-light .input-group .btn-outline-secondary {
     color: var(--black-color)
 }
 
 [data-theme="high-contrast"] .main .navbar.navbar-light .input-group .btn-outline-secondary:focus {
     background-color: var(--white-color)
 }
 
 [data-theme="high-contrast"] .main .navbar.navbar-light .input-group .form-control {
     color: var(--black-color) !important
 }
 
 [data-theme="high-contrast"] .main .navbar.navbar-light .input-group .form-control::-webkit-input-placeholder {
     color: var(--black-color)
 }
 
 [data-theme="high-contrast"] .main .navbar.navbar-light .input-group .form-control::-moz-placeholder {
     color: var(--black-color)
 }
 
 [data-theme="high-contrast"] .main .navbar.navbar-light .input-group .form-control:-ms-input-placeholder {
     color: var(--black-color)
 }
 
 [data-theme="high-contrast"] .main .navbar.navbar-light .input-group .form-control:-moz-placeholder {
     color: var(--black-color)
 }
 
 [data-theme="high-contrast"] .main .navbar.navbar-dark {
     background-color: var(--black-color) !important
 }
 
 [data-theme="high-contrast"] .main .navbar.navbar-dark .navbar-toggler {
     border-color: var(--border-color) !important
 }
 
 [data-theme="high-contrast"] .main .navbar.navbar-light {
     background-color: var(--white-color) !important
 }
 
 [data-theme="high-contrast"] .main .navbar.navbar-light .nav-pills .nav-link {
     color: var(--black-color)
 }
 
 [data-theme="high-contrast"] .main .navbar.navbar-light .nav-pills .nav-link.active,
 [data-theme="high-contrast"] .main .navbar.navbar-light .nav-pills .nav-link:hover {
     background-color: var(--black-color);
     color: var(--white-color)
 }
 
 [data-theme="high-contrast"] .main .toast {
     background-color: var(--white-color)
 }
 
 [data-theme="high-contrast"] .main .toast .toast-header,
 [data-theme="high-contrast"] .main .toast .toast-body {
     color: var(--black-color);
     border-color: var(--black-color)
 }
 
 [data-theme="high-contrast"] .sidebar-nav .metismenu a:hover,
 [data-theme="high-contrast"] .sidebar-nav .metismenu a:focus,
 [data-theme="high-contrast"] .sidebar-nav .metismenu a:active {
     color: var(--black-color)
 }
 
 [data-theme="high-contrast"] .sidebar .menu-list .m-link,
 [data-theme="high-contrast"] .sidebar .menu-list .ms-link {
     padding: 8px 10px !important;
     border-radius: .25rem;
     margin-bottom: 3px !important
 }
 
 [data-theme="high-contrast"] .sidebar .menu-list .ms-link::before {
     left: -16px
 }
 
 [data-theme="high-contrast"] .sidebar .menu-list .sub-menu::before {
     left: 16px
 }
 
 [data-theme="high-contrast"] #SearchModal {
     color: var(--black-color)
 }
 
 [data-theme="high-contrast"] #SearchModal .modal-header.bg-secondary {
     background: var(--white-color) !important;
     border-bottom: 1px solid var(--black-color) !important
 }
 
 [data-theme="high-contrast"] #SearchModal .text-primary {
     color: var(--black-color) !important
 }
 
 [data-theme="high-contrast"] #SearchModal .dropdown-item:hover,
 [data-theme="high-contrast"] #SearchModal .dropdown-item:focus,
 [data-theme="high-contrast"] #SearchModal .dropdown-item.active {
     background-color: var(--black-color);
     color: var(--white-color)
 }
 
 [data-theme="high-contrast"] #SearchModal .dropdown-item:hover .text-primary,
 [data-theme="high-contrast"] #SearchModal .dropdown-item:focus .text-primary,
 [data-theme="high-contrast"] #SearchModal .dropdown-item.active .text-primary {
     color: var(--white-color) !important
 }
 
 [data-theme="high-contrast"] #notificationsModal {
     color: var(--black-color)
 }
 
 [data-theme="high-contrast"] #notificationsModal .modal-header {
     border-color: var(--black-color)
 }
 
 [data-theme="high-contrast"] #notificationsModal .modal-header .badge {
     background-color: var(--black-color) !important
 }
 
 [data-theme="high-contrast"] #notificationsModal .nav-pills .nav-link {
     color: var(--black-color)
 }
 
 [data-theme="high-contrast"] #notificationsModal .nav-pills .nav-link.active,
 [data-theme="high-contrast"] #notificationsModal .nav-pills .show>.nav-link {
     background-color: var(--black-color);
     color: var(--white-color)
 }
 
 [data-theme="high-contrast"] #LayoutModal .bg-primary {
     background: var(--black-color) !important
 }
 
 [data-theme="high-contrast"] #LayoutModal .border-primary {
     border-color: var(--black-color) !important
 }
 
 [data-theme="high-contrast"] .modal-header {
     border-color: var(--black-color);
     color: var(--black-color) !important
 }
 
 [data-theme="high-contrast"] .modal-header .btn-close {
     opacity: 1
 }
 
 [data-theme="high-contrast"] .modal-header .badge {
     background-color: var(--black-color) !important
 }
 
 [data-theme="high-contrast"] .modal-body .text-muted {
     color: var(--black-color) !important
 }
 
 [data-theme="high-contrast"] .modal-body a {
     color: var(--black-color)
 }
 
 [data-theme="high-contrast"] .apexcharts-tooltip .apexcharts-tooltip-title,
 [data-theme="high-contrast"] .apexcharts-tooltip .apexcharts-tooltip-series-group {
     background-color: var(--white-color) !important;
     color: var(--black-color) !important;
     border-color: var(--black-color) !important
 }
 
 [data-theme="high-contrast"] .rtl_mode .sidebar .menu-list .sub-menu::before {
     right: 16px
 }
 
 [data-theme="high-contrast"] .rtl_mode .sidebar .menu-list .ms-link::before {
     right: -16px
 }
 
 .rtl {
     direction: rtl;
     text-align: right
 }
 
 .rtl ul {
     padding-right: 0
 }
 
 .rtl .float-end {
     float: left !important
 }
 
 .rtl .text-end {
     text-align: left !important
 }
 
 .rtl .ms-auto {
     margin-left: unset !important;
     margin-right: auto !important
 }
 
 .rtl .me-auto {
     margin-right: unset !important;
     margin-left: auto !important
 }
 
 .rtl .end-0 {
     left: 0 !important;
     right: auto !important
 }
 
 .rtl .ms-1 {
     margin-right: .25rem !important
 }
 
 .rtl .ms-1 {
     margin-left: 0 !important;
     margin-right: .25rem !important
 }
 
 .rtl .me-1 {
     margin-right: 0 !important;
     margin-left: .25rem !important
 }
 
 .rtl .ps-1 {
     padding-left: 0 !important;
     padding-right: .25rem !important
 }
 
 .rtl .ps-1 {
     padding-right: 0 !important;
     padding-left: .25rem !important
 }
 
 .rtl .ms-2 {
     margin-left: 0 !important;
     margin-right: .5rem !important
 }
 
 .rtl .me-2 {
     margin-right: 0 !important;
     margin-left: .5rem !important
 }
 
 .rtl .ps-2 {
     padding-left: 0 !important;
     padding-right: .5rem !important
 }
 
 .rtl .pe-2 {
     padding-right: 0 !important;
     padding-left: .5rem !important
 }
 
 .rtl .ms-3 {
     margin-left: 0 !important;
     margin-right: 1rem !important
 }
 
 .rtl .me-3 {
     margin-right: 0 !important;
     margin-left: 1rem !important
 }
 
 .rtl .me-4 {
     margin-right: 0 !important;
     margin-left: 1.5rem !important
 }
 
 .rtl .ms-4 {
     margin-left: 0 !important;
     margin-right: 1.5rem !important
 }
 
 .rtl .ps-3 {
     padding-left: 0 !important;
     padding-right: 1rem !important
 }
 
 .rtl .pe-3 {
     padding-right: 0 !important;
     padding-left: 1rem !important
 }
 
 .rtl .dropdown-menu {
     text-align: right
 }
 
 .rtl .dropdown-menu.dropdown-menu-end[data-bs-popper] {
     right: auto;
     left: 0
 }
 
 .rtl .dropdown-menu-right {
     left: 0;
     right: auto;
     text-align: right
 }
 
 .rtl .modal-header .btn-close {
     margin: unset
 }
 
 .rtl .list-inline-item:not(:last-child) {
     margin-left: .5rem;
     margin-right: 0
 }
 
 .rtl .form-check {
     padding-right: 1.5em;
     padding-left: 0
 }
 
 .rtl .form-check .form-check-input {
     float: right;
     margin-left: 10px;
     margin-right: -1.5em
 }
 
 .rtl .breadcrumb-item+.breadcrumb-item {
     padding-right: .5rem;
     padding-left: 0
 }
 
 .rtl .breadcrumb-item+.breadcrumb-item:before {
     float: right;
     padding-left: .5rem;
     padding-right: 0
 }
 
 .rtl .modal.fade .modal-dialog-vertical {
     transform: translateX(100%)
 }
 
 .rtl .modal.show .modal-dialog-vertical {
     transform: translateX(0)
 }
 
 .rtl .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
 .rtl .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3) {
     border-top-right-radius: .25rem;
     border-bottom-right-radius: .25rem;
     border-top-left-radius: 0;
     border-bottom-left-radius: 0
 }
 
 .rtl .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
     margin-right: -1px;
     border-top-right-radius: 0;
     border-bottom-right-radius: 0;
     border-top-left-radius: .25rem;
     border-bottom-left-radius: .25rem
 }
 
 .rtl .btn-group .btn:first-child {
     border-top-right-radius: .25rem;
     border-bottom-right-radius: .25rem
 }
 
 .rtl .btn-group>.btn:not(:last-child):not(.dropdown-toggle),
 .rtl .btn-group>.btn-group:not(:last-child)>.btn {
     border-top-left-radius: 0;
     border-bottom-left-radius: 0
 }
 
 .rtl .btn-group>.btn:nth-child(n+3),
 .rtl .btn-group>:not(.btn-check)+.btn,
 .rtl .btn-group>.btn-group:not(:first-child)>.btn {
     border-top-right-radius: 0;
     border-bottom-right-radius: 0;
     border-top-left-radius: .25rem;
     border-bottom-left-radius: .25rem
 }
 
 .rtl .sidebar-mini-btn .fa-arrow-left {
     transform: rotate(180deg)
 }
 
 .rtl .dropdown-toggle::after {
     margin-left: unset;
     margin-right: 0.255em
 }
 
 @media (min-width: 1200px) {
     .rtl .ms-xl-5 {
         margin-left: 0 !important;
         margin-right: 3rem !important
     }
 }
 
 @media (min-width: 992px) {
     .rtl .me-lg-0 {
         margin-left: 0 !important
     }
 
     .rtl .pe-lg-4 {
         padding-left: 1.5rem !important;
         padding-right: 0 !important
     }
 }
 
 @media (min-width: 768px) {
     .rtl .ms-md-5 {
         margin-right: 3rem !important
     }
 
     .rtl .text-md-start {
         text-align: right !important
     }
 }
 
 body {
     background-color: var(--body-color);
     font-family: "Ubuntu", sans-serif;
     font-size: 14px;
     color: var(--text-color);
     overflow-y: scroll;
     scrollbar-width: none;
     -ms-overflow-style: none
 }
 
 body:before,
 body:after {
     height: 5px;
     width: 100%;
     position: fixed;
     content: '';
     z-index: 999;
     background: var(--primary-color)
 }
 
 body:before {
     top: 0
 }
 
 body:after {
     bottom: 0
 }
 
 body::-webkit-scrollbar {
     width: 5px
 }
 
 @media screen and (max-width: 992px) {
 
     body:before,
     body:after {
         height: 2px
     }
 }
 
 a {
     text-decoration: none
 }
 
 a:hover,
 a:focus {
     color: #437ac7;
     text-decoration: none
 }
 
 .inlineblock,
 .card,
 .card .header .header-dropdown li,
 .navbar-fixed-top .navbar-right .navbar-nav,
 .navbar-fixed-top .navbar-right .navbar-nav>li,
 .user-account .dropdown,
 ul.choose-skin li div,
 ul.choose-skin li span,
 .wizard .actions ul,
 .chat-widget li.right .chat-info,
 .chat-widget li .chat-info,
 .info-box-2 .icon,
 .info-box-2 .content,
 .weather .days li,
 .weather3 .top .wrapper .temp .temp-value,
 .weather3 .top .wrapper .temp .deg,
 .stats-report .stat-item,
 .new_timeline .header .color-overlay .day-number,
 .new_timeline .header .color-overlay .date-right,
 .new_timeline li div,
 .team-info li,
 .chart.easy-pie-chart-1,
 .c_list .c_name,
 .right_chat .media .message,
 .right_chat .media .status,
 .dt-bootstrap4 .dt-buttons,
 .dt-bootstrap4 .dataTables_info,
 .dt-bootstrap4 .dt-buttons+.dataTables_filter,
 .dt-bootstrap4 .dt-buttons+.dataTables_paginate,
 .dt-bootstrap4 .dataTables_info+.dataTables_filter,
 .dt-bootstrap4 .dataTables_info+.dataTables_paginate,
 .social_icon,
 .member-card .social-links li {
     display: inline-block
 }
 
 .displayblock,
 .card .header .header-dropdown li a,
 .card .header h2 small,
 .card .header .h2 small,
 .card .header h2 .small,
 .card .header .h2 .small,
 .navbar-nav ul.notifications li>a,
 .navbar-nav ul.notifications li .text,
 .navbar-nav ul.user-menu>li>a,
 .user-account .user-name,
 .user-account .dropdown .dropdown-menu a,
 .sidebar-nav .metismenu a,
 ul.choose-skin li,
 .auth-box .helper-text,
 .auth-box .btn-signin-social,
 .metric-inline i,
 .metric-inline span,
 .separator-linethrough:after,
 .wizard .actions,
 .wizard .actions a,
 .wizard .actions a:hover,
 .wizard .actions a:active,
 .wizard .steps,
 .wizard .steps a,
 .wizard .steps a:hover,
 .wizard .steps a:active,
 .chat-widget li .message,
 .info-box-2 .chart,
 .weather2 .city-selected .temp h2,
 .weather2 .city-selected .temp .h2,
 .weather .days li .degrees,
 .weather3 .top::after,
 .weather3 .bottom .forecast li,
 .basic-list li,
 .right_chat .media .name,
 .social_media_table .list-name,
 .testimonial4 blockquote,
 .testimonial4 blockquote:before,
 .testimonial4 blockquote:after,
 .member-card .text-pink,
 .w_social2 .pw_img:before,
 .w_social2 .pw_content .pw_meta small,
 .w_social2 .pw_content .pw_meta .small,
 .w_social2 .pw_content .pw_meta span,
 .w_profile .job_post,
 .feeds_widget li .feeds-body,
 .feeds_widget li .feeds-body>small,
 .feeds_widget li .feeds-body>.small,
 .timeline-item:before,
 .timeline-item:after,
 .fc .fc-view-container .fc-event:focus .fc-overlay,
 .fc .fc-view-container .fc-event:hover .fc-overlay,
 .fc .fc-view-container .fc-event:active .fc-overlay,
 .file_manager .file a:hover .hover,
 .file_manager .file .file-name small,
 .file_manager .file .file-name .small,
 .pricing3 .pricing-option .price,
 .invoice1 .info {
     display: block
 }
 
 .displaynone {
     display: none
 }
 
 .overflowhidden,
 .member-card {
     overflow: hidden
 }
 
 .z-index {
     z-index: 9999
 }
 
 .br8,
 .navbar-nav .dropdown-menu {
     border-radius: 8px
 }
 
 .p-10 {
     padding: 10px !important
 }
 
 .l-green {
     background: linear-gradient(45deg, #9ce89d, #cdfa7e) !important
 }
 
 .l-pink {
     background: linear-gradient(45deg, pink, #fbc1cb) !important
 }
 
 .l-turquoise {
     background: linear-gradient(45deg, #00ced1, #08e5e8) !important
 }
 
 .l-cyan {
     background: linear-gradient(45deg, #49cdd0, #00bcd4) !important
 }
 
 .l-khaki {
     background: linear-gradient(45deg, khaki, #fdf181) !important
 }
 
 .l-coral {
     background: linear-gradient(45deg, #f08080, #f58787) !important
 }
 
 .l-salmon {
     background: linear-gradient(45deg, #ec74a1, #fbc7c0) !important
 }
 
 .l-blue {
     background: linear-gradient(45deg, #72c2ff, #86f0ff) !important
 }
 
 .l-seagreen {
     background: linear-gradient(45deg, #8ed8ec, #85f7b5) !important
 }
 
 .l-amber {
     background: linear-gradient(45deg, #fda582, #f7cf68) !important
 }
 
 .l-blush {
     background: linear-gradient(45deg, #dd5e89, #f7bb97) !important
 }
 
 .l-parpl {
     background: linear-gradient(45deg, #a890d3, #edbae7) !important
 }
 
 .l-slategray {
     background: linear-gradient(45deg, #708090, #7c8ea0) !important
 }
 
 .xl-pink {
     background: #f9edee !important
 }
 
 .xl-turquoise {
     background: #bdf3f5 !important
 }
 
 .xl-khaki {
     background: #f9f1d8 !important
 }
 
 .xl-parpl {
     background: #efebf4 !important
 }
 
 .xl-salmon {
     background: #ffd4c3 !important
 }
 
 .xl-blue {
     background: #e0eff5 !important
 }
 
 .xl-seagreen {
     background: #ebf2e8 !important
 }
 
 .xl-slategray {
     background: #eceeef !important
 }
 
 .page-title {
     margin-top: 0;
     margin-bottom: 30px;
     font-size: 24px;
     font-weight: 400
 }
 
 footer {
     padding: 0 30px;
     padding-bottom: 15px;
     position: absolute;
     right: 0
 }
 
 @media screen and (max-width: 768px) {
     footer {
         position: relative;
         text-align: center
     }
 }
 
 .copyright {
     margin-bottom: 0;
     font-size: 13px
 }
 
 .btn-default {
     color: var(--text-color);
     background-color: var(--card-color);
     border-color: var(--border-color);
     padding: 6px 18px
 }
 
 ::selection {
     color: var(--color-fff);
     background: var(--primary-color)
 }
 
 .page-loader-wrapper {
     background: var(--primary-color)
 }
 
 #left-sidebar .nav-tabs .nav-link.active {
     color: var(--primary-color)
 }
 
 #wrapper:before,
 #wrapper:after {
     background: var(--primary-color)
 }
 
 .sidebar-nav .metismenu>li.active>a {
     border-left-color: var(--primary-color)
 }
 
 .navbar-nav ul.menu-icon {
     background: var(--primary-color)
 }
 
 .fancy-checkbox input[type="checkbox"]:checked+span:before {
     background: var(--primary-color);
     border-color: var(--primary-color)
 }
 
 .chat-widget li.right .chat-info {
     background: var(--primary-color);
     color: var(--color-fff)
 }
 
 .chat-widget li.right .chat-info:before {
     border-left: 10px solidvar(--primary-color)
 }
 
 .fc .fc-view-container .fc-view.fc-basic-view>table>thead tr th.fc-widget-header {
     background: var(--primary-color);
     border-color: var(--primary-color);
     color: var(--color-fff)
 }
 
 .blog-page .single_post .footer .stats li a:hover {
     color: var(--primary-color)
 }
 
 .auth-main .btn-primary {
     background: var(--primary-color);
     border-color: var(--primary-color)
 }
 
 .sidebar-nav .metismenu>li i {
     color: var(--primary-color)
 }
 
 .st0 {
     color: var(--color-900);
     fill: var(--color-900)
 }
 
 #basic-form label,
 #advanced-form label {
     margin-bottom: 5px
 }
 
 .right_chat li a:hover .media-object {
     border-color: var(--primary-color)
 }
 
 .dd-content {
     color: var(--text-color);
     background: var(--card-color);
     border: 1px solid var(--body-color)
 }
 
 .setting-mode .list-group-item {
     background-color: var(--body-color);
     color: var(--text-color)
 }
 
 .noUi-connect {
     background: var(--primary-color)
 }
 
 .note-editor.note-frame {
     background: var(--card-color);
     border: 1px solid var(--border-color)
 }
 
 .note-editor.note-frame .note-editing-area .note-editable {
     background: var(--card-color);
     color: var(--text-color)
 }
 
 .select2-chosen,
 .select2-input,
 .select2-search-choice,
 .bootstrap-tagsinput {
     background: var(--body-color)
 }
 
 @media only screen and (max-width: 767px) {
     .hidden-xs {
         display: none
     }
 }
 
 @media only screen and (max-width: 992px) {
     .hidden-sm {
         display: none
     }
 }
 
 @media only screen and (max-width: 1024px) {
     .hidden-md {
         display: none
     }
 }
 
 *[dir="rtl"] .metismenu .arrow {
     float: left
 }
 
 *[dir="rtl"] .metismenu .plus-times {
     float: left
 }
 
 *[dir="rtl"] .metismenu .plus-minus {
     float: left
 }
 
 .metismenu .arrow {
     float: right;
     line-height: 1.42857
 }
 
 .metismenu .glyphicon.arrow:before {
     content: "\e079"
 }
 
 .metismenu .active>a>.glyphicon.arrow:before {
     content: "\e114"
 }
 
 .metismenu .fa.arrow:before {
     content: "\f104"
 }
 
 .metismenu .active>a>.fa.arrow:before {
     content: "\f107"
 }
 
 .metismenu .ion.arrow:before {
     content: "\f3d2"
 }
 
 .metismenu .active>a>.ion.arrow:before {
     content: "\f3d0"
 }
 
 .metismenu .plus-times {
     float: right
 }
 
 .metismenu .fa.plus-times:before {
     content: "\f067"
 }
 
 .metismenu .active>a>.fa.plus-times {
     transform: rotate(45deg)
 }
 
 .metismenu .plus-minus {
     float: right
 }
 
 .metismenu .fa.plus-minus:before {
     content: "\f067"
 }
 
 .metismenu .active>a>.fa.plus-minus:before {
     content: "\f068"
 }
 
 .metismenu .collapse {
     display: none
 }
 
 .metismenu .collapse.in {
     display: block
 }
 
 .metismenu .collapsing {
     position: relative;
     height: 0;
     overflow: hidden;
     transition-timing-function: ease;
     transition-duration: .35s;
     transition-property: height, visibility
 }
 
 .metismenu .has-arrow {
     position: relative
 }
 
 .metismenu .has-arrow:after {
     position: absolute;
     content: '';
     width: .5em;
     height: .5em;
     border-width: 1px 0 0 1px;
     border-style: solid;
     border-color: initial;
     right: 1em;
     transform: rotate(-45deg) translate(0, -50%);
     transform-origin: top;
     top: 50%;
     transition: all .3s ease-out
 }
 
 *[dir="rtl"] .metismenu .has-arrow::after {
     right: auto;
     left: 1em;
     transform: rotate(135deg) translate(0, -50%)
 }
 
 .metismenu .active>.has-arrow::after,
 .metismenu .has-arrow[aria-expanded="true"]::after {
     transform: rotate(-135deg) translate(0, -50%)
 }
 
 *[dir="rtl"] .metismenu .active>.has-arrow::after,
 *[dir="rtl"] .metismenu .has-arrow[aria-expanded="true"]::after {
     transform: rotate(225deg) translate(0, -50%)
 }
 
 .badge {
     padding: 4px 8px;
     text-transform: uppercase;
     line-height: 12px;
     border: 1px solid;
     font-weight: 400
 }
 
 .badge-icon {
     padding: 0.4em 0.55em
 }
 
 .badge-icon i {
     font-size: 0.8em
 }
 
 .badge-default {
     background: transparent;
     border-color: #9A9A9A;
     color: #9A9A9A
 }
 
 .badge-primary {
     background: transparent;
     border-color: #49c5b6;
     color: #49c5b6
 }
 
 .badge-info {
     background: transparent;
     border-color: #3C89DA;
     color: #3C89DA
 }
 
 .badge-success {
     background: transparent;
     border-color: #22af46;
     color: #22af46
 }
 
 .badge-warning {
     background: transparent;
     border-color: #f3ad06;
     color: #f3ad06
 }
 
 .badge-danger {
     background: transparent;
     border-color: #de4848;
     color: #de4848
 }
 
 #wrapper:before,
 #wrapper:after {
     height: 100vh;
     width: 5px;
     position: fixed;
     content: '';
     z-index: 999;
     top: 0
 }
 
 #wrapper:before {
     left: 0
 }
 
 #wrapper:after {
     right: 0
 }
 
 @media screen and (max-width: 992px) {
 
     #wrapper:before,
     #wrapper:after {
         width: 2px
     }
 }
 
 #main-content {
     -webkit-transition: all 0.3s ease-in-out;
     -moz-transition: all 0.3s ease-in-out;
     -ms-transition: all 0.3s ease-in-out;
     -o-transition: all 0.3s ease-in-out;
     transition: all 0.3s ease-in-out;
     width: calc(100% - 0px);
     float: right;
     position: relative;
     margin-top: 86px;
     padding: 0 10px
 }
 
 @media screen and (max-width: 1200px) {
     #main-content {
         width: 100%;
         padding: 0
     }
 }
 
 html.fullscreen-bg,
 html.fullscreen-bg body,
 html.fullscreen-bg #wrapper {
     height: 100%
 }
 
 .vertical-align-wrap {
     position: absolute;
     width: 100%;
     height: 100%;
     display: table
 }
 
 .vertical-align-middle {
     display: table-cell;
     vertical-align: middle
 }
 
 .card {
     background: var(--card-color);
     transition: .5s;
     border: 0;
     border-radius: .35rem;
     position: relative;
     width: 100%;
     box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1)
 }
 
 .card .header {
     color: var(--color-800);
     padding: 20px;
     position: relative;
     box-shadow: none
 }
 
 .card .header .header-dropdown {
     position: absolute;
     top: 14px;
     right: 10px;
     list-style: none
 }
 
 .card .header .header-dropdown li a {
     padding: 5px 10px
 }
 
 .card .header .header-dropdown li .dropdown-menu {
     background: var(--card-color);
     border-radius: .35rem;
     overflow: hidden;
     transform: none !important;
     left: auto !important;
     right: 0;
     padding: 10px;
     box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.5);
     border: 0
 }
 
 .card .header .header-dropdown li .dropdown-menu li {
     display: block !important
 }
 
 .card .header .header-dropdown li .dropdown-menu li a {
     padding: 10px;
     color: var(--color-500);
     white-space: nowrap;
     font-size: 14px
 }
 
 .card .header .header-dropdown li .dropdown-menu li a:hover {
     color: var(--color-600)
 }
 
 .card .header .header-dropdown i {
     font-size: 20px;
     color: var(--color-500);
     -webkit-transition: all 0.5s;
     -moz-transition: all 0.5s;
     -ms-transition: all 0.5s;
     -o-transition: all 0.5s;
     transition: all 0.5s
 }
 
 .card .header h2,
 .card .header .h2 {
     font-size: 16px;
     color: var(--color-800);
     position: relative
 }
 
 .card .header h2 small,
 .card .header .h2 small,
 .card .header h2 .small,
 .card .header .h2 .small {
     font-size: 13px;
     color: var(--color-500);
     line-height: 15px
 }
 
 .card .header h2 small a,
 .card .header .h2 small a,
 .card .header h2 .small a,
 .card .header .h2 .small a {
     font-weight: 700;
     color: var(--color-600)
 }
 
 .card .p-15 {
     padding: 15px !important
 }
 
 .card .body {
     color: var(--color-800);
     padding: 20px;
     font-weight: 400
 }
 
 .navbar-fixed-top {
     position: fixed;
     top: 5px;
     left: 0;
     width: 100%;
     background: var(--card-color);
     z-index: 99;
     border-bottom: 1px solid var(--border-color)
 }
 
 @media screen and (max-width: 992px) {
     .navbar-fixed-top {
         top: 2px
     }
 }
 
 .navbar-fixed-top .navbar-btn {
     display: none;
     float: left
 }
 
 .navbar-fixed-top .navbar-btn button {
     font-size: 20px;
     line-height: .7;
     border: none;
     background: none;
     outline: none
 }
 
 @media screen and (max-width: 640px) {
     .navbar-fixed-top .navbar-btn {
         margin-left: 0
     }
 }
 
 @media screen and (max-width: 1200px) {
     .navbar-fixed-top .navbar-btn {
         display: block
     }
 }
 
 .navbar-fixed-top .navbar-brand {
     padding: 15px 0;
     position: relative
 }
 
 @media screen and (max-width: 992px) {
     .navbar-fixed-top .navbar-brand {
         padding: 15px 10px
     }
 }
 
 @media screen and (max-width: 767px) {
     .navbar-fixed-top .navbar-brand {
         margin-right: 0;
         float: left
     }
 }
 
 .navbar-fixed-top .navbar-brand img {
     height: 60px;
     vertical-align: top;
     margin-top: 2px
 }
 
 .navbar-fixed-top .navbar-right {
     width: calc(100% - 300px)
 }
 
 @media screen and (max-width: 1024px) {
     .navbar-fixed-top .navbar-right {
         width: calc(100% - 300px)
     }
 }
 
 @media screen and (max-width: 992px) {
     .navbar-fixed-top .navbar-right {
         width: calc(100% - 300px)
     }
 }
 
 @media screen and (max-width: 768px) {
     .navbar-fixed-top .navbar-right {
         width: calc(100% - 300px)
     }
 }
 
 .navbar-fixed-top .navbar-right #navbar-search {
     float: left;
     margin-top: 6px
 }
 
 .navbar-fixed-top .navbar-right #navbar-search .form-control {
     height: 40px
 }
 
 .navbar-fixed-top .navbar-right #navbar-search i {
     font-size: 12px
 }
 
 @media screen and (max-width: 767px) {
     .navbar-fixed-top .navbar-right #navbar-search {
         display: none
     }
 }
 
 .navbar-nav .icon-menu {
     display: inline-block;
     vertical-align: middle;
     *vertical-align: auto;
     *zoom: 1;
     *display: inline;
     padding: 15px;
     position: relative
 }
 
 .navbar-nav .icon-menu i {
     font-size: 18px;
     color: var(--color-700)
 }
 
 .navbar-nav .icon-menu::after {
     display: none
 }
 
 .navbar-nav .icon-menu .notification-dot {
     -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
     border-radius: 50%;
     width: 8px;
     height: 8px;
     position: absolute;
     top: 12px;
     right: 7px;
     background-color: var(--dark-color)
 }
 
 .navbar-nav .dropdown-menu {
     -moz-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
     -webkit-box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
     box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
     background: #fff;
     top: 80%;
     position: absolute;
     right: 0;
     left: auto;
     border: 1px solid #fff;
     padding: 20px
 }
 
 .navbar-nav ul.notifications {
     background: var(--card-color);
     width: 500px
 }
 
 @media screen and (max-width: 768px) {
     .navbar-nav ul.notifications {
         width: calc(100% - 20px);
         margin: 0 10px
     }
 }
 
 .navbar-nav ul.notifications li {
     border-bottom: 1px solid #1f2125
 }
 
 .navbar-nav ul.notifications li.header {
     color: var(--color-500)
 }
 
 .navbar-nav ul.notifications li.footer a {
     color: #5c8ed4
 }
 
 .navbar-nav ul.notifications li.footer a:hover,
 .navbar-nav ul.notifications li.footer a:focus {
     background: transparent
 }
 
 .navbar-nav ul.notifications li:last-child {
     border-bottom: none
 }
 
 .navbar-nav ul.notifications li i {
     font-size: 24px
 }
 
 .navbar-nav ul.notifications li>a {
     padding: 15px 0;
     color: var(--color-600)
 }
 
 .navbar-nav ul.notifications li>a:hover,
 .navbar-nav ul.notifications li>a:focus {
     background-color: rgba(255, 255, 255, 0.03)
 }
 
 .navbar-nav ul.notifications li .text {
     white-space: nowrap;
     width: 88%;
     text-overflow: ellipsis;
     overflow: hidden
 }
 
 .navbar-nav ul.notifications li .timestamp {
     font-size: 13px;
     color: var(--color-700)
 }
 
 .navbar-nav ul.user-menu {
     width: 250px
 }
 
 @media screen and (max-width: 768px) {
     .navbar-nav ul.user-menu {
         right: 20px
     }
 }
 
 .navbar-nav ul.user-menu>li.menu-heading {
     padding: 25px 20px 5px 0px;
     font-weight: 700
 }
 
 .navbar-nav ul.user-menu>li>a:hover,
 .navbar-nav ul.user-menu>li>a:focus {
     background: none
 }
 
 .navbar-nav ul.user-menu.menu-icon {
     color: #fff
 }
 
 .navbar-nav ul.user-menu.menu-icon>li>a {
     color: #fff
 }
 
 @media screen and (max-width: 767px) {
     .navbar-nav {
         margin-right: 0
     }
 
     .navbar-nav>li {
         display: table-cell;
         position: inherit;
         text-align: center
     }
 
     .navbar-nav .dropdown-menu {
         max-height: 400px;
         overflow: auto
     }
 
     .navbar-nav .dropdown-menu>li>a {
         white-space: normal
     }
 
     .navbar-nav .open .dropdown-menu {
         -moz-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
         -webkit-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
         box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
         position: absolute;
         width: 100%;
         background: #fff
     }
 
     .navbar-nav .open .dropdown-menu>li>a {
         padding: 3px 20px
     }
 
     .navbar-nav .open .dropdown-menu.notifications>li>a {
         padding: 12px 15px
     }
 }
 
 @media screen and (max-width: 768px) {
 
     .navbar>.container .navbar-brand,
     .navbar>.container-fluid .navbar-brand,
     .navbar>.container-sm .navbar-brand,
     .navbar>.container-md .navbar-brand,
     .navbar>.container-lg .navbar-brand,
     .navbar>.container-xl .navbar-brand,
     .navbar>.container-xxl .navbar-brand {
         margin-left: 0
     }
 }
 
 #navbar-menu {
     float: right
 }
 
 .nav-tabs>li.active>a,
 .nav-tabs>li.active>a:hover,
 .nav-tabs>li.active>a:focus {
     background-color: #f4f7f6;
     color: var(--text-color)
 }
 
 .nav-tabs>li>a {
     color: inherit;
     font-weight: 600
 }
 
 .nav-tabs>li>a:hover,
 .nav-tabs>li>a:focus {
     background-color: #f5f5f5
 }
 
 .tab-content {
     padding: 15px
 }
 
 .nav-tabs-colored>li.active>a,
 .nav-tabs-colored>li.active>a:hover,
 .nav-tabs-colored>li.active>a:focus {
     background-color: #f5f5f5;
     color: var(--text-color)
 }
 
 .tab-content-colored {
     background-color: #f5f5f5
 }
 
 .nav .open>a,
 .nav .open>a:hover,
 .nav .open>a:focus {
     border-color: #ddd
 }
 
 .dropdown-menu>li>a,
 .dropdown-menu>li>a:hover,
 .dropdown-menu>li>a:focus {
     color: var(--text-color)
 }
 
 .dropdown-menu>.active>a,
 .dropdown-menu>.active>a:hover,
 .dropdown-menu>.active>a:focus {
     background-color: #5c8ed4
 }
 
 .nav-pills>li.active>a,
 .nav-pills>li.active>a:hover,
 .nav-pills>li.active>a:focus {
     background-color: #efefef;
     color: var(--text-color)
 }
 
 .nav-pills>li>a {
     color: inherit
 }
 
 .btn-toggle-fullwidth {
     z-index: 99;
     margin-top: -6px;
     padding-left: 0 !important
 }
 
 .btn-toggle-fullwidth i {
     margin: 0 !important
 }
 
 @media screen and (max-width: 1024px) {
     .btn-toggle-fullwidth {
         display: none
     }
 }
 
 .nav-tabs-new>li>a {
     color: var(--color-600);
     border: 1px solid var(--border-color);
     border-radius: 30px;
     padding: 7px 23px;
     background-color: transparent
 }
 
 .nav-tabs-new>li>a:hover,
 .nav-tabs-new>li>a:focus {
     background-color: #49c5b6;
     color: #fff
 }
 
 .nav-tabs-new>li>a.active {
     border: 1px solid #49c5b6 !important;
     background: #49c5b6;
     color: #fff
 }
 
 .nav-tabs-new2 {
     border-bottom: 1px solid var(--border-color)
 }
 
 .nav-tabs-new2>li>a {
     color: var(--color-600);
     padding: 7px 20px;
     background-color: transparent;
     border-bottom: transparent;
     font-size: 15px
 }
 
 .nav-tabs-new2>li>a:hover,
 .nav-tabs-new2>li>a:focus {
     color: #49c5b6;
     border-bottom: 2px solid #49c5b6 !important
 }
 
 .nav-tabs-new2>li>a.active {
     border-bottom: 2px solid #49c5b6 !important;
     background-color: transparent;
     color: #49c5b6
 }
 
 .dropdown-lg .dropdown-menu {
     top: 40px !important;
     right: 0 !important;
     width: 350px;
     left: auto !important;
     transform: inherit !important;
     padding: 20px;
     box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175)
 }
 
 #left-sidebar {
     -webkit-transition: all 0.3s ease-in-out;
     -moz-transition: all 0.3s ease-in-out;
     -ms-transition: all 0.3s ease-in-out;
     -o-transition: all 0.3s ease-in-out;
     transition: all 0.3s ease-in-out;
     width: 250px;
     height: calc(100vh - 61px);
     float: left;
     margin-top: 61px;
     position: fixed;
     left: 5px;
     background-color: var(--body-color);
     z-index: 11;
     overflow-y: scroll;
     scrollbar-width: none;
     -ms-overflow-style: none
 }
 
 #left-sidebar::-webkit-scrollbar {
     width: 5px
 }
 
 @media screen and (max-width: 1200px) {
     #left-sidebar {
         -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.2);
         -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.2);
         box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.2);
         left: -250px
     }
 }
 
 #left-sidebar .nav-tabs .nav-link.active {
     background-color: transparent;
     border-color: #dee2e6 #dee2e6 #f4f7f6
 }
 
 .layout-fullwidth #wrapper #left-sidebar {
     left: -250px
 }
 
 .layout-fullwidth #wrapper #left-sidebar.ps {
     overflow: visible !important
 }
 
 .layout-fullwidth #wrapper #main-content {
     width: 100%
 }
 
 .offcanvas-active #left-sidebar {
     left: 5px
 }
 
 .user-account {
     margin: 20px
 }
 
 .user-account .user-photo {
     width: 50px;
     vertical-align: top;
     border: 2px solid #dfdede
 }
 
 .user-account .user-name {
     color: inherit
 }
 
 .user-account .user-name:hover,
 .user-account .user-name:focus {
     text-decoration: none
 }
 
 .user-account .dropdown .dropdown-menu {
     transform: none !important;
     border: none;
     box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.5);
     padding: 15px;
     background: var(--dark-color);
     border-radius: .35rem
 }
 
 .user-account .dropdown .dropdown-menu li.divider {
     border-bottom: 1px solid var(--color-800);
     margin: 10px 0
 }
 
 .user-account .dropdown .dropdown-menu a {
     padding: 10px;
     font-size: 14px;
     color: var(--color-500)
 }
 
 .user-account .dropdown .dropdown-menu a:hover {
     color: #fff
 }
 
 .user-account .dropdown .dropdown-menu a:hover i {
     color: #fff
 }
 
 .user-account .dropdown .dropdown-menu a i {
     font-size: 14px
 }
 
 .sidebar-nav ul {
     list-style: none
 }
 
 .sidebar-nav .metismenu {
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-orient: vertical;
     -webkit-box-direction: normal;
     -ms-flex-direction: column;
     flex-direction: column
 }
 
 .sidebar-nav .metismenu>li {
     -webkit-box-flex: 1;
     -ms-flex: 1 1 0%;
     flex: 1 1 0%;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-orient: vertical;
     -webkit-box-direction: normal;
     -ms-flex-direction: column;
     flex-direction: column;
     position: relative
 }
 
 .sidebar-nav .metismenu>li a {
     border-left: 5px solid transparent
 }
 
 .sidebar-nav .metismenu>li.active>a {
     background-color: var(--color-300)
 }
 
 .sidebar-nav .metismenu>li .active a {
     font-weight: 700
 }
 
 .sidebar-nav .metismenu>li i {
     position: relative;
     top: 2px;
     font-size: 17px
 }
 
 .sidebar-nav .metismenu a {
     -webkit-transition: all 0.3s ease-out;
     -moz-transition: all 0.3s ease-out;
     -ms-transition: all 0.3s ease-out;
     -o-transition: all 0.3s ease-out;
     transition: all 0.3s ease-out;
     position: relative;
     padding: 13px 15px;
     outline-width: 0;
     color: var(--color-600);
     font-size: 15px
 }
 
 .sidebar-nav .metismenu ul a {
     padding: 10px 15px 10px 57px;
     position: relative;
     color: var(--color-600);
     font-size: 14px
 }
 
 .sidebar-nav .metismenu ul a::before {
     content: '--';
     position: absolute;
     left: 19px
 }
 
 .sidebar-nav .metismenu ul.collapse a:hover,
 .sidebar-nav .metismenu ul.collapse a:focus {
     text-decoration: none
 }
 
 .sidebar-nav .metismenu a:hover,
 .sidebar-nav .metismenu a:focus,
 .sidebar-nav .metismenu a:active {
     text-decoration: none;
     background: var(--color-300);
     color: var(--primary-color)
 }
 
 .sidebar-nav .metismenu .has-arrow::after {
     color: #b5b5b5
 }
 
 ul.choose-skin li {
     margin-bottom: 4px
 }
 
 ul.choose-skin li div {
     height: 22px;
     width: 22px;
     -webkit-border-radius: 22px;
     -moz-border-radius: 22px;
     border-radius: 22px;
     position: relative
 }
 
 ul.choose-skin li div.purple {
     background: #6f42c1
 }
 
 ul.choose-skin li div.blue {
     background: #0d6efd
 }
 
 ul.choose-skin li div.cyan {
     background: #0dcaf0
 }
 
 ul.choose-skin li div.green {
     background: #198754
 }
 
 ul.choose-skin li div.orange {
     background: #fd7e14
 }
 
 ul.choose-skin li div.blush {
     background: #ED5782
 }
 
 ul.choose-skin li.active div::before {
     position: absolute;
     color: #fff;
     font-size: 12px;
     top: 2px;
     left: 5px;
     content: '\f00c';
     font-family: 'FontAwesome'
 }
 
 ul.choose-skin li span {
     line-height: 22px;
     vertical-align: top
 }
 
 .question li {
     padding: 3px 0
 }
 
 .question li a {
     position: relative
 }
 
 .question li a:before {
     content: '--';
     left: 0;
     position: absolute
 }
 
 .question .help-search-form i {
     font-size: 12px
 }
 
 label {
     font-weight: 600
 }
 
 .search-form {
     position: relative
 }
 
 .search-form .btn {
     -moz-box-shadow: none;
     -webkit-box-shadow: none;
     box-shadow: none;
     position: absolute;
     top: 10px;
     right: 15px;
     border: none;
     color: var(--color-500);
     background-color: transparent
 }
 
 .search-form .btn:hover,
 .search-form .btn:focus {
     background-color: inherit;
     border-left: none;
     border-color: inherit
 }
 
 .fancy-checkbox {
     margin-right: 10px
 }
 
 .fancy-checkbox,
 .fancy-checkbox label {
     font-weight: normal
 }
 
 .fancy-checkbox input[type="checkbox"] {
     display: none
 }
 
 .fancy-checkbox input[type="checkbox"]+span {
     display: inline-block;
     vertical-align: middle;
     *vertical-align: auto;
     *zoom: 1;
     *display: inline;
     cursor: pointer;
     position: relative
 }
 
 .fancy-checkbox input[type="checkbox"]+span:before {
     display: inline-block;
     vertical-align: middle;
     *vertical-align: auto;
     *zoom: 1;
     *display: inline;
     position: relative;
     bottom: 1px;
     width: 24px;
     height: 24px;
     margin-right: 10px;
     content: "";
     border: 1px solid #ccc;
     border-radius: 3px
 }
 
 .fancy-checkbox input[type="checkbox"]:checked+span:before {
     font-family: FontAwesome;
     content: '\f00c';
     font-size: 10px;
     color: #fff;
     text-align: center;
     line-height: 21px
 }
 
 .fancy-checkbox.custom-color-green input[type="checkbox"]:checked+span:before {
     color: #22af46;
     background-color: #fff
 }
 
 .fancy-checkbox.custom-bgcolor-green input[type="checkbox"]:checked+span:before {
     color: #fff;
     background-color: #22af46;
     border-color: #1b8d38
 }
 
 .fancy-radio {
     margin-right: 10px
 }
 
 .fancy-radio,
 .fancy-radio label {
     font-weight: normal
 }
 
 .fancy-radio input[type="radio"] {
     display: none
 }
 
 .fancy-radio input[type="radio"]+span {
     display: block;
     cursor: pointer;
     position: relative
 }
 
 .fancy-radio input[type="radio"]+span i {
     display: inline-block;
     vertical-align: middle;
     *vertical-align: auto;
     *zoom: 1;
     *display: inline;
     -webkit-border-radius: 8px;
     -moz-border-radius: 8px;
     border-radius: 8px;
     position: relative;
     bottom: 1px;
     content: "";
     border: 1px solid #ccc;
     width: 18px;
     height: 18px;
     margin-right: 5px
 }
 
 .fancy-radio input[type="radio"]:checked+span i:after {
     -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
     border-radius: 50%;
     display: block;
     position: relative;
     top: 3px;
     left: 3px;
     content: '';
     width: 10px;
     height: 10px;
     background-color: #7b848c
 }
 
 .fancy-radio.custom-color-green input[type="radio"]:checked+span i:after {
     background-color: #53D76A
 }
 
 .fancy-radio.custom-bgcolor-green input[type="radio"]:checked+span i {
     background-color: #53D76A
 }
 
 .fancy-radio.custom-bgcolor-green input[type="radio"]:checked+span i:after {
     background-color: #fff
 }
 
 .input-group-addon .fancy-radio,
 .input-group-addon .fancy-checkbox {
     margin: 0;
     position: relative;
     top: 1px
 }
 
 .input-group-addon .fancy-radio input[type="radio"]+span i,
 .input-group-addon .fancy-checkbox input[type="checkbox"]+span {
     margin: 0
 }
 
 .input-group-addon .fancy-radio input[type="radio"]+span i:before,
 .input-group-addon .fancy-checkbox input[type="checkbox"]+span:before {
     margin: 0
 }
 
 .form-control {
     -moz-box-shadow: none;
     -webkit-box-shadow: none;
     box-shadow: none;
     background-color: var(--card-color);
     font-size: 14px
 }
 
 .form-control:focus {
     -moz-box-shadow: none;
     -webkit-box-shadow: none;
     box-shadow: none;
     outline: none;
     border-color: #c1c0c0
 }
 
 .input-group-text {
     background-color: var(--color-200);
     border-color: var(--border-color)
 }
 
 .input-group-text i {
     color: var(--primary-color)
 }
 
 .help-block {
     font-size: 13px;
     color: #929292
 }
 
 .multiselect-custom+.btn-group ul.multiselect-container>li>a {
     padding: 5px 15px;
     display: block;
     outline: none
 }
 
 .multiselect-custom+.btn-group ul.multiselect-container>li>a label.checkbox {
     padding: 0;
     font-size: 14px
 }
 
 .multiselect-custom+.btn-group ul.multiselect-container>li>a label.checkbox:before {
     display: inline-block;
     vertical-align: middle;
     *vertical-align: auto;
     *zoom: 1;
     *display: inline;
     position: relative;
     bottom: 1px;
     content: "";
     width: 16px;
     height: 16px;
     margin-right: 10px;
     border: 1px solid #ccc;
     background-color: #fafafa
 }
 
 .multiselect-custom+.btn-group ul.multiselect-container>li>a label.checkbox input[type="checkbox"] {
     display: none
 }
 
 .multiselect-custom+.btn-group ul.multiselect-container>li.active>a label.checkbox:before {
     font-family: FontAwesome;
     content: '\f00c';
     font-size: 12px;
     color: #99A1A7;
     text-align: center;
     line-height: 15px;
     background-color: var(--color-200)
 }
 
 .multiselect-custom+.btn-group ul.multiselect-container>li.active .checkbox {
     color: #fff
 }
 
 .multiselect-custom+.btn-group ul.multiselect-container>li:hover {
     background-color: var(--color-100)
 }
 
 .multiselect-custom+.btn-group ul.multiselect-container>li>a {
     padding: 5px 15px;
     display: block;
     outline: none
 }
 
 .multiselect-custom+.btn-group ul.multiselect-container>li>a label.radio {
     padding: 0
 }
 
 .multiselect-custom+.btn-group ul.multiselect-container>li>a label.radio:before {
     display: inline-block;
     vertical-align: middle;
     *vertical-align: auto;
     *zoom: 1;
     *display: inline;
     -webkit-border-radius: 8px;
     -moz-border-radius: 8px;
     border-radius: 8px;
     position: relative;
     bottom: 1px;
     content: "";
     width: 16px;
     height: 16px;
     margin-right: 10px;
     border: 1px solid #ccc;
     background-color: #fafafa
 }
 
 .multiselect-custom+.btn-group ul.multiselect-container>li>a label.radio input[type="radio"] {
     display: none
 }
 
 .multiselect-custom+.btn-group ul.multiselect-container>li.active>a label.radio:before {
     font-family: FontAwesome;
     content: '\f111';
     color: #99a1a7;
     font-size: 8px;
     text-align: center;
     line-height: 15px
 }
 
 .multiselect-custom+.btn-group ul.multiselect-container>li.active .radio {
     color: #fff
 }
 
 .multiselect-custom+.btn-group ul.multiselect-container>li:hover {
     background-color: var(--color-100)
 }
 
 .multiselect-search {
     -moz-border-radius-topright: 2px !important;
     -webkit-border-top-right-radius: 2px !important;
     border-top-right-radius: 2px !important;
     -moz-border-radius-bottomright: 2px !important;
     -webkit-border-bottom-right-radius: 2px !important;
     border-bottom-right-radius: 2px !important
 }
 
 .btn.multiselect-clear-filter {
     -moz-box-shadow: none;
     -webkit-box-shadow: none;
     box-shadow: none;
     position: absolute;
     top: 2px;
     right: 0;
     padding-left: 0;
     padding-right: 0;
     border: none;
     background: transparent;
     z-index: 9 !important;
     outline: none
 }
 
 .btn.multiselect-clear-filter:hover,
 .btn.multiselect-clear-filter:focus {
     background: transparent;
     outline: none
 }
 
 .datepicker table tr td.active.active,
 .datepicker table tr td.active.highlighted.active,
 .datepicker table tr td.active.highlighted:active,
 .datepicker table tr td.active.active:active {
     background-color: #5c8ed4;
     border-color: #3c78cb
 }
 
 .parsley-errors-list {
     margin-top: 8px
 }
 
 .parsley-errors-list li {
     color: #de4848;
     font-size: 0.9em;
     margin-top: 3px
 }
 
 input.parsley-error,
 select.parsley-error,
 textarea.parsley-error {
     background-color: #fbf5f5;
     border-color: #efd8d8
 }
 
 .form-control {
     border-color: var(--border-color)
 }
 
 .form-control.parsley-error:focus {
     border-color: #e1b3b3
 }
 
 .form-control.parsley-success:focus {
     border-color: #afd99d
 }
 
 .md-editor>textarea {
     padding: 10px
 }
 
 .dropify-wrapper {
     -webkit-border-radius: 3px;
     -moz-border-radius: 3px;
     border-radius: 3px;
     background-color: #f9f9f9;
     color: #b9b9b9;
     border: 1px solid #e4e4e4
 }
 
 .dropify-wrapper .dropify-preview {
     background-color: #f9f9f9
 }
 
 select.form-control:not([size]):not([multiple]) {
     height: 36px
 }
 
 .auth-main::before {
     content: '';
     position: absolute;
     left: 0;
     top: 0;
     width: 400px;
     height: 100%;
     z-index: -1;
     background: #191f28
 }
 
 @media screen and (max-width: 640px) {
     .auth-main::before {
         width: 100%
     }
 }
 
 .auth-main:after {
     content: '';
     position: absolute;
     right: 0;
     top: 0;
     width: 100%;
     height: 100%;
     z-index: -2;
     background: url(../../assets/images/auth_bg.jpg) no-repeat top left fixed
 }
 
 .auth-main .card {
     padding: 10px
 }
 
 .auth-box {
     width: 380px;
     height: auto;
     margin-left: 130px;
     margin-top: -5%;
 }
 
 .auth-box .top img {
     width: 230px
 }
 
 .auth-box .form-auth-small .element-left {
     float: left
 }
 
 .auth-box .form-auth-small .element-right {
     float: right
 }
 
 .auth-box .lead {
     font-size: 18px
 }
 
 .auth-box .helper-text {
     color: #9A9A9A
 }
 
 .auth-box .btn-signin-social {
     padding: 10px 22px;
     width: 100%;
     background-color: #fff;
     border-color: var(--color-200)
 }
 
 .auth-box .btn-signin-social:hover,
 .auth-box .btn-signin-social:focus {
     background-color: var(--color-200)
 }
 
 .auth-box .btn-signin-social i {
     font-size: 16px;
     margin: 5px
 }
 
 @media screen and (max-width: 992px) {
     .auth-box {
         width: 80%;
         margin: 0 auto
     }
 }
 
 @media screen and (max-width: 640px) {
     .auth-box {
         width: 90%
     }
 }
 
 .btn {
     font-size: 14px
 }
 
 .metric-inline {
     -webkit-border-radius: 3px;
     -moz-border-radius: 3px;
     border-radius: 3px;
     padding: 20px;
     font-size: 36px;
     background-color: #f5f5f5
 }
 
 .metric-inline i {
     margin-bottom: 12px;
     color: #9A9A9A
 }
 
 .metric-inline span {
     font-size: 20px
 }
 
 .separator-linethrough {
     position: relative;
     margin: 30px 0
 }
 
 .separator-linethrough span {
     display: inline-block;
     vertical-align: middle;
     *vertical-align: auto;
     *zoom: 1;
     *display: inline;
     padding: 0 10px;
     position: inherit;
     font-size: 18px;
     background-color: #fff;
     z-index: 9
 }
 
 .separator-linethrough:after {
     position: absolute;
     top: 50%;
     width: 100%;
     content: '';
     border-top: 1px solid #f0f0f0;
     z-index: 0
 }
 
 .block-header h2,
 .block-header .h2 {
     font-size: 22px
 }
 
 .block-header .breadcrumb {
     background: transparent
 }
 
 #toast-container>div {
     opacity: 1;
     filter: alpha(opacity=100)
 }
 
 #toast-container>div,
 #toast-container>div:hover {
     -moz-box-shadow: none;
     -webkit-box-shadow: none;
     box-shadow: none
 }
 
 #toast-container .toast-close-button {
     opacity: .3;
     filter: alpha(opacity=30);
     top: -11px;
     text-shadow: none;
     color: #fff
 }
 
 #toast-container .toast-close-button:hover,
 #toast-container .toast-close-button:focus {
     opacity: .5;
     filter: alpha(opacity=50)
 }
 
 #toast-container .toast-info {
     background-color: #5c8ed4
 }
 
 #toast-container .toast-info .toast-close-button {
     color: #2a5ba0
 }
 
 #toast-container .toast-success {
     background-color: #22af46
 }
 
 #toast-container .toast-success .toast-close-button {
     color: #115a24
 }
 
 #toast-container .toast-warning {
     background-color: #f3ad06
 }
 
 #toast-container .toast-warning .toast-close-button {
     color: #8f6604
 }
 
 #toast-container .toast-error {
     background-color: #de4848
 }
 
 #toast-container .toast-error .toast-close-button {
     color: #8d1919
 }
 
 #toast-container .toast a {
     text-decoration: underline
 }
 
 #toast-container .toast a:hover,
 #toast-container .toast a:focus {
     text-decoration: none;
     color: #fff
 }
 
 #toast-container .toast .toast-message {
     font-size: 14px;
     line-height: 1.4
 }
 
 .select2-container .select2-choice {
     border: none;
     background-image: none;
     box-shadow: none;
     padding: 0;
     margin: 0
 }
 
 .select2-container .select2-choice:focus,
 .select2-container .select2-choice:hover,
 .select2-container .select2-choice:active {
     box-shadow: none !important;
     border: 0 !important
 }
 
 .select2-container .select2-choice .select2-arrow {
     border-left: none;
     background-image: none;
     background: transparent
 }
 
 .select2-container.select2-dropdown-open .select2-choice {
     background: transparent;
     box-shadow: none !important
 }
 
 .select2-drop {
     border-radius: 0;
     border: 0 !important
 }
 
 .select2-container-multi .select2-choices {
     border: 0 !important;
     padding: 0;
     margin: 0;
     background-image: none;
     box-shadow: none !important
 }
 
 .select2-container-multi .select2-choices:focus,
 .select2-container-multi .select2-choices:hover,
 .select2-container-multi .select2-choices:active {
     box-shadow: none !important;
     border: none;
     border: 0 !important
 }
 
 .select2-container-multi .select2-choices .select2-search-field input {
     padding: 0
 }
 
 .accordion .card-header .btn {
     padding: 10px 20px;
     width: 100%;
     font-size: 16px
 }
 
 .progress {
     -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
     border-radius: 2px;
     -moz-box-shadow: none;
     -webkit-box-shadow: none;
     box-shadow: none;
     background-color: #f1f1f1
 }
 
 .progress.wide {
     width: 60px
 }
 
 .progress .progress-bar {
     -webkit-transition: width 3s ease;
     -moz-transition: width 3s ease;
     -ms-transition: width 3s ease;
     -o-transition: width 3s ease;
     transition: width 3s ease;
     -moz-box-shadow: none;
     -webkit-box-shadow: none;
     box-shadow: none
 }
 
 .progress.progress-lg {
     height: 28px
 }
 
 .progress.progress-sm {
     height: 12px
 }
 
 .progress.progress-xs {
     height: 5px
 }
 
 .progress.progress-sm .progress-bar,
 .progress.progress-xs .progress-bar {
     text-indent: -9999px
 }
 
 .progress-bar {
     background-color: #5c8ed4
 }
 
 .progress-bar-success {
     background-color: #22af46
 }
 
 .progress-bar-warning {
     background-color: #f3ad06
 }
 
 .progress-bar-danger {
     background-color: #de4848
 }
 
 .progress-bar-info {
     background-color: #3C89DA
 }
 
 .progress-transparent.custom-color-blue {
     background-color: rgba(14, 155, 226, 0.2)
 }
 
 .progress-transparent.custom-color-blue .progress-bar {
     background-color: #0E9BE2
 }
 
 .progress-transparent.custom-color-green {
     background-color: rgba(124, 172, 37, 0.2)
 }
 
 .progress-transparent.custom-color-green .progress-bar {
     background-color: #7CAC25
 }
 
 .progress-transparent.custom-color-orange {
     background-color: rgba(255, 68, 2, 0.2)
 }
 
 .progress-transparent.custom-color-orange .progress-bar {
     background-color: #FF4402
 }
 
 .progress-transparent.custom-color-purple {
     background-color: rgba(171, 125, 246, 0.2)
 }
 
 .progress-transparent.custom-color-purple .progress-bar {
     background-color: #AB7DF6
 }
 
 .progress-transparent.custom-color-yellow {
     background-color: rgba(243, 187, 35, 0.2)
 }
 
 .progress-transparent.custom-color-yellow .progress-bar {
     background-color: #F3BB23
 }
 
 .progress-transparent.custom-color-lightseagreen {
     background-color: rgba(32, 178, 170, 0.2)
 }
 
 .progress-transparent.custom-color-lightseagreen .progress-bar {
     background-color: #20b2aa
 }
 
 .tooltip-inner {
     background-color: #5a5a5a
 }
 
 .tooltip.in {
     opacity: 1;
     filter: alpha(opacity=100)
 }
 
 .tooltip.left .tooltip-arrow {
     border-left-color: #5a5a5a
 }
 
 .tooltip.right .tooltip-arrow {
     border-right-color: #5a5a5a
 }
 
 .tooltip.top .tooltip-arrow {
     border-top-color: #5a5a5a
 }
 
 .tooltip.bottom .tooltip-arrow {
     border-bottom-color: #5a5a5a
 }
 
 .popover {
     -moz-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
     -webkit-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
     box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
     font-family: "Ubuntu", sans-serif;
     border-color: #ededed
 }
 
 .popover-title {
     font-weight: 600;
     background-color: #f5f5f5;
     color: var(--text-color)
 }
 
 .panel-group .panel .panel-heading {
     padding: 0
 }
 
 .panel-group .panel .panel-title {
     font-size: 14px;
     font-family: "Ubuntu", sans-serif
 }
 
 .panel-group .panel .panel-title>a {
     display: block;
     padding: 10px 15px
 }
 
 .panel-group .panel .panel-title>a:hover,
 .panel-group .panel .panel-title>a:focus {
     color: inherit
 }
 
 .panel-group .panel .panel-title>a i {
     font-size: 18px;
     margin-right: 5px
 }
 
 .panel-group .panel .panel-title>a i.icon-collapsed {
     display: none
 }
 
 .panel-group .panel .panel-title>a i.right {
     float: right;
     margin-right: 0
 }
 
 .panel-group .panel .panel-title>a.collapsed .icon-expanded {
     display: none
 }
 
 .panel-group .panel .panel-title>a.collapsed .icon-collapsed {
     display: inline-block;
     vertical-align: middle;
     *vertical-align: auto;
     *zoom: 1;
     *display: inline;
     vertical-align: baseline
 }
 
 .pagination>li>a,
 .pagination>li>span {
     color: #5c8ed4
 }
 
 .pagination>li>a:hover,
 .pagination>li>span:hover,
 .pagination>li>a:focus,
 .pagination>li>span:focus {
     background-color: #7ca4dd;
     border-color: #5c8ed4;
     color: var(--white-color)
 }
 
 .pagination>li:first-child>a,
 .pagination>li:first-child>span {
     -moz-border-radius-topleft: 2px;
     -webkit-border-top-left-radius: 2px;
     border-top-left-radius: 2px;
     -moz-border-radius-bottomleft: 2px;
     -webkit-border-bottom-left-radius: 2px;
     border-bottom-left-radius: 2px
 }
 
 .pagination>li:last-child>a,
 .pagination>li:last-child>span {
     -moz-border-radius-topright: 2px;
     -webkit-border-top-right-radius: 2px;
     border-top-right-radius: 2px;
     -moz-border-radius-bottomright: 2px;
     -webkit-border-bottom-right-radius: 2px;
     border-bottom-right-radius: 2px
 }
 
 .pagination>.active>a:hover,
 .pagination>.active>span:hover,
 .pagination>.active>a:focus,
 .pagination>.active>span:focus {
     background-color: #7ca4dd;
     border-color: #5c8ed4;
     color: var(--white-color)
 }
 
 .pagination>.active>a,
 .pagination>.active>span {
     background-color: #5c8ed4;
     border-color: #4880cf
 }
 
 .pagination.borderless>li>a,
 .pagination.borderless>li>span {
     -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
     border-radius: 2px;
     border-color: transparent;
     margin: 0 1px
 }
 
 .pagination.borderless>li:first-child>a,
 .pagination.borderless>li:first-child>span,
 .pagination.borderless>li:last-child>a,
 .pagination.borderless>li:last-child>span {
     -webkit-border-radius: 2px;
     -moz-border-radius: 2px;
     border-radius: 2px
 }
 
 .pagination.borderless>li.active>a,
 .pagination.borderless>li.active>span {
     color: var(--white-color);
     background-color: #5c8ed4
 }
 
 .pagination.borderless>li.active>a:hover,
 .pagination.borderless>li.active>a:focus,
 .pagination.borderless>li.active>span:hover,
 .pagination.borderless>li.active>span:focus {
     color: var(--white-color);
     background-color: #5c8ed4
 }
 
 .pager li>a {
     border-color: #5c8ed4
 }
 
 .pager li>a:hover,
 .pager li>a:focus {
     background-color: #5c8ed4;
     border-color: #5086d1;
     color: var(--white-color)
 }
 
 .pager .disabled>a,
 .pager .disabled>a:hover,
 .pager .disabled>a:focus,
 .pager .disabled>span {
     border-color: #ddd
 }
 
 .bd-example-border-utils [class^=border] {
     display: inline-block;
     width: 5rem;
     height: 5rem;
     margin: .25rem;
     background-color: #f5f5f5
 }
 
 .blockquote {
     border-left: none;
     border: 1px solid var(--color-600);
     padding: 20px;
     font-size: 1.2em;
     line-height: 1.8
 }
 
 .blockquote.blockquote-primary {
     border-color: #49c5b6
 }
 
 .blockquote.blockquote-primary small,
 .blockquote.blockquote-primary .small {
     color: #49c5b6
 }
 
 .blockquote.blockquote-info {
     border-color: #3C89DA
 }
 
 .blockquote.blockquote-info small,
 .blockquote.blockquote-info .small {
     color: #3C89DA
 }
 
 .blockquote.blockquote-danger {
     border-color: #de4848
 }
 
 .blockquote.blockquote-danger small,
 .blockquote.blockquote-danger .small {
     color: #de4848
 }
 
 .blockquote small,
 .blockquote .small {
     font-size: .93em;
     text-transform: uppercase
 }
 
 #jqstooltip {
     background-color: #fff;
     border-color: #ddd;
     color: var(--text-color)
 }
 
 #jqstooltip .jqsfield {
     color: var(--text-color)
 }
 
 .ct-chart {
     position: relative
 }
 
 .ct-series .ct-line {
     stroke-width: 3px
 }
 
 .ct-series .ct-bar {
     stroke-width: 15px
 }
 
 .ct-series .ct-point {
     stroke-width: 10px;
     stroke-linecap: circle
 }
 
 .ct-series-a .ct-line,
 .ct-series-a .ct-bar,
 .ct-series-a .ct-point {
     stroke: #6ebdd1
 }
 
 .ct-series-a .ct-area,
 .ct-series-a .ct-slice-donut-solid,
 .ct-series-a .ct-slice-pie,
 .ct-series-a .ct-bar {
     fill: #6ebdd1
 }
 
 .ct-series-b .ct-line,
 .ct-series-b .ct-bar,
 .ct-series-b .ct-point {
     stroke: #f9ab6c
 }
 
 .ct-series-b .ct-area,
 .ct-series-b .ct-slice-donut-solid,
 .ct-series-b .ct-slice-pie,
 .ct-series-b .ct-bar {
     fill: #f9ab6c
 }
 
 .ct-series-c .ct-line,
 .ct-series-c .ct-bar,
 .ct-series-c .ct-point {
     stroke: #afc979
 }
 
 .ct-series-c .ct-area,
 .ct-series-c .ct-slice-donut-solid,
 .ct-series-c .ct-slice-pie,
 .ct-series-c .ct-bar {
     fill: #afc979
 }
 
 .ct-series-d .ct-line,
 .ct-series-d .ct-bar,
 .ct-series-d .ct-point {
     stroke: #AB7DF6
 }
 
 .ct-series-d .ct-area,
 .ct-series-d .ct-slice-donut-solid,
 .ct-series-d .ct-slice-pie,
 .ct-series-d .ct-bar {
     fill: #AB7DF6
 }
 
 .ct-series-e .ct-line,
 .ct-series-e .ct-bar,
 .ct-series-e .ct-point {
     stroke: #5cc196
 }
 
 .ct-series-e .ct-area,
 .ct-series-e .ct-slice-donut-solid,
 .ct-series-e .ct-slice-pie,
 .ct-series-e .ct-bar {
     fill: #5cc196
 }
 
 .ct-series-f .ct-line,
 .ct-series-f .ct-bar,
 .ct-series-f .ct-point {
     stroke: #d17905
 }
 
 .ct-series-f .ct-area,
 .ct-series-f .ct-slice-donut-solid,
 .ct-series-f .ct-slice-pie,
 .ct-series-f .ct-bar {
     fill: #d17905
 }
 
 .ct-series-g .ct-line,
 .ct-series-g .ct-bar,
 .ct-series-g .ct-point {
     stroke: #453d3f
 }
 
 .ct-series-g .ct-area,
 .ct-series-g .ct-slice-donut-solid,
 .ct-series-g .ct-slice-pie,
 .ct-series-g .ct-bar {
     fill: #453d3f
 }
 
 .ct-series-h .ct-line,
 .ct-series-h .ct-bar,
 .ct-series-h .ct-point {
     stroke: #59922b
 }
 
 .ct-series-h .ct-area,
 .ct-series-h .ct-slice-donut-solid,
 .ct-series-h .ct-slice-pie,
 .ct-series-h .ct-bar {
     fill: #59922b
 }
 
 .ct-series-i .ct-line,
 .ct-series-i .ct-bar,
 .ct-series-i .ct-point {
     stroke: #0544d3
 }
 
 .ct-series-i .ct-area,
 .ct-series-i .ct-slice-donut-solid,
 .ct-series-i .ct-slice-pie,
 .ct-series-i .ct-bar {
     fill: #0544d3
 }
 
 .ct-series-j .ct-line,
 .ct-series-j .ct-bar,
 .ct-series-j .ct-point {
     stroke: #6b0392
 }
 
 .ct-series-j .ct-area,
 .ct-series-j .ct-slice-donut-solid,
 .ct-series-j .ct-slice-pie,
 .ct-series-j .ct-bar {
     fill: #6b0392
 }
 
 .ct-series-k .ct-line,
 .ct-series-k .ct-bar,
 .ct-series-k .ct-point {
     stroke: #f05b4f
 }
 
 .ct-series-k .ct-area,
 .ct-series-k .ct-slice-donut-solid,
 .ct-series-k .ct-slice-pie,
 .ct-series-k .ct-bar {
     fill: #f05b4f
 }
 
 .ct-series-l .ct-line,
 .ct-series-l .ct-bar,
 .ct-series-l .ct-point {
     stroke: #dda458
 }
 
 .ct-series-l .ct-area,
 .ct-series-l .ct-slice-donut-solid,
 .ct-series-l .ct-slice-pie,
 .ct-series-l .ct-bar {
     fill: #dda458
 }
 
 .ct-series-m .ct-line,
 .ct-series-m .ct-bar,
 .ct-series-m .ct-point {
     stroke: #eacf7d
 }
 
 .ct-series-m .ct-area,
 .ct-series-m .ct-slice-donut-solid,
 .ct-series-m .ct-slice-pie,
 .ct-series-m .ct-bar {
     fill: #eacf7d
 }
 
 .ct-series-n .ct-line,
 .ct-series-n .ct-bar,
 .ct-series-n .ct-point {
     stroke: #86797d
 }
 
 .ct-series-n .ct-area,
 .ct-series-n .ct-slice-donut-solid,
 .ct-series-n .ct-slice-pie,
 .ct-series-n .ct-bar {
     fill: #86797d
 }
 
 .ct-series-o .ct-line,
 .ct-series-o .ct-bar,
 .ct-series-o .ct-point {
     stroke: #b2c326
 }
 
 .ct-series-o .ct-area,
 .ct-series-o .ct-slice-donut-solid,
 .ct-series-o .ct-slice-pie,
 .ct-series-o .ct-bar {
     fill: #b2c326
 }
 
 .chartist-tooltip {
     -webkit-border-radius: 3px;
     -moz-border-radius: 3px;
     border-radius: 3px;
     min-width: 3.5em;
     background: #5a5a5a;
     color: #fff
 }
 
 .chartist-tooltip:before {
     margin-left: -8px;
     border-width: 8px;
     border-top-color: #5a5a5a
 }
 
 .ct-axis-title {
     fill: #9a9a9a
 }
 
 .ct-label {
     font-size: 14px;
     color: var(--text-color)
 }
 
 .ct-legend {
     position: relative;
     z-index: 10;
     text-align: right;
     padding: 0
 }
 
 .ct-legend li {
     display: inline-block;
     vertical-align: middle;
     *vertical-align: auto;
     *zoom: 1;
     *display: inline;
     position: relative;
     padding-left: 18px;
     margin-bottom: 3px;
     margin-right: 20px;
     list-style-type: none
 }
 
 .ct-legend li:before {
     width: 12px;
     height: 12px;
     position: absolute;
     top: 5px;
     left: 0;
     content: '';
     border: 3px solid transparent;
     border-radius: 2px
 }
 
 .ct-legend li.inactive:before {
     background: transparent
 }
 
 .ct-legend.ct-legend-inside {
     position: absolute;
     top: 0;
     right: 0
 }
 
 .ct-legend .ct-series-0:before {
     background-color: #6ebdd1;
     border-color: #6ebdd1
 }
 
 .ct-legend .ct-series-1:before {
     background-color: #f9ab6c;
     border-color: #f9ab6c
 }
 
 .ct-legend .ct-series-2:before {
     background-color: #afc979;
     border-color: #afc979
 }
 
 .ct-legend .ct-series-3:before {
     background-color: #AB7DF6;
     border-color: #AB7DF6
 }
 
 .ct-legend .ct-series-4:before {
     background-color: #5cc196;
     border-color: #5cc196
 }
 
 .ct-legend .ct-series-5:before {
     background-color: #d17905;
     border-color: #d17905
 }
 
 .ct-legend .ct-series-6:before {
     background-color: #453d3f;
     border-color: #453d3f
 }
 
 .ct-legend .ct-series-7:before {
     background-color: #59922b;
     border-color: #59922b
 }
 
 .ct-legend .ct-series-8:before {
     background-color: #0544d3;
     border-color: #0544d3
 }
 
 .ct-legend .ct-series-9:before {
     background-color: #6b0392;
     border-color: #6b0392
 }
 
 .ct-legend .ct-series-10:before {
     background-color: #f05b4f;
     border-color: #f05b4f
 }
 
 .ct-legend .ct-series-11:before {
     background-color: #dda458;
     border-color: #dda458
 }
 
 .ct-legend .ct-series-12:before {
     background-color: #eacf7d;
     border-color: #eacf7d
 }
 
 .ct-legend .ct-series-13:before {
     background-color: #86797d;
     border-color: #86797d
 }
 
 .ct-legend .ct-series-14:before {
     background-color: #b2c326;
     border-color: #b2c326
 }
 
 .ct-grid {
     stroke: rgba(0, 0, 0, 0.1)
 }
 
 .user_statistics .ct-series .ct-point {
     stroke-width: 3px
 }
 
 .user_statistics .ct-series .ct-line {
     stroke-width: 1px
 }
 
 .user_statistics .ct-series-0:before {
     background-color: var(--white-color);
     border-color: #f8921a
 }
 
 .user_statistics .ct-series-1:before {
     background-color: var(--white-color);
     border-color: #6fb92c
 }
 
 .user_statistics .ct-series-2:before {
     background-color: var(--white-color);
     border-color: #157dd1
 }
 
 .user_statistics .ct-series-3:before {
     background-color: var(--white-color);
     border-color: #282828
 }
 
 .user_statistics .ct-series-a .ct-line,
 .user_statistics .ct-series-a .ct-point {
     stroke: #f8921a
 }
 
 .user_statistics .ct-series-b .ct-line,
 .user_statistics .ct-series-b .ct-point {
     stroke: #6fb92c
 }
 
 .user_statistics .ct-series-c .ct-line,
 .user_statistics .ct-series-c .ct-point {
     stroke: #157dd1
 }
 
 .user_statistics .ct-series-d .ct-line,
 .user_statistics .ct-series-d .ct-point {
     stroke: #282828
 }
 
 .currency_state .icon {
     line-height: 50px
 }
 
 .z500px-color {
     color: #0099e5 !important
 }
 
 .about-me-color {
     color: #00405d !important
 }
 
 .airbnb-color {
     color: #fd5c63 !important
 }
 
 .alphabet-color {
     color: #ed1c24 !important
 }
 
 .amazon-color {
     color: #f90 !important
 }
 
 .american-express-color {
     color: #002663 !important
 }
 
 .aol-color {
     color: #ff0b00 !important
 }
 
 .behance-color {
     color: #1769ff !important
 }
 
 .bing-color {
     color: #ffb900 !important
 }
 
 .bitbucket-color {
     color: #205081 !important
 }
 
 .bitly-color {
     color: #ee6123 !important
 }
 
 .blogger-color {
     color: #f57d00 !important
 }
 
 .booking-com-color {
     color: #003580 !important
 }
 
 .buffer-color {
     color: #168eea !important
 }
 
 .code-school-color {
     color: #616f67 !important
 }
 
 .codecademy-color {
     color: #f65a5b !important
 }
 
 .creative-market-color {
     color: #8ba753 !important
 }
 
 .delicious-color {
     color: #39f !important
 }
 
 .deviantart-color {
     color: #05cc47 !important
 }
 
 .digg-color {
     color: #005be2 !important
 }
 
 .disqus-color {
     color: #2e9fff !important
 }
 
 .django-color {
     color: #092e20 !important
 }
 
 .dribbble-color {
     color: #ea4c89 !important
 }
 
 .dropbox-color {
     color: #007ee5 !important
 }
 
 .drupal-color {
     color: #0077c0 !important
 }
 
 .elance-color {
     color: #0d69af !important
 }
 
 .envato-color {
     color: #82b541 !important
 }
 
 .etsy-color {
     color: #d5641c !important
 }
 
 .facebook-color {
     color: #3b5998 !important
 }
 
 .feedly-color {
     color: #2bb24c !important
 }
 
 .flattr-color {
     color: #f67c1a !important
 }
 
 .flickr-color {
     color: #0063dc !important
 }
 
 .flipboard-color {
     color: #e12828 !important
 }
 
 .flixster-color {
     color: #2971b2 !important
 }
 
 .foursquare-color {
     color: #0732a2 !important
 }
 
 .github-color {
     color: #333 !important
 }
 
 .google-color {
     color: #4285f4 !important
 }
 
 .google-plus-color {
     color: #dc4e41 !important
 }
 
 .instagram-color {
     color: #3f729b !important
 }
 
 .kickstarter-color {
     color: #2bde73 !important
 }
 
 .kik-color {
     color: #82bc23 !important
 }
 
 .lastfm-color {
     color: #d51007 !important
 }
 
 .line-color {
     color: #00c300 !important
 }
 
 .linkedin-color {
     color: #0077b5 !important
 }
 
 .mail-ru-color {
     color: #168de2 !important
 }
 
 .mailchimp-color {
     color: #2c9ab7 !important
 }
 
 .myspace-color {
     color: #000 !important
 }
 
 .netflix-color {
     color: #e50914 !important
 }
 
 .ning-color {
     color: #75af42 !important
 }
 
 .path-color {
     color: #ee3423 !important
 }
 
 .patreon-color {
     color: #e6461a !important
 }
 
 .paypal-color {
     color: #003087 !important
 }
 
 .photobucket-color {
     color: #0ea0db !important
 }
 
 .pinterest-color {
     color: #bd081c !important
 }
 
 .product-hunt-color {
     color: #da552f !important
 }
 
 .quora-color {
     color: #a82400 !important
 }
 
 .rdio-color {
     color: #007dc3 !important
 }
 
 .reddit-color {
     color: #ff4500 !important
 }
 
 .rss-color {
     color: #f26522 !important
 }
 
 .salesforce-color {
     color: #1798c1 !important
 }
 
 .scribd-color {
     color: #1a7bba !important
 }
 
 .shopify-color {
     color: #96bf48 !important
 }
 
 .skype-color {
     color: #00aff0 !important
 }
 
 .slack-color {
     color: #6ecadc !important
 }
 
 .slideshare-color {
     color: #0077b5 !important
 }
 
 .soundcloud-color {
     color: #f80 !important
 }
 
 .spotify-color {
     color: #2ebd59 !important
 }
 
 .squarespace-color {
     color: #222 !important
 }
 
 .stackoverflow-color {
     color: #fe7a15 !important
 }
 
 .stripe-color {
     color: #00afe1 !important
 }
 
 .stumbleupon-color {
     color: #eb4924 !important
 }
 
 .telegram-color {
     color: #08c !important
 }
 
 .tumblr-color {
     color: #35465c !important
 }
 
 .twitch-tv-color {
     color: #6441a5 !important
 }
 
 .twitter-color {
     color: #55acee !important
 }
 
 .vimeo-color {
     color: #162221 !important
 }
 
 .vine-color {
     color: #00b488 !important
 }
 
 .wechat-color {
     color: #7bb32e !important
 }
 
 .whatsapp-color {
     color: #43d854 !important
 }
 
 .wordpress-color {
     color: #21759b !important
 }
 
 .yahoo-color {
     color: #410093 !important
 }
 
 .youtube-color {
     color: #cd201f !important
 }
 
 .z500px-bg {
     background-color: #0099e5 !important
 }
 
 .z500px-bg:hover,
 .z500px-bg:focus {
     background-color: #08c !important
 }
 
 .about-me-bg {
     background-color: #00405d !important
 }
 
 .about-me-bg:hover,
 .about-me-bg:focus {
     background-color: #002e44 !important
 }
 
 .airbnb-bg {
     background-color: #fd5c63 !important
 }
 
 .airbnb-bg:hover,
 .airbnb-bg:focus {
     background-color: #fd434b !important
 }
 
 .alphabet-bg {
     background-color: #ed1c24 !important
 }
 
 .alphabet-bg:hover,
 .alphabet-bg:focus {
     background-color: #de1219 !important
 }
 
 .amazon-bg {
     background-color: #f90 !important
 }
 
 .amazon-bg:hover,
 .amazon-bg:focus {
     background-color: #e68a00 !important
 }
 
 .american-express-bg {
     background-color: #002663 !important
 }
 
 .american-express-bg:hover,
 .american-express-bg:focus {
     background-color: #001c4a !important
 }
 
 .aol-bg {
     background-color: #ff0b00 !important
 }
 
 .aol-bg:hover,
 .aol-bg:focus {
     background-color: #e60a00 !important
 }
 
 .behance-bg {
     background-color: #1769ff !important
 }
 
 .behance-bg:hover,
 .behance-bg:focus {
     background-color: #0059fd !important
 }
 
 .bing-bg {
     background-color: #ffb900 !important
 }
 
 .bing-bg:hover,
 .bing-bg:focus {
     background-color: #e6a700 !important
 }
 
 .bitbucket-bg {
     background-color: #205081 !important
 }
 
 .bitbucket-bg:hover,
 .bitbucket-bg:focus {
     background-color: #1b436d !important
 }
 
 .bitly-bg {
     background-color: #ee6123 !important
 }
 
 .bitly-bg:hover,
 .bitly-bg:focus {
     background-color: #e65312 !important
 }
 
 .blogger-bg {
     background-color: #f57d00 !important
 }
 
 .blogger-bg:hover,
 .blogger-bg:focus {
     background-color: #dc7000 !important
 }
 
 .booking-com-bg {
     background-color: #003580 !important
 }
 
 .booking-com-bg:hover,
 .booking-com-bg:focus {
     background-color: #002a67 !important
 }
 
 .buffer-bg {
     background-color: #168eea !important
 }
 
 .buffer-bg:hover,
 .buffer-bg:focus {
     background-color: #1380d3 !important
 }
 
 .code-school-bg {
     background-color: #616f67 !important
 }
 
 .code-school-bg:hover,
 .code-school-bg:focus {
     background-color: #55615a !important
 }
 
 .codecademy-bg {
     background-color: #f65a5b !important
 }
 
 .codecademy-bg:hover,
 .codecademy-bg:focus {
     background-color: #f54243 !important
 }
 
 .creative-market-bg {
     background-color: #8ba753 !important
 }
 
 .creative-market-bg:hover,
 .creative-market-bg:focus {
     background-color: #7d964b !important
 }
 
 .delicious-bg {
     background-color: #39f !important
 }
 
 .delicious-bg:hover,
 .delicious-bg:focus {
     background-color: #1a8cff !important
 }
 
 .deviantart-bg {
     background-color: #05cc47 !important
 }
 
 .deviantart-bg:hover,
 .deviantart-bg:focus {
     background-color: #04b33e !important
 }
 
 .digg-bg {
     background-color: #005be2 !important
 }
 
 .digg-bg:hover,
 .digg-bg:focus {
     background-color: #0051c9 !important
 }
 
 .disqus-bg {
     background-color: #2e9fff !important
 }
 
 .disqus-bg:hover,
 .disqus-bg:focus {
     background-color: #1593ff !important
 }
 
 .django-bg {
     background-color: #092e20 !important
 }
 
 .django-bg:hover,
 .django-bg:focus {
     background-color: #051911 !important
 }
 
 .dribbble-bg {
     background-color: #ea4c89 !important
 }
 
 .dribbble-bg:hover,
 .dribbble-bg:focus {
     background-color: #e7357a !important
 }
 
 .dropbox-bg {
     background-color: #007ee5 !important
 }
 
 .dropbox-bg:hover,
 .dropbox-bg:focus {
     background-color: #0070cc !important
 }
 
 .drupal-bg {
     background-color: #0077c0 !important
 }
 
 .drupal-bg:hover,
 .drupal-bg:focus {
     background-color: #0067a7 !important
 }
 
 .elance-bg {
     background-color: #0d69af !important
 }
 
 .elance-bg:hover,
 .elance-bg:focus {
     background-color: #0b5b97 !important
 }
 
 .envato-bg {
     background-color: #82b541 !important
 }
 
 .envato-bg:hover,
 .envato-bg:focus {
     background-color: #75a23a !important
 }
 
 .etsy-bg {
     background-color: #d5641c !important
 }
 
 .etsy-bg:hover,
 .etsy-bg:focus {
     background-color: #be5919 !important
 }
 
 .facebook-bg {
     background-color: #3b5998 !important
 }
 
 .facebook-bg:hover,
 .facebook-bg:focus {
     background-color: #344e86 !important
 }
 
 .feedly-bg {
     background-color: #2bb24c !important
 }
 
 .feedly-bg:hover,
 .feedly-bg:focus {
     background-color: #269d43 !important
 }
 
 .flattr-bg {
     background-color: #f67c1a !important
 }
 
 .flattr-bg:hover,
 .flattr-bg:focus {
     background-color: #ed6f09 !important
 }
 
 .flickr-bg {
     background-color: #0063dc !important
 }
 
 .flickr-bg:hover,
 .flickr-bg:focus {
     background-color: #0058c3 !important
 }
 
 .flipboard-bg {
     background-color: #e12828 !important
 }
 
 .flipboard-bg:hover,
 .flipboard-bg:focus {
     background-color: #d21d1d !important
 }
 
 .flixster-bg {
     background-color: #2971b2 !important
 }
 
 .flixster-bg:hover,
 .flixster-bg:focus {
     background-color: #24649d !important
 }
 
 .foursquare-bg {
     background-color: #0732a2 !important
 }
 
 .foursquare-bg:hover,
 .foursquare-bg:focus {
     background-color: #062a8a !important
 }
 
 .github-bg {
     background-color: #333 !important
 }
 
 .github-bg:hover,
 .github-bg:focus {
     background-color: #262626 !important
 }
 
 .google-bg {
     background-color: #4285f4 !important
 }
 
 .google-bg:hover,
 .google-bg:focus {
     background-color: #2a75f3 !important
 }
 
 .google-plus-bg {
     background-color: #dc4e41 !important
 }
 
 .google-plus-bg:hover,
 .google-plus-bg:focus {
     background-color: #d83a2b !important
 }
 
 .instagram-bg {
     background-color: #3f729b !important
 }
 
 .instagram-bg:hover,
 .instagram-bg:focus {
     background-color: #386589 !important
 }
 
 .kickstarter-bg {
     background-color: #2bde73 !important
 }
 
 .kickstarter-bg:hover,
 .kickstarter-bg:focus {
     background-color: #20cf67 !important
 }
 
 .kik-bg {
     background-color: #82bc23 !important
 }
 
 .kik-bg:hover,
 .kik-bg:focus {
     background-color: #73a71f !important
 }
 
 .lastfm-bg {
     background-color: #d51007 !important
 }
 
 .lastfm-bg:hover,
 .lastfm-bg:focus {
     background-color: #bc0e06 !important
 }
 
 .line-bg {
     background-color: #00c300 !important
 }
 
 .line-bg:hover,
 .line-bg:focus {
     background-color: #0a0 !important
 }
 
 .linkedin-bg {
     background-color: #0077b5 !important
 }
 
 .linkedin-bg:hover,
 .linkedin-bg:focus {
     background-color: #00669c !important
 }
 
 .mail-ru-bg {
     background-color: #168de2 !important
 }
 
 .mail-ru-bg:hover,
 .mail-ru-bg:focus {
     background-color: #147fcb !important
 }
 
 .mailchimp-bg {
     background-color: #2c9ab7 !important
 }
 
 .mailchimp-bg:hover,
 .mailchimp-bg:focus {
     background-color: #2789a2 !important
 }
 
 .myspace-bg {
     background-color: #000 !important
 }
 
 .myspace-bg:hover,
 .myspace-bg:focus {
     background-color: #000 !important
 }
 
 .netflix-bg {
     background-color: #e50914 !important
 }
 
 .netflix-bg:hover,
 .netflix-bg:focus {
     background-color: #cc0812 !important
 }
 
 .ning-bg {
     background-color: #75af42 !important
 }
 
 .ning-bg:hover,
 .ning-bg:focus {
     background-color: #699c3b !important
 }
 
 .path-bg {
     background-color: #ee3423 !important
 }
 
 .path-bg:hover,
 .path-bg:focus {
     background-color: #e62412 !important
 }
 
 .patreon-bg {
     background-color: #e6461a !important
 }
 
 .patreon-bg:hover,
 .patreon-bg:focus {
     background-color: #d03f17 !important
 }
 
 .paypal-bg {
     background-color: #003087 !important
 }
 
 .paypal-bg:hover,
 .paypal-bg:focus {
     background-color: #00276e !important
 }
 
 .photobucket-bg {
     background-color: #0ea0db !important
 }
 
 .photobucket-bg:hover,
 .photobucket-bg:focus {
     background-color: #0c8ec3 !important
 }
 
 .pinterest-bg {
     background-color: #bd081c !important
 }
 
 .pinterest-bg:hover,
 .pinterest-bg:focus {
     background-color: #a50718 !important
 }
 
 .product-hunt-bg {
     background-color: #da552f !important
 }
 
 .product-hunt-bg:hover,
 .product-hunt-bg:focus {
     background-color: #cb4924 !important
 }
 
 .quora-bg {
     background-color: #a82400 !important
 }
 
 .quora-bg:hover,
 .quora-bg:focus {
     background-color: #8f1f00 !important
 }
 
 .rdio-bg {
     background-color: #007dc3 !important
 }
 
 .rdio-bg:hover,
 .rdio-bg:focus {
     background-color: #006daa !important
 }
 
 .reddit-bg {
     background-color: #ff4500 !important
 }
 
 .reddit-bg:hover,
 .reddit-bg:focus {
     background-color: #e63e00 !important
 }
 
 .rss-bg {
     background-color: #f26522 !important
 }
 
 .rss-bg:hover,
 .rss-bg:focus {
     background-color: #ed560e !important
 }
 
 .salesforce-bg {
     background-color: #1798c1 !important
 }
 
 .salesforce-bg:hover,
 .salesforce-bg:focus {
     background-color: #1486aa !important
 }
 
 .scribd-bg {
     background-color: #1a7bba !important
 }
 
 .scribd-bg:hover,
 .scribd-bg:focus {
     background-color: #176ca4 !important
 }
 
 .shopify-bg {
     background-color: #96bf48 !important
 }
 
 .shopify-bg:hover,
 .shopify-bg:focus {
     background-color: #89b03e !important
 }
 
 .skype-bg {
     background-color: #00aff0 !important
 }
 
 .skype-bg:hover,
 .skype-bg:focus {
     background-color: #009cd7 !important
 }
 
 .slack-bg {
     background-color: #6ecadc !important
 }
 
 .slack-bg:hover,
 .slack-bg:focus {
     background-color: #59c2d7 !important
 }
 
 .slideshare-bg {
     background-color: #0077b5 !important
 }
 
 .slideshare-bg:hover,
 .slideshare-bg:focus {
     background-color: #00669c !important
 }
 
 .soundcloud-bg {
     background-color: #f80 !important
 }
 
 .soundcloud-bg:hover,
 .soundcloud-bg:focus {
     background-color: #e67a00 !important
 }
 
 .spotify-bg {
     background-color: #2ebd59 !important
 }
 
 .spotify-bg:hover,
 .spotify-bg:focus {
     background-color: #29a84f !important
 }
 
 .squarespace-bg {
     background-color: #222 !important
 }
 
 .squarespace-bg:hover,
 .squarespace-bg:focus {
     background-color: #151515 !important
 }
 
 .stackoverflow-bg {
     background-color: #fe7a15 !important
 }
 
 .stackoverflow-bg:hover,
 .stackoverflow-bg:focus {
     background-color: #f86c01 !important
 }
 
 .stripe-bg {
     background-color: #00afe1 !important
 }
 
 .stripe-bg:hover,
 .stripe-bg:focus {
     background-color: #009bc8 !important
 }
 
 .stumbleupon-bg {
     background-color: #eb4924 !important
 }
 
 .stumbleupon-bg:hover,
 .stumbleupon-bg:focus {
     background-color: #e13b15 !important
 }
 
 .telegram-bg {
     background-color: #08c !important
 }
 
 .telegram-bg:hover,
 .telegram-bg:focus {
     background-color: #0077b3 !important
 }
 
 .tumblr-bg {
     background-color: #35465c !important
 }
 
 .tumblr-bg:hover,
 .tumblr-bg:focus {
     background-color: #2c3a4c !important
 }
 
 .twitch-tv-bg {
     background-color: #6441a5 !important
 }
 
 .twitch-tv-bg:hover,
 .twitch-tv-bg:focus {
     background-color: #593a93 !important
 }
 
 .twitter-bg {
     background-color: #55acee !important
 }
 
 .twitter-bg:hover,
 .twitter-bg:focus {
     background-color: #3ea1ec !important
 }
 
 .vimeo-bg {
     background-color: #162221 !important
 }
 
 .vimeo-bg:hover,
 .vimeo-bg:focus {
     background-color: #0c1312 !important
 }
 
 .vine-bg {
     background-color: #00b488 !important
 }
 
 .vine-bg:hover,
 .vine-bg:focus {
     background-color: #009b75 !important
 }
 
 .wechat-bg {
     background-color: #7bb32e !important
 }
 
 .wechat-bg:hover,
 .wechat-bg:focus {
     background-color: #6d9f29 !important
 }
 
 .whatsapp-bg {
     background-color: #43d854 !important
 }
 
 .whatsapp-bg:hover,
 .whatsapp-bg:focus {
     background-color: #2ed441 !important
 }
 
 .wordpress-bg {
     background-color: #21759b !important
 }
 
 .wordpress-bg:hover,
 .wordpress-bg:focus {
     background-color: #1d6586 !important
 }
 
 .yahoo-bg {
     background-color: #410093 !important
 }
 
 .yahoo-bg:hover,
 .yahoo-bg:focus {
     background-color: #36007a !important
 }
 
 .youtube-bg {
     background-color: #cd201f !important
 }
 
 .youtube-bg:hover,
 .youtube-bg:focus {
     background-color: #b71d1c !important
 }
 
 ul.list-justify>li {
     margin-bottom: 5px
 }
 
 ul.list-justify>li span,
 ul.list-justify>li strong {
     float: right
 }
 
 ul.list-justify.large-number>li {
     margin-bottom: 15px
 }
 
 ul.list-justify.large-number span {
     font-size: 32px;
     line-height: 1
 }
 
 .text-primary {
     color: #5c8ed4
 }
 
 .text-info {
     color: #3C89DA
 }
 
 .text-success {
     color: #22af46
 }
 
 .text-warning {
     color: #f3ad06
 }
 
 .text-danger {
     color: #de4848
 }
 
 .text-muted {
     color: #9A9A9A
 }
 
 .text-larger {
     font-size: 1.2em
 }
 
 .text-large {
     font-size: 1.53em
 }
 
 .bg-success {
     background-color: #22af46
 }
 
 .bg-warning {
     background-color: #f3ad06
 }
 
 .bg-danger {
     background-color: #de4848
 }
 
 .bg-info {
     background-color: #3C89DA
 }
 
 .m-l--125 {
     margin-left: -125px
 }
 
 .m-t--125 {
     margin-top: -125px
 }
 
 .m-r--125 {
     margin-right: -125px
 }
 
 .m-b--125 {
     margin-bottom: -125px
 }
 
 .m-l--120 {
     margin-left: -120px
 }
 
 .m-t--120 {
     margin-top: -120px
 }
 
 .m-r--120 {
     margin-right: -120px
 }
 
 .m-b--120 {
     margin-bottom: -120px
 }
 
 .m-l--115 {
     margin-left: -115px
 }
 
 .m-t--115 {
     margin-top: -115px
 }
 
 .m-r--115 {
     margin-right: -115px
 }
 
 .m-b--115 {
     margin-bottom: -115px
 }
 
 .m-l--110 {
     margin-left: -110px
 }
 
 .m-t--110 {
     margin-top: -110px
 }
 
 .m-r--110 {
     margin-right: -110px
 }
 
 .m-b--110 {
     margin-bottom: -110px
 }
 
 .m-l--105 {
     margin-left: -105px
 }
 
 .m-t--105 {
     margin-top: -105px
 }
 
 .m-r--105 {
     margin-right: -105px
 }
 
 .m-b--105 {
     margin-bottom: -105px
 }
 
 .m-l--100 {
     margin-left: -100px
 }
 
 .m-t--100 {
     margin-top: -100px
 }
 
 .m-r--100 {
     margin-right: -100px
 }
 
 .m-b--100 {
     margin-bottom: -100px
 }
 
 .m-l--95 {
     margin-left: -95px
 }
 
 .m-t--95 {
     margin-top: -95px
 }
 
 .m-r--95 {
     margin-right: -95px
 }
 
 .m-b--95 {
     margin-bottom: -95px
 }
 
 .m-l--90 {
     margin-left: -90px
 }
 
 .m-t--90 {
     margin-top: -90px
 }
 
 .m-r--90 {
     margin-right: -90px
 }
 
 .m-b--90 {
     margin-bottom: -90px
 }
 
 .m-l--85 {
     margin-left: -85px
 }
 
 .m-t--85 {
     margin-top: -85px
 }
 
 .m-r--85 {
     margin-right: -85px
 }
 
 .m-b--85 {
     margin-bottom: -85px
 }
 
 .m-l--80 {
     margin-left: -80px
 }
 
 .m-t--80 {
     margin-top: -80px
 }
 
 .m-r--80 {
     margin-right: -80px
 }
 
 .m-b--80 {
     margin-bottom: -80px
 }
 
 .m-l--75 {
     margin-left: -75px
 }
 
 .m-t--75 {
     margin-top: -75px
 }
 
 .m-r--75 {
     margin-right: -75px
 }
 
 .m-b--75 {
     margin-bottom: -75px
 }
 
 .m-l--70 {
     margin-left: -70px
 }
 
 .m-t--70 {
     margin-top: -70px
 }
 
 .m-r--70 {
     margin-right: -70px
 }
 
 .m-b--70 {
     margin-bottom: -70px
 }
 
 .m-l--65 {
     margin-left: -65px
 }
 
 .m-t--65 {
     margin-top: -65px
 }
 
 .m-r--65 {
     margin-right: -65px
 }
 
 .m-b--65 {
     margin-bottom: -65px
 }
 
 .m-l--60 {
     margin-left: -60px
 }
 
 .m-t--60 {
     margin-top: -60px
 }
 
 .m-r--60 {
     margin-right: -60px
 }
 
 .m-b--60 {
     margin-bottom: -60px
 }
 
 .m-l--55 {
     margin-left: -55px
 }
 
 .m-t--55 {
     margin-top: -55px
 }
 
 .m-r--55 {
     margin-right: -55px
 }
 
 .m-b--55 {
     margin-bottom: -55px
 }
 
 .m-l--50 {
     margin-left: -50px
 }
 
 .m-t--50 {
     margin-top: -50px
 }
 
 .m-r--50 {
     margin-right: -50px
 }
 
 .m-b--50 {
     margin-bottom: -50px
 }
 
 .m-l--45 {
     margin-left: -45px
 }
 
 .m-t--45 {
     margin-top: -45px
 }
 
 .m-r--45 {
     margin-right: -45px
 }
 
 .m-b--45 {
     margin-bottom: -45px
 }
 
 .m-l--40 {
     margin-left: -40px
 }
 
 .m-t--40 {
     margin-top: -40px
 }
 
 .m-r--40 {
     margin-right: -40px
 }
 
 .m-b--40 {
     margin-bottom: -40px
 }
 
 .m-l--35 {
     margin-left: -35px
 }
 
 .m-t--35 {
     margin-top: -35px
 }
 
 .m-r--35 {
     margin-right: -35px
 }
 
 .m-b--35 {
     margin-bottom: -35px
 }
 
 .m-l--30 {
     margin-left: -30px
 }
 
 .m-t--30 {
     margin-top: -30px
 }
 
 .m-r--30 {
     margin-right: -30px
 }
 
 .m-b--30 {
     margin-bottom: -30px
 }
 
 .m-l--25 {
     margin-left: -25px
 }
 
 .m-t--25 {
     margin-top: -25px
 }
 
 .m-r--25 {
     margin-right: -25px
 }
 
 .m-b--25 {
     margin-bottom: -25px
 }
 
 .m-l--20 {
     margin-left: -20px
 }
 
 .m-t--20 {
     margin-top: -20px
 }
 
 .m-r--20 {
     margin-right: -20px
 }
 
 .m-b--20 {
     margin-bottom: -20px
 }
 
 .m-l--15 {
     margin-left: -15px
 }
 
 .m-t--15 {
     margin-top: -15px
 }
 
 .m-r--15 {
     margin-right: -15px
 }
 
 .m-b--15 {
     margin-bottom: -15px
 }
 
 .m-l--10 {
     margin-left: -10px
 }
 
 .m-t--10 {
     margin-top: -10px
 }
 
 .m-r--10 {
     margin-right: -10px
 }
 
 .m-b--10 {
     margin-bottom: -10px
 }
 
 .m-l--5 {
     margin-left: -5px
 }
 
 .m-t--5 {
     margin-top: -5px
 }
 
 .m-r--5 {
     margin-right: -5px
 }
 
 .m-b--5 {
     margin-bottom: -5px
 }
 
 .m-l-0 {
     margin-left: 0px
 }
 
 .m-t-0,
 .weather2 .days-list .day h5,
 .weather2 .days-list .day .h5,
 .weather .city,
 .weather .city h3,
 .weather .city .h3 {
     margin-top: 0px
 }
 
 .m-r-0,
 .c_list .fancy-checkbox {
     margin-right: 0px
 }
 
 .m-b-0,
 .card .header,
 .navbar-nav ul.notifications li>a .media,
 .navbar-nav ul.notifications li .text,
 ul.choose-skin,
 .auth-box .lead,
 #toast-container .toast,
 .weather .city h3,
 .weather .city .h3,
 .weather6 p,
 .top_counter .content .number,
 .basic-list,
 .c_list .fancy-checkbox,
 .c_list .c_name,
 .c_list address,
 .social_media_table .table,
 .feeds_widget,
 .folder h6,
 .folder .h6 {
     margin-bottom: 0px
 }
 
 .m-l-5,
 .btn-toolbar>.btn-group,
 .badge,
 ul.choose-skin li span,
 .wizard .actions a {
     margin-left: 5px
 }
 
 .m-t-5 {
     margin-top: 5px
 }
 
 .m-r-5,
 .badge,
 .nav-tabs-new>li>a,
 .nav-tabs-new2>li>a,
 .new_timeline .bullet,
 .b4gallery .nav-item {
     margin-right: 5px
 }
 
 .m-b-5,
 .auth-box .btn-signin-social,
 .weather .days li img {
     margin-bottom: 5px
 }
 
 .m-l-10,
 .navbar-nav ul.user-menu.menu-icon>li>a span,
 .c_list .c_name {
     margin-left: 10px
 }
 
 .m-t-10,
 .weather3 .top .wrapper .temp .deg,
 .member-card .social-links {
     margin-top: 10px
 }
 
 .m-r-10,
 .user-account .user-photo,
 .user-account .dropdown .dropdown-menu a i,
 .top_counter .icon,
 .right_chat .media .media-object,
 .testimonial4 .carousel-info img {
     margin-right: 10px
 }
 
 .m-b-10,
 .weather4 i {
     margin-bottom: 10px
 }
 
 .m-l-15 {
     margin-left: 15px
 }
 
 .m-t-15,
 .question li.menu-heading {
     margin-top: 15px
 }
 
 .m-r-15,
 .navbar-nav,
 .new_timeline .header .color-overlay .day-number {
     margin-right: 15px
 }
 
 .m-b-15,
 .w_social3 .card .fa {
     margin-bottom: 15px
 }
 
 .m-l-20,
 .media.mright .media-right,
 .chat-widget li.left .chat-info {
     margin-left: 20px
 }
 
 .m-t-20,
 .auth-box .form-auth-small .btn,
 .auth-box .form-auth-small .bottom,
 .block-header,
 .weather3 .top .wrapper .heading,
 .weather3 .top .wrapper .location,
 .stats-report {
     margin-top: 20px
 }
 
 .m-r-20,
 .navbar-fixed-top .navbar-right #navbar-search,
 .navbar-nav ul.notifications li>a .media .media-left,
 .sidebar-nav .metismenu>li i,
 .media.mleft .media-left,
 .bs-example .media .media-left,
 .chat-widget li.right .chat-info {
     margin-right: 20px
 }
 
 .m-b-20,
 .block-header,
 .media,
 .chat-widget li,
 .weather .city,
 .chart.easy-pie-chart-1 {
     margin-bottom: 20px
 }
 
 .m-l-25 {
     margin-left: 25px
 }
 
 .m-t-25 {
     margin-top: 25px
 }
 
 .m-r-25 {
     margin-right: 25px
 }
 
 .m-b-25 {
     margin-bottom: 25px
 }
 
 .m-l-30 {
     margin-left: 30px
 }
 
 .m-t-30 {
     margin-top: 30px
 }
 
 .m-r-30 {
     margin-right: 30px
 }
 
 .m-b-30,
 .auth-box .top,
 .testimonial2,
 .testimonial3,
 .testimonial4 {
     margin-bottom: 24px
 }
 
 .m-l-35 {
     margin-left: 35px
 }
 
 .m-t-35 {
     margin-top: 35px
 }
 
 .m-r-35 {
     margin-right: 35px
 }
 
 .m-b-35 {
     margin-bottom: 35px
 }
 
 .m-l-40 {
     margin-left: 40px
 }
 
 .m-t-40 {
     margin-top: 40px
 }
 
 .m-r-40 {
     margin-right: 40px
 }
 
 .m-b-40 {
     margin-bottom: 40px
 }
 
 .m-l-45 {
     margin-left: 45px
 }
 
 .m-t-45 {
     margin-top: 45px
 }
 
 .m-r-45 {
     margin-right: 45px
 }
 
 .m-b-45 {
     margin-bottom: 45px
 }
 
 .m-l-50 {
     margin-left: 50px
 }
 
 .m-t-50 {
     margin-top: 50px
 }
 
 .m-r-50 {
     margin-right: 50px
 }
 
 .m-b-50 {
     margin-bottom: 50px
 }
 
 .m-l-55 {
     margin-left: 55px
 }
 
 .m-t-55 {
     margin-top: 55px
 }
 
 .m-r-55 {
     margin-right: 55px
 }
 
 .m-b-55 {
     margin-bottom: 55px
 }
 
 .m-l-60 {
     margin-left: 60px
 }
 
 .m-t-60 {
     margin-top: 60px
 }
 
 .m-r-60 {
     margin-right: 60px
 }
 
 .m-b-60 {
     margin-bottom: 60px
 }
 
 .m-l-65 {
     margin-left: 65px
 }
 
 .m-t-65 {
     margin-top: 65px
 }
 
 .m-r-65 {
     margin-right: 65px
 }
 
 .m-b-65 {
     margin-bottom: 65px
 }
 
 .m-l-70 {
     margin-left: 70px
 }
 
 .m-t-70 {
     margin-top: 70px
 }
 
 .m-r-70 {
     margin-right: 70px
 }
 
 .m-b-70 {
     margin-bottom: 70px
 }
 
 .m-l-75 {
     margin-left: 75px
 }
 
 .m-t-75 {
     margin-top: 75px
 }
 
 .m-r-75 {
     margin-right: 75px
 }
 
 .m-b-75 {
     margin-bottom: 75px
 }
 
 .m-l-80 {
     margin-left: 80px
 }
 
 .m-t-80 {
     margin-top: 80px
 }
 
 .m-r-80 {
     margin-right: 80px
 }
 
 .m-b-80 {
     margin-bottom: 80px
 }
 
 .m-l-85 {
     margin-left: 85px
 }
 
 .m-t-85 {
     margin-top: 85px
 }
 
 .m-r-85 {
     margin-right: 85px
 }
 
 .m-b-85 {
     margin-bottom: 85px
 }
 
 .m-l-90 {
     margin-left: 90px
 }
 
 .m-t-90 {
     margin-top: 90px
 }
 
 .m-r-90 {
     margin-right: 90px
 }
 
 .m-b-90 {
     margin-bottom: 90px
 }
 
 .m-l-95 {
     margin-left: 95px
 }
 
 .m-t-95 {
     margin-top: 95px
 }
 
 .m-r-95 {
     margin-right: 95px
 }
 
 .m-b-95 {
     margin-bottom: 95px
 }
 
 .m-l-100 {
     margin-left: 100px
 }
 
 .m-t-100 {
     margin-top: 100px
 }
 
 .m-r-100 {
     margin-right: 100px
 }
 
 .m-b-100 {
     margin-bottom: 100px
 }
 
 .m-l-105 {
     margin-left: 105px
 }
 
 .m-t-105 {
     margin-top: 105px
 }
 
 .m-r-105 {
     margin-right: 105px
 }
 
 .m-b-105 {
     margin-bottom: 105px
 }
 
 .m-l-110 {
     margin-left: 110px
 }
 
 .m-t-110 {
     margin-top: 110px
 }
 
 .m-r-110 {
     margin-right: 110px
 }
 
 .m-b-110 {
     margin-bottom: 110px
 }
 
 .m-l-115 {
     margin-left: 115px
 }
 
 .m-t-115 {
     margin-top: 115px
 }
 
 .m-r-115 {
     margin-right: 115px
 }
 
 .m-b-115 {
     margin-bottom: 115px
 }
 
 .m-l-120 {
     margin-left: 120px
 }
 
 .m-t-120 {
     margin-top: 120px
 }
 
 .m-r-120 {
     margin-right: 120px
 }
 
 .m-b-120 {
     margin-bottom: 120px
 }
 
 .m-l-125 {
     margin-left: 125px
 }
 
 .m-t-125 {
     margin-top: 125px
 }
 
 .m-r-125 {
     margin-right: 125px
 }
 
 .m-b-125 {
     margin-bottom: 125px
 }
 
 .margin-0,
 .card .header .header-dropdown,
 .card .header h2,
 .card .header .h2,
 .navbar-nav,
 .nav-tabs-new>li>a,
 .sidebar-nav ul,
 .block-header .breadcrumb,
 .media .media-body .media-heading,
 .wizard .content,
 .wizard .content .body label.error,
 .weather2 .city-selected .temp h2,
 .weather2 .city-selected .temp .h2,
 .weather2 .days-list,
 .weather3 .bottom .forecast,
 .weather4 p,
 .stats-report .stat-item h5,
 .stats-report .stat-item .h5,
 .new_timeline>ul,
 .new_timeline h3,
 .new_timeline .h3,
 .new_timeline h4,
 .new_timeline .h4,
 .c_list tr td .btn,
 .project_report h6,
 .project_report .h6,
 .project_report .team-info,
 .w_social2 .pw_content .pw_header h6,
 .w_social2 .pw_content .pw_header .h6,
 .w_user .wid-u-info h5,
 .w_user .wid-u-info .h5,
 .feeds_widget li .feeds-body .title,
 .timeline-item h5,
 .timeline-item .h5,
 .fc-overlay.right,
 .fc .fc-toolbar,
 .fc .fc-toolbar h2,
 .fc .fc-toolbar .h2 {
     margin: 0 !important
 }
 
 .p-l-0,
 .navbar-fixed-top .navbar-btn button {
     padding-left: 0px
 }
 
 .p-t-0,
 .navbar-nav ul.user-menu>li:first-child,
 .basic-list li:first-child {
     padding-top: 0px
 }
 
 .p-r-0 {
     padding-right: 0px
 }
 
 .p-b-0,
 .navbar-nav ul.notifications li.footer a,
 .basic-list li:last-child {
     padding-bottom: 0px
 }
 
 .p-l-5 {
     padding-left: 5px
 }
 
 .p-t-5,
 .navbar-nav ul.user-menu.menu-icon>li>a,
 .fc .fc-toolbar h2,
 .fc .fc-toolbar .h2 {
     padding-top: 5px
 }
 
 .p-r-5,
 .wizard.vertical>.steps {
     padding-right: 5px
 }
 
 .p-b-5,
 .navbar-nav ul.user-menu.menu-icon>li>a,
 .sidebar-nav .metismenu>li {
     padding-bottom: 5px
 }
 
 .p-l-10 {
     padding-left: 10px
 }
 
 .p-t-10,
 .fc .fc-toolbar {
     padding-top: 10px
 }
 
 .p-r-10 {
     padding-right: 10px
 }
 
 .p-b-10,
 .fc .fc-toolbar {
     padding-bottom: 10px
 }
 
 .p-l-15,
 .question li a {
     padding-left: 15px
 }
 
 .p-t-15,
 .basic-list li {
     padding-top: 15px
 }
 
 .p-r-15,
 .stats-report .stat-item {
     padding-right: 15px
 }
 
 .p-b-15,
 .basic-list li {
     padding-bottom: 15px
 }
 
 .p-l-20,
 .navbar-fixed-top .navbar-right #navbar-search .form-control {
     padding-left: 20px
 }
 
 .p-t-20,
 .navbar-nav ul.notifications li.footer a,
 .navbar-nav ul.user-menu .menu-button,
 .weather3 .bottom .forecast {
     padding-top: 20px
 }
 
 .p-r-20 {
     padding-right: 20px
 }
 
 .p-b-20,
 .navbar-nav ul.notifications li.header {
     padding-bottom: 20px
 }
 
 .p-l-25 {
     padding-left: 25px
 }
 
 .p-t-25 {
     padding-top: 25px
 }
 
 .p-r-25 {
     padding-right: 25px
 }
 
 .p-b-25 {
     padding-bottom: 25px
 }
 
 .p-l-30 {
     padding-left: 30px
 }
 
 .p-t-30 {
     padding-top: 30px
 }
 
 .p-r-30 {
     padding-right: 30px
 }
 
 .p-b-30 {
     padding-bottom: 30px
 }
 
 .p-l-35 {
     padding-left: 35px
 }
 
 .p-t-35 {
     padding-top: 35px
 }
 
 .p-r-35 {
     padding-right: 35px
 }
 
 .p-b-35 {
     padding-bottom: 35px
 }
 
 .p-l-40 {
     padding-left: 40px
 }
 
 .p-t-40 {
     padding-top: 40px
 }
 
 .p-r-40 {
     padding-right: 40px
 }
 
 .p-b-40 {
     padding-bottom: 40px
 }
 
 .p-l-45 {
     padding-left: 45px
 }
 
 .p-t-45 {
     padding-top: 45px
 }
 
 .p-r-45 {
     padding-right: 45px
 }
 
 .p-b-45 {
     padding-bottom: 45px
 }
 
 .p-l-50 {
     padding-left: 50px
 }
 
 .p-t-50 {
     padding-top: 50px
 }
 
 .p-r-50 {
     padding-right: 50px
 }
 
 .p-b-50 {
     padding-bottom: 50px
 }
 
 .p-l-55 {
     padding-left: 55px
 }
 
 .p-t-55 {
     padding-top: 55px
 }
 
 .p-r-55 {
     padding-right: 55px
 }
 
 .p-b-55 {
     padding-bottom: 55px
 }
 
 .p-l-60 {
     padding-left: 60px
 }
 
 .p-t-60 {
     padding-top: 60px
 }
 
 .p-r-60 {
     padding-right: 60px
 }
 
 .p-b-60 {
     padding-bottom: 60px
 }
 
 .p-l-65 {
     padding-left: 65px
 }
 
 .p-t-65 {
     padding-top: 65px
 }
 
 .p-r-65 {
     padding-right: 65px
 }
 
 .p-b-65 {
     padding-bottom: 65px
 }
 
 .p-l-70 {
     padding-left: 70px
 }
 
 .p-t-70 {
     padding-top: 70px
 }
 
 .p-r-70 {
     padding-right: 70px
 }
 
 .p-b-70 {
     padding-bottom: 70px
 }
 
 .p-l-75 {
     padding-left: 75px
 }
 
 .p-t-75 {
     padding-top: 75px
 }
 
 .p-r-75 {
     padding-right: 75px
 }
 
 .p-b-75 {
     padding-bottom: 75px
 }
 
 .p-l-80 {
     padding-left: 80px
 }
 
 .p-t-80 {
     padding-top: 80px
 }
 
 .p-r-80 {
     padding-right: 80px
 }
 
 .p-b-80 {
     padding-bottom: 80px
 }
 
 .p-l-85 {
     padding-left: 85px
 }
 
 .p-t-85 {
     padding-top: 85px
 }
 
 .p-r-85 {
     padding-right: 85px
 }
 
 .p-b-85 {
     padding-bottom: 85px
 }
 
 .p-l-90 {
     padding-left: 90px
 }
 
 .p-t-90 {
     padding-top: 90px
 }
 
 .p-r-90 {
     padding-right: 90px
 }
 
 .p-b-90 {
     padding-bottom: 90px
 }
 
 .p-l-95 {
     padding-left: 95px
 }
 
 .p-t-95 {
     padding-top: 95px
 }
 
 .p-r-95 {
     padding-right: 95px
 }
 
 .p-b-95 {
     padding-bottom: 95px
 }
 
 .p-l-100 {
     padding-left: 100px
 }
 
 .p-t-100 {
     padding-top: 100px
 }
 
 .p-r-100 {
     padding-right: 100px
 }
 
 .p-b-100 {
     padding-bottom: 100px
 }
 
 .p-l-105 {
     padding-left: 105px
 }
 
 .p-t-105 {
     padding-top: 105px
 }
 
 .p-r-105 {
     padding-right: 105px
 }
 
 .p-b-105 {
     padding-bottom: 105px
 }
 
 .p-l-110 {
     padding-left: 110px
 }
 
 .p-t-110 {
     padding-top: 110px
 }
 
 .p-r-110 {
     padding-right: 110px
 }
 
 .p-b-110 {
     padding-bottom: 110px
 }
 
 .p-l-115 {
     padding-left: 115px
 }
 
 .p-t-115 {
     padding-top: 115px
 }
 
 .p-r-115 {
     padding-right: 115px
 }
 
 .p-b-115 {
     padding-bottom: 115px
 }
 
 .p-l-120 {
     padding-left: 120px
 }
 
 .p-t-120 {
     padding-top: 120px
 }
 
 .p-r-120 {
     padding-right: 120px
 }
 
 .p-b-120 {
     padding-bottom: 120px
 }
 
 .p-l-125 {
     padding-left: 125px
 }
 
 .p-t-125 {
     padding-top: 125px
 }
 
 .p-r-125 {
     padding-right: 125px
 }
 
 .p-b-125 {
     padding-bottom: 125px
 }
 
 .padding-0,
 .card .header .header-dropdown,
 .navbar-fixed-top,
 .navbar-fixed-top .navbar-btn,
 .sidebar-nav ul,
 .search-form,
 .search-form .btn,
 .block-header .breadcrumb,
 .accordion .card-header,
 .chat-widget,
 .weather3 .bottom .forecast,
 .new_timeline .header,
 .right_chat .media .status,
 .fc .fc-view-container .fc-view.fc-basic-view>table>thead tr th.fc-agenda-gutter,
 .fc .fc-view-container .fc-view.fc-basic-view>table>thead tr td.fc-agenda-gutter,
 .fc .fc-view-container .fc-view.fc-agenda>table>thead tr th.fc-agenda-gutter,
 .fc .fc-view-container .fc-view.fc-agenda>table>thead tr td.fc-agenda-gutter,
 .fc .fc-view-container .fc-view.fc-basic-view>table>thead tr td.fc-widget-header,
 .fc .fc-view-container .fc-view.fc-agenda>table>thead tr td.fc-widget-header {
     padding: 0 !important
 }
 
 .font-6 {
     font-size: 6px
 }
 
 .font-7 {
     font-size: 7px
 }
 
 .font-8 {
     font-size: 8px
 }
 
 .font-9 {
     font-size: 9px
 }
 
 .font-10 {
     font-size: 10px
 }
 
 .font-11 {
     font-size: 11px
 }
 
 .font-12 {
     font-size: 12px
 }
 
 .font-13 {
     font-size: 13px
 }
 
 .font-14 {
     font-size: 14px
 }
 
 .font-15 {
     font-size: 15px
 }
 
 .font-16 {
     font-size: 16px
 }
 
 .font-17 {
     font-size: 17px
 }
 
 .font-18 {
     font-size: 18px
 }
 
 .font-19 {
     font-size: 19px
 }
 
 .font-20 {
     font-size: 20px
 }
 
 .font-21 {
     font-size: 21px
 }
 
 .font-22 {
     font-size: 22px
 }
 
 .font-23 {
     font-size: 23px
 }
 
 .font-24 {
     font-size: 24px
 }
 
 .font-25 {
     font-size: 25px
 }
 
 .font-26 {
     font-size: 26px
 }
 
 .font-27 {
     font-size: 27px
 }
 
 .font-28 {
     font-size: 28px
 }
 
 .font-29 {
     font-size: 29px
 }
 
 .font-30 {
     font-size: 30px
 }
 
 .font-31 {
     font-size: 31px
 }
 
 .font-32 {
     font-size: 32px
 }
 
 .font-33 {
     font-size: 33px
 }
 
 .font-34 {
     font-size: 34px
 }
 
 .font-35 {
     font-size: 35px
 }
 
 .font-36 {
     font-size: 36px
 }
 
 .font-37 {
     font-size: 37px
 }
 
 .font-38 {
     font-size: 38px
 }
 
 .font-39 {
     font-size: 39px
 }
 
 .font-40 {
     font-size: 40px
 }
 
 .font-41 {
     font-size: 41px
 }
 
 .font-42 {
     font-size: 42px
 }
 
 .font-43 {
     font-size: 43px
 }
 
 .font-44 {
     font-size: 44px
 }
 
 .font-45 {
     font-size: 45px
 }
 
 .font-46 {
     font-size: 46px
 }
 
 .font-47 {
     font-size: 47px
 }
 
 .font-48 {
     font-size: 48px
 }
 
 .font-49 {
     font-size: 49px
 }
 
 .font-50 {
     font-size: 50px
 }
 
 .align-left,
 .user-account,
 .user-account .dropdown,
 .accordion .card-header .btn,
 .chat-widget li.left .chat-info {
     text-align: left
 }
 
 .align-center,
 .navbar-nav ul.user-menu .menu-button,
 .auth-box .form-auth-small .bottom,
 .metric-inline,
 .separator-linethrough,
 .info-box-2 .chart,
 .weather2 .days-list .day,
 .weather .days li,
 .weather3 .top,
 .top_counter .icon,
 .chart.easy-pie-chart-1,
 .chart.easy-pie-chart-1>span>img,
 .c_list address i,
 .social_icon,
 .profile_state,
 .testimonials .body,
 .member-card,
 .w_social3 .card,
 .file_manager .file .icon,
 .pricing {
     text-align: center
 }
 
 .align-right,
 .media.mright .media-body,
 .wizard .actions,
 .chat-widget li.right,
 .chat-widget li.right .chat-info {
     text-align: right
 }
 
 .align-justify {
     text-align: justify
 }
 
 .padding-25 {
     padding: 25px !important
 }
 
 .media .media-body {
     color: var(--color-700);
     font-size: 14px
 }
 
 .media .media-body .media-heading {
     font-size: 16px;
     font-weight: 700;
     color: var(--color-900)
 }
 
 .wizard ul {
     list-style: none
 }
 
 .wizard .content {
     -webkit-border-radius: 4px;
     -moz-border-radius: 4px;
     border-radius: 4px;
     min-height: 245px;
     overflow-y: auto;
     background: transparent;
     border: 1px solid var(--color-200)
 }
 
 .wizard .content .body {
     padding: 15px;
     width: 100%;
     box-shadow: none
 }
 
 .wizard .content .body label.error {
     font-size: 14px;
     color: red
 }
 
 .wizard .content .body input[type="checkbox"] {
     display: none
 }
 
 .wizard .actions {
     position: relative;
     width: 100%;
     margin-top: 10px !important
 }
 
 .wizard .actions ul>li {
     float: left;
     margin: 0 !important
 }
 
 .wizard .actions a {
     background: var(--primary-color);
     color: var(--white-color);
     text-decoration: none
 }
 
 .wizard .actions a:hover,
 .wizard .actions a:active {
     color: var(--white-color);
     text-decoration: none
 }
 
 .wizard .actions .disabled a {
     background: var(--color-200);
     color: var(--color-400)
 }
 
 .wizard .actions .disabled a:hover,
 .wizard .actions .disabled a:active {
     background: var(--color-200);
     color: var(--color-400)
 }
 
 .wizard .steps {
     position: relative;
     width: 100%;
     margin-bottom: 2px
 }
 
 .wizard .steps a {
     -webkit-border-radius: 4px;
     -moz-border-radius: 4px;
     border-radius: 4px;
     width: auto;
     margin: 0 2px 0 0;
     padding: 10px;
     text-decoration: none
 }
 
 .wizard .steps a:hover,
 .wizard .steps a:active {
     -webkit-border-radius: 4px;
     -moz-border-radius: 4px;
     border-radius: 4px;
     width: auto;
     margin: 0 2px 0 0;
     padding: 10px;
     text-decoration: none
 }
 
 .wizard .steps .number {
     font-size: 17px
 }
 
 .wizard .steps .disabled a {
     background: var(--color-200);
     color: var(--color-400);
     cursor: default
 }
 
 .wizard .steps .disabled a:hover,
 .wizard .steps .disabled a:active {
     background: var(--color-200);
     color: var(--color-400);
     cursor: default
 }
 
 .wizard .steps .current {
     border-color: var(--border-color)
 }
 
 .wizard .steps .current a {
     background: var(--primary-color);
     color: var(--white-color);
     cursor: default
 }
 
 .wizard .steps .current a:hover,
 .wizard .steps .current a:active {
     color: var(--white-color);
     cursor: default
 }
 
 .wizard .steps .current>.body input {
     border-color: initial
 }
 
 .wizard .steps .done a {
     background: var(--primary-color);
     color: var(--white-color);
     opacity: 0.5
 }
 
 .wizard .steps .done a:hover,
 .wizard .steps .done a:active {
     color: var(--white-color);
     opacity: 0.7
 }
 
 .wizard .steps>ul>li {
     width: 25%;
     float: left
 }
 
 .wizard .steps>ul>li:last-child a {
     margin: 0
 }
 
 .wizard.vertical>.steps {
     float: left;
     width: 30%
 }
 
 .wizard.vertical>.steps>ul>li {
     float: none;
     width: 100%
 }
 
 .wizard.vertical>.steps>ul>li a {
     margin: 0 0 2px 0
 }
 
 .wizard.vertical>.content {
     float: left;
     width: 70%;
     margin: 0
 }
 
 .wizard.vertical>.actions {
     float: right;
     width: 100%;
     margin: 0
 }
 
 .wizard_validation .wizard .steps>ul>li {
     width: auto !important
 }
 
 @media screen and (max-width: 767px) {
     .wizard>.steps>ul>li {
         width: 100%;
         margin-bottom: 2px
     }
 
     .wizard>.steps a {
         margin: 0
     }
 
     .wizard.wizard.vertical>.content,
     .wizard.wizard.vertical>.steps {
         width: 100%;
         padding: 0
     }
 
     .wizard_validation .wizard .steps>ul>li {
         width: 100% !important
     }
 }
 
 .table {
     color: var(--text-color) !important
 }
 
 .table .thead-primary th {
     color: #fff;
     background-color: #49c5b6;
     border-color: #49c5b6
 }
 
 .table .thead-success th {
     color: #fff;
     background-color: #22af46;
     border-color: #22af46
 }
 
 .table .thead-danger th {
     color: #fff;
     background-color: #de4848;
     border-color: #de4848
 }
 
 .table .thead-info th {
     color: #fff;
     background-color: #3C89DA;
     border-color: #3C89DA
 }
 
 .table .thead-purple th {
     color: #fff;
     background-color: #6f42c1;
     border-color: #6f42c1
 }
 
 .table tbody tr td,
 .table tbody th td {
     color: var(--text-color) !important;
     vertical-align: middle;
     white-space: nowrap
 }
 
 .page-loader-wrapper {
     z-index: 99999999;
     position: fixed;
     top: 0;
     left: 0;
     bottom: 0;
     right: 0;
     width: 100%;
     height: 100%;
     overflow: hidden
 }
 
 .page-loader-wrapper p {
     color: var(--white-color)
 }
 
 .page-loader-wrapper .loader {
     position: relative;
     top: calc(40% - 30px)
 }
 
 .list-referrals>li {
     margin-bottom: 22px
 }
 
 .list-referrals .value {
     font-size: 20px
 }
 
 .number-chart .number {
     font-weight: 600
 }
 
 .number-chart .number>span {
     font-size: 22px;
     line-height: 1.4
 }
 
 .number-chart canvas {
     width: 100% !important
 }
 
 .chat-widget {
     height: 250px;
     overflow-y: scroll;
     scrollbar-width: none;
     -ms-overflow-style: none
 }
 
 .chat-widget::-webkit-scrollbar {
     width: 5px;
     background: var(--color-100)
 }
 
 .chat-widget li {
     list-style: none;
     width: 100%
 }
 
 .chat-widget li.left img {
     float: left
 }
 
 .chat-widget li.left .chat-info {
     float: left;
     background: var(--color-100)
 }
 
 .chat-widget li.left .chat-info:before {
     border-top: 10px solid transparent;
     border-bottom: 10px solid transparent;
     border-right: 10px solid var(--color-100);
     left: -10px;
     top: 12px
 }
 
 .chat-widget li.right img {
     float: right
 }
 
 .chat-widget li.right .chat-info:before {
     border-top: 10px solid transparent;
     border-bottom: 10px solid transparent;
     right: -10px;
     top: 12px
 }
 
 .chat-widget li .chat-info {
     width: calc(100% - 60px);
     border-radius: 8px;
     padding: 20px;
     position: relative
 }
 
 .chat-widget li .chat-info:before {
     width: 0;
     height: 0;
     content: '';
     position: absolute;
     top: 12px
 }
 
 .chat-widget li .datetime {
     font-size: 12px;
     color: var(--color-400)
 }
 
 .chat-widget li img {
     height: 40px;
     width: 40px
 }
 
 .info-box-2 {
     display: flex;
     cursor: default;
     position: relative;
     overflow: hidden;
     min-height: 130px
 }
 
 .info-box-2 .icon i {
     font-size: 30px;
     line-height: 70px
 }
 
 .info-box-2 .chart {
     position: relative;
     width: 100px
 }
 
 .info-box-2 .chart.chart-bar {
     height: 100%
 }
 
 .info-box-2 .chart.chart-bar canvas {
     vertical-align: baseline !important
 }
 
 .info-box-2 .chart.chart-pie {
     height: 100%
 }
 
 .info-box-2 .chart.chart-pie canvas {
     vertical-align: baseline !important
 }
 
 .info-box-2 .chart.chart-line {
     height: 100%
 }
 
 .info-box-2 .chart.chart-line canvas {
     vertical-align: baseline !important
 }
 
 .info-box-2 .content {
     padding: 7px 0px
 }
 
 .info-box-2 .content .text {
     margin-top: 11px;
     color: var(--color-500)
 }
 
 .info-box-2 .content .number {
     font-weight: normal;
     font-size: 26px;
     color: var(--color-700)
 }
 
 .gender-overview .fa-male {
     color: #00bdfb
 }
 
 .gender-overview .fa-female {
     color: #ff68b3
 }
 
 .weather2 .city-selected {
     position: relative;
     overflow: hidden;
     border-radius: 3px 3px 0 0
 }
 
 .weather2 .city-selected .city {
     font-size: 24px
 }
 
 .weather2 .city-selected .city span {
     font-size: 13px;
     text-transform: lowercase
 }
 
 .weather2 .city-selected .night {
     font-size: 15px;
     text-transform: uppercase
 }
 
 .weather2 .city-selected .temp h2,
 .weather2 .city-selected .temp .h2 {
     font-size: 73px;
     position: relative
 }
 
 .weather2 .city-selected .icon i {
     font-size: 70px
 }
 
 .weather2 .table tbody tr td {
     padding: 14px 20px
 }
 
 .weather2 .days-list .day {
     padding: 20px 10px
 }
 
 .weather2 .days-list .day i {
     font-size: 30px
 }
 
 .weather2 .carousel .carousel-inner {
     box-shadow: none
 }
 
 .weather .city {
     position: relative
 }
 
 .weather .city i {
     font-size: 40px;
     position: absolute;
     right: 0;
     top: 0
 }
 
 .weather .days li {
     width: 13.20%
 }
 
 .weather .days li img {
     width: 25px
 }
 
 .weather .days li h5,
 .weather .days li .h5 {
     font-size: 11px
 }
 
 .weather3 {
     overflow: hidden
 }
 
 .weather3 .top {
     position: relative;
     height: 430px;
     width: 100%;
     overflow: hidden;
     /*background: url("../img/weather_img.jpg") no-repeat;*/
     background-size: cover;
     background-position: center center
 }
 
 .weather3 .top::after {
     content: "";
     height: 100%;
     width: 100%;
     position: absolute;
     top: 0;
     left: 0;
     background: rgba(0, 0, 0, 0.5)
 }
 
 .weather3 .top .wrapper {
     padding: 30px;
     position: relative;
     z-index: 1;
     color: #fff
 }
 
 .weather3 .top .wrapper .mynav {
     height: 20px
 }
 
 .weather3 .top .wrapper .heading {
     font-size: 35px;
     font-weight: 400
 }
 
 .weather3 .top .wrapper .location {
     font-size: 21px;
     font-weight: 400
 }
 
 .weather3 .top .wrapper .temp .temp-value {
     font-size: 85px;
     font-weight: 600
 }
 
 .weather3 .top .wrapper .temp .deg {
     font-size: 35px;
     font-weight: 600;
     vertical-align: top
 }
 
 .weather3 .top .wrapper .temp .temp-type {
     font-size: 85px;
     color: #fff
 }
 
 .weather3 .bottom {
     padding: 20px;
     background: #fff
 }
 
 .weather3 .bottom .forecast {
     overflow: hidden;
     font-size: 0;
     max-height: 155px
 }
 
 .weather3 .bottom .forecast li {
     font-size: 18px;
     color: var(--color-400);
     line-height: 2em
 }
 
 .weather3 .bottom .forecast li.active {
     color: var(--color-600)
 }
 
 .weather3 .bottom .forecast li .condition {
     float: right;
     line-height: 2em
 }
 
 .weather4 i {
     font-size: 35px
 }
 
 .weather5 {
     overflow: hidden
 }
 
 .weather5 .widget {
     display: flex;
     flex-wrap: wrap
 }
 
 .weather5 .weatherIcon {
     flex: 1 100%;
     height: 60%;
     background: #fff;
     display: flex;
     align-items: center;
     justify-content: space-around;
     font-size: 80px;
     padding: 20px 0
 }
 
 .weather5 .weatherIcon i {
     padding-top: 20px
 }
 
 .weather5 .weatherInfo {
     flex: 0 0 70%;
     height: 40%;
     background: var(--dark-color);
     display: flex;
     align-items: center;
     color: #fff
 }
 
 .weather5 .weatherInfo .temperature {
     flex: 0 0 40%;
     width: 100%;
     font-size: 65px;
     display: flex;
     justify-content: space-around
 }
 
 .weather5 .weatherInfo .description {
     flex: 0 60%;
     display: flex;
     flex-direction: column;
     width: 100%;
     height: 100%;
     justify-content: center
 }
 
 .weather5 .date {
     flex: 0 0 30%;
     height: 40%;
     background: #49c5b6;
     display: flex;
     justify-content: space-around;
     align-items: center;
     color: #fff;
     font-size: 28px;
     line-height: 97px
 }
 
 .weather6 {
     text-align: center
 }
 
 .weather6 .temp {
     font-size: 30px
 }
 
 .top_counter .icon {
     float: left;
     width: 50px;
     height: 50px;
     background: var(--color-100);
     border-radius: .35rem
 }
 
 .top_counter .icon i {
     font-size: 25px;
     line-height: 50px
 }
 
 .top_counter .content {
     height: 50px
 }
 
 .stats-report .stat-item h5,
 .stats-report .stat-item .h5 {
     font-size: 13px
 }
 
 .basic-list li span {
     float: right
 }
 
 .new_timeline .header {
     background: url(http://via.placeholder.com/275x120);
     background-size: cover;
     color: #fff;
     position: relative
 }
 
 .new_timeline .header .color-overlay {
     padding: 2em;
     box-sizing: border-box;
     background: rgba(123, 94, 155, 0.5);
     line-height: normal
 }
 
 .new_timeline .header .color-overlay .day-number {
     font-size: 4em
 }
 
 .new_timeline .header .color-overlay .day-name {
     font-size: 23px
 }
 
 .new_timeline .header .color-overlay .month {
     text-transform: uppercase;
     font-weight: 300;
     letter-spacing: 2px;
     margin-top: 2px
 }
 
 .new_timeline>ul {
     padding: 1em 0 0 2em;
     list-style: none;
     position: relative
 }
 
 .new_timeline>ul::before {
     content: ' ';
     height: 100%;
     width: 1px;
     background-color: var(--color-200);
     position: absolute;
     top: 0;
     left: 2.5em;
     z-index: 1
 }
 
 .new_timeline li div {
     margin: 1em 0;
     vertical-align: top
 }
 
 .new_timeline .bullet {
     width: 1em;
     height: 1em;
     box-sizing: border-box;
     -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
     border-radius: 50%;
     background: #fff;
     z-index: 2;
     position: relative
 }
 
 .new_timeline .bullet.pink {
     border: 2px solid #ED5782
 }
 
 .new_timeline .bullet.green {
     border: 2px solid #198754
 }
 
 .new_timeline .bullet.orange {
     border: 2px solid #fd7e14
 }
 
 .new_timeline .time {
     width: 20%;
     font-size: 0.75em;
     padding-top: 0.25em
 }
 
 .new_timeline .desc {
     width: 65%
 }
 
 .new_timeline h3,
 .new_timeline .h3 {
     font-size: 16px;
     font-weight: 400
 }
 
 .new_timeline h4,
 .new_timeline .h4 {
     font-size: 13px;
     font-weight: 400;
     color: var(--color-500)
 }
 
 .new_timeline .people img {
     width: 30px;
     height: 30px;
     -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
     border-radius: 50%
 }
 
 .team-info li {
     transition: all .25s ease;
     -webkit-transition: all .25s ease;
     -o-transition: all .25s ease;
     -moz-transition: all .25s ease
 }
 
 .team-info li+li {
     margin-left: -10px
 }
 
 .team-info li img {
     width: 30px;
     -webkit-border-radius: 30px;
     -moz-border-radius: 30px;
     border-radius: 30px;
     border: 2px solid #fff;
     box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2)
 }
 
 .team-info li:hover {
     -webkit-transform: translateY(-4px) scale(1.02);
     -moz-transform: translateY(-4px) scale(1.02);
     -ms-transform: translateY(-4px) scale(1.02);
     -o-transform: translateY(-4px) scale(1.02);
     transform: translateY(-4px) scale(1.02);
     z-index: 999
 }
 
 .team-info li:hover img {
     -webkit-box-shadow: 0 14px 24px rgba(62, 57, 107, 0.2);
     box-shadow: 0 14px 24px rgba(62, 57, 107, 0.2)
 }
 
 .list-group-item {
     color: var(--text-color);
     background-color: var(--card-color);
     border-color: var(--border-color)
 }
 
 .list-widget .badge {
     float: right
 }
 
 .list-widget .list-group-item {
     border-left: 0;
     border-right: 0;
     padding: 20px 20px;
     color: var(--text-color);
     background-color: var(--card-color);
     border-color: var(--border-color)
 }
 
 .list-widget .list-group-item:first-child {
     border-top: 0
 }
 
 .list-widget .list-group-item:last-child {
     border-bottom: 0
 }
 
 .list-widget .list-group-item i {
     width: 30px;
     font-size: 18px
 }
 
 .chart.easy-pie-chart-1 {
     position: relative;
     width: 100px;
     height: 100px
 }
 
 .chart.easy-pie-chart-1>span>img {
     left: 0;
     margin-top: -2px;
     position: absolute;
     right: 0;
     top: 50%;
     width: 60%;
     height: 60%;
     -webkit-transform: translateY(-50%);
     -ms-transform: translateY(-50%);
     transform: translateY(-50%);
     margin: 0 auto
 }
 
 .c_list tr td {
     vertical-align: middle
 }
 
 .c_list .c_name {
     font-weight: 600
 }
 
 .c_list .avatar {
     width: 40px;
     box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
     border: 1px solid #fff
 }
 
 .c_list address i {
     width: 25px;
     font-size: 15px
 }
 
 .team_list .dd-handle {
     background: #f4f7f6
 }
 
 .taskboard .planned_task .dd-handle {
     background: var(--color-200)
 }
 
 .taskboard .progress_task .dd-handle {
     background: #fd7e14;
     color: #fff
 }
 
 .taskboard .completed_task .dd-handle {
     background: #86c541;
     color: #fff
 }
 
 .taskboard .dd:hover {
     cursor: move
 }
 
 .taskboard .dd .dd-list .dd-item {
     margin: 10px
 }
 
 .taskboard .dd .dd-list .dd-item>button {
     margin: 13px 0 9px 9px
 }
 
 .taskboard .dd .dd-list .btn {
     padding: 0 5px;
     border: 0
 }
 
 .taskboard .dd .dd-list .btn:hover {
     background: transparent
 }
 
 .b4gallery .gallery-item:hover {
     cursor: pointer
 }
 
 .right_chat li {
     position: relative
 }
 
 .right_chat li.offline .status {
     background: #FF9800
 }
 
 .right_chat li.online .status {
     background: #8BC34A
 }
 
 .right_chat li.me .status {
     background: #0498bd
 }
 
 .right_chat .media .media-object {
     width: 40px;
     height: 40px;
     -webkit-border-radius: 40px;
     -moz-border-radius: 40px;
     border-radius: 40px;
     border: 2px solid transparent;
     -webkit-transition: all 0.3s ease-in-out;
     -moz-transition: all 0.3s ease-in-out;
     -ms-transition: all 0.3s ease-in-out;
     -o-transition: all 0.3s ease-in-out;
     transition: all 0.3s ease-in-out
 }
 
 .right_chat .media .name {
     color: var(--color-800);
     margin: 0px;
     font-size: 15px
 }
 
 .right_chat .media .message {
     font-size: 13px;
     color: var(--color-400)
 }
 
 .right_chat .media .status {
     position: absolute;
     left: 23px;
     top: 30px;
     width: 11px;
     height: 11px;
     min-width: inherit;
     border: 2px solid #fff;
     -webkit-border-radius: 50px;
     -moz-border-radius: 50px;
     border-radius: 50px
 }
 
 .table-custom.table {
     border-collapse: collapse !important
 }
 
 .table-custom.table thead th {
     border-top: 0
 }
 
 .table-custom.table tfoot th {
     border-bottom: 0
 }
 
 .table-custom.table tbody tr:hover {
     background-color: #f4f7f6
 }
 
 .dt-bootstrap4 .dt-buttons,
 .dt-bootstrap4 .dataTables_info {
     margin-bottom: 10px
 }
 
 .dt-bootstrap4 .dt-buttons+.dataTables_filter,
 .dt-bootstrap4 .dt-buttons+.dataTables_paginate,
 .dt-bootstrap4 .dataTables_info+.dataTables_filter,
 .dt-bootstrap4 .dataTables_info+.dataTables_paginate {
     float: right
 }
 
 .social_media_table .table tbody tr td {
     vertical-align: middle
 }
 
 .social_media_table .list-name {
     text-transform: uppercase
 }
 
 .social_icon {
     -webkit-border-radius: 50px;
     -moz-border-radius: 50px;
     border-radius: 50px;
     color: #fff;
     line-height: 40px;
     width: 40px;
     height: 40px
 }
 
 .social_icon.linkedin {
     background: #007bb5
 }
 
 .social_icon.twitter-table {
     background: #55acee
 }
 
 .social_icon.facebook {
     background: #007bb5
 }
 
 .social_icon.google {
     background: #dd4b39
 }
 
 .social_icon.youtube {
     background: #bb0000
 }
 
 .profile_state i {
     font-size: 25px
 }
 
 .maintenance {
     background: var(--dark-color)
 }
 
 .testimonials .body {
     padding: 40px
 }
 
 .testimonials .body i {
     font-size: 50px;
     color: var(--color-400)
 }
 
 .testimonials .body p {
     font-size: 15px
 }
 
 .testimonial2 .testimonial-section {
     width: 100%;
     height: auto;
     padding: 15px;
     -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
     border-radius: 5px;
     position: relative;
     border: 1px solid var(--white-color)
 }
 
 .testimonial2 .testimonial-section::after {
     top: 100%;
     left: 50px;
     border: solid transparent;
     content: " ";
     position: absolute;
     border-top-color: var(--white-color);
     border-width: 15px;
     margin-left: -15px
 }
 
 .testimonial2 .testimonial-desc {
     margin-top: 20px;
     text-align: left;
     padding-left: 15px
 }
 
 .testimonial2 .testimonial-desc img {
     width: 70px
 }
 
 .testimonial2 .testimonial-desc .testimonial-writer {
     display: inline-block;
     vertical-align: top;
     padding-left: 10px
 }
 
 .testimonial2.default .testimonial-section {
     border-color: var(--color-600)
 }
 
 .testimonial2.default .testimonial-section::after {
     border-top-color: var(--color-600)
 }
 
 .testimonial2.primary .testimonial-section {
     border-color: #49c5b6
 }
 
 .testimonial2.primary .testimonial-section::after {
     border-top-color: #49c5b6
 }
 
 .testimonial3 .testimonial-section {
     width: 100%;
     height: auto;
     padding: 15px;
     -webkit-border-radius: 5px;
     -moz-border-radius: 5px;
     border-radius: 5px;
     position: relative;
     border: 1px solid var(--white-color)
 }
 
 .testimonial3 .testimonial-section::after {
     top: 100%;
     right: 50px;
     border: solid transparent;
     content: " ";
     position: absolute;
     border-top-color: var(--white-color);
     border-width: 15px;
     margin-right: -15px
 }
 
 .testimonial3 .testimonial-desc {
     margin-top: 20px;
     text-align: right;
     padding-right: 15px
 }
 
 .testimonial3 .testimonial-desc img {
     width: 70px;
     float: right
 }
 
 .testimonial3 .testimonial-desc .testimonial-writer {
     display: inline-block;
     vertical-align: top;
     padding-right: 10px
 }
 
 .testimonial3.default .testimonial-section {
     border-color: var(--color-600)
 }
 
 .testimonial3.default .testimonial-section::after {
     border-top-color: var(--color-600)
 }
 
 .testimonial3.primary .testimonial-section {
     border-color: #49c5b6
 }
 
 .testimonial3.primary .testimonial-section::after {
     border-top-color: #49c5b6
 }
 
 .testimonial3.warning .testimonial-section {
     border-color: #f3ad06
 }
 
 .testimonial3.warning .testimonial-section::after {
     border-top-color: #f3ad06
 }
 
 .testimonial3.danger .testimonial-section {
     border-color: #de4848
 }
 
 .testimonial3.danger .testimonial-section::after {
     border-top-color: #de4848
 }
 
 .testimonial4 blockquote {
     background: var(--color-100) none repeat scroll 0 0;
     border: medium none;
     color: var(--color-600);
     line-height: 20px;
     padding: 15px;
     position: relative
 }
 
 .testimonial4 blockquote:before {
     width: 0;
     height: 0;
     right: 0;
     bottom: 0;
     content: " ";
     position: absolute;
     border-bottom: 20px solid #fff;
     border-right: 0 solid transparent;
     border-left: 15px solid transparent;
     border-left-style: inset;
     border-bottom-style: inset
 }
 
 .testimonial4 blockquote:after {
     width: 0;
     height: 0;
     right: 0;
     bottom: 0;
     content: " ";
     position: absolute;
     border-style: solid;
     border-width: 20px 20px 0 0
 }
 
 .testimonial4 blockquote.danger:after {
     border-color: #de4848 transparent transparent transparent
 }
 
 .testimonial4 blockquote.warning:after {
     border-color: #f3ad06 transparent transparent transparent
 }
 
 .testimonial4 blockquote.info:after {
     border-color: #3C89DA transparent transparent transparent
 }
 
 .testimonial4 blockquote.primary:after {
     border-color: #49c5b6 transparent transparent transparent
 }
 
 .testimonial4 .carousel-info {
     overflow: hidden
 }
 
 .accordion.custom .card-header {
     background: transparent;
     border-color: var(--color-200)
 }
 
 .accordion.custom .card-header .btn {
     color: var(--color-700)
 }
 
 .accordion.custom .card-header .btn:hover,
 .accordion.custom .card-header .btn:focus {
     text-decoration: none
 }
 
 .accordion.custom .card-body {
     font-size: 15px;
     line-height: 28px;
     color: var(--color-600)
 }
 
 .morris-hover.morris-default-style {
     -webkit-border-radius: 0;
     -moz-border-radius: 0;
     border-radius: 0
 }
 
 .flot-chart {
     width: 100%;
     height: 320px
 }
 
 .panel-switch-btn {
     position: relative;
     right: 20px;
     z-index: 9
 }
 
 .panel-switch-btn label {
     font-weight: bold !important
 }
 
 .legendLabel {
     width: 85px !important;
     position: relative;
     left: 3px
 }
 
 #multiple_axis_chart .legendLabel {
     width: 160px !important
 }
 
 .sparkline {
     text-align: center
 }
 
 .dashboard-donut-chart {
     width: 280px;
     margin: 0 auto
 }
 
 .member-card .header {
     min-height: 150px
 }
 
 .member-card .member-img {
     position: relative;
     margin-top: -70px
 }
 
 .member-card .member-img img {
     width: 150px;
     border: 3px solid #fff;
     box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3)
 }
 
 .member-card .social-links li a {
     padding: 5px 10px
 }
 
 .w_social2 .pw_img {
     position: relative;
     overflow: hidden
 }
 
 .w_social2 .pw_img:before {
     position: absolute;
     top: 0;
     left: -75%;
     z-index: 2;
     content: '';
     width: 50%;
     height: 100%;
     background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
     background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
     -webkit-transform: skewX(-25deg);
     transform: skewX(-25deg)
 }
 
 .w_social2 .pw_img:hover::before {
     -webkit-animation: shine .75s;
     animation: shine .75s
 }
 
 @-webkit-keyframes shine {
     100% {
         left: 125%
     }
 }
 
 @keyframes shine {
     100% {
         left: 125%
     }
 }
 
 .w_social2 .pw_img img {
     border-radius: 3px 3px 0 0
 }
 
 .w_social2 .pw_content .pw_header {
     padding: 20px;
     border-bottom: 1px solid var(--color-200)
 }
 
 .w_social2 .pw_content .pw_header small,
 .w_social2 .pw_content .pw_header .small {
     font-size: 12px
 }
 
 .w_social2 .pw_content .pw_meta {
     padding: 20px
 }
 
 .w_social2 .pw_content .pw_meta span {
     font-weight: 500
 }
 
 .w_user img {
     height: 72px;
     float: left
 }
 
 .w_user .wid-u-info {
     margin-left: 90px
 }
 
 .w_feed {
     border-radius: .35rem;
     color: #fff
 }
 
 .w_feed.facebook {
     background: #3b5998
 }
 
 .w_feed.twitter {
     background: #00aced
 }
 
 .w_feed.google {
     background: #d34836
 }
 
 .w_feed .carousel-inner {
     box-shadow: none
 }
 
 .w_feed .carousel-inner .carousel-item {
     padding: 20px
 }
 
 .w_feed .carousel-inner h4,
 .w_feed .carousel-inner .h4 {
     font-weight: 300
 }
 
 .w_feed .carousel-inner h4 span,
 .w_feed .carousel-inner .h4 span {
     font-weight: 700
 }
 
 .w_followers a:hover .btn {
     opacity: 1
 }
 
 .w_followers .btn {
     position: absolute;
     right: 0;
     top: 4px;
     opacity: 0
 }
 
 .w_social3 .card {
     padding: 25px
 }
 
 .w_social3 .card .fa {
     font-size: 30px
 }
 
 .w_social3 .text {
     margin-top: 0 !important
 }
 
 .w_social3 .facebook-widget .icon i {
     color: #3b5998
 }
 
 .w_social3 .google-widget .icon i {
     color: #d34836
 }
 
 .w_social3 .twitter-widget .icon i {
     color: #0084b4
 }
 
 .w_social3 .instagram-widget .icon i {
     color: #fb3958
 }
 
 .w_social3 .linkedin-widget .icon i {
     color: #0077B5
 }
 
 .w_social3 .behance-widget .icon i {
     color: #053eff
 }
 
 .w_profile {
     position: relative
 }
 
 .w_profile .profile-image img {
     border-radius: 50%;
     width: 180px;
     border: 3px solid #fff;
     box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
 }
 
 .w_profile .social-icon a {
     margin: 0 5px
 }
 
 @media screen and (max-width: 640px) {
     .w_profile {
         text-align: center
     }
 
     .w_profile .profile-image {
         margin-bottom: 20px
     }
 }
 
 .ms-container {
     width: auto !important
 }
 
 .todo_list .fancy-checkbox input[type="checkbox"]:checked+span {
     text-decoration: line-through
 }
 
 .w_bitcoin .body {
     position: absolute
 }
 
 .w_bitcoin #sparkline16 {
     margin-bottom: -2px
 }
 
 .feeds_widget li {
     padding: 12px 15px;
     border: 1px solid var(--color-200);
     margin: 4px 0
 }
 
 .feeds_widget li:hover {
     box-shadow: 0 2px 5px rgba(0, 0, 0, 0.14), 0 2px 10px rgba(0, 0, 0, 0.1);
     transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
     cursor: pointer
 }
 
 .feeds_widget li .feeds-left {
     float: left;
     width: 33px;
     font-size: 18px
 }
 
 .feeds_widget li .feeds-body {
     width: auto
 }
 
 .feeds_widget li .feeds-body .title {
     font-size: 16px
 }
 
 .feeds_widget li .feeds-body>small,
 .feeds_widget li .feeds-body>.small {
     text-overflow: ellipsis;
     white-space: nowrap;
     overflow: hidden;
     width: 80%;
     font-size: 13px;
     color: var(--color-600)
 }
 
 .profilepage_1 .profile-heading {
     margin-bottom: 30px;
     font-size: 20px;
     font-weight: normal
 }
 
 .profilepage_1 .user-photo {
     width: 90px;
     border: 2px solid var(--border-color)
 }
 
 .profilepage_1 .profile-section {
     margin-bottom: 70px
 }
 
 .plan {
     -webkit-border-radius: 3px;
     -moz-border-radius: 3px;
     border-radius: 3px;
     padding: 15px 30px;
     margin-bottom: 30px;
     background-color: #FBFBFB;
     border: 1px solid #E3E3E3;
     color: #777
 }
 
 .plan .plan-title {
     margin: 0 0 15px 0
 }
 
 .plan .plan-title span {
     font-size: 15px;
     color: #afafaf
 }
 
 .plan .plan-title span i {
     margin-left: 10px;
     color: #7ED321
 }
 
 .plan.selected-plan {
     background-color: #F0FFDF;
     border: 1px solid #C3D7AE
 }
 
 .plan .list-plan-details {
     margin-bottom: 20px
 }
 
 .plan .list-plan-details li {
     margin-bottom: 5px
 }
 
 .payment-info .payment-name {
     margin-bottom: 12px;
     font-size: 20px
 }
 
 .payment-info .edit-payment-info {
     float: right;
     position: relative;
     top: -15px
 }
 
 .billing-history .billing-title {
     margin-top: 0;
     margin-bottom: 5px;
     font-size: 18px
 }
 
 .billing-history .billing-title span {
     margin-left: 10px;
     font-size: 15px
 }
 
 .billing-history .amount {
     font-size: 18px
 }
 
 .billing-history .action {
     text-align: right
 }
 
 .login-session {
     padding: 30px 0;
     position: relative;
     border-bottom: 1px solid #e8e8e8
 }
 
 .login-session .device-icon {
     font-size: 25px;
     float: left
 }
 
 .login-session .login-info {
     padding-left: 40px
 }
 
 .login-session .login-info .login-title {
     margin: 0 0 5px 0;
     font-size: 16px
 }
 
 .login-session .login-info .login-detail {
     color: #9A9A9A
 }
 
 .login-session .btn-logout {
     position: absolute;
     right: 0;
     top: 50%;
     padding: 0;
     margin-top: -11px
 }
 
 ul.list-login-session>li:last-child .login-session {
     border-bottom: none
 }
 
 ul.list-email-received>li {
     margin-bottom: 10px
 }
 
 .connected-app {
     padding: 10px 0;
     position: relative;
     border-bottom: 1px solid #e8e8e8
 }
 
 .connected-app .app-icon {
     font-size: 22px;
     float: left
 }
 
 .connected-app .connection-info {
     padding-left: 40px
 }
 
 .connected-app .connection-info .app-title {
     margin: 0 0 5px 0;
     font-size: 18px
 }
 
 .connected-app .connection-info .actions a {
     margin-right: 15px
 }
 
 ul.list-connected-app>li:first-child .connected-app {
     padding-top: 0
 }
 
 ul.list-connected-app>li:last-child .connected-app {
     border-bottom: none
 }
 
 .timeline-item {
     padding: 3em 2em 2em;
     position: relative;
     border-left: 1px solid var(--border-color)
 }
 
 .timeline-item:before {
     content: attr(date-is);
     position: absolute;
     left: 2em;
     top: 1em;
     color: var(--color-600)
 }
 
 .timeline-item:after {
     width: 15px;
     height: 15px;
     top: 1em;
     position: absolute;
     left: -8px;
     border-radius: 10px;
     content: '';
     background: #fff;
     border: 3px solid
 }
 
 .timeline-item.green:after {
     border-color: #86c541
 }
 
 .timeline-item.blue:after {
     border-color: #1d8ecd
 }
 
 .timeline-item.warning:after {
     border-color: #f5a81d
 }
 
 .timeline-item.red:after {
     border-color: #ee2558
 }
 
 .timeline-item.dark:after {
     border-color: var(--dark-color)
 }
 
 .timeline-item .msg {
     margin: 15px 0
 }
 
 .fc-overlay {
     display: none;
     position: absolute;
     min-width: 260px;
     padding: 15px 0;
     top: auto;
     left: 50%;
     margin-left: -150px;
     z-index: 1000;
     color: var(--color-800) !important
 }
 
 .fc-overlay.left {
     left: 100%;
     top: -15px;
     padding: 0 10px;
     margin: 0
 }
 
 .fc-overlay.right {
     left: auto;
     right: 100%;
     top: -15px;
     padding: 0 10px
 }
 
 .fc-overlay .panel {
     padding: 10px
 }
 
 .fc .fc-toolbar {
     color: var(--color-800);
     background: transparent;
     border-radius: 0
 }
 
 .fc .fc-toolbar h2,
 .fc .fc-toolbar .h2 {
     font-size: 16px;
     text-transform: uppercase
 }
 
 .fc .fc-toolbar .fc-today-button,
 .fc .fc-toolbar .fc-state-default {
     background: var(--color-800);
     color: var(--color-400);
     font-size: 14px;
     padding: 8px 18px;
     height: auto;
     border: none;
     box-shadow: none;
     text-shadow: none
 }
 
 .fc .fc-view-container .fc-event {
     padding: 3px 5px;
     font-size: 12px;
     border-radius: .2875rem;
     border: 0;
     color: var(--color-100)
 }
 
 .fc .fc-view-container .fc-event.fc-event-end:not(.fc-event-start) {
     border: 0 !important
 }
 
 .fc .fc-view-container .fc-event:focus,
 .fc .fc-view-container .fc-event:hover,
 .fc .fc-view-container .fc-event:active {
     z-index: 1000;
     color: #fff
 }
 
 .fc .fc-view-container .fc-view.fc-basic-view>table>thead tr th.fc-widget-header,
 .fc .fc-view-container .fc-view.fc-basic-view>table>thead tr td.fc-widget-header,
 .fc .fc-view-container .fc-view.fc-agenda>table>thead tr th.fc-widget-header,
 .fc .fc-view-container .fc-view.fc-agenda>table>thead tr td.fc-widget-header {
     border: 1px solid var(--border-color);
     padding: 10px;
     color: var(--color-700)
 }
 
 .fc .fc-view-container .fc-view.fc-basic-view>table tbody tr td.fc-widget-content,
 .fc .fc-view-container .fc-view.fc-agenda>table tbody tr td.fc-widget-content {
     border: 0;
     border-bottom: 1px solid var(--border-color);
     border-right: 1px solid var(--border-color)
 }
 
 .fc .fc-view-container .fc-view.fc-basic-view>table tbody tr td.fc-widget-content.fc-state-highlight,
 .fc .fc-view-container .fc-view.fc-agenda>table tbody tr td.fc-widget-content.fc-state-highlight {
     background: #fff
 }
 
 .fc .fc-view-container .fc-view.fc-basic-view>table tbody tr td.fc-day-number,
 .fc .fc-view-container .fc-view.fc-agenda>table tbody tr td.fc-day-number {
     padding: 5px 10px;
     margin: 5px;
     color: var(--color-800);
     font-size: 15px
 }
 
 .fc .fc-view-container .fc-view.fc-basic-view>table tbody tr td.fc-day-number.fc-state-highlight,
 .fc .fc-view-container .fc-view.fc-agenda>table tbody tr td.fc-day-number.fc-state-highlight {
     background-color: #ee2558;
     color: #fff;
     -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
     border-radius: 50%;
     padding: 4px 6px;
     float: right
 }
 
 .fc .fc-view-container .fc-view.fc-basic-view>table tbody tr td:first-child.fc-widget-content,
 .fc .fc-view-container .fc-view.fc-agenda>table tbody tr td:first-child.fc-widget-content {
     border-left: 1px solid var(--border-color)
 }
 
 .fc .fc-view-container .fc-view.fc-basic-view .fc-body .fc-row .fc-bg,
 .fc .fc-view-container .fc-view.fc-agenda .fc-body .fc-row .fc-bg {
     border-bottom: 1px solid var(--border-color)
 }
 
 .fc .fc-view-container .fc-view.fc-basic-view .fc-body .fc-row:last-of-type .fc-bg,
 .fc .fc-view-container .fc-view.fc-agenda .fc-body .fc-row:last-of-type .fc-bg {
     border-bottom: 0
 }
 
 .fc .fc-view-container .fc-view.fc-basic-view .fc-agenda-allday .fc-agenda-axis,
 .fc .fc-view-container .fc-view.fc-basic-view .fc-agenda-allday .fc-agenda-gutter,
 .fc .fc-view-container .fc-view.fc-agenda .fc-agenda-allday .fc-agenda-axis,
 .fc .fc-view-container .fc-view.fc-agenda .fc-agenda-allday .fc-agenda-gutter {
     border-left: 1px solid var(--border-color);
     border-right: 1px solid var(--border-color);
     background-color: var(--color-200);
     color: var(--color-800);
     font-size: 12px;
     font-weight: 100;
     padding: 10px 5px
 }
 
 .fc .fc-view-container .fc-view.fc-basic-view>table>tbody>tr>td.fc-state-highlight,
 .fc .fc-view-container .fc-view.fc-agenda>table>tbody>tr>td.fc-state-highlight {
     background: var(--color-200)
 }
 
 .fc .fc-view-container .fc-view.fc-basic-view>table>tbody>tr>td.fc-sat,
 .fc .fc-view-container .fc-view.fc-agenda>table>tbody>tr>td.fc-sat {
     border-right: 0
 }
 
 .fc .fc-view-container .fc-view.fc-basic-view .fc-agenda-slots tr,
 .fc .fc-view-container .fc-view.fc-agenda .fc-agenda-slots tr {
     line-height: 35px
 }
 
 .fc .fc-view-container .fc-view.fc-basic-view .fc-agenda-slots tr th,
 .fc .fc-view-container .fc-view.fc-agenda .fc-agenda-slots tr th {
     border: 0;
     border-left: 1px solid var(--border-color);
     border-right: 1px solid var(--border-color);
     background-color: var(--color-200);
     color: var(--color-800);
     font-size: 12px;
     font-weight: 100;
     padding-top: 10px
 }
 
 .fc .fc-view-container .fc-view.fc-basic-view .fc-agenda-slots tr td.fc-widget-content,
 .fc .fc-view-container .fc-view.fc-agenda .fc-agenda-slots tr td.fc-widget-content {
     border-bottom: 1px solid var(--border-color);
     line-height: 50px
 }
 
 .fc .fc-view-container .fc-view.fc-basic-view .fc-agenda-slots tr td.fc-widget-content:last-child,
 .fc .fc-view-container .fc-view.fc-agenda .fc-agenda-slots tr td.fc-widget-content:last-child {
     border-right: 0
 }
 
 .fc .fc-view-container .fc-view.fc-basic-view .fc-agenda-slots tr.fc-minor td.fc-widget-content,
 .fc .fc-view-container .fc-view.fc-agenda .fc-agenda-slots tr.fc-minor td.fc-widget-content {
     border-bottom: 1px solid var(--border-color)
 }
 
 .fc .fc-view-container .fc-view.fc-basic-view .fc-agenda-divider,
 .fc .fc-view-container .fc-view.fc-agenda .fc-agenda-divider {
     border: 1px solid var(--border-color)
 }
 
 .fc .fc-view-container .fc-view.fc-basic-view .fc-agenda-divider .fc-agenda-divider-inner,
 .fc .fc-view-container .fc-view.fc-agenda .fc-agenda-divider .fc-agenda-divider-inner {
     background-color: var(--color-200);
     height: 3px
 }
 
 .fc .fc-view-container .fc-list-view .fc-list-item {
     color: var(--color-200)
 }
 
 .fc .fc-view-container .fc-other-month {
     background: var(--color-100)
 }
 
 .fc .fc-view-container .fc-basic-view .fc-day-number,
 .fc .fc-view-container .fc-basic-view .fc-week-number {
     float: left;
     padding: 10px 0 5px 10px
 }
 
 .fc .fc-popover {
     box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
     border: none;
     padding: 10px;
     border-radius: .35rem
 }
 
 .fc .fc-popover .fc-event-container {
     padding: 0
 }
 
 .fc .fc-popover .fc-event {
     padding: 4px 8px;
     margin: 2px 0px
 }
 
 .fc .fc-agendaWeek-view .fc-head .fc-widget-header span,
 .fc .fc-agendaDay-view .fc-head .fc-widget-header span,
 .fc .fc-listWeek-view .fc-head .fc-widget-header span {
     padding: 10px;
     display: inline-block
 }
 
 .fc .fc-agendaWeek-view .fc-unthemed td,
 .fc .fc-agendaDay-view .fc-unthemed td,
 .fc .fc-listWeek-view .fc-unthemed td {
     border-color: #f4f7f6
 }
 
 @media only screen and (max-width: 420px) {
     .fc-toolbar .fc-right {
         display: none
     }
 
     .fc-toolbar .fc-left {
         width: 100%;
         margin-bottom: 15px
     }
 
     .fc .fc-toolbar .fc-today-button {
         float: right
     }
 }
 
 .file_manager .file {
     position: relative;
     border-radius: .35rem;
     overflow: hidden
 }
 
 .file_manager .file .image,
 .file_manager .file .icon {
     max-height: 180px;
     overflow: hidden;
     background-size: cover;
     background-position: top
 }
 
 .file_manager .file .hover {
     position: absolute;
     right: 10px;
     top: 10px;
     display: none;
     transition: all 0.2s ease-in-out
 }
 
 .file_manager .file a:hover .hover {
     transition: all 0.2s ease-in-out
 }
 
 .file_manager .file .icon {
     padding: 15px 10px;
     display: table;
     width: 100%
 }
 
 .file_manager .file .icon i {
     display: table-cell;
     font-size: 30px;
     vertical-align: middle;
     color: var(--color-600);
     line-height: 100px
 }
 
 .file_manager .file .file-name {
     padding: 10px;
     border-top: 1px solid var(--color-100)
 }
 
 .file_manager .file .file-name small .date,
 .file_manager .file .file-name .small .date {
     float: right
 }
 
 .folder {
     padding: 20px;
     display: block;
     color: var(--color-600)
 }
 
 @media only screen and (max-width: 992px) {
     .file_manager .nav-tabs {
         padding-left: 0;
         padding-right: 0
     }
 
     .file_manager .nav-tabs .nav-item {
         display: inline-block
     }
 }
 
 .pricing {
     color: var(--color-600)
 }
 
 .pricing li {
     list-style: none;
     padding: 13px
 }
 
 .pricing li:first-child,
 .pricing li:last-child {
     padding: 20px 13px
 }
 
 .pricing li:nth-last-child(2) {
     padding: 30px 13px
 }
 
 .pricing li+li {
     border-top: 1px solid var(--border-color)
 }
 
 .pricing big {
     font-size: 32px
 }
 
 .pricing h3,
 .pricing .h3 {
     font-size: 36px
 }
 
 .pricing span {
     color: var(--color-400);
     font-weight: normal
 }
 
 .pricing.active big,
 .pricing.active h3,
 .pricing.active .h3 {
     color: #1d8ecd
 }
 
 .pricing2 .pricing-plan {
     border-bottom: none;
     flex-basis: 100%;
     padding: 25px 0
 }
 
 .pricing2 .pricing-plan .pricing-img {
     max-width: 100%
 }
 
 .pricing2 .pricing-plan .pricing-header {
     color: var(--color-600);
     font-weight: 600;
     letter-spacing: 1px
 }
 
 .pricing2 .pricing-plan .pricing-features {
     letter-spacing: 1px;
     margin: 50px 0 25px;
     line-height: 30px;
     list-style: none
 }
 
 .pricing2 .pricing-plan .pricing-price {
     display: block;
     font-size: 32px;
     font-weight: 700
 }
 
 .pricing3 .pricing-option {
     padding: 20px
 }
 
 .pricing3 .pricing-option i {
     font-size: 60px
 }
 
 .pricing3 .pricing-option .price {
     font-size: 2rem;
     text-transform: uppercase;
     margin-top: 20px;
     margin-bottom: 10px;
     position: relative
 }
 
 .pricing3 .pricing-option .price b {
     position: absolute;
     font-size: 1rem;
     margin-left: 5px;
     font-weight: 600
 }
 
 .invoice1 .logo,
 .invoice1 .clientlogo {
     float: left;
     height: 70px;
     width: 70px
 }
 
 .invoice1 .info {
     float: left
 }
 
 .invoice1 .invoice-top .title {
     float: right
 }
 
 .invoice1 .invoice-mid .info {
     width: calc(100% - 90px)
 }
 
 .invoice .table tr td {
     vertical-align: middle
 }
 
 .number-chart .number {
     font-weight: 600
 }
 
 .number-chart .number>span {
     font-size: 22px;
     line-height: 1.4
 }
 
 .number-chart canvas {
     width: 100% !important
 }
 
 .appliances-grp .body {
     clear: both;
     overflow: hidden;
     min-height: 115px
 }
 
 .appliances-grp .icon {
     float: left;
     width: 40px;
     margin-right: 15px
 }
 
 .appliances-grp .icon img {
     max-width: 100%
 }
 
 .appliances-grp .content {
     float: right;
     width: calc(100% - 60px)
 }
 
 .appliances-grp .content h6,
 .appliances-grp .content .h6 {
     font-size: 15px;
     line-height: 20px;
     font-weight: 500
 }
 
 .appliances-grp .content h6 span,
 .appliances-grp .content .h6 span {
     float: right;
     font-size: 14px;
     line-height: 20px;
     font-weight: 400
 }
 
 .appliances-grp .content p {
     font-size: 13px;
     line-height: 20px;
     font-weight: 400;
     margin-bottom: 5px
 }
 
 .appliances-grp .content p:last-child {
     margin-bottom: 0
 }
 
 .appliances-grp .content p span {
     float: right
 }
 
 .basic-list {
     margin-bottom: 0
 }
 
 .basic-list li {
     padding-top: 15px;
     padding-bottom: 15px;
     display: block
 }
 
 .basic-list li:first-child {
     padding-top: 0
 }
 
 .basic-list li span {
     float: right
 }
 
 .badge {
     padding: 4px 8px;
     text-transform: uppercase;
     line-height: 12px;
     border: 1px solid;
     font-weight: 400
 }
 
 .chat-app .people-list {
     width: 280px;
     position: absolute;
     left: 0;
     top: 0;
     padding: 20px;
     z-index: 7
 }
 
 .chat-app .chat {
     margin-left: 280px;
     border-left: 1px solid var(--border-color)
 }
 
 .people-list {
     -moz-transition: .5s;
     -o-transition: .5s;
     -webkit-transition: .5s;
     transition: .5s
 }
 
 .people-list .chat-list li {
     padding: 10px 15px;
     list-style: none;
     border-radius: 3px
 }
 
 .people-list .chat-list li:hover {
     background: var(--color-300);
     cursor: pointer
 }
 
 .people-list .chat-list li.active {
     background: var(--color-300)
 }
 
 .people-list .chat-list li .name {
     font-size: 15px
 }
 
 .people-list .chat-list img {
     width: 45px;
     border-radius: 50%
 }
 
 .people-list img {
     float: left;
     border-radius: 50%
 }
 
 .people-list .about {
     float: left;
     padding-left: 8px
 }
 
 .people-list .status {
     color: #999;
     font-size: 13px
 }
 
 .chat .chat-header {
     padding: 15px 20px;
     border-bottom: 2px solid var(--border-color)
 }
 
 .chat .chat-header img {
     float: left;
     border-radius: 40px;
     width: 40px
 }
 
 .chat .chat-header .chat-about {
     float: left;
     padding-left: 10px
 }
 
 .chat .chat-history {
     padding: 20px;
     border-bottom: 2px solid var(--white-color)
 }
 
 .chat .chat-history ul {
     padding: 0
 }
 
 .chat .chat-history ul li {
     list-style: none;
     margin-bottom: 30px
 }
 
 .chat .chat-history ul li:last-child {
     margin-bottom: 0px
 }
 
 .chat .chat-history .message-data {
     margin-bottom: 15px
 }
 
 .chat .chat-history .message-data img {
     border-radius: 40px;
     width: 40px
 }
 
 .chat .chat-history .message-data-time {
     color: #434651;
     padding-left: 6px
 }
 
 .chat .chat-history .message {
     color: #444;
     padding: 18px 20px;
     line-height: 26px;
     font-size: 16px;
     border-radius: 7px;
     display: inline-block;
     position: relative
 }
 
 .chat .chat-history .message:after {
     bottom: 100%;
     left: 7%;
     border: solid transparent;
     content: " ";
     height: 0;
     width: 0;
     position: absolute;
     pointer-events: none;
     border-bottom-color: var(--white-color);
     border-width: 10px;
     margin-left: -10px
 }
 
 .chat .chat-history .my-message {
     background: var(--color-300)
 }
 
 .chat .chat-history .my-message:after {
     bottom: 100%;
     left: 30px;
     border: solid transparent;
     content: " ";
     height: 0;
     width: 0;
     position: absolute;
     pointer-events: none;
     border-bottom-color: var(--color-300);
     border-width: 10px;
     margin-left: -10px
 }
 
 .chat .chat-history .other-message {
     background: var(--border-color);
     text-align: right
 }
 
 .chat .chat-history .other-message:after {
     border-bottom-color: var(--border-color);
     left: 93%
 }
 
 .chat .chat-message {
     padding: 20px
 }
 
 .online,
 .offline,
 .me {
     margin-right: 2px;
     font-size: 8px;
     vertical-align: middle
 }
 
 .online {
     color: #86c541
 }
 
 .offline {
     color: #e47297
 }
 
 .me {
     color: #1d8ecd
 }
 
 .float-right {
     float: right
 }
 
 .clearfix:after {
     visibility: hidden;
     display: block;
     font-size: 0;
     content: " ";
     clear: both;
     height: 0
 }
 
 @media only screen and (max-width: 767px) {
     .chat-app .people-list {
         height: 465px;
         width: 100%;
         overflow-x: auto;
         background: var(--white-color);
         left: -400px;
         display: none
     }
 
     .chat-app .people-list.open {
         left: 0
     }
 
     .chat-app .chat {
         margin: 0
     }
 
     .chat-app .chat .chat-header {
         border-radius: 0.55rem 0.55rem 0 0
     }
 
     .chat-app .chat-history {
         height: 300px;
         overflow-x: auto
     }
 }
 
 @media only screen and (min-width: 768px) and (max-width: 992px) {
     .chat-app .chat-list {
         height: 650px;
         overflow-x: auto
     }
 
     .chat-app .chat-history {
         height: 600px;
         overflow-x: auto
     }
 }
 
 @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
     .chat-app .chat-list {
         height: 480px;
         overflow-x: auto
     }
 
     .chat-app .chat-history {
         height: calc(100vh - 350px);
         overflow-x: auto
     }
 }
 
 .single_post {
     -webkit-transition: all .4s ease;
     transition: all .4s ease
 }
 
 .single_post .body {
     padding: 30px
 }
 
 .single_post .img-post {
     position: relative;
     overflow: hidden;
     max-height: 500px;
     margin-bottom: 30px
 }
 
 .single_post .img-post>img {
     -webkit-transform: scale(1);
     -ms-transform: scale(1);
     transform: scale(1);
     opacity: 1;
     -webkit-transition: -webkit-transform .4s ease, opacity .4s ease;
     transition: transform .4s ease, opacity .4s ease;
     max-width: 100%;
     filter: none;
     -webkit-filter: grayscale(0);
     -webkit-transform: scale(1.01)
 }
 
 .single_post .img-post:hover img {
     -webkit-transform: scale(1.02);
     -ms-transform: scale(1.02);
     transform: scale(1.02);
     opacity: .7;
     filter: gray;
     -webkit-filter: grayscale(1);
     -webkit-transition: all .8s ease-in-out
 }
 
 .single_post .img-post:hover .social_share {
     display: block
 }
 
 .single_post .footer {
     padding: 0 30px 30px 30px
 }
 
 .single_post .footer .actions {
     display: inline-block
 }
 
 .single_post .footer .stats {
     cursor: default;
     list-style: none;
     padding: 0;
     display: inline-block;
     float: right;
     margin: 0;
     line-height: 35px
 }
 
 .single_post .footer .stats li {
     border-left: solid 1px rgba(160, 160, 160, 0.3);
     display: inline-block;
     font-weight: 400;
     letter-spacing: 0.25em;
     line-height: 1;
     margin: 0 0 0 2em;
     padding: 0 0 0 2em;
     text-transform: uppercase;
     font-size: 13px
 }
 
 .single_post .footer .stats li a {
     color: #777
 }
 
 .single_post .footer .stats li:first-child {
     border-left: 0;
     margin-left: 0;
     padding-left: 0
 }
 
 .single_post h3,
 .single_post .h3 {
     font-size: 20px;
     text-transform: uppercase
 }
 
 .single_post h3 a,
 .single_post .h3 a {
     color: #242424;
     text-decoration: none
 }
 
 .single_post p {
     font-size: 16px;
     line-height: 26px;
     font-weight: 300;
     margin: 0
 }
 
 .single_post .blockquote p {
     margin-top: 0 !important
 }
 
 .single_post .meta {
     list-style: none;
     padding: 0;
     margin: 0
 }
 
 .single_post .meta li {
     display: inline-block;
     margin-right: 15px
 }
 
 .single_post .meta li a {
     font-style: italic;
     color: #959595;
     text-decoration: none;
     font-size: 12px
 }
 
 .single_post .meta li a i {
     margin-right: 6px;
     font-size: 12px
 }
 
 .single_post2 {
     overflow: hidden
 }
 
 .single_post2 .content {
     margin-top: 15px;
     margin-bottom: 15px;
     padding-left: 80px;
     position: relative
 }
 
 .single_post2 .content .actions_sidebar {
     position: absolute;
     top: 0px;
     left: 0px;
     width: 60px
 }
 
 .single_post2 .content .actions_sidebar a {
     display: inline-block;
     width: 100%;
     height: 60px;
     line-height: 60px;
     margin-right: 0;
     text-align: center;
     border-right: 1px solid #e4eaec
 }
 
 .single_post2 .content .title {
     font-weight: 100
 }
 
 .single_post2 .content .text {
     font-size: 15px
 }
 
 .right-box .categories-clouds li {
     display: inline-block;
     margin-bottom: 5px
 }
 
 .right-box .categories-clouds li a {
     display: block;
     border: 1px solid;
     padding: 6px 10px;
     border-radius: 3px
 }
 
 .right-box .instagram-plugin {
     overflow: hidden
 }
 
 .right-box .instagram-plugin li {
     float: left;
     overflow: hidden;
     border: 1px solid var(--white-color)
 }
 
 .comment-reply li {
     margin-bottom: 15px
 }
 
 .comment-reply li:last-child {
     margin-bottom: none
 }
 
 .comment-reply li h5,
 .comment-reply li .h5 {
     font-size: 18px
 }
 
 .comment-reply li p {
     margin-bottom: 0px;
     font-size: 15px;
     color: #777
 }
 
 .comment-reply .list-inline li {
     display: inline-block;
     margin: 0;
     padding-right: 20px
 }
 
 .comment-reply .list-inline li a {
     font-size: 13px
 }
 
 @media (max-width: 640px) {
     .blog-page .left-box .single-comment-box>ul>li {
         padding: 25px 0
     }
 
     .blog-page .left-box .single-comment-box ul li .icon-box {
         display: inline-block
     }
 
     .blog-page .left-box .single-comment-box ul li .text-box {
         display: block;
         padding-left: 0;
         margin-top: 10px
     }
 
     .blog-page .single_post .footer .stats {
         float: none;
         margin-top: 10px
     }
 
     .blog-page .single_post .body,
     .blog-page .single_post .footer {
         padding: 30px
     }
 }
 
 .c_review {
     margin-bottom: 0
 }
 
 .c_review li {
     margin-bottom: 16px;
     padding-bottom: 13px;
     border-bottom: 1px solid var(--border-color)
 }
 
 .c_review li:last-child {
     margin: 0;
     border: none
 }
 
 .c_review .avatar {
     float: left;
     width: 80px
 }
 
 .c_review .comment-action {
     float: left;
     width: calc(100% - 80px)
 }
 
 .c_review .comment-action .c_name {
     margin: 0
 }
 
 .c_review .comment-action p {
     text-overflow: ellipsis;
     white-space: nowrap;
     overflow: hidden;
     max-width: 95%;
     display: block
 }
 
 .product_item:hover .cp_img {
     top: -40px
 }
 
 .product_item:hover .cp_img img {
     box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22)
 }
 
 .product_item:hover .cp_img .hover {
     display: block
 }
 
 .product_item .cp_img {
     position: absolute;
     top: 0px;
     left: 50%;
     transform: translate(-50%);
     -webkit-transform: translate(-50%);
     -ms-transform: translate(-50%);
     -moz-transform: translate(-50%);
     -o-transform: translate(-50%);
     -khtml-transform: translate(-50%);
     width: 100%;
     padding: 15px;
     transition: all 0.2s ease-in-out
 }
 
 .product_item .cp_img img {
     transition: all 0.2s ease-in-out;
     border-radius: 6px
 }
 
 .product_item .cp_img .hover {
     display: none;
     text-align: center;
     margin-top: 10px
 }
 
 .product_item .product_details {
     padding-top: 110%;
     text-align: center
 }
 
 .product_item .product_details h5,
 .product_item .product_details .h5 {
     margin-bottom: 5px
 }
 
 .product_item .product_details h5 a,
 .product_item .product_details .h5 a {
     font-size: 16px;
     color: var(--text-color)
 }
 
 .product_item .product_details h5 a:hover,
 .product_item .product_details .h5 a:hover {
     text-decoration: none
 }
 
 .product_item .product_details .product_price {
     margin: 0
 }
 
 .product_item .product_details .product_price li {
     display: inline-block;
     padding: 0 10px
 }
 
 .product_item .product_details .product_price .new_price {
     font-weight: 600;
     color: #ff4136
 }
 
 .product_item_list table tr td {
     vertical-align: middle
 }
 
 .product_item_list table tr td h5,
 .product_item_list table tr td .h5 {
     font-size: 15px;
     margin: 0
 }
 
 .product_item_list table tr td .btn {
     box-shadow: none !important
 }
 
 .product-order-list table tr th:last-child {
     width: 145px
 }
 
 .preview .preview-pic {
     padding: 0
 }
 
 .preview .preview-thumbnail {
     border: 0;
     margin-top: 15px
 }
 
 .preview .preview-thumbnail li {
     width: 20%
 }
 
 .preview .preview-thumbnail li a {
     padding: 2px;
     border: 0
 }
 
 .details .rating .stars {
     display: inline-block
 }
 
 .details .sizes .size {
     margin-right: 10px
 }
 
 .details .sizes .size:first-of-type {
     margin-left: 40px
 }
 
 .details .colors .color {
     display: inline-block;
     vertical-align: middle;
     margin-right: 10px;
     height: 25px;
     width: 25px;
     border-radius: 2px
 }
 
 .details .colors .color:first-of-type {
     margin-left: 20px
 }
 
 .details .colors .not-available {
     text-align: center;
     line-height: 25px
 }
 
 .details .colors .not-available:before {
     font-family: Material-Design-Iconic-Font;
     content: "\f136";
     color: var(--white-color)
 }
 
 @media screen and (max-width: 996px) {
     .preview {
         margin-bottom: 20px
     }
 }
 
 @-webkit-keyframes opacity {
     0% {
         opacity: 0;
         -webkit-transform: scale(3);
         transform: scale(3)
     }
 
     100% {
         opacity: 1;
         -webkit-transform: scale(1);
         transform: scale(1)
     }
 }
 
 @keyframes opacity {
     0% {
         opacity: 0;
         -webkit-transform: scale(3);
         transform: scale(3)
     }
 
     100% {
         opacity: 1;
         -webkit-transform: scale(1);
         transform: scale(1)
     }
 }
 
 .cart-page .cart-table tr th:last-child {
     width: 145px
 }
 
 .cart-table .quantity-grp {
     width: 120px
 }
 
 .cart-table .quantity-grp .input-group {
     margin-bottom: 0
 }
 
 .cart-table .quantity-grp .input-group-addon {
     padding: 0 !important;
     text-align: center;
     background-color: #1ab1e3
 }
 
 .cart-table .quantity-grp .input-group-addon a {
     display: block;
     padding: 8px 10px 10px;
     color: var(--white-color)
 }
 
 .cart-table .quantity-grp .input-group-addon a i {
     vertical-align: middle
 }
 
 .cart-table .quantity-grp .form-control {
     background-color: var(--white-color)
 }
 
 .cart-table .quantity-grp .form-control+.input-group-addon {
     background-color: #1ab1e3
 }
 
 .ec-checkout .wizard .content {
     min-height: auto
 }
 
 .ec-checkout .wizard .content .form-group .btn-group.bootstrap-select.form-control {
     padding: 0
 }
 
 .ec-checkout .wizard .content .form-group .btn-group.bootstrap-select.form-control .btn-round.btn-simple {
     padding-top: 12px;
     padding-bottom: 12px
 }
 
 .ec-checkout .wizard .content ul.card-type {
     font-size: 0
 }
 
 .ec-checkout .wizard .content ul.card-type li {
     display: inline-block;
     margin-right: 10px
 }
 
 .mail-inbox {
     display: flex
 }
 
 .mail-inbox .mail-left {
     width: 280px;
     padding: 15px;
     border-right: 1px solid var(--border-color);
     display: block
 }
 
 .mail-inbox .mail-left .mail-side .nav {
     flex-direction: column
 }
 
 .mail-inbox .mail-left .mail-side .nav li {
     padding: 0 10px;
     margin-bottom: 5px;
     line-height: 40px
 }
 
 .mail-inbox .mail-left .mail-side .nav li a {
     color: #666666;
     display: flex;
     align-items: center
 }
 
 .mail-inbox .mail-left .mail-side .nav li a i {
     font-size: 17px;
     width: 35px;
     -webkit-transition: font-size 0.2s;
     -moz-transition: font-size 0.2s;
     transition: font-size 0.2s
 }
 
 .mail-inbox .mail-left .mail-side .nav li a .badge {
     margin-left: auto;
     margin-right: 0
 }
 
 .mail-inbox .mail-left .mail-side .nav li:hover {
     background-color: #f4f7f6
 }
 
 .mail-inbox .mail-left .mail-side .nav li:hover a i {
     font-size: 20px
 }
 
 .mail-inbox .mail-left .mail-side .nav li.active {
     background-color: #f4f7f6
 }
 
 .mail-inbox .mail-left .mail-side .nav li.active a {
     color: var(--dark-color)
 }
 
 .mail-inbox .mail-left .mail-side h3,
 .mail-inbox .mail-left .mail-side .h3 {
     font-size: 15px;
     font-weight: 500;
     margin-bottom: 15px;
     margin-top: 30px;
     line-height: 20px
 }
 
 .mail-inbox .mail-right {
     width: calc(100% - 280px);
     position: relative
 }
 
 .mail-inbox .mail-right .header {
     padding: 15px
 }
 
 .mail-inbox .mail-right h2,
 .mail-inbox .mail-right .h2 {
     line-height: 35px
 }
 
 .mail-inbox .mail-right .mail-action {
     padding: 15px
 }
 
 .mail-inbox .mail-right .mail-action .fancy-checkbox label {
     margin-bottom: 0
 }
 
 .mail-inbox .mail-right .mail-action .fancy-checkbox input[type="checkbox"]+span:before {
     bottom: 0
 }
 
 .mail-inbox .mail-right .mail-action .btn {
     border-color: #cccccc;
     padding: 5px 15px
 }
 
 .mail-inbox .mail-right .mail-action .pagination-email p {
     line-height: 30px;
     margin-bottom: 0
 }
 
 .mail-inbox .mail-right .mail-list {
     padding: 15px 0
 }
 
 .mail-inbox .mail-right .mail-list ul li {
     padding: 17px 15px 15px;
     border-top: 1px solid var(--border-color);
     width: 100%;
     position: relative
 }
 
 .mail-inbox .mail-right .mail-list ul li:last-child {
     border-bottom: 1px solid var(--border-color)
 }
 
 .mail-inbox .mail-right .mail-list ul li .hover-action {
     position: absolute;
     opacity: 0;
     top: 0;
     right: 0;
     padding: 23px 16px 23px;
     background: var(--card-color);
     transition: all 0.5s ease-in-out
 }
 
 .mail-inbox .mail-right .mail-list ul li:hover .hover-action {
     opacity: 1
 }
 
 .mail-inbox .mail-right .mail-list ul li:hover .mail-detail-expand {
     color: #007bff
 }
 
 .mail-inbox .mail-right .mail-list ul li.unread {
     background-color: var(--card-color)
 }
 
 .mail-inbox .mail-right .mail-list ul li.unread .hover-action {
     background-color: var(--card-color)
 }
 
 .mail-inbox .mail-right .mail-list ul li .mail-detail-left,
 .mail-inbox .mail-right .mail-list ul li .mail-detail-right {
     float: left
 }
 
 .mail-inbox .mail-right .mail-list ul li .mail-detail-left {
     max-width: 60px;
     min-width: 60px;
     width: 60px;
     position: relative
 }
 
 .mail-inbox .mail-right .mail-list ul li .mail-detail-left .mail-star {
     position: absolute;
     right: 13px;
     top: 0;
     color: #6c757d
 }
 
 .mail-inbox .mail-right .mail-list ul li .mail-detail-left .mail-star.active {
     color: #ffc107
 }
 
 .mail-inbox .mail-right .mail-list ul li .mail-detail-right {
     position: relative;
     padding-right: 70px;
     width: calc(100% - 70px)
 }
 
 .mail-inbox .mail-right .mail-list ul li .mail-detail-right span.time {
     position: absolute;
     top: 0;
     right: 0
 }
 
 .mail-inbox .mail-right .mail-list ul li .mail-detail-right h6,
 .mail-inbox .mail-right .mail-list ul li .mail-detail-right .h6,
 .mail-inbox .mail-right .mail-list ul li .mail-detail-right p {
     width: 100%;
     display: block;
     white-space: nowrap;
     text-overflow: ellipsis;
     overflow: hidden
 }
 
 .mail-inbox .mail-right .mail-list ul li .mail-detail-right h6 a,
 .mail-inbox .mail-right .mail-list ul li .mail-detail-right .h6 a {
     color: #5a5a5a
 }
 
 .mail-inbox .mail-right .mail-list ul li .mail-detail-right p {
     margin-bottom: 0
 }
 
 .mail-inbox .mail-right .mail-detail-full {
     position: absolute;
     width: 100%;
     height: 100%;
     top: 0;
     left: 0;
     background: var(--white-color)
 }
 
 .mail-inbox .mail-right .mail-detail-full .mail-action {
     padding: 15px
 }
 
 .mail-inbox .mail-right .mail-detail-full .detail-header {
     padding: 20px 15px 0;
     border-top: 1px solid var(--border-color);
     border-bottom: 1px solid var(--border-color)
 }
 
 .mail-inbox .mail-right .mail-detail-full .detail-header .float-left img {
     max-width: 63px;
     border-radius: 5px
 }
 
 .mail-inbox .mail-right .mail-detail-full .media-body p a {
     color: #6c757d
 }
 
 .mail-inbox .mail-right .mail-detail-full .mail-cnt {
     padding: 20px 15px
 }
 
 .mobile-left {
     display: none
 }
 
 @media screen and (max-width: 991px) {
     .mail-inbox .mail-left {
         width: 230px
     }
 
     .mail-inbox .mail-right {
         width: calc(100% - 230px)
     }
 
     .mail-inbox .mail-right .mail-action .btn {
         padding-left: 10px;
         padding-right: 10px
     }
 
     .mail-inbox .mail-right .mail-action .pagination-email p {
         display: none
     }
 }
 
 @media screen and (max-width: 767px) {
     .mobile-left {
         display: block;
         padding: 15px 15px 0;
         position: absolute;
         z-index: 9999;
         right: 0
     }
 
     .mail-inbox .mail-left {
         position: absolute;
         left: 0;
         background-color: var(--white-color);
         z-index: 99
     }
 
     .mail-inbox .mail-left.collapse:not(.show) {
         display: none
     }
 
     .mail-inbox .mail-left.collapse:not(.show)+.mail-right {
         width: 100%
     }
 
     .mail-inbox .mail-right {
         width: 100%
     }
 
     .mail-inbox .mail-right .header {
         flex-direction: column
     }
 
     .mail-inbox .mail-right .header .ml-auto {
         margin-left: 0 !important;
         margin-top: 15px
     }
 
     .btn-group {
         margin-bottom: 5px
     }
 
     .mail-detail-full a.mail-back {
         top: -60px
     }
 
     .media-body p span,
     .media-body p small,
     .media-body p .small {
         display: none
     }
 }
 
 .rtl {
     direction: rtl
 }
 
 .rtl ul {
     padding: 0
 }
 
 .rtl #left-sidebar {
     right: 5px;
     left: auto
 }
 
 .rtl.layout-fullwidth #wrapper #left-sidebar {
     right: -250px
 }
 
 .rtl .btn-toggle-fullwidth {
     padding: 0
 }
 
 .rtl #main-content {
     float: left
 }
 
 .rtl .card .header .header-dropdown {
     left: 10px;
     right: auto
 }
 
 .rtl .card .header .header-dropdown li .dropdown-menu {
     right: auto !important;
     left: 0 !important
 }
 
 .rtl .fancy-checkbox {
     margin-left: 10px;
     margin-right: 0px
 }
 
 .rtl .fancy-checkbox input[type="checkbox"]+span:before {
     margin-right: 0px;
     margin-left: 10px
 }
 
 .rtl .fancy-radio input[type="radio"]+span i {
     margin-right: 0px;
     margin-left: 5px
 }
 
 .rtl .fancy-radio input[type="radio"]+span i::after {
     right: 3px;
     left: auto
 }
 
 .rtl .multiselect-custom+.btn-group ul.multiselect-container>li>a label.checkbox:before {
     margin-right: 0;
     margin-left: 10px
 }
 
 .rtl .multiselect-container {
     right: 0 !important;
     left: auto !important
 }
 
 .rtl .multiselect-container>li>a>label {
     padding: 3px 20px
 }
 
 .rtl .dropdown-lg .dropdown-menu {
     left: 0 !important;
     right: auto !important
 }
 
 .rtl #navbar-search {
     float: right;
     margin-right: 0;
     margin-left: 20px
 }
 
 .rtl #navbar-search .btn {
     left: 15px;
     right: auto
 }
 
 .rtl #navbar-menu {
     float: left
 }
 
 .rtl .navbar-nav .dropdown-menu {
     right: auto;
     left: 0
 }
 
 .rtl .navbar-nav ul.user-menu>li.menu-heading {
     padding: 25px 0px 5px 20px
 }
 
 .rtl .navbar-nav ul.user-menu>li:first-child {
     padding-top: 0px
 }
 
 .rtl .navbar-nav ul.user-menu.menu-icon>li>a i {
     float: right;
     line-height: 30px
 }
 
 .rtl #left-sidebar .user-account {
     text-align: right
 }
 
 .rtl #left-sidebar .user-account .user-photo {
     margin-right: 0px;
     margin-left: 10px
 }
 
 .rtl #left-sidebar .user-account .dropdown {
     text-align: right
 }
 
 .rtl #left-sidebar .user-account .dropdown a i {
     margin-left: 10px;
     margin-right: 0px;
     float: right;
     line-height: 21px
 }
 
 .rtl #left-sidebar .question li a {
     padding-right: 15px;
     padding-left: 0px
 }
 
 .rtl #left-sidebar .question li a::before {
     left: auto;
     right: 0
 }
 
 .rtl .sidebar-nav .metismenu .has-arrow:after {
     left: 1em;
     right: auto
 }
 
 .rtl .sidebar-nav .metismenu ul a {
     padding: 10px 57px 10px 15px
 }
 
 .rtl .sidebar-nav .metismenu ul a::before {
     right: 19px;
     left: auto
 }
 
 .rtl .sidebar-nav .metismenu>li a {
     border-left: 0;
     border-right: 5px solid transparent
 }
 
 .rtl .sidebar-nav .metismenu>li i {
     float: right;
     margin-right: 0px;
     margin-left: 20px
 }
 
 .rtl ul.choose-skin li span {
     margin-left: 0px;
     margin-right: 5px
 }
 
 .rtl .auth-main:before {
     right: 0;
     left: auto
 }
 
 .rtl .auth-box {
     margin-right: 130px;
     margin-left: 0px
 }
 
 .rtl .auth-box .form-auth-small .element-left {
     float: right
 }
 
 .rtl .right_chat .media .media-object {
     margin-right: 0px;
     margin-left: 10px
 }
 
 .rtl .right_chat .media .status {
     left: auto;
     right: 23px
 }
 
 .rtl .feeds_widget li .feeds-left {
     float: right
 }
 
 .rtl .list-widget .badge {
     float: left
 }
 
 .rtl .timeline-item {
     border-right: 1px solid #eee;
     border-left: 0
 }
 
 .rtl .timeline-item::before {
     right: 2em;
     left: auto
 }
 
 .rtl .timeline-item:after {
     right: -8px;
     left: auto
 }
 
 .rtl .list-referrals .value {
     margin-right: 0
 }
 
 .rtl .top_counter .icon {
     float: right;
     margin-right: 0px;
     margin-left: 10px
 }
 
 .rtl .chat-widget li.left img {
     float: right
 }
 
 .rtl .chat-widget li.right img {
     float: left
 }
 
 .rtl .basic-list li span {
     float: left
 }
 
 .rtl .new_timeline>ul {
     padding: 1em 2em 0 0
 }
 
 .rtl .new_timeline>ul:before {
     right: 2.5em;
     left: auto
 }
 
 .rtl .dt-bootstrap4 .dt-buttons+.dataTables_filter,
 .rtl .dt-bootstrap4 .dt-buttons+.dataTables_paginate,
 .rtl .dt-bootstrap4 .dataTables_info+.dataTables_filter,
 .rtl .dt-bootstrap4 .dataTables_info+.dataTables_paginate {
     float: left
 }
 
 .rtl div.dataTables_wrapper div.dataTables_filter {
     text-align: left
 }
 
 .rtl div.dataTables_wrapper div.dataTables_filter input {
     margin-left: 0;
     margin-right: 0.5em
 }
 
 .rtl .wizard>.steps>ul>li {
     float: right
 }
 
 .rtl .testimonial2 .testimonial-desc img {
     float: left
 }
 
 .rtl .invoice1 .info,
 .rtl .invoice1 .logo,
 .rtl .invoice1 .clientlogo {
     float: right
 }
 
 .rtl .invoice1 .invoice-top .title {
     text-align: left;
     float: left
 }
 
 .rtl .payment-info .edit-payment-info {
     float: left
 }
 
 .rtl .login-session .device-icon {
     float: right
 }
 
 .rtl .login-session .login-info {
     padding-right: 40px
 }
 
 .rtl .connected-app .app-icon {
     float: right
 }
 
 .rtl .connected-app .connection-info {
     padding-right: 40px
 }
 
 .rtl .connected-app .connection-info .actions a {
     margin-right: 0;
     margin-left: 15px
 }
 
 .rtl .c_review .avatar {
     float: right
 }
 
 .rtl .weather3 .bottom .forecast li .condition {
     float: left
 }
 
 .rtl .weather3 .bottom .forecast li .condition.wi-day-hail:before {
     display: none
 }
 
 .rtl .weather3 .bottom .forecast li .condition.wi-day-hail::after {
     content: "\f004"
 }
 
 .rtl .weather .city i {
     left: 0;
     right: auto
 }
 
 .rtl .single_post2 .content {
     padding-left: 0;
     padding-right: 80px
 }
 
 .rtl .single_post2 .content .actions_sidebar {
     left: auto;
     right: 0
 }
 
 .rtl .single_post2 .content .actions_sidebar a {
     border-right: none;
     border-left: 1px solid #e4eaec
 }
 
 .rtl pre.prettyprint {
     text-align: right
 }
 
 .rtl .dd-list .dd-list {
     padding-left: 0;
     padding-right: 30px
 }
 
 .rtl .chat-app .people-list {
     left: auto;
     right: 0
 }
 
 .rtl .chat-app .chat {
     margin-left: 0;
     margin-right: 280px;
     border-left: 0;
     border-right: 1px solid #eaeaea
 }
 
 .rtl #calendar .btn-group>.btn-group:not(:last-child)>.btn {
     border-top-left-radius: .25rem;
     border-bottom-left-radius: .25rem;
     border-top-right-radius: 0;
     border-bottom-right-radius: 0
 }
 
 .rtl #calendar .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
     border-top-left-radius: .25rem;
     border-bottom-left-radius: .25rem;
     border-top-right-radius: 0;
     border-bottom-right-radius: 0
 }
 
 .rtl #calendar .btn-group>.btn-group:not(:first-child)>.btn {
     border-top-right-radius: .25rem;
     border-bottom-right-radius: .25rem;
     border-top-left-radius: 0;
     border-bottom-left-radius: 0
 }
 
 .rtl #calendar .btn-group>.btn:not(:first-child) {
     border-top-right-radius: .25rem;
     border-bottom-right-radius: .25rem;
     border-top-left-radius: 0;
     border-bottom-left-radius: 0
 }
 
 .rtl .mail-inbox .mail-left .mail-side .nav li a .badge {
     margin-left: 0;
     margin-right: auto
 }
 
 .rtl .mail-inbox .mail-right .mail-list ul li .mail-detail-left {
     float: right
 }
 
 .rtl .mail-inbox .mail-right .mail-list ul li .mail-detail-left .mail-star {
     right: 38px
 }
 
 .rtl .mail-inbox .mail-right .mail-list ul li .mail-detail-right {
     padding-right: 0;
     padding-left: 70px
 }
 
 .rtl .mail-inbox .mail-right .mail-list ul li .mail-detail-right span.time {
     left: 0;
     right: auto
 }
 
 .rtl .mail-inbox .mail-right .mail-list ul li .hover-action {
     right: auto;
     left: 0
 }
 
 .rtl .people-list img {
     float: right
 }
 
 .rtl .people-list .about {
     float: right;
     padding-left: 0;
     padding-right: 8px
 }
 
 @media screen and (max-width: 1200px) {
     .rtl #left-sidebar {
         right: -250px;
         left: auto
     }
 
     .rtl.layout-fullwidth #wrapper #left-sidebar {
         right: 5px
     }
 
     .rtl.offcanvas-active #left-sidebar {
         right: 5px
     }
 }
 
 @media screen and (max-width: 992px) {
     .rtl .auth-box {
         margin: 0 auto
     }
 }
 
 @media screen and (max-width: 767px) {
 
     .rtl div.dataTables_wrapper div.dataTables_length,
     .rtl div.dataTables_wrapper div.dataTables_filter,
     .rtl div.dataTables_wrapper div.dataTables_info,
     .rtl div.dataTables_wrapper div.dataTables_paginate {
         text-align: center
     }
 }